import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import axios from "axios";
import * as config from "../config";
import {createPostFromFirestoreDocument, createPostFromHttps, Post} from "../models/post";
import LikeType from "../models/live_type";
import PostCache from "../caches/post_cache";
import UserCache from "../caches/user_cache";

const apiUrl = config.apiURL + '/posts';

class FirebasePostController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }

    /**
     * @param {string} pid
     */
    getPostByPid = (pid) => {
        let post = undefined;
        if (!!pid) {
            post = PostCache.getPostByPidIfValid(pid);
            if (!post) {
                this.loadPostByPid(pid);
                post = PostCache.getPostByPid(pid);
            }
        }
        return post;
    };

    /**
     * @param {string} pid
     */
    loadPostByPid = async (pid) => {
        let post = undefined;
        if (!!pid) {
            post = PostCache.getPostByPidIfValid(pid);
            if (!post) {
                let postDoc = await this.app.firestore().collection('posts').doc(pid).get();
                post = createPostFromFirestoreDocument(postDoc);
                if (!!post) {
                    PostCache.updateCacheEntry(post);
                    await this.updatePostCountForPost(post.pid);
                    this.getUserNameForPost(post);
                }
            }
        }
        return post;
    };

    getUserNameForPost= async (post) => {
        if (!UserCache.getUserNameForUid(post.uid)) {
            let ref = this.app.database().ref().child('users/' + post.uid + '/userinfo/name');
            let name = (await ref.once('value')).value;
            if (!!name) {
                UserCache.setUserNameForUid(post.uid,name);
            }
        }
    };

    /**
     * @param {Post} post
     * */
    createNewPost = async(post) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = post.toHttpBody();
        body['idToken'] = idToken;

        let response = await axios.post(apiUrl, body );

        if (response.status === 200) {
            let createdPost = createPostFromHttps(response.data);

            if (!!createdPost) {
                PostCache.updateCacheEntry(createdPost);
            }
            PostCache.removeCacheEntry(post.pid);
            return createdPost
        }
        return null;
    };

    updatePost = async(post) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = post.toHttpBody();
        body['idToken'] = idToken;

        let response = await axios.post(apiUrl  + '/update', body );

        if (response.status === 200) {
            let post = createPostFromHttps(response.data);

            if (!!post) {
                PostCache.updateCacheEntry(post);
            }
            return post
        }
        return null;
    };

    deletePost = async(post) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        post.deleted = true;
        PostCache.updateCacheEntry(post);

        let body = post.toHttpBody();
        body['idToken'] = idToken;
        body['pid'] = post.pid;

        let response = await axios.post(apiUrl + '/delete', body );

        if (response.status === 200) {
            return createPostFromHttps(response.data);
        }
        return null;
    };


    updatePostCountForPost = async (pid,callback) => {
        if (!!pid && !!PostCache.getPostByPid(pid)) {

            let countsRef = this.app.database().ref().child('posts/' + pid + '/counts');
            let counts = (await countsRef.once('value')).val();

            if(counts != null) {
                let post = PostCache.getPostByPid(pid);
                if (counts.hasOwnProperty('lC')) {
                    post.likes = counts['lC'];
                }
                if (counts.hasOwnProperty('dC')) {
                    post.dislikes = counts['dC'];
                }
                if (counts.hasOwnProperty('sC')) {
                    post.shareCount = counts['sC'];
                }
                if (counts.hasOwnProperty('rC')) {
                    post.reactionCount = counts['rC'];
                }
                if (counts.hasOwnProperty('cC')) {
                    post.commentCount = counts['cC'];
                }

                PostCache.updateCacheEntry(post);
                if (!!callback) {
                    callback();
                }
            }
        }
    };

    loadLatestNewsForStable = async (sid, lastLoading, callback) => {

        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
            'lastLoadDate': lastLoading || 0
        };

        let ids = [];

        let response = await axios.post(apiUrl + '/stable/lastLoading', body );

        if(response.status === 200){
            let responseJson = response.data;

            for (let postJson of responseJson) {
                let post = createPostFromHttps(postJson);

                if (!!post) {
                    PostCache.updateCacheEntry(post);
                    this.updatePostCountForPost(post.pid,callback);
                    this.getUserNameForPost(post);
                    ids.push(post.pid);
                }
            }
            PostCache.prependStableNewsPostIds(sid,ids);
        }

        return ids;
    };

    loadOldNewsForStable = async (sid, startDate,callback, force=false) => {

        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let ids;
        if (!force) {
            ids = PostCache.getStableNewsPostIds(sid);
        }

        if (!ids || ids.length === 0 ) {

            ids = [];
            let body = {
                'idToken': idToken,
                'stableId': sid,
                'startDate': startDate
            };

            let response = await axios.post(apiUrl + '/stable/old', body );

            if(response.status === 200){
                let responseJson = response.data;
                for (let postJson of responseJson) {
                    let post = createPostFromHttps(postJson);

                    if (post != null) {
                        PostCache.updateCacheEntry(post);
                        this.updatePostCountForPost(post.pid,callback);
                        this.getUserNameForPost(post);
                        ids.push(post.pid);
                    }
                }
                PostCache.appendStableNewsPostIds(sid,ids);
            }
        }

        return ids;
    };

    hasOldNewsForStableCached = (sid) => {
        let ids =PostCache.getStableNewsPostIds(sid);
        return !!ids && ids.length>0;
    };

    loadLatestPublicPostForStable = async (sid, lastLoading,callback) => {

        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
            'lastLoadDate': lastLoading || 0
        };

        let ids = [];

        let response = await axios.post(apiUrl + '/stable/recent/lastLoading', body );

        if(response.status === 200){
            let responseJson = response.data;
            for (let postJson of responseJson) {
                let post = createPostFromHttps(postJson);

                if (post != null) {
                    PostCache.updateCacheEntry(post);
                    this.updatePostCountForPost(post.pid,callback);
                    this.getUserNameForPost(post);
                    ids.push(post.pid);
                }
            }
            PostCache.prependStablePublicPostIds(sid,ids);
        }

        return ids;
    };

    loadOldPublicPostForStable = async (sid, startDate, callback, force = false) => {

        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
            'startDate': startDate
        };

        let ids;
        if (!force) {
            ids = PostCache.getStablePublicPostIds(sid);
        }

        if (!ids || ids.length === 0 ) {
            ids = [];

            let response = await axios.post(apiUrl + '/stable/recent/old', body);

            if (response.status === 200) {
                let responseJson = response.data;
                for (let postJson of responseJson) {
                    let post = createPostFromHttps(postJson);

                    if (post != null) {
                        PostCache.updateCacheEntry(post);
                        this.updatePostCountForPost(post.pid,callback);
                        this.getUserNameForPost(post);
                        ids.push(post.pid);
                    }
                }
                PostCache.appendStablePublicPostIds(sid,ids);
            }
        }

        return ids;
    };

    hasOldPublicPostForStableCached = (sid) => {
        let ids =PostCache.getStablePublicPostIds(sid);
        return !!ids && ids.length>0;
    };

    postSaveUserLikeType = async (auth, postId, likeType) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'pid': postId,
            'userName':  auth.name,
            'likeType': likeType,
        };
        await axios.post(apiUrl + '/saveLikeType', body );
    };

    changeUserLikeTypeForPost = (auth,post,oldLikeType,newLikeType) => {
        switch (newLikeType) {
            case LikeType.DISLIKE:
                if (oldLikeType === LikeType.LIKE) {
                    post.likes --;
                    post.dislikes ++;
                    post.userLikeType = LikeType.DISLIKE;
                }
                else if (oldLikeType === LikeType.DISLIKE) {
                    post.dislikes --;
                    post.reactionCount--;
                    post.userLikeType = LikeType.NEUTRAL;
                }
                else {
                    post.dislikes ++;
                    post.reactionCount++;
                    post.userLikeType = LikeType.DISLIKE;
                }
                break;
            case LikeType.LIKE:
                if (oldLikeType === LikeType.LIKE) {
                    post.likes --;
                    post.reactionCount--;
                    post.userLikeType = LikeType.NEUTRAL;
                }
                else if (oldLikeType === LikeType.DISLIKE) {
                    post.likes ++;
                    post.dislikes --;
                    post.userLikeType = LikeType.LIKE;
                }
                else {
                    post.likes ++;
                    post.reactionCount++;
                    post.userLikeType = LikeType.LIKE;
                }
                break;
            default:
                break;
        }
        this.postSaveUserLikeType(auth,post.pid,newLikeType);
        PostCache.updateCacheEntryWithoutTimeout(post);
        return post;
    };

}

export default FirebasePostController;