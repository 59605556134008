import React, {Component} from 'react';
import Loading from "../../loading";
import StableContact from "./stable_contact";
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {withRouter} from "react-router-dom";
import Carousel from 're-carousel'
import {Buttons, IndicatorDots} from "../../additional/carousel_controlls";
import * as ROUTES from "../../../routes";
import StableHeader from "../header";
import AreYouSureModal from "../../additional/are_your_sure_modal";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        about:"Über diesen Stall",
        offer:"Unser Angebot",
        farmingSystems:"Unsere Haltungsysteme",
        contacts:"Ansprechpartner",
        join: "Beitreten",
        edit: "Bearbeiten",
        accept: "Annehmen",
        leave: "Stall verlassen",
        leaveInfo: "Willst du den Stall wirklich verlassen?",
        leaveInfoLastAdmin: "Du bist der letzte Admin vom Stall, wenn du ihn verlässt, wird der Stall gelöscht.",
        requested: "Angefragt - Anfrage zurückziehen?",
        refuse: "Ablehnen",
        withdraw: "Zurückziehen",

        joinStableDesc: 'Du bist Einsteller in diesem Stall oder arbeitest hier? Super! Dann werde jetzt Stallmitglied. Deine Anfrage muss von den Verantwortlichen des Stalls angenommen werden, bevor du weitermachen kannst.\n\nDu möchtest nur mehr über diesen Stall erfahren? Kontaktiere einfach das Team!',
        invitedStableDesc: 'Du wurdest eingeladen, diesem Stall beizureten. Willst du die Anfrage annehmen?',
        invitedAdminStableDesc: 'Du wurdest eingeladen Administrator von diesem Stall zu werden. Willst du die Anfrage annehmen?',
        requestedStableDesc: 'Du hast bereits angefragt, diesem Stall beizutreten. Deine Anfrage muss zuerst von einem Administrator bestätigt werden.',
        stableImageAlt: "Stallbild",
        stableNotFound: "Es tut uns leid, dieser Stall konnte nicht gefunden werden."
    },
    en: {
        about:"About this stable",
        offer:"Our offer",
        farmingSystems:"Our farming systems",
        contacts:"Contacts",
        join: "Join",
        edit: "Edit",
        accept: "Accept",
        leave: "Leave stable",
        leaveInfo: "Do you realy want to leave this stable?",
        leaveInfoLastAdmin: "You are the last admin of this stable, if you leave, the stable will be deleted.",
        requested: "Requested - Withdraw request",
        refuse: "Refuse",
        withdraw: "Withdraw",
        joinStableDesc: 'You are boarder of this stable or working here? Great, just join this stable. Your request needs to be accepted by a responsible person of this stable, which can take some time.\n\nYou just want to know more about this stable? Please contact the given contact persons instead of joining.',
        invitedStableDesc: 'You got invited to join this stable. Do you want to accept?',
        invitedAdminStableDesc: 'You got invited to become administrator for this stable. Do you want to accept?',
        requestedStableDesc: 'You already requested to join this stable. Your request needs to be confirmed by an administrator.',
        stableImageAlt: "Stable image",
        stableNotFound: "We're sorry, this stable could not be found."
    }
});

class StableInformation extends Component{

    constructor(props) {
        super(props);
        this.state = {
            stable: undefined,
            loading: true,
            waiting: false,
            invitedAsAdmin: false,
            showLeave: false,
            lastAdminOfStable: false
        }
    }

    loadDisplayedStable() {
        this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(stable => {
            this.setState({ stable : stable, loading: false });
            if (!!stable) {
                this.props.firebase.stableController.checkIfUserIsInvitedAsAdmin(stable.sid).then( invited => {
                    this.setState({ invitedAsAdmin: invited});
                });

                this.props.firebase.stableController.loadAdminsForStable(stable.sid).then(admins => {
                    if(admins.some(admin => admin.uid === this.props.auth.user.uid) && admins.length === 1) {
                        this.setState({lastAdminOfStable: true});
                    }
                });
            }
        });
    }

    getInvitedAsAdminButtons = () => {
        return (
            <div>
                <p>{strings.invitedAdminStableDesc}</p>
                <div className="action-wrapper">
                    <button disabled={this.state.waiting} className={"secondary-button"} onClick={this.refuseAdminInvite}>
                        {strings.refuse}
                    </button>
                    <button disabled={this.state.waiting} className={"primary-button"} onClick={this.acceptAdminInvite}>
                        {strings.accept}
                    </button>
                </div>
            </div>
        );
    };

    getJoinStableInfotext = () => {
        if ((this.props.auth.stables.active.hasOwnProperty(this.state.stable.sid) || this.props.auth.stables.admin.hasOwnProperty(this.state.stable.sid))) {
            return null;
        } else if (this.props.auth.stables.invited.hasOwnProperty(this.state.stable.sid)) {
            return (
                <div>
                    <div className="info-div">
                        <h1>!</h1>
                        <small>{strings.invitedStableDesc}</small>
                    </div>
                    <div className="action-wrapper">
                        <button type="button" disabled={this.state.waiting} className={"secondary-button"} onClick={this.refuseStable}>
                            {strings.refuse}
                        </button>
                        <button type="button" disabled={this.state.waiting} className={"primary-button"} onClick={this.joinStable}>
                            {strings.join}
                        </button>
                    </div>
                </div>

            );
        } else if (this.props.auth.stables.requested.hasOwnProperty(this.state.stable.sid)) {
            return (
                    <div className="info-div">
                        <h1>!</h1>
                        <small>{strings.requestedStableDesc}</small>
                    </div>
            );
        } else {
            return (
                <div className="info-div">
                    <h1>!</h1>
                    <small>{strings.joinStableDesc}</small>
                </div>
            );
        }
    };

    getJoinStableButtons = () => {
        if ((this.props.auth.stables.active.hasOwnProperty(this.state.stable.sid) || this.props.auth.stables.admin.hasOwnProperty(this.state.stable.sid))) {
            return (
                <button className="warning-button leave-stable-button" onClick={ () => this.setState({showLeave: true})}>{strings.leave}</button>
            );
        } else if (this.props.auth.stables.invited.hasOwnProperty(this.state.stable.sid)) {
            return (
                    <div className="action-wrapper">
                        <button type="button" disabled={this.state.waiting} className={"secondary-button"} onClick={this.refuseStable}>
                            {strings.refuse}
                        </button>
                        <button type="button" disabled={this.state.waiting} className={"primary-button"} onClick={this.joinStable}>
                            {strings.join}
                        </button>
                    </div>
            );
        } else if (this.props.auth.stables.requested.hasOwnProperty(this.state.stable.sid)) {
            return (
                <button type="button" disabled={this.state.waiting} className={"secondary-button withdraw-join-stable-button"}  onClick={this.withdrawStable}>
                    {strings.requested}
                </button>
            );
        } else {
            return (
                <button type="button" disabled={this.state.waiting} className={"primary-button "} onClick={this.joinStable}>
                    {strings.join}
                </button>
            );
        }
    };

    getStableImages  = () => {
        if (!!this.state.stable.images && Object.keys(this.state.stable.images).length >0) {
            return (
                <div className="stable-image-carousel">
                    {/*TODO The Carousel component does not seem to resize images for the smallest site */}

                    <Carousel loop auto widgets={[IndicatorDots, Buttons]}>
                        {
                            Object.keys(this.state.stable.images).map((key, i) => {
                            return (
                                <div className="stable-image-carousel-image-wrapper" key={"stableImage" + key}>
                                    <img key={"stable_image"} src={this.state.stable.images[key]} alt={strings.stableImageAlt}/>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
            )
        }
        return ""
    };

    componentDidMount() {
        this.loadDisplayedStable();

    }

    setWaiting = (waiting) => {
        this.setState({
            waiting: waiting
        });
    };

    acceptAdminInvite = async () => {
        if (!this.state.waiting) {
            this.setWaiting(true);
            this.props.firebase.stableController.acceptAdminInvite(this.state.stable.sid,this.props.auth.user.name);
            this.props.invitedAsAdmin = false;
            delete this.props.auth.stables.adminInvites[this.state.stable.sid];
            this.setWaiting(false);
        }
    };

    refuseAdminInvite = async () => {
        if (!this.state.waiting) {
            this.setWaiting(true);
            this.props.firebase.stableController.refuseAdminInvite(this.state.stable.sid,this.props.auth.user.name);
            delete this.props.auth.stables.adminInvites[this.state.stable.sid];
            this.setWaiting(false);
        }
    };

    joinStable = async (e) => {
        if (!this.state.waiting) {
            this.setWaiting(true);
            let response = await this.props.firebase.stableController.joinStable(this.state.stable.sid,this.props.auth.user.name);
            if(response === "Joined") {
                this.props.auth.stables.active[this.state.stable.sid] = this.state.stable;
                delete this.props.auth.stables.requested[this.state.stable.sid];
            } else if (response === "Admin"){
                this.props.auth.stables.admin[this.state.stable.sid] = this.state.stable;
                this.props.auth.stables.active[this.state.stable.sid] = this.state.stable;
                delete this.props.auth.stables.requested[this.state.stable.sid];
            } else{
                this.props.auth.stables.requested[this.state.stable.sid] = this.state.stable;
            }
            this.setState({...this.state});
            this.setWaiting(false);
        }
    };

    leaveStable = async () => {
        if (!this.state.waiting) {
            this.setWaiting(true);
            if(this.state.lastAdminOfStable) {
                this.props.firebase.stableController.deleteStable(this.state.stable);
            } else {
                this.props.firebase.stableController.leaveStable(this.state.stable.sid);
            }

            delete this.props.auth.stables.active[this.state.stable.sid];
            this.props.history.push(ROUTES.HOME);
            this.setWaiting(false);
        }
    };

    refuseStable = async () => {
        if (!this.state.waiting) {
            this.setWaiting(true);
            this.props.firebase.stableController.refuseInviteForStable(this.state.stable.sid);
            delete this.props.auth.stables.invited[this.state.stable.sid];
            this.setWaiting(false);
        }
    };

    withdrawStable = async () => {
        if (!this.state.waiting) {
            this.setWaiting(true);
            this.props.firebase.stableController.withdrawRequestForStable(this.state.stable.sid);
            delete this.props.auth.stables.requested[this.state.stable.sid];
            this.setWaiting(false);
        }
    };

    showChangeStable = () => {
        for (let stable in this.props.auth.stables.active) {
            if (this.props.match.params.sid === this.props.auth.stables.active[stable].sid) {
                return true;
            }
        };
    };

    render() {
        if(!!this.state.stable && this.state.stable.sid !== this.props.match.params.sid) {
            this.setState({
                stable: undefined,
                loading: true
            });
            this.loadDisplayedStable();
        }

        if(this.state.loading) {
            return (
                <div className={"stable-about"}>
                    <StableHeader name={""} stables={this.props.auth.stables.active}/>
                    <Loading />
                </div>
            );
        }

        if(!this.state.stable && !this.state.loading) {
            return(
                <div className="stable-about">
                    {strings.stableNotFound}
                </div>
            )
        }

        return (
            <div className={"stable-about"}>
                <div className="row">
                    <StableHeader name={this.state.stable.name} stables={this.showChangeStable() ? this.props.auth.stables.active : ""}/>
                    {this.getJoinStableButtons()}
                    {this.props.auth.stables.admin[this.props.match.params.sid] ? <button onClick={() =>  this.props.history.push("/stables/" + this.props.match.params.sid + "/edit")}>{strings.edit}</button> : <div/>}
                </div>

                <span className={"location"}>{!!this.state.stable.locationData && !!this.state.stable.locationData.fullAddress ? this.state.stable.locationData.fullAddress : "" }</span>

                { this.getStableImages() }

                {
                    !!this.state.stable.about ?
                        <div className={"stable-information"}>
                            <h3>{strings.about}</h3>
                            <p>{this.state.stable.about}</p>
                        </div>
                        : ""
                }

                {
                    !!this.state.stable.offer ?
                        <div className={"stable-information"}>
                            <h3>{strings.offer}</h3>
                            <p>{this.state.stable.offer}</p>
                        </div>
                        : ""
                }

                {
                    !!this.state.stable.farmingSystem ?
                        <div className={"stable-information"}>
                            <h3>{strings.farmingSystems}</h3>
                            <p>{this.state.stable.farmingSystem}</p>
                        </div>
                        : ""
                }

                {
                    !!this.state.stable.contacts && this.state.stable.contacts.length>0 ?
                        <div className={"stable-information"}>
                            <h3>{strings.contacts}</h3>
                            <div className="content-column width-auto">

                                {this.state.stable.contacts.map((contact, i) => {
                                    return (
                                        <StableContact key={"stable_contact"+ i} image={contact.avatar} name={contact.name}  position={contact.position}  telephone={contact.telephone}/>
                                    )
                                })}

                            </div>
                        </div>
                        : ""
                }
                {
                    this.state.invitedAsAdmin ?
                        <div className={"join-stable-option"}>
                            {this.getInvitedAsAdminButtons()}
                        </div>
                        : <div className={"join-stable-option"}>
                            {this.getJoinStableInfotext()}
                        </div>
                }
                {
                    !!this.state.showLeave ? <AreYouSureModal description={this.state.lastAdminOfStable ? strings.leaveInfoLastAdmin : strings.leaveInfo} onConfirm={() => this.leaveStable()} onClose={() => this.setState({showLeave: false})}/> : null
                }
        </div>

        );
    }
}
export default withRouter(WithAuthentication(WithFirebase(StableInformation)));


