const firebaseConfig = {
    apiKey: "AIzaSyAsJGQzpm7x99VuMkI8Hqhml25NBK4iFZA",
    authDomain: "jutta-live-913c1.firebaseapp.com",
    databaseURL: "https://jutta-live-913c1.firebaseio.com",
    projectId: "jutta-live-913c1",
    storageBucket: "jutta-live-913c1.appspot.com",
    messagingSenderId: "967014814451",
    appId: "1:967014814451:web:9b7c5f1d4dd1197856dd86",
    measurementId: "G-R1383VTP6Z"
};

export {firebaseConfig};
