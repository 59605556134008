import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {WithAuthentication} from "../firebase/auth_provider";
import {WithFirebase} from "../firebase/firebase";
import * as ROUTES from "../../routes";
import AreYouSureModal from "../additional/are_your_sure_modal";
import FacilityCache from "../caches/facility_cache";
import {getFacilityHeaderUrl, secondsToTimeOfDay, timeOfDayToSeconds} from "../additional/helpers";
import LocalizationsWithLanguage from "../localization_wrapper";
import {WithBlockRoute} from "../navigation/block_route_provider";
import DeletableImage from "../additional/deletable_image";

let strings = new LocalizationsWithLanguage({
    de:{
        title: "Anlage bearbeiten",
        information: "Verwalte die Anlagen deines Stalls mit unserer Hilfe. Nachdem du eine Anlage, wie eine Reithalle oder einen Paddock, hinzugefügt hast, können deine Einsteller die Anlage für bestimmte Zeiten buchen. Wir brauchen nur einen Namen, eine kurze Beschreibung und die Anzahl der maximal zur gleichen Zeiten verfügbaren Plätze.",
        namePlaceholder: "Wie heißt die Anlage?",
        infoPlaceholder: "Zusätzliche Informationen zur Anlage",
        slotsPlaceholder: "Maximal buchbare Plätze",
        delete: "Anlage dauerhaft löschen",
        name: "Name der Anlage",
        info: "Beschreibung der Anlage",
        slots: 'Maximale Anzahl an parallelen Buchungen',
        slotsDescription: 'Wie viele Plätze dürfen in dieser Anlage maximal belegt werden?',
        save: "Speichern",
        facilityHeaderAlt: "Anlagenbild",
        generalInfo: "Allgemeine Informationen",
        generalInfoDesc: "Füge Informationen zu deiner Anlage hinzu, die für deine Stallmitglieder sichtbar sein werden.",
        maxBookingDuration: "Maximal erlaubte Buchungsdauer.",
        maxBookingDurationDescription: "Wie lang darf eine Buchung maximal dauern?  0 bedeutet unbegrenzt. Wichtig alle existierenden Buchungen, die länger als die gewählte Dauer sind, werden gelöscht!",
        maxFutureBookingDays: "Begrenzungen von Vorbuchungen",
        maxFutureBookingDaysDescription: "Wie viele Tage im Voraus und ab welcher Uhrzeit dürfen Buchungen getätigt werden?",
        openingHours: "Öffnungszeiten",
        openingHoursDescription: "Du kannst die Öffnungszeiten für deine Anlagen definieren, wenn du möchtest.",
        startTime: "Startzeit",
        endTime: "Endzeit",
        maxBookingDurationPlaceholder: "Dauer wählen",
        image: "Stallbild",
        settings: "Einstellungen",
        oClock: 'Uhr',
        minutes: 'Minuten'
    },
    en: {
        title: "Edit facility",
        information: "You can manage your facilities usage with our help. After you added one, the members of your stable can add bookings to each facility. Just add a name, a description and the number of maximum bookable slots at the same time.",
        namePlaceholder: "What's the name of the facility?",
        infoPlaceholder: "Give some information about the facility!",
        slotsDescription: 'How many slots can be occupied in this facility at once?',
        slotsPlaceholder: "Count of bookable slots",
        name: "Name of the facility",
        info: "Maximum amount of parallel bookings",
        slots: 'Max. slots',
        save: "Save",
        delete: "Delete facility permanentely",
        facilityHeaderAlt: "Facility image",
        generalInfo: "General information",
        generalInfoDesc: "Add general information about your facility, which will be visible for stable members.",
        maxBookingDuration: "Maximum permitted bookingduration",
        maxBookingDurationDescription: "How long can a booking be at maximum? 0 means no limitation. Important all bookings that have a longer duration than the selected one will be deleted!",
        maxFutureBookingDays: "Limitations on pre-bookings",
        maxFutureBookingDaysDescription: "How many days in advance and starting at which time can boarders book this facility?",
        openingHours: "Opening hours",
        openingHoursDescription: "You can define the opening hours of your facility if you'd like to.",
        startTime: "Starting time",
        endTime: "Ending time",
        maxBookingDurationPlaceholder: "Choose duration",
        image: "Stable avatar",
        settings: "Settings",
        oClock: 'o\'Clock',
        minutes: 'minutes'
    }
});

class EditFacility extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            saving: false,
            stable: undefined,
            facility: undefined,
            name: "",
            description: "",
            maxBookingDuration: 0,
            slots: 0,
            openingStartHour: null,
            openingStartMin: null,
            openingEndHour: null,
            openingEndMin: null,
            maxFutureBookingDays: null,
            futureBookingDaysStart: null
        };
    }

    loadFacility = async () => {
        if (await this.props.auth.isMemberOfStable(this.props.match.params.sid)) {
            let facilityId = await this.props.firebase.facilityController.loadFacilityByID(this.props.match.params.sid, this.props.match.params.fid);
            let facility = FacilityCache.getFacilityById(facilityId);

            this.props.blockRoute.setCanLeaveRoute(() => (this.state.name.trim() === facility.name && this.state.description.trim() === facility.description && this.state.slots === facility.maxBookers));

            this.setState({
                facility: facility,
                name: facility.name,
                description: facility.description,
                slots: facility.maxBookers,
                maxBookingDuration: ( !!facility.maxBookingDuration) ? Math.floor(facility.maxBookingDuration / 60 ) : 0,
                facilityHeaderImage: facility.hasImage ? facility.header : null,
                openingStartHour: !!facility.openingStart ? Math.floor( facility.openingStart / (60 *60) ) : null,
                openingEndHour: facility.openingEnd ? Math.floor( facility.openingEnd / (60 *60) ) : null,
                maxFutureBookingDays: facility.maxFutureBookingDays,
                futureBookingDaysStart: !!facility.futureDaysBookingStart ? secondsToTimeOfDay(facility.futureDaysBookingStart) : null
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    };

    async loadStable() {
        let stable = await this.props.firebase.stableController.loadStableByID(this.props.match.params.sid);

        this.setState({
            stable: stable
        });
    }

    componentDidMount() {
        this.loadStable();
        let facility = FacilityCache.getFacilityById(this.props.match.params.fid);

        if (!!facility) {
            this.props.blockRoute.setCanLeaveRoute(() => (this.state.name.trim() === facility.name && this.state.description.trim() === facility.description && this.state.slots === facility.maxBookers));

            this.setState({
                facility: facility,
                name: facility.name,
                description: facility.description,
                slots: facility.maxBookers,
                maxBookingDuration: ( !!facility.maxBookingDuration) ? Math.floor(facility.maxBookingDuration / 60 ) : 0,
                facilityHeaderImage: facility.hasImage ? facility.header : null,
                openingStartHour: !!facility.openingStart ? Math.floor( facility.openingStart / (60 *60) ) : null,
                openingEndHour: facility.openingEnd ? Math.floor( facility.openingEnd / (60 *60) ) : null,
                maxFutureBookingDays: facility.maxFutureBookingDays,
                futureBookingDaysStart: !!facility.futureDaysBookingStart ? secondsToTimeOfDay(facility.futureDaysBookingStart) : null
            })
        } else {
            this.loadFacility();
        }
    }

    onSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.refs['edit-facility-form'].reportValidity();

        if (!this.state.saving && !!this.state.name && !!this.state.slots) {
            this.props.blockRoute.setCanLeaveRoute(() => true);
            this.setState({saving : true});

            this.state.facility.name = this.state.name;
            this.state.facility.description = this.state.description;
            this.state.facility.maxBookers = parseInt(this.state.slots);
            this.state.facility.hasImage = !!this.state.facilityHeaderImage;
            this.state.facility.maxBookingDuration = (this.state.maxBookingDuration > 0) ? parseInt(this.state.maxBookingDuration) * 60 : null;

            if (!!this.state.openingStartHour && !!this.state.openingEndHour ) {
                this.state.facility.openingStart = this.state.openingStartHour*  60 *60;
                this.state.facility.openingEnd  = this.state.openingEndHour*  60 *60 ;
            } else {
                this.state.facility.openingStart = null;
                this.state.facility.openingEnd = null;
            }

            if(!!this.state.maxFutureBookingDays) {
                this.state.facility.maxFutureBookingDays = parseInt(this.state.maxFutureBookingDays);
            } else {
                this.state.facility.maxFutureBookingDays = null;
            }

            if(!!this.state.futureBookingDaysStart) {
                this.state.facility.futureDaysBookingStart = timeOfDayToSeconds(this.state.futureBookingDaysStart);
            } else {
                this.state.facility.futureDaysBookingStart = null;
            }

            if (this.state.facility.hasImage) {
                this.state.facility.header = getFacilityHeaderUrl(this.state.facility.fid);
            } else {
                this.state.facility.header = "";
            }

            this.props.history.push(ROUTES.BOOKINGS.replace(":sid", this.props.match.params.sid).replace(":fid", this.props.match.params.fid));

            if(!this.state.facilityHeaderImage && !!this.state.facility.header) {
                await this.props.firebase.storageController.deleteFacilityHeaderImage(this.state.facility.fid);
            } else if(!!this.state.facilityHeaderImage && this.state.facilityHeaderImage !== this.state.facility.header) {
                await this.props.firebase.storageController.uploadHeaderForFacility(this.state.facilityHeaderImage, this.state.facility.fid);
            }

            await this.props.firebase.facilityController.updateFacility(this.state.facility);
            this.props.auth.rebuildApp();
        }
    };

    onDelete = async () => {
        FacilityCache.removeCacheEntry(this.state.facility.fid);
        await this.props.firebase.facilityController.deleteFacility(this.state.facility);
        this.props.history.push(ROUTES.FACILITY.replace(":sid", this.props.match.params.sid));
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    hideDeleteDialog = () => {
        this.setState({
            showDelete: false
        });
    };



    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
            this.setState(this.state);
        }
    };



    render() {
        const { name, description, slots, maxBookingDuration, openingStartHour, openingEndHour, maxFutureBookingDays, futureBookingDaysStart } = this.state;
        const isInvalid = !name || !slots || (!!openingStartHour &&  !openingEndHour) || (!openingStartHour &&  !!openingEndHour) || (!!openingStartHour &&  !!openingEndHour && (( parseInt(openingStartHour) >= parseInt(openingEndHour)) || parseInt(openingStartHour)<0 || parseInt(openingStartHour)>23 || parseInt(openingEndHour)< 1 || parseInt(openingEndHour)> 24 ));

        return (
            <div>
                <div className="row">
                    <h2 className={"create-edit-facility-header"}>{strings.title}</h2>
                    <button type="button" className={"secondary-button"} onClick={() => this.setState({showDelete: true})}>
                        {strings.delete}
                    </button>
                </div>
                <p>{strings.information}</p>
                <form ref="edit-facility-form" className="mt-2">
                    <div className="row flex-start">

                        <div className="ml-2">
                            <div className="card">
                                <div className="card-header">
                                    <h4>{strings.generalInfo}</h4>
                                    <small>{strings.generalInfoDesc}</small>
                                </div>
                                <div className="card-body">
                                    <div className="card-row">
                                        <label htmlFor="name">{strings.name}</label>
                                        <input
                                            name="name"
                                            onChange={this.onChange}
                                            type="text"
                                            value={name}
                                            placeholder={strings.namePlaceholder}
                                            required
                                        />
                                    </div>

                                    <div className="card-row">
                                        <label htmlFor="description">{strings.info}</label>
                                        <input
                                            name="description"
                                            onChange={this.onChange}
                                            type="text"
                                            value={description}
                                            placeholder={strings.infoPlaceholder}
                                        />
                                    </div>

                                    <div className="card-row">
                                        <label htmlFor="description">{strings.image}</label>
                                        <DeletableImage image={this.facilityHeaderImage} onImageChange={(image) => {
                                            this.setState({
                                                facilityHeaderImage: image
                                            });
                                        }}/>
                                    </div>


                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h4>{strings.settings}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-column">
                                        <label htmlFor="slots">{strings.slots}</label>
                                        <p>{strings.slotsDescription}</p>
                                        <input
                                            name="slots"
                                            onChange={this.onChange}
                                            type="number"
                                            value={slots}
                                            min={1}
                                            max={100}
                                            placeholder={strings.slotsPlaceholder}
                                            required
                                        />
                                    </div>

                                    <div className="card-column">
                                        <label htmlFor="maxBookingDuration">{strings.maxBookingDuration}</label>
                                        <p>{strings.maxBookingDurationDescription}</p>
                                        <div className="card-row">
                                            <input
                                                name="maxBookingDuration"
                                                onChange={this.onChange}
                                                type="number"
                                                min="0"
                                                max="1000"
                                                value={maxBookingDuration}
                                                placeholder={strings.maxBookingDurationPlaceholder}
                                            />
                                            <p>{strings.minutes}</p>
                                        </div>
                                    </div>

                                    {
                                        !!this.state.stable && this.state.stable.hasPro() &&
                                        (
                                            <div className="card-column">
                                                <label htmlFor="maxBookingDuration">{strings.maxFutureBookingDays}</label>
                                                <small className="caption">{strings.maxFutureBookingDaysDescription}</small>
                                                <div className="card-row">
                                                    <div className="card-row">
                                                        <input
                                                            className="mr-1"
                                                            name="maxFutureBookingDays"
                                                            onChange={this.onChange}
                                                            type="number"
                                                            min="0"
                                                            max="3600"
                                                            value={maxFutureBookingDays}
                                                            placeholder={strings.always}
                                                        />
                                                    </div>
                                                    <div className="card-row">
                                                        <input
                                                            className="mr-1"
                                                            name="futureBookingDaysStart"
                                                            onChange={this.onChange}
                                                            type="time"
                                                            value={futureBookingDaysStart}
                                                            placeholder={strings.maxBookingDurationPlaceholder}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h4>{strings.openingHours}</h4>
                                    <label>{strings.openingHoursDescription}</label>
                                </div>
                                <div className="card-body" id="openingHourBody">
                                    <div className="time-row">
                                        <div className="card-row">
                                            <label htmlFor="openingStartHour">{strings.startTime}</label>
                                            <input
                                                className="mr-1"
                                                name="openingStartHour"
                                                onChange={this.onChange}
                                                type="number"
                                                min={0}
                                                max={23}
                                                value={openingStartHour}
                                                required
                                            />
                                            <p>:00 {strings.oClock}</p>
                                        </div>
                                    </div>
                                    <div className="time-row">
                                        <div className="card-row">
                                            <label htmlFor="openingEndHour">{strings.endTime}</label>
                                            <input
                                                className="mr-1"
                                                name="openingEndHour"
                                                className="mr-1"
                                                onChange={this.onChange}
                                                type="number"
                                                min={1}
                                                max={24}
                                                value={openingEndHour}
                                                required
                                            />
                                            <p>:00 {strings.oClock}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end">
                                <button className="width-50" disabled={isInvalid} onClick={this.onSubmit}>{strings.save}</button>
                            </div>
                        </div>
            </div>

                </form>
                { this.state.showDelete && <AreYouSureModal onClose={this.hideDeleteDialog} onConfirm={this.onDelete}/> }
            </div>
        )
    }
}

export default withRouter(WithBlockRoute(WithAuthentication(WithFirebase(EditFacility))));