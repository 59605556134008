import React, {Component} from 'react';
import {Link} from "react-router-dom";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        stableImageAlt: "Stallbild"
    },
    en: {
        stableImageAlt: "Stable image"
    }
});

class StableCard extends React.PureComponent {

    imgHasFailed = false;

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        return (
            <Link to={this.props.link || ""}>
                <div className="stable-card">
                    {this.props.addcard ? <div className="stable-card-add"><h1>+</h1></div> :  <img src={this.props.image} onError={this.onImageLoadFail} alt={strings.stableImageAlt}/> }
                    <div className="stable-body">
                        <h4>{this.props.title}</h4>
                        <span className="accent-color">{this.props.linktext} ></span>
                    </div>
                </div>
            </Link>
        )

    }
}

export default StableCard;