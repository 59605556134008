import {getUserAvatarUrl} from "../additional/helpers";
import {ShallowUser} from "./shallow_user";

export class StableUser extends ShallowUser{

    joined;

    /**
     * @param {String} name
     * @param {String} uid
     * @param {int} joined
     */
    constructor({uid = "",name = "", joined} = {}){
        super( {uid: uid,name: name ,avatar: getUserAvatarUrl(uid)} );
        this.joined = joined;
    }
}


export function createStableUserFromFirestoreDocument(firestoreDocument) {

    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        return new StableUser( {
            uid: firestoreDocument.id,
            name: data['fullName'] || data['businessName'] || data['name'] || "",
            joined:  data['joined'] ,
        });
    }
    return null;

}