function configureFacilityCache() {
    const cache = {
        facility: {
        },
        timeout: {
        }
    };

    const stable= {};
    const timoutSpan = 300000;

    cache.getFacilityById = function(fid){
        if (cache.facility.hasOwnProperty(fid) && cache.timeout.hasOwnProperty(fid)){
            return cache.facility[fid];
        }
        return undefined;
    };

    cache.getFacilityByIdIfValid = function(fid){
        if (cache.facility.hasOwnProperty(fid) && cache.timeout.hasOwnProperty(fid) && (Date.now() < cache.timeout[fid]) ){
            return cache.facility[fid];
        }
        return undefined;
    };

    cache.updateCacheEntry = function(facility){
        if (!!facility) {
            cache.facility[facility.fid] = facility;
            cache.timeout[facility.fid] = Date.now() + timoutSpan;
        }
    };

    cache.removeCacheEntry = function(fid){
        delete cache.facility[fid];
        delete cache.timeout[fid] ;
    };

    cache.updateCacheEntryWithoutTimeout = function(facility){
        if (!!facility) {
            cache.facility[facility.fid] = facility;
        }
    };

    cache.getFacilityIdsForStable = function(sid){
        if (stable.hasOwnProperty(sid)){
            return stable[sid];
        }
        return undefined;
    };

    cache.appendFacilityIdsForStable = function(sid,ids){
        if (!stable.hasOwnProperty(sid) ){
            stable[sid]= [];
        }
        stable[sid] = stable[sid].concat(ids);
    };

    cache.prependFacilityIdsForStable = function(sid,ids){
        if (!stable.hasOwnProperty(sid) ){
            stable[sid] = [];
        }
        stable[sid] = ids.concat(stable[sid]);
    };

    cache.overwriteFacilityIdsForStable = function(sid, ids) {
        if (!stable.hasOwnProperty(sid) ){
            stable[sid]= [];
        }
        stable[sid] = ids;
    };

    return cache;

}

const FacilityCache = configureFacilityCache();

export default FacilityCache;