import {storageUrl} from "../config";
import AuthType from "../models/authtype";
import RepetitionWeek from "../models/repetition_week";
import Weekday from "../models/weekday";
import {Color} from "../models/color";

export function getUserAvatarUrl(uid){
    return storageUrl + 'users%2Favatars%2F' + uid + '?alt=media';
}

export function getUserAvatarLargeUrl(uid){
    return storageUrl + 'users%2Favatars%2F' + uid + '_large?alt=media';
}

export function getHorseAvatarUrl(hid) {
    return storageUrl + 'horses%2Favatars%2F' + hid + '?alt=media';
}


export function getStableHeaderUrl(sid){
    return storageUrl + 'stables%2F' + sid + '%2Fheader?alt=media';
}

export function getFacilityHeaderUrl(fid){
    return storageUrl + 'facilities%2F' + fid + '%2Fheader?alt=media';
}

export function getStableContactAvatarUrl(sid, key) {
    return storageUrl + 'stables%2F' + sid + '%2Fcontact' + key + '?alt=media';
}

export function getStableImageUrl(sid, key) {
    return storageUrl + 'stables%2F' + sid + '%2Fimage%2F' + key + '?alt=media';
}


export function stringToAuthType( authType) {

    switch(authType) {
        case "Google":
            return AuthType.GOOGLE;
        case "Facebook":
            return AuthType.FACEBOOK;
        case "Email":
            return AuthType.EMAIL;
        default:
            return undefined;
    }

}

/**
 * @param {Geopoint} geolocation
 */
export function toVagueGeolocation(geolocation) {
    let longVague = ((Math.random()-0.5) / 35) * 10000;
    let latValue = ((Math.random()-0.5) / 35) * 10000;
    geolocation.long = geolocation.long + Math.round(longVague) / 10000;
    geolocation.lat = geolocation.lat +  Math.round(latValue)/ 10000;
    geolocation.lat = Math.round(geolocation.lat * 100000) / 100000;
    geolocation.long = Math.round(geolocation.long * 100000) / 100000;
    return geolocation;
}

/**
 * @param {StableLocation} stableLocation
 */
export function getFullAddressFromStableLocation(stableLocation) {
    let fullAddress = "";
    if(!!stableLocation.street) {
        fullAddress = stableLocation.street;
        if(!!stableLocation.streetNumber) {
            fullAddress  += ' ' + stableLocation.streetNumber;
        }
    }

   if (!!stableLocation.postalCode) {
        if(!stableLocation.street) {
            fullAddress  = stableLocation.postalCode;
        }
        else {
            fullAddress  += ', ' + stableLocation.postalCode;
        }
    }

    if(!!stableLocation.city) {
        if(!stableLocation.street && !stableLocation.postalCode) {
            fullAddress  = stableLocation.city;
        } else if (!stableLocation.postalCode) {
            fullAddress  += ', ' + stableLocation.city;
        }
        else {
            fullAddress  += ' ' + stableLocation.city;
        }
    }

    if(!!stableLocation.country) {
        if(!!stableLocation.street  || !!stableLocation.city) {
            fullAddress  += ' (' + stableLocation.country + ')';
        }
    }
    return fullAddress;
}

/**
 * @param {string} timeOfDay
 */
export function timeOfDayToSeconds(timeOfDay) {
    let split = timeOfDay.split(":");
    let hours = parseInt(split[0]);
    let minutes = parseInt(split[1]);

    return (minutes * 60) + (hours * 60 * 60);
}

/**
 * @param {Date} date
 */
export function dateToDatePickerValue(date) {
    if(!!date) {
        return date.toISOString().split("T")[0];
    }

    return undefined;
}

/**
 * @param {int} seconds
 */
export function secondsToTimeOfDay(seconds) {
    let timeFormatOptions = {hour: "2-digit", minute: "2-digit"};
    let date = new Date();
    let timeZoneOffset = date.getTimezoneOffset();

    let jan = new Date(date.getFullYear(), 0, 1);
    let jul = new Date(date.getFullYear(), 6, 1);
    let stdTimezoneOffset =  Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    if (timeZoneOffset < stdTimezoneOffset) {
        timeZoneOffset= timeZoneOffset+60;
    }

    return (new Date((seconds + timeZoneOffset * 60) * 1000)).toLocaleString('de-DE', timeFormatOptions)
}

/**
 * @param {number} hour
 */
export function hourNumberToTimeOfDay(hour) {
    let hourInSeconds = hour * 60 * 60;
    return secondsToTimeOfDay(hourInSeconds);
}

/**
 * @param {Date} a
 * @param {Date} b
 */

export function dateIsSameDayAs(a, b) {
    return a.getUTCFullYear() === b.getUTCFullYear() && a.getUTCMonth() === b.getUTCMonth() && a.getUTCDate() === b.getUTCDate();
}

/**
 * @param {Date} date
 */
export function getSecondsSinceStartOfDate(date) {
    return date.getSeconds() + (date.getMinutes() * 60) + (date.getHours() * 60 * 60);
}

export function getEndOfToday() {
    let now = new Date();
    now.setHours(23);
    now.setMinutes(59);
    now.setSeconds(59);
    now.setMilliseconds(999);

    return now;
}

export function getStartOfToday() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    return now;
}

/**
 * @param {number} seconds
 */
export function secondsToHours(seconds) {
    return seconds / 60 / 60;
}

export function isLeapYear(year)
{
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export function getIntValuesForRepetition() {
    return {
        [RepetitionWeek.ONEWEEK] : 1,
        [RepetitionWeek.TWOWEEKS] : 2,
        [RepetitionWeek.THREEWEEKS] : 3,
        [RepetitionWeek.FOURWEEKS] : 4,
        [RepetitionWeek.FIVEWEEKS] : 5,
        [RepetitionWeek.SIXWEEKS] : 6,
        [RepetitionWeek.SEVENWEEKS] :7,
        [RepetitionWeek.EIGHTWEEKS] : 8,
        [RepetitionWeek.NINEWEEKS] : 9,
        [RepetitionWeek.TENWEEKS] :10,
        [RepetitionWeek.ELEVENWEEKS] : 11,
        [RepetitionWeek.TWELVEWEEKS] : 12
    };
}

export function getWeekdayForInt() {
    return {
        1: Weekday.MONDAY,
        2: Weekday.TUESDAY,
        3: Weekday.WEDNESDAY,
        4: Weekday.THURSDAY,
        5: Weekday.FRIDAY,
        6: Weekday.SATURDAY,
        0: Weekday.SUNDAY
    }
}

export function getColorFromString(val) {
    let i = hashCode(val);

    let c = (i & 0x00DBDBDB)
        .toString(16)
        .toUpperCase();

    let color = new Color(parseInt("00000".substring(0, 6 - c.length) + c, 32));

    if(color.getRed() > color.getBlue() && color.getRed() > color.getGreen()) {
        color = color.withRed(255).withOpacity(0.5);
    } else if(color.getBlue() > color.getGreen()) {
        color = color.withBlue(255).withOpacity(0.5);
    } else {
        color = color.withGreen(255).withOpacity(0.5);
    }

    return color;
}

// Almost correct colors (sometimes)
export function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return 420 * hash;
}