import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {WithAuthentication} from "../firebase/auth_provider";
import {WithFirebase} from "../firebase/firebase";
import {Facility} from "../models/facility";
import * as ROUTES from "../../routes";
import FacilityCache from "../caches/facility_cache";
import {getColorFromString, getFacilityHeaderUrl, timeOfDayToSeconds} from "../additional/helpers";
import LocalizationsWithLanguage from "../localization_wrapper";
import {WithBlockRoute} from "../navigation/block_route_provider";
import DeletableImage from "../additional/deletable_image";

let strings = new LocalizationsWithLanguage({
    de:{
        title: "Anlage erstellen",
        information: "Verwalte die Anlagen deines Stalls mit unserer Hilfe. Nachdem du eine Anlage, wie eine Reithalle oder einen Paddock, hinzugefügt hast, können deine Einsteller die Anlage für bestimmte Zeiten buchen. Wir brauchen nur einen Namen, eine kurze Beschreibung und die Anzahl der maximal zur gleichen Zeiten verfügbaren Plätze.",
        namePlaceholder: "Wie heißt die Anlage?",
        infoPlaceholder: "Zusätzliche Informationen zur Anlage",
        slotsPlaceholder: "Maximal buchbare Plätze",
        name: "Name der Anlage",
        info: "Beschreibung der Anlage",
        slots: 'Maximale Anzahl an parallelen Buchungen',
        slotsDescription: 'Wie viele Plätze dürfen in dieser Anlage maximal belegt werden?',
        save: "Speichern",
        facilityHeaderAlt: "Anlagenbild",
        generalInfo: "Allgemeine Informationen",
        generalInfoDesc: "Füge Informationen zu deiner Anlage hinzu, die für deine Stallmitglieder sichtbar sein werden.",
        maxBookingDuration: "Maximal erlaubte Buchungsdauer",
        maxBookingDurationDescription: "Wie lang darf eine Buchung maximal dauern?  0 bedeutet unbegrenzt.",
        maxFutureBookingDays: "Begrenzungen von Vorbuchungen",
        maxFutureBookingDaysDescription: "Wie viele Tage im Voraus und ab welcher Uhrzeit dürfen Buchungen getätigt werden?",
        openingHours: "Öffnungszeiten",
        openingHoursDescription: "Du kannst die Öffnungszeiten für deine Anlagen definieren, wenn du möchtest.",
        startTime: "Startzeit",
        endTime: "Endzeit",
        maxBookingDurationPlaceholder: "Dauer wählen",
        image: "Foto der Anlage",
        settings: "Einstellungen",
        oClock: 'Uhr',
        minutes: 'Minuten',
        always: 'Immer'
    },
    en: {
        title: "Create new facility",
        information: "You can manage your facilities usage with our help. After you added one, the members of your stable can add bookings to each facility. Just add a name, a description and the number of maximum bookable slots at the same time.",
        namePlaceholder: "What's the name of the facility?",
        infoPlaceholder: "Give some information about the facility!",
        slotsDescription: 'How many slots can be occupied in this facility at once?',
        slotsPlaceholder: "Count of bookable slots",
        name: "Name of the facility",
        info: "Maximum amount of parallel bookings",
        slots: 'Max. slots',
        save: "Save",
        facilityHeaderAlt: "Facility image",
        generalInfo: "General information",
        generalInfoDesc: "Add general information about your facility, which will be visible for stable members.",
        maxBookingDuration: "Maximum permitted bookingduration",
        maxBookingDurationDescription: "How long can a booking be at maximum? 0 means no limitation.",
        maxFutureBookingDays: "Limitations on pre-bookings",
        maxFutureBookingDaysDescription: "How many days in advance and starting at which time can boarders book this facility?",
        openingHours: "Opening hours",
        openingHoursDescription: "You can define the opening hours of your facility if you'd like to.",
        startTime: "Starting time",
        endTime: "Ending time",
        maxBookingDurationPlaceholder: "Choose duration",
        image: "Facility image",
        settings: "Settings",
        oClock: 'o\'Clock',
        minutes: 'minutes',
        always: 'Always'
    }
});

class CreateFacility extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            stable: null,
            creating: false,
            name: "",
            description: "",
            maxBookingDuration: 0,
            slots: 3,
            openingStartHour: null,
            openingStartMin: null,
            openingEndHour: null,
            openingEndMin: null,
            maxFutureBookingDays: null,
            futureBookingDaysStart: null
        };
    }

    async loadStableAccess() {
        if (await this.props.auth.isMemberOfStable(this.props.match.params.sid)) {

        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    async loadStable() {
        let stable = await this.props.firebase.stableController.loadStableByID(this.props.match.params.sid);

        this.setState({
            stable: stable
        });
    }

    componentDidMount() {
        this.loadStableAccess();
        this.loadStable();
        this.props.blockRoute.setCanLeaveRoute(() => (this.state.name.trim().length === 0 && this.state.description.trim().length === 0 && this.state.slots === 3));
    }

    onSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.refs['create-facility-form'].reportValidity();

        if (!this.state.creating && !!this.state.name && !!this.state.slots) {
            this.props.blockRoute.setCanLeaveRoute(() => true);
            this.setState({creating : true});

            let openingStart;
            let openingEnd;

            if (!!this.state.openingStartHour && !!this.state.openingEndHour ) {
                openingStart = parseInt(this.state.openingStartHour)*  60 *60;
                openingEnd = parseInt(this.state.openingEndHour)*  60 *60 ;
            }

            let maxFutureBookingDays;
            let futureBookingDaysStart;

            if(!!this.state.maxFutureBookingDays) {
                maxFutureBookingDays = parseInt(this.state.maxFutureBookingDays);
            }

            if(!!this.state.futureBookingDaysStart) {
                futureBookingDaysStart = timeOfDayToSeconds(this.state.futureBookingDaysStart);
            }

            let facility = new Facility({
                sid: this.props.match.params.sid,
                name: this.state.name,
                description: this.state.description,
                maxBookingDuration: (this.state.maxBookingDuration > 0) ? parseInt(this.state.maxBookingDuration) * 60 : null,
                maxBookers: parseInt(this.state.slots),
                hasImage: !!this.state.facilityHeaderImage,
                openingStart:  openingStart,
                openingEnd: openingEnd,
                maxFutureBookingDays: maxFutureBookingDays,
                futureDaysBookingStart: futureBookingDaysStart
            });

            this.props.history.push(ROUTES.FACILITY.replace(":sid", this.props.match.params.sid));

            facility.fid  = await this.props.firebase.facilityController.createFacilityForStable(facility);

            if(!!this.state.facilityHeaderImage) {
                await this.props.firebase.storageController.uploadHeaderForFacility(this.state.facilityHeaderImage, facility.fid);
                facility.header = getFacilityHeaderUrl(facility.fid);
                FacilityCache.updateCacheEntry(facility);
            }
            this.props.auth.rebuildApp();
        }
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
            this.setState(this.state);
        }
    };

    render() {
        const { name, description, slots, maxBookingDuration,  openingStartHour, openingEndHour, maxFutureBookingDays, futureBookingDaysStart } = this.state;
        const isInvalid = !name || !slots || (!!openingStartHour &&  !openingEndHour) || (!openingStartHour &&  !!openingEndHour) || (!!openingStartHour &&  !!openingEndHour && (( parseInt(openingStartHour) >= parseInt(openingEndHour)) || parseInt(openingStartHour)<0 || parseInt(openingStartHour)>23 || parseInt(openingEndHour)< 1 || parseInt(openingEndHour)> 24 ));

        return (
            <div className="edit-facility-page">
                <form ref="create-facility-form">
                    <h2 className={"create-edit-facility-header"}>{strings.title}</h2>
                    <p>{strings.information}</p>

                    <div className="row flex-start">

                        <div className="ml-2">
                            <div className="card">
                                <div className="card-header">
                                    <h4>{strings.generalInfo}</h4>
                                    <small>{strings.generalInfoDesc}</small>
                                </div>
                                <div className="card-body">
                                    <div className="card-row">
                                        <label htmlFor="name">{strings.name}</label>
                                        <input
                                            name="name"
                                            onChange={this.onChange}
                                            type="text"
                                            value={name}
                                            placeholder={strings.namePlaceholder}
                                            required
                                        />
                                    </div>

                                    <div className="card-row">
                                        <label htmlFor="description">{strings.info}</label>
                                        <input
                                            name="description"
                                            onChange={this.onChange}
                                            type="text"
                                            value={description}
                                            placeholder={strings.infoPlaceholder}
                                        />
                                    </div>

                                    <div className="card-row">
                                        <label htmlFor="description">{strings.image}</label>
                                        <DeletableImage image={this.facilityHeaderImage} onImageChange={(image) => {
                                            this.setState({
                                                facilityHeaderImage: image
                                            });
                                        }}/>
                                    </div>


                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h4>{strings.settings}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="card-column">
                                        <label htmlFor="slots">{strings.slots}</label>
                                        <small className="caption">{strings.slotsDescription}</small>
                                        <input
                                            name="slots"
                                            onChange={this.onChange}
                                            type="number"
                                            min={1}
                                            max={100}
                                            value={slots}
                                            placeholder={strings.slotsPlaceholder}
                                            required
                                        />
                                    </div>

                                    <div className="card-column">
                                        <label htmlFor="maxBookingDuration">{strings.maxBookingDuration}</label>
                                        <small className="caption">{strings.maxBookingDurationDescription}</small>
                                        <div className="card-row">
                                            <input
                                                className="mr-1"
                                                name="maxBookingDuration"
                                                onChange={this.onChange}
                                                type="number"
                                                min="0"
                                                max="3600"
                                                value={maxBookingDuration}
                                                placeholder={strings.maxBookingDurationPlaceholder}
                                            />
                                            <p>{strings.minutes}</p>
                                        </div>
                                    </div>

                                    {
                                        !!this.state.stable && this.state.stable.hasPro() &&
                                        (
                                            <div className="card-column">
                                                <label htmlFor="maxBookingDuration">{strings.maxFutureBookingDays}</label>
                                                <small className="caption">{strings.maxFutureBookingDaysDescription}</small>
                                                <div className="card-row">
                                                    <div className="card-row">
                                                        <input
                                                            className="mr-1"
                                                            name="maxFutureBookingDays"
                                                            onChange={this.onChange}
                                                            type="number"
                                                            min="0"
                                                            max="3600"
                                                            value={maxFutureBookingDays}
                                                            placeholder={strings.always}
                                                        />
                                                    </div>
                                                    <div className="card-row">
                                                        <input
                                                            className="mr-1"
                                                            name="futureBookingDaysStart"
                                                            onChange={this.onChange}
                                                            type="time"
                                                            value={futureBookingDaysStart}
                                                            placeholder={strings.maxBookingDurationPlaceholder}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h4>{strings.openingHours}</h4>
                                    <label>{strings.openingHoursDescription}</label>
                                </div>
                                <div className="card-body" id="openingHourBody">
                                    <div className="time-row">
                                        <div className="card-row">
                                            <label htmlFor="openingStartHour">{strings.startTime}</label>
                                            <input
                                                className="mr-1"
                                                name="openingStartHour"
                                                min={0}
                                                max={23}
                                                onChange={this.onChange}
                                                type="number"
                                            />
                                           <p>:00 {strings.oClock}</p>
                                        </div>
                                    </div>
                                    <div className="time-row">
                                        <div className="card-row">
                                            <label htmlFor="openingEndHour">{strings.endTime}</label>
                                            <input
                                                className="mr-1"
                                                min={1}
                                                max={24}
                                                name="openingEndHour"
                                                onChange={this.onChange}
                                                type="number"
                                            />
                                            <p>:00 {strings.oClock}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end">
                                <button className="width-50" disabled={isInvalid} onClick={this.onSubmit}>{strings.save}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default withRouter(WithBlockRoute(WithAuthentication(WithFirebase(CreateFacility))));