import React from 'react';
import {Link, withRouter} from "react-router-dom";
import SideBar from "./sidebar";
import * as ROUTES from "../../routes";
import Firebase, {WithFirebase} from "../firebase/firebase";
import {WithAuthentication} from "../firebase/auth_provider";

import LocalizationsWithLanguage, {localizedLanguages} from "../localization_wrapper";
import ContextMenu from "../additional/context_menu";

let strings = new LocalizationsWithLanguage({
    de:{
        login:"Account erstellen",
        imprint:"Impressum",
        terms:"AGB",
        dpd:"Datenschutzerklärung",
        aboutJutta: "Über Jutta",
        icon: "german.png",
        cookie: "Cookies"
    },
    en: {
        login:"Create account",
        imprint:"Imprint",
        terms:"Terms of use",
        dpd:"Data protection",
        aboutJutta: "About Jutta",
        icon: "english.png",
        cookie: "Cookies"
    }
});

const LogoTopComponent = ({ text, abortSignUp, history, auth, firebase }) => (
    <header>
            <img src={"/img/jutta-mit-kreis-ohne-claim.png"} className={abortSignUp ? "clickable" : ""} onClick={async () => {
                if (abortSignUp) {
                    auth.firebaseUser.delete();
                    firebase.logout();
                    history.push(ROUTES.LANDING);
                }
            }}/>
            <h2>{ text || strings.login}</h2>
        </header>
);


const LogoTop = WithFirebase(WithAuthentication(withRouter(LogoTopComponent)));

const changeLanguage = (lang) => {
    if(strings.getLanguage() !== lang) {
        localStorage.setItem('lang', lang);
        window.location.reload();
    }
};

const Footer = () => (
    <footer>
        <ContextMenu overrideClassName="top-bar-contextmenu" overrideIcon={"/img/icons/flags/" + strings.icon} infoText={localizedLanguages[strings.getLanguage()]}>
            {
                Object.keys(localizedLanguages).map((code) => {
                    if (code !== strings.getLanguage()) {
                        return (
                            <div key={code} className="option language-option" onClick={() => changeLanguage(code)}>{localizedLanguages[code]}</div>
                        );
                    }
                })
            }
        </ContextMenu>
        <a href={"https://jutta.app/"} target="_blank" rel="noopener noreferrer">{strings.aboutJutta}</a>
        <a href={"#"} onClick={() => window.mmcm.launchDialogue()} >{strings.cookie}</a>
        <a href={"https://jutta.app/imprint"} target="_blank" rel="noopener noreferrer">{strings.imprint}</a>
        <Link to={ROUTES.TERMSOFUSE} target="_blank" rel="noopener noreferrer">{strings.terms}</Link>
        <Link to={ROUTES.DATAPROTECTION} target="_blank" rel="noopener noreferrer">{strings.dpd}</Link>
    </footer>
);

export default withRouter(SideBar);
export {LogoTop, Footer};
