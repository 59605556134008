import React, { Component } from 'react';
import {WithFirebase} from '../firebase/firebase';
import {withRouter} from "react-router-dom";
import {WithAuthentication} from "../firebase/auth_provider";
import * as elasticSearchController from "../elastic/elastic_controller";
import SearchResultEntry from "../elastic/search_result";
import * as ROUTES from "../../routes";
import LocalizationsWithLanguage, {localizedLanguages} from "../localization_wrapper";
import ContextMenu from "../additional/context_menu";

let strings = new LocalizationsWithLanguage({
    de:{
        searchPlaceholder:"Suche nach anderen Nutzern oder Reitställen",
        user:"Nutzer",
        stables:"Ställe",
        logout:"Ausloggen",
        viewProfile: "Profil ansehen",
        editProfile: "Profil bearbeiten"
    },
    en: {
        searchPlaceholder:"Search for other users or stables",
        user:"User",
        stables:"Stables",
        logout:"Sign out",
        viewProfile: "View profile",
        editProfile: "Edit profile"
    }
});

class SideBarUserLink extends React.Component {
    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
    };

    render() {
        return (
            <div className="userlink">
                <img src={this.props.avatar} className="profile-image" alt={strings.userProfileImageAlt} onClick={this.props.onClick} onError={this.onImageLoadFail}/>
                <h4>{this.props.name}</h4>
                <img src="/img/icons/white/dropdownTriangle.png" alt="Dropdown Icon" className="dropdown-triangle"/>
            </div>
        )
    }
}

class TopBarComponent extends Component {

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            term: "",
            searchUserResults: undefined,
            searchStableResults: undefined
        }
    }

    search = async (event) =>   {
        let val = event.target.value;
        this.state.term = val;
        this.setState(this.state);
        if (!!val && val.length >= 2) {
            let userResult = await elasticSearchController.searchUser(val);

            this.setState({searchUserResults: userResult});

            let stableResult = await elasticSearchController.searchStable(val);

            this.setState({searchStableResults: stableResult});
        } else {
            this.setState({searchStableResults: [], searchUserResults: []});
        }
    };

    onClick = async (link) =>  {
        this.props.history.push(link);
        this.setState({
            term: "",
            searchUserResults: undefined,
            searchStableResults: undefined
        });
    };

    onLogout = () => {
        this.props.firebase.logout();
        this.props.history.push(ROUTES.SIGN_IN);
    };

    switchToUserProfile= () => {
        this.props.history.push(ROUTES.USER.replace(':uid', this.props.auth.user.uid));
    };

    editUser = () => {
        this.props.history.push(ROUTES.EDITUSER);
    };

    render() {
        let usersToRender = !!this.state.searchUserResults && !!this.state.searchUserResults.users ? this.state.searchUserResults.users : [];
        let stablesToRender = !!this.state.searchStableResults && !!this.state.searchStableResults.stables ? this.state.searchStableResults.stables : [];
        let searchResults;

        if(usersToRender.length !== 0 || stablesToRender.length !== 0) {
            searchResults = <div className="search-results">
                {
                    usersToRender.length>0 ? <h4>{strings.user}</h4> : ""
                }

                {
                    usersToRender.map((user, i) => {
                        return (
                            <SearchResultEntry key={"user"+ user.uid} image={user.avatar} name={user.name} onClick={ (e) => this.onClick(`/users/${user.uid}`)}/>
                        )
                    })
                }

                {
                    stablesToRender.length>0 ? <h4> {strings.stables} </h4> : ""
                }

                {
                    stablesToRender.map((stable, i) => {
                        return (
                            <SearchResultEntry key={"stable"+ stable.sid} image={stable.header} name={stable.name} onClick={ (e) => this.onClick(`/stables/${stable.sid}/information`)}/>
                        )
                    })
                }

            </div>
        }

        return (
            <div className="top-bar">
                <div className="top-bar-search">
                    <input
                        name="search"
                        className="search"
                        type="text"
                        onChange={this.search}
                        value={this.state.term}
                        placeholder={strings.searchPlaceholder}
                    />

                    {searchResults}
                </div>

                <div className="top-links">
                    <ContextMenu overrideClassName="top-bar-contextmenu"
                                 openingElement={<SideBarUserLink key={this.props.auth.user.lastChangedAt + "SideBarUserLink"}
                                                                  avatar={this.props.auth.user.avatar}
                                                                  name={this.props.auth.user.name}/>}
                                 direction="down">
                        <div className="option language-option" onClick={this.switchToUserProfile}>{strings.viewProfile}</div>
                        <div className="option language-option" onClick={this.editUser}>{strings.editProfile}</div>
                        <div className="option language-option" onClick={this.onLogout}>{strings.logout}</div>
                    </ContextMenu>
                </div>
            </div>
        )
    }
}

const TopBar = withRouter(WithAuthentication(WithFirebase(TopBarComponent)));
export default TopBar;