import React, {Component} from 'react';
import * as ROUTES from '../../routes'
import {Link, withRouter} from "react-router-dom";
import {WithFirebase} from "../firebase/firebase";
import {SignInOptions} from "./sign_in";
import {Footer, LogoTop} from "../navigation/Footer";
import {WithAuthentication} from "../firebase/auth_provider";
import AuthType from "../models/authtype";
import LocalizationsWithLanguage from "../localization_wrapper";
import LoadingIndicator from "../additional/loading_indicator";

let strings = new LocalizationsWithLanguage({
    de:{
        resetPassword:"Passwort zurücksetzen!",
        resetDescription:"Du hast Dein Passwort vergessen? Wir senden dir einen Link zum Zurücksetzen des Passworts an deine E-Mail Adresse.",
        emailPlaceholder:"Email",
        resetConfirm:"Wenn ein Account mit dieser Email existiert senden erhälst du in den nächsten Minuten einen Link zum Zurücksetzen deines Passworts.",
        continue:"Fortfahren",
        landingPage: "Startseite",
        backToLogin: "Zurück zur Startseite",
    },
    en: {
        resetPassword:"Reset password!",
        resetDescription:"You forgot your password? We will send you a link to your email address to reset your password.",
        emailPlaceholder:"email",
        resetConfirm:"If an account with this email exist, you will receive an link to reset your password in the next minutes.",
        continue:"Continue",
        landingPage: "Home",
        backToLogin: "Back to home",
    }
});

const INITIAL_STATE = {
    email: '',
    isLoading: false,
    resetSuccess: false,
    error: null,
};

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state =  {...INITIAL_STATE} ;
    }

    onSubmit = event => {
        event.preventDefault();

        this.setState({isLoading: true});
        const { email } = this.state;

        this.props.firebase
            .sendPasswordResetEmail(email)
            .then(authUser => {
                this.setState({isLoading: false});
                this.setState({resetSuccess: true});
            })
            .catch(error => {
                this.setState({ error: error, isLoading: false });
            });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        return (
            <main>
                <div className="sign-in-container-wrapper">
                    <div className={"sign-up-container"}>
                        <div className="breadcrumbs justify-content-center mb-1">
                            <Link to={ROUTES.SIGN_IN} onClick={this.abortSignUp}>{strings.landingPage}</Link>
                            <p className="crumb">/</p>
                            <p>{strings.resetPassword}</p>
                        </div>

                        {
                            this.state.resetSuccess
                                ? <div className={"input-description"}>{strings.resetConfirm}</div>
                                : <div className={"input-description"}>{strings.resetDescription}</div>
                        }


                        { this.state.isLoading
                            ? <div className="mt-2"><LoadingIndicator/></div>
                            : this.state.resetSuccess
                                ? this.showSuccessOptions()
                                : this.showResetOptions()
                        }
                    </div>
                </div>
            </main>
        );
    }

    showSuccessOptions() {
        return <div className="reset-password-options">
            <form>
                <button className={"mt-1"}  onClick={() => this.props.history.push(ROUTES.HOME)}>{strings.backToLogin}</button>
            </form>
        </div>;
    }

    showResetOptions() {

        const isInvalid =
            this.state.email === '' ||  !this.state.email.includes('@');

        return <div className="reset-password-options">
            <form onSubmit={this.onSubmit}>
                <label htmlFor="email">{strings.emailPlaceholder}</label>
                <input
                    name="email"
                    onChange={this.onChange}
                    type="email"
                    placeholder={strings.emailPlaceholder}
                    required
                />
                {this.state.error &&
                    <div className="error-message mt-1">
                        {this.state.error.message}
                    </div>
                }
                <button className={"mt-1"} onClick={this.onSubmit} disabled={isInvalid}>{strings.continue}</button>
            </form>
        </div>;
    }
}
const ResetPassword = withRouter(WithAuthentication(WithFirebase(ResetPasswordForm)));

class ResetPasswordPage extends Component{
    render() {
        return (
            <ResetPassword {...this.props}/>
        )
    }
}
export default ResetPasswordPage;


