import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import LocalizationsWithLanguage from "../localization_wrapper";
import * as ROUTES from "../../routes";
import {WithAuthentication} from "../firebase/auth_provider";

let strings = new LocalizationsWithLanguage({
    de:{
        facilityHeaderAlt: "Anlagenbild",
        editFacility: "Anlage bearbeiten",
        bookingPlanExport: "Buchungsplan exportieren (PDF)",
        showFacility: "Buchungskalender öffnen",
    },
    en: {
        facilityHeaderAlt: "Facility image",
        editFacility: "Edit facility",
        bookingPlanExport: "Export booking plan",
        showFacility: "Book facility",
    }
});

class FacilityCard extends Component {

    imgHasFailed = false;

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/no_facility_picture.png";
        }
    };

    render() {
        return (
            <div className="card">
                <div className="card-header facility-card-header">
                    <div className="card-row">
                        <img className="facility-card-img" src={this.props.image} onError={this.onImageLoadFail} alt={strings.facilityHeaderAlt}/>
                        <div className="card-column">
                            <h3>{this.props.title}</h3>
                            <small>{this.props.description}</small>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="setting">
                        <Link to={ROUTES.BOOKINGS.replace(':sid', this.props.sid).replace(':fid', this.props.fid)}>
                            <div className="settings-icon">
                                <img alt="Show booking plan" src="/img/icons/white/time.png"/>
                            </div>
                        </Link>
                        <Link to={ROUTES.BOOKINGS.replace(':sid', this.props.sid).replace(':fid', this.props.fid)}>{strings.showFacility}</Link>
                    </div>

                    {
                        this.props.auth.stables.admin.hasOwnProperty(this.props.sid) &&
                        <div className="setting">
                            <Link to={ROUTES.EDITFACILITY.replace(':sid', this.props.sid).replace(':fid', this.props.fid)}>
                                <div className="settings-icon">
                                    <img alt="Edit your facility" src="/img/icons/white/edit.png"/>
                                </div>
                            </Link>
                            <Link to={ROUTES.EDITFACILITY.replace(':sid', this.props.sid).replace(':fid', this.props.fid)}>{strings.editFacility}</Link>
                        </div>
                    }

                    {
                        this.props.auth.stables.admin.hasOwnProperty(this.props.sid) &&
                        <div className="setting">
                            <Link to={ROUTES.EXPORTBOOKINGPLAN.replace(':sid', this.props.sid).replace(':fid', this.props.fid)}>
                                <div className="settings-icon">
                                    <img alt="Export booking plan" src="/img/icons/white/export.png"/>
                                </div>
                            </Link>
                            <Link to={ROUTES.EXPORTBOOKINGPLAN.replace(':sid', this.props.sid).replace(':fid', this.props.fid)}>{strings.bookingPlanExport}</Link>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

export default withRouter(WithAuthentication(FacilityCard));