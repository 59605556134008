import React from "react";

const Loading= () =>

            <div className="sign-in-logo-wrapper">
                    <img className="sign-in-logo" src={"/img/jutta-mit-kreis-ohne-claim.png"}/>
            </div>

;

export default Loading;