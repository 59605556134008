import React, {Component} from 'react';
import FirebaseInstance from './firebase_instance';

const Firebase = React.createContext(null);

function WithFirebase(WrappedComponent) {
    return class extends React.PureComponent {
        render() {
            return (
                <Firebase.Consumer>
                    {firebase => <WrappedComponent {...this.props} firebase={firebase}/>}
                </Firebase.Consumer>
            )
        }
    };
}

export default Firebase;

export { FirebaseInstance, WithFirebase};