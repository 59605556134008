import React, { Component} from 'react';
import StableHeader from "../header";
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {withRouter} from "react-router-dom";
import Loading from "../../loading";
import RenderedPost from "../../posts";
import debounce from "lodash.debounce";
import CreatePost from "../../posts/create";
import PostCache from "../../caches/post_cache";
import LocalizationsWithLanguage from "../../localization_wrapper";


let strings = new LocalizationsWithLanguage({
    de:{
        stableNoPostsHeader:"Keine öffentlichen Beiträge gefunden!",
        stableNoPostsText:"Die Stalladministratoren können hier im Namen des Stalls posten, für jeden Jutta! Nutzer sichtbar.",
        stableNoPostsImageAlt: "Keine öffentliche Beiträge Bild"
    },
    en: {
        stableNoPostsHeader:"No public posts found!",
        stableNoPostsText:"The stable administrator can post on behalf of the stable, visible for everyone on Jutta.",
        stableNoPostsImageAlt: "no public posts image"
    }
});

class LatestPosts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stable: undefined,
            init: true,
            loadingStable: true,
            loadingPosts: true,
            hasMorePosts: true,
            posts: []
        }

        window.onscroll = debounce(() => {
            const {
                loadOlderPostForStable,
                state: {
                    init,
                    loadingPosts,
                    hasMorePosts,
                },
            } = this;

            if (init || loadingPosts || !hasMorePosts) return;

            if (
                window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
            ) {
                loadOlderPostForStable(true);
            }
        }, 100);
    }

    async loadDisplayedStable() {
        this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
            this.setState({ stable : stable, loadingStable: false, init: false });
            let hasLoadedPosts = this.props.firebase.postController.hasOldPublicPostForStableCached(stable.sid);
            if (hasLoadedPosts) {
                let posts = await this.props.firebase.postController.loadOldPublicPostForStable(stable.sid, Date.now(),this.rebuild);
                this.setState({ stable : stable, posts: posts,loadingStable: false, init: false });
            } else {
                this.setState({ stable : stable, loadingStable: false, init: false });
            }

            if (hasLoadedPosts) {
                this.loadNewerPostsForStable();
            } else {
                let posts = await this.props.firebase.postController.loadOldPublicPostForStable(stable.sid, Date.now(),this.rebuild);
                this.setState({ posts: posts,loadingPosts: false});
            }
        });
    }

    loadOlderPostForStable = async (force = false) => {
        if (!!this.state.stable) {
            this.setState({loadingPosts: true});
            let lastPostId = this.state.posts.slice(-1)[0];
            let lastPost = this.props.firebase.postController.getPostByPid(lastPostId);
            if (!lastPost) {
                this.setState({hasMorePosts: false});
            } else {
                let posts = await this.props.firebase.postController.loadOldPublicPostForStable(this.state.stable.sid,lastPost.createdAt.getTime(),this.rebuild,force);
                let newPostArray = this.state.posts.concat(posts);

                this.setState({posts : newPostArray, loadingPosts: false});
            }

        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.refreshFsLightbox();
    }

    loadNewerPostsForStable = async () => {
        if (!!this.state.stable) {
            this.setState({loadingNewerPosts: true});
            if (this.state.posts.length > 0) {
                let lastPostId = this.state.posts.slice(0)[0];
                let lastPost = this.props.firebase.postController.getPostByPid(lastPostId);
                if (!lastPost) {
                    this.setState({hasMorePosts: false});
                } else {
                    let posts = await this.props.firebase.postController.loadLatestPublicPostForStable(this.state.stable.sid,lastPost.createdAt.getTime(),this.rebuild);
                    let newPostArray = posts.concat(this.state.posts);

                    this.setState({posts : newPostArray, loadingNewerPosts: false});
                }
            } else {
                let posts = await this.props.firebase.postController.loadLatestPublicPostForStable(this.state.stable.sid,0,this.rebuild);
                let newPostArray = posts.concat(this.state.posts);

                this.setState({posts : newPostArray, loadingNewerPosts: false});
            }
        }
    };

    rebuild = () =>  {
        this.setState(this.state);
    };

    componentDidMount() {
        this.loadDisplayedStable();
    }

    getContent = () => {

        if (!this.state.loadingPosts && (!this.state.posts || this.state.posts.length === 0) ) {
            return (
                <div className="stable-onboarding">
                    <img src="/img/jutta-groups.png" alt={strings.stableNoPostsImageAlt}/>
                    <b>{strings.stableNoPostsHeader}</b>
                    <p>{strings.stableNoPostsText}</p>
                </div>
            )
        }

        return (
            <div className="post-list">
                {
                    this.state.posts.map((postId, i) => {
                        let key = !!postId ? postId + i : i;
                        let post = this.props.firebase.postController.getPostByPid(postId);
                        return <RenderedPost key={"stableNewsStream" + key } post={post} canDelete={this.props.auth.stables.admin.hasOwnProperty(this.state.stable.sid)}/>
                    })
                }

            </div>
        )

    };


    addNewPost(post) {
        this.setState({
            createPostFromKey: Date.now()
        });
        if (!!post) {
            let posts = [post.pid];
            PostCache.prependStablePublicPostIds(this.state.stable.sid,posts);
            posts = posts.concat(this.state.posts);
            this.setState( {posts: posts});
        }
    }

    render() {
        return (
            <div>
                <StableHeader name={!!this.state.stable ? this.state.stable.name : ""} stables={this.props.auth.stables.active}/>
                {
                    (this.props.auth.stables.admin.hasOwnProperty(this.props.match.params.sid)) &&
                    <CreatePost key={this.state.createPostFromKey} sid={!!this.state.stable ? this.state.stable.sid : 0 } asStable={true} callback={(post) => this.addNewPost(post)}/>
                }
                {!this.state.loadingStable ? this.getContent() : <Loading />}
            </div>
        );

    }


}
export default withRouter(WithAuthentication(WithFirebase(LatestPosts)));

