import React, {Component, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import DateSelector from "./date_selector";
import BookingModal from "./booking_modal";
import Selection from '@simonwep/selection-js';
import Modal from "../../additional/modal";
import CreateNewBookingPage from "./create";
import {
    getColorFromString, getEndOfToday,
    getSecondsSinceStartOfDate,
    hourNumberToTimeOfDay,
    secondsToHours, secondsToTimeOfDay
} from "../../additional/helpers";
import BookingPreview from "./booking_preview";
import FacilityCache from "../../caches/facility_cache";
import BookingCache from "../../caches/booking_cache";
import LocalizationsWithLanguage from "../../localization_wrapper";
import * as ROUTES from "../../../routes";
import ErrorDialog from "../../additional/error_dialog";
import ContextMenu from "../../additional/context_menu";
import {Color} from "../../models/color";
import StableBookingsPermission from "../../models/stable_bookings_permissions";

let strings = new LocalizationsWithLanguage({
    de:{
        addBooking: "+ Buchung hinzufügen",
        oClock: "Uhr",
        loadFacility: "Anlage wird geladen...",
        goBack: "Zurück zum Stall",
        changeFacility: "Anlage wechseln",
        facilityImageAlt: "Anlagenvorschaubild",
        bookingsInAdvance: "Du kannst maximal $days Tage im Voraus buchen.",
        bookingsInAdvanceWithTime: "Du kannst maximal $days Tage im Voraus buchen, beginnend um $time.",
        needsProModalTitle: "Du brauchst Jutta! Pro",
        needsProModalDescription: "Um neue Buchungen anzulegen brauchst du Jutta! Pro. Du kannst Jutta! Pro in der App kaufen. Weitere Informationen gibt es hier:"
    },
    en: {
        addBooking: "+ Add booking",
        oClock: "o clock",
        loadFacility: "Loading facility...",
        goBack: "Go back to stable",
        changeFacility: "Change facility",
        facilityImageAlt: "Facility preview picture",
        bookingsInAdvance: "You can only book $days days in advance.",
        bookingsInAdvanceWithTime: "You can only book $days days in advance, starting at $time.",
        needsProModalTitle: "You need Jutta! Pro",
        needsProModalDescription: "If you want to create new bookings, you need Jutta! Pro. You can purchase Jutta! Pro in the app. More information is available here:"
    }
});

class BookingOverview extends Component{
    slotsPerHour = 4;
    selectionInstance;

    constructor(props) {
        super(props);
        let date = new Date();

        this.state = {
            timeInSeconds: getSecondsSinceStartOfDate(date),
            bookings: [],
            stable: undefined,
            facility: undefined,
            openingStartInHours: undefined,
            openingEndInHours: undefined,
            chosenDate: date,
            chosenStart: undefined,
            chosenEnd: undefined,
            chosenSlots: undefined,
            showCreateBooking: false,
            errorTitle: "",
            errorDescription: "",
            showErrorDialog: false,
            showBookingContent: true,
            hasPro: false,
            needsPro: false
        };
    }

    loadBookingsForDay = async (day) => {
        let bookings = await this.props.firebase.facilityController.loadBookingsForFacilityAndDay(this.props.match.params.sid, this.state.facility.fid, day);
        this.setState({
            bookings: bookings,
            openBooking: undefined
        });
    };

    moveCurrentTimeLine = () => {
        let currentTimeLineRef = this.refs['current-time-line'];
        if(!!currentTimeLineRef) {
            if (this.state.timeInSeconds < this.state.facility.openingStart) {
                currentTimeLineRef.style.display = "none";
            } else {
                let offset = (this.state.timeInSeconds - this.state.facility.openingStart) / 60 / 60 * 96;
                currentTimeLineRef.style.marginTop = offset + "px";
            }
        }
    };

    correctDate(date) {
        date.setUTCHours(12);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);

        return date;
    }

    /**
     * @param {Date} newDate
     */
    changeDate(newDate) {
        this.moveCurrentTimeLine();
        this.setState({chosenDate:  this.correctDate(newDate)});
        this.loadBookingsForDay(newDate);
    }

    loadStableAndFacility = async () => {
        if (await this.props.auth.isMemberOfStable(this.props.match.params.sid)) {
            let stable = this.props.auth.stables.active[this.props.match.params.sid];
            if (!stable || typeof stable === "boolean") {
                stable = await this.props.firebase.stableController.loadStableByID(this.props.match.params.sid);
            }

            stable.customMemberColors = await this.props.firebase.stableController.loadCustomMemberColorsForStable(this.props.match.params.sid);

            if (stable.bookingsPermission === StableBookingsPermission.HIDEALL && stable.hasPro()) {
                let canViewAllBookings = await this.props.auth.isEmployeeOfStable(this.props.match.params.sid) || await this.props.auth.isAdminOfStable(this.props.match.params.sid);
                this.setState( { showBookingContent: canViewAllBookings})
            }
            this.setState({stable: stable});

            let facility = FacilityCache.getFacilityById(this.props.match.params.fid);
            if(!facility) {
                await this.props.firebase.facilityController.loadFacilityByID(this.props.match.params.sid, this.props.match.params.fid);
                facility = FacilityCache.getFacilityById(this.props.match.params.fid);
            }
            this.setState({
                facility: facility,
                openingStartInHours: facility.openingStart / 60 / 60,
                openingEndInHours: facility.openingEnd / 60 / 60
            });

            this.moveCurrentTimeLine();
            this.loadBookingsForDay(this.correctDate(this.state.chosenDate));
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    };

    loadProStatus = async (retryCount = 0) => {
        let stable = this.props.auth.stables.active[this.props.match.params.sid];

        if(!stable && retryCount < 3) {
            setTimeout(() => {
                this.loadProStatus(retryCount + 1);
            }, 250);
            return;
        }

        let stableHasPro = stable?.newPro || false;
        let userHasPro = false;
        if(!stableHasPro) {
            userHasPro = this.props.firebase.proController.getCachedProStatus();

            if(userHasPro === undefined) {
                userHasPro = await this.props.firebase.proController.loadProStatus();
            }
        }

        this.setState({
            hasPro: stableHasPro || userHasPro,
            needsPro: this.props.firebase.proController.hasReachedCutoffDate()
        })
    };

    removeSelection = () => {
        for (const el of this.selectionInstance.getSelection()) {
            el.classList.remove('selected');
        }
        this.selectionInstance.clearSelection();
    };

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
    };

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    componentDidMount() {
        this.loadStableAndFacility();
        this.loadProStatus();
        this.loadFacilities();

        this.refreshInterval = setInterval(() => {
            this.loadBookingsForDay(this.correctDate(this.state.chosenDate));
        }, 5 * 60 * 1000);

        this.selectionInstance = new Selection({
            // Class for the selection-area-element
            class: 'all-bookings',
            // px, how many pixels the point should move before starting the selection (combined distance).
            // Or specifiy the threshold for each axis by passing an object like {x: <number>, y: <number>}.
            startThreshold: 10,
            // Disable the selection functionality for touch devices
            disableTouch: false,
            // On which point an element should be selected.
            // Available modes are cover (cover the entire element), center (touch the center) or
            // the default mode is touch (just touching it).
            mode: 'touch',
            // Enable single-click selection (Also disables range-selection via shift + ctrl)
            singleClick: true,
            // Query selectors from elements which can be selected
            selectables: ['.free-booking-slot'],
            // Query selectors for elements from where a selection can be start
            startareas: ['.all-bookings'],
            // Query selectors for elements which will be used as boundaries for the selection
            boundaries: ['.all-bookings'],
            // Query selector or dom node to set up container for selection-area-element
            selectionAreaContainer: '.all-bookings',
            // On scrollable areas the number on px per frame is devided by this amount.
            // Default is 10 to provide a enjoyable scroll experience.
            scrollSpeedDivider: 10
        }).on('start', ({inst, selected, oe}) => {
            for (const el of selected) {
                el.classList.remove('selected');
            }
            inst.clearSelection();
        }).on('move', ({changed: {removed, added}}) => {
            for (const el of added) {
                el.classList.add('selected');
            }
            for (const el of removed) {
                el.classList.remove('selected');
            }
        }).on('stop', event => {
            let inst = event.inst;
            inst.keepSelection();
            let selections = inst.getSelection();
            if(selections.length > 0) {
                let startTime = parseInt(selections[0].attributes['data-y'].value) / this.slotsPerHour;
                let endTime = parseInt(selections[selections.length-1].attributes['data-y'].value) / this.slotsPerHour;
                endTime = endTime + 1 / this.slotsPerHour;

                let newChosenSlots = [];

                selections.forEach((entry) => {
                    let slot = parseInt(entry.attributes['data-x'].value);

                    if(!newChosenSlots.includes(slot)) {
                        newChosenSlots.push(slot);
                    }
                });

                event.oe.preventDefault();
                event.oe.stopPropagation();
                this.setState({
                    showCreateBooking: true,
                    chosenStart: hourNumberToTimeOfDay(startTime),
                    chosenEnd: hourNumberToTimeOfDay(endTime),
                    chosenSlots: newChosenSlots
                });
            }
        });
    }

    getSlotHeader() {
        // The extra divs around the slots are needed, otherwise they would just use as little space as possible and not align with the columns
        let headers = [
            <div id="time-slot-header-wrapper" key={"sth"}>
                <div className="time-slot-header" key="header0"/>
            </div>
        ];

        for(let i = 1; i <= this.state.facility.maxBookers; i++) {
            // The extra divs around the slots are needed, otherwise they would just use as little space as possible and not align with the columns
            headers.push(<div className="slot-header" ref={"header" + i} key={"header" + i}>{i}.</div>)
        }
        return headers;
    }

    getTimeSlots() {
        let timeSlots = [];
        for(let i = 0; i < 24; i++) {
            if(!this.state.facility.openingStart && !this.state.facility.openingEnd || (i >= this.state.openingStartInHours && i < this.state.openingEndInHours)) {
                timeSlots.push(<div className="time-slot" key={"hour" + i} ref={"hour" + i}><span className="hour">{i}</span><span className="minute">00 {strings.oClock}</span></div>);
            }
        }
        return timeSlots;
    }

    bookingClicked = (booking) => {
        this.setState({
           openBooking: booking
        });
    };

    showErrorDialog = (title,description) => {
        this.loadBookingsForDay(this.correctDate(this.state.chosenDate));

        this.setState({
            errorTitle: title,
            errorDescription: description,
            showErrorDialog: true
        });
    };

    closeErrorDialog = () => {
        this.setState({
            errorTitle: "",
            errorDescription: "",
            showErrorDialog: false
        });
    };

    closeBooking = () => {
        this.setState({
            openBooking: undefined
        });
    };

    closeCreateBooking = () => {
        this.removeSelection();
        this.setState({
            showCreateBooking: false
        })
    };

    removeAllHighlights = () => {
        let fullHeaderRef = this.refs['bookings-header'];
        let fullTimeSlotsRef = this.refs['time-slots'];

        for(let element of fullHeaderRef.getElementsByClassName('booking-slot-highlight')) {
            element.classList.remove("booking-slot-highlight");
        }

        for(let element of fullTimeSlotsRef.getElementsByClassName('booking-slot-highlight')) {
            element.classList.remove("booking-slot-highlight");
        }
    };

    highlightPlanHeaderAndTime = (x, y) => {
        this.removeBookingPreview();
        let headerRef = this.refs['header' + (x+1)];
        let hourRef = this.refs['hour' + Math.floor(y / this.slotsPerHour)];

        this.removeAllHighlights();

        if(!!headerRef) {
            headerRef.classList.add("booking-slot-highlight");
        }
        if(!!hourRef) {
            hourRef.classList.add("booking-slot-highlight");
        }
    };

    showBookingPreview = (id,x) => {
        this.setState({previewBooking: id, previewBookingOffset:x || undefined });
    };

    removeBookingPreview = () => {
        if (!!this.state.previewBooking) {
            this.setState({previewBooking: undefined,previewBookingOffset: undefined});
        }
    };

    getBookingSlots() {
        let columns = [];
        let detailedBookings = {};
        let bookings = [];
        let that = this;
        this.state.bookings.forEach( function(bookingId) {
            let booking = BookingCache.getBookingById(bookingId);
            if (!!booking) {
                if (booking.isActiveAtDay(that.state.chosenDate)) {
                    bookings.push(booking);
                }
            }
        });

        for(let i = 0; i < this.state.facility.maxBookers; i++) {
            let rows = [];
            for(let j = 0; j < 24 * this.slotsPerHour; j++) {


                if(!this.state.facility.openingStart && !this.state.facility.openingEnd || (j / this.slotsPerHour >= this.state.openingStartInHours && j / this.slotsPerHour < this.state.openingEndInHours)) {

                    let bookingIndex = bookings.findIndex((booking) => !booking.deleted && (j / this.slotsPerHour) >= (booking.start / 60 / 60) && (j / this.slotsPerHour) < (booking.end / 60 / 60) && booking.lanes.includes(i));

                    if(bookingIndex >= 0) {
                        if(!!this.state.stable.customMemberColors && !!this.state.stable.customMemberColors[bookings[bookingIndex].uid]) {
                            bookings[bookingIndex].bookingColor = new Color(parseInt(this.state.stable.customMemberColors[bookings[bookingIndex].uid], 16));
                        } else {
                            bookings[bookingIndex].bookingColor = getColorFromString(bookings[bookingIndex].uid);
                        }
                    }

                    let that = this;
                    let onMouseEnter = function(event) {
                        that.highlightPlanHeaderAndTime(i, j);
                        if (bookingIndex >= 0) {
                            let xOffset;
                            let bookingStart  = that.refs["booking"+bookings[bookingIndex].bid];
                            let bookingPreview  = document.getElementById("bookingPreview"+bookings[bookingIndex].bid);
                            if (!!bookingStart) {
                                let rect = event.target.getBoundingClientRect();
                                xOffset= rect.left - bookingStart.getBoundingClientRect().left;

                                if (!!bookingPreview && (xOffset > ( window.innerWidth - bookingPreview.getBoundingClientRect().width -100))) {
                                    xOffset = ( window.innerWidth - bookingPreview.getBoundingClientRect().width -100);
                                }

                                if (!!bookingPreview && window.innerWidth < bookingPreview.getBoundingClientRect().width +100) {
                                    xOffset = 0;
                                }
                            }
                            that.showBookingPreview(bookings[bookingIndex].bid,xOffset )
                        }
                    };

                    let styleObject;

                    if(bookingIndex >= 0) {
                        let booking = bookings[bookingIndex];
                        let borderColor = (booking.approved !== undefined && !booking.approved) ? booking.bookingColor.getRGBAString() : null;

                        styleObject = {
                            backgroundColor: (booking.approved === undefined || booking.approved) ? booking.bookingColor.getRGBAString() : booking.bookingColor.withOpacity(0.3).getRGBAString(),
                            borderWidth: '2px',
                            borderColor: borderColor,
                            boxSizing: 'border-box'
                        };

                        if(!!borderColor) {
                            // If currently rendering lane is the first slot of the booking
                            if(i === booking.lanes[0]) {
                                styleObject.borderLeftStyle = 'solid';
                            }

                            // If currently rendering lane is the last slot of the booking
                            if(i === booking.lanes[booking.lanes.length - 1]) {
                                styleObject.borderRightStyle = 'solid';
                            }

                            // If currently rendering slot is the start of the booking
                            if((j / this.slotsPerHour) === (booking.start / 60 / 60)) {
                                styleObject.borderTopStyle = 'solid';
                            }

                            // If currently rendering slot is the end of the booking
                            if((j / this.slotsPerHour) === ((booking.end / 60 - 15) / 60)) {
                                styleObject.borderBottomStyle = 'solid';
                            }
                        }
                    }

                    let canViewBooking = false;
                    if ( bookingIndex >= 0 ) {
                        canViewBooking = this.state.showBookingContent
                            ||  bookings[bookingIndex].uid === this.props.auth.user.uid;
                    }


                    rows.push(
                        <div className={bookingIndex >= 0 ? "used-booking-slot" : "free-booking-slot"} key={"booking" + i + j}
                             onClick={
                                 canViewBooking
                                     ? () => this.bookingClicked(bookings[bookingIndex])
                                     : null
                             }
                             data-x={i} data-y={j} onMouseEnter={(e) => onMouseEnter(e)}
                             style={styleObject}>
                            {bookingIndex >= 0 ? this.getRenderedBooking(bookings[bookingIndex], detailedBookings,canViewBooking) : null}
                        </div>
                    );
                }
            }

            columns.push(<div key={"column" + i}>{rows}</div>);
        }

        return columns;
    }

    getRenderedBooking(booking, detailedBookings, canView) {
        if(!detailedBookings.hasOwnProperty(booking.bid)) {
            let returnBooking = (
                <div ref={"booking"+booking.bid}
                     className={"booking " + ((!detailedBookings[booking.bid] && this.state.previewBooking === booking.bid) ? "booking-primary" : "")}
                     style={{height: (secondsToHours(booking.end) - secondsToHours(booking.start)) * this.slotsPerHour * 22 + "px" }}
                     >

                    <div className="booking-slot-info">
                        <div>
                            <img src={"/img/icons/dark/person.png"}/>
                            {!!detailedBookings[booking.bid] || !canView ? null : this.shortenUserName(booking)}
                        </div>
                        <div>{!!detailedBookings[booking.bid]  || !canView ? null : booking.notes}</div>
                    </div>

                    {!detailedBookings[booking.bid] && this.state.previewBooking === booking.bid && canView && <BookingPreview offsetY={this.state.previewBookingOffset} color={booking.bookingColor.getRGBAString()} booking={booking}/>}
                </div>
            );

            detailedBookings[booking.bid] = true;
            return returnBooking;
        }
    }

    shortenUserName(booking) {
        let laneCount = booking.lanes.length;

        if(laneCount === 1 && booking.userName.length > 12 ) {
            return booking.userName.substr(0, 12) + (booking.userName.length > 4 ? "..." : "");
        }
        else if(laneCount === 2 && booking.userName.length > 24) {
            return booking.userName.substr(0, 24) + (booking.userName.length > 9 ? "..." : "");
        }
        else if (booking.userName.length > 36) {
            return booking.userName.substr(0, 36) + (booking.userName.length > 13 ? "..." : "");
        }
        return booking.userName;
    }

    onBookingCreated = () => {
        this.setState({
            showCreateBooking: false,
            chosenStart: undefined,
            chosenEnd: undefined,
            chosenSlots: undefined
        });
    };

    loadFacilities = async () => {
        await this.props.firebase.facilityController.loadFacilitiesForStable(this.props.match.params.sid);
        this.setState(this.state);
    };

    getBookingFutureWarning = () => {
        if(!this.props.auth.stables.admin.hasOwnProperty(this.state.stable.sid)) {
            let endOfToday = getEndOfToday();
            let maxBookingDate = new Date(endOfToday.getTime());
            maxBookingDate.setDate(maxBookingDate.getDate() + this.state.facility.maxFutureBookingDays);

            if(!!this.state.facility.futureDaysBookingStart) {
                let now = new Date();
                let timeOfDayInSeconds = (now.getHours() * 60 + now.getMinutes()) * 60;

                if(this.state.facility.futureDaysBookingStart > timeOfDayInSeconds) {
                    maxBookingDate.setDate(maxBookingDate.getDate() - 1);
                }
            }

            if(this.state.chosenDate > maxBookingDate) {
                return (
                    <p className="mr-1">
                        {
                            !!this.state.facility.futureDaysBookingStart
                                ? strings.bookingsInAdvanceWithTime.replace('$days', this.state.facility.maxFutureBookingDays).replace('$time', secondsToTimeOfDay(this.state.facility.futureDaysBookingStart))
                                : strings.bookingsInAdvance.replace('$days', this.state.facility.maxFutureBookingDays)
                        }
                    </p>
                );
            }
        }
    }

    render() {
        let facility = FacilityCache.getFacilityById(this.props.match.params.fid);

        if(!facility) {
            return (
                <p className="ml-2">{strings.loadFacility}</p>
            );
        }
        this.state.facility = facility;

        let bookings =  BookingCache.getBookingIdsByFacilityAndDay(facility.fid,this.state.chosenDate.getTime());
        if (!!bookings) {
            this.state.bookings = bookings;
        }

        let facilityIds = FacilityCache.getFacilityIdsForStable(this.props.match.params.sid);

        let facilities = [];
        if (!!facilityIds) {
            facilityIds.forEach( function(id) {
                let facility = FacilityCache.getFacilityById(id);
                if (!!facility) {
                    facilities.push(facility );
                }
            });

            facilities = facilities.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        }

        return (
            <Fragment>
                <div className="booking-plan-header">
                    <div className="stable-facility">
                        <b>{!this.state.stable ? "" : this.state.stable.name}</b>
                        <span className="dot"/>
                        <b>{!this.state.facility ? "" : this.state.facility.name}</b>
                        {facilities.length > 1 ?
                            <ContextMenu overrideClassName="top-bar-contextmenu" selectType="facility-select" direction="down" openingElement={
                                <a onClick={this.openCloseModal}>{strings.changeFacility}
                                    <img src="/img/icons/primary/dropdowntriangle.png" alt="Dropdown Icon" className="dropdown-triangle-stable"/>
                                </a>}>
                            {
                                facilities.map((facility, i) => {
                                        return (
                                            <Link key={facility.fid} to={`/stables/${!this.state.stable ? "" : this.state.stable.sid}/facility/${facility.fid}`} className={this.state.facility.fid === facility.fid ? "disabled-option" : ""}>
                                            <div className="option language-option" key={"changeStable" + facility.fid}>
                                                <div className="stable-preview-div">
                                                <img src={facility.header} onError={this.onImageLoadFail} alt={strings.facilityImageAlt}/>
                                                </div>
                                                    {facility.name}
                                            </div>
                                            </Link>
                                        )
                                    }
                                )
                            }
                        </ContextMenu> : ""
                        }
                            </div>
                    <div className="booking-selector">
                        {
                            !!this.state.stable && this.state.stable.hasPro() && !!this.state.facility.maxFutureBookingDays &&
                                this.getBookingFutureWarning()
                        }
                        <DateSelector date={this.state.chosenDate} onDateChange={(newDate) => this.changeDate(newDate)}/>
                        <button className="addBooking" onClick={() => this.setState({showCreateBooking: true})}>{strings.addBooking}</button>
                        <button className="secondary-button facility-go-back-button" onClick={() => this.props.history.push(ROUTES.FACILITY.replace(":sid", this.props.match.params.sid))}>
                            <img src="/img/icons/primary/backIcon.png" alt="Back Icon"/>
                            {strings.goBack}
                        </button>
                    </div>
                </div>

                <div className="booking-plan">
                    <div className="bookings-header" ref="bookings-header">
                        {this.getSlotHeader()}
                    </div>

                    <div className="booking-plan-content">
                        <div ref="current-time-line" className="current-time-line"/>
                        <div className="time-slots" ref="time-slots">
                            {this.getTimeSlots()}
                        </div>

                        <div className="all-bookings" onMouseLeave={this.removeAllHighlights}>
                            {this.getBookingSlots()}
                        </div>
                    </div>
                </div>

                <BookingModal
                    onClose={this.closeBooking}
                    booking={this.state.openBooking}
                    stable={this.state.stable}
                    facility={this.state.facility}
                    onError={(title,description) => this.showErrorDialog(title,description)}/>
                <Modal onClose={this.closeCreateBooking} show={this.state.showCreateBooking} key={new Date()}>
                    {
                        !this.state.needsPro || this.state.hasPro
                        ? <CreateNewBookingPage
                                stable={this.state.stable}
                                facility={this.state.facility}
                                onCreate={this.onBookingCreated}
                                date={this.state.chosenDate}
                                start={this.state.chosenStart}
                                end={this.state.chosenEnd}
                                slots={this.state.chosenSlots}
                                onClose={() => this.setState({showCreateBooking: false})}
                                onError={(title,description) => this.showErrorDialog(title,description)}
                            />
                        : <ErrorDialog
                                title={strings.needsProModalTitle}
                                description={strings.needsProModalDescription}
                                link={'https://jutta.app/preise'}
                                onClose={this.closeCreateBooking}
                            />
                    }
                </Modal>
                <Modal onClose={this.closeErrorDialog} show={this.state.showErrorDialog}>
                    <ErrorDialog
                        title={this.state.errorTitle}
                        description={this.state.errorDescription}
                        onClose={this.closeErrorDialog}
                    />
                </Modal>
            </Fragment>
        )
    }
}


const BookingOverviewPage = withRouter(WithAuthentication(WithFirebase(BookingOverview)));
export default BookingOverviewPage;