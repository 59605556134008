import React, {Component} from 'react';
import * as ROUTES from '../../routes'
import {Link, withRouter} from "react-router-dom";
import {WithFirebase} from "../firebase/firebase";
import {SignInOptions} from "./sign_in";
import {Footer, LogoTop} from "../navigation/Footer";
import {WithAuthentication} from "../firebase/auth_provider";
import AuthType from "../models/authtype";
import LocalizationsWithLanguage from "../localization_wrapper";
import LoadingIndicator from "../additional/loading_indicator";

let strings = new LocalizationsWithLanguage({
    de:{
        welcome:"Herzlich Willkommen!",
        welcomeDescription:"Wie möchtest du Dich zukünftig bei Jutta! anmelden?",
        emailPlaceholder:"Email",
        passwordPlaceholder:"Passwort",
        passwordConfirmPlaceholder: "Passwort wiederholen",
        continue:"Fortfahren",
        createAccount:"Benutzerkonto erstellen",
        useSocialNetwork:"Oder nutze ein soziales Netzwerk!",
        passwordsMustMatch: "Die Passwörter müssen übereinstimmen.",
        mandatoryFields: "* Pflichtfelder",
        passwordTooShort: "Das Passwort muss mindestens 6 Zeichen lang sein.",
        landingPage: "Startseite",
    },
    en: {
        welcome:"Welcome!",
        welcomeDescription:"How do you want to sign in into Jutta!?",
        emailPlaceholder:"Email address",
        passwordPlaceholder:"Password",
        passwordConfirmPlaceholder: "Repeat password",
        continue:"Continue",
        createAccount:"Create account",
        useSocialNetwork:"Or use a social network account!",
        passwordsMustMatch: "The passwords must match.",
        mandatoryFields: "* Mandatory Fields",
        passwordTooShort: "The password must be at least 6 characters long.",
        landingPage: "Home",
    }
});

const INITIAL_STATE = {
    email: '',
    password: '',
    passwordValidation: '',
    isLoading: false,
    error: null,
    passwordsValidated: true,
    passwordValid: true
};

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state =  {...INITIAL_STATE} ;
    }

    onSubmit = event => {
        event.preventDefault();

        this.setState({isLoading: true});
        const { email, password } = this.state;
        this.props.auth.setLastUsedAuthType(AuthType.EMAIL);

        this.props.firebase
            .createUserWithEmailAndPassword(email, password)
            .then(authUser => {
                this.props.firebase.sendVerificationEmail();
                this.setState({isLoading: false});
                this.props.history.push(ROUTES.ADDITIONALINFORMATION);
            })
            .catch(error => {
                this.setState({ error: error, isLoading: false });
            });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.validatePasswords();
            if (!this.state.passwordValid) {
                this.validatePasswordLength();
            }
        });
    };

    validatePasswords = () => {
        this.setState({
            passwordsValidated: (
                (this.state.password.length < 5 && this.state.passwordValidation.length < 5)
                || (this.state.password.length > this.state.passwordValidation.length))
                || (this.state.password === this.state.passwordValidation)
        });
    };

    blurValidatePasswords = () => {
        this.setState({
            passwordsValidated: (this.state.password === this.state.passwordValidation)
        });
    };

    validatePasswordLength = () => {
        this.setState({passwordValid: this.state.password.length >= 6});
    };

    render() {
        return (
            <main>
                <div className="sign-in-container-wrapper">
                    <div className={"sign-up-container"}>
                        <div className="breadcrumbs justify-content-center mb-1">
                            <Link to={ROUTES.SIGN_IN} onClick={this.abortSignUp}>{strings.landingPage}</Link>
                            <p className="crumb">/</p>
                            <p>{strings.welcome}</p>
                        </div>
                        <div className={"input-description"}>{strings.welcomeDescription}</div>

                        { this.state.isLoading ? <div className="mt-2"><LoadingIndicator/></div> : this.showSignUpOptions() }
                    </div>
                </div>
            </main>
        );
    }

    showSignUpOptions() {

        const isInvalid =
            this.state.password !== this.state.passwordValidation ||
            this.state.password === '' ||
            this.state.password.length < 6 ||
            this.state.email === '';

        return <div id="sign-up-options">
            <form onSubmit={this.onSubmit}>
                <label htmlFor="email">{strings.emailPlaceholder + "*"}</label>
                <input
                    name="email"
                    onChange={this.onChange}
                    type="email"
                    placeholder={strings.emailPlaceholder}
                    required
                />
                <label htmlFor="password">{strings.passwordPlaceholder + "*"}</label>
                <input
                    name="password"
                    onChange={this.onChange}
                    type="password"
                    minLength="6"
                    onBlur={() => this.validatePasswordLength()}
                    placeholder={strings.passwordPlaceholder}
                    required
                />
                <small className="error-message">{ this.state.passwordValid ? "" : strings.passwordTooShort}</small>
                <label htmlFor="passwordValidation">{strings.passwordConfirmPlaceholder + "*"}</label>
                <input
                    name="passwordValidation"
                    onChange={this.onChange}
                    type="password"
                    minLength="6"
                    placeholder={strings.passwordConfirmPlaceholder}
                    onBlur={this.blurValidatePasswords}
                    required
                />

                {this.state.error &&
                <div className="error-message mt-1">
                    {this.state.error.message}
                </div>
                }
                <small>{strings.mandatoryFields}</small>
                {
                    this.state.passwordsValidated
                        ? ""
                        : <small style={{"margin-bottom": 0}} className="error-message">{strings.passwordsMustMatch}</small>
                }
                <button className={"mt-1"} onClick={this.onSubmit} disabled={isInvalid}>{strings.continue}</button>
            </form>
            <div className="mt-2">
                <div className="hr-sect">{strings.useSocialNetwork}</div>
                <SignInOptions {...this.props}/>
            </div>
        </div>;
    }
}
const SignUp = withRouter(WithAuthentication(WithFirebase(SignUpForm)));

class SignUpPage extends Component{
    render() {
        return (
            <SignUp {...this.props}/>
        )
    }
}


class SignUpButtonLayout extends Component {

    onRedirect = () => {
        this.props.history.push(ROUTES.SIGN_UP);
    };

    render() {
        return (
            <button onClick={this.onRedirect}>{strings.createAccount}</button>
        );
    };

}

const SignUpButton = withRouter(SignUpButtonLayout);
export default SignUpPage;
export {  SignUpButton };

