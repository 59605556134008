import React, { Component } from 'react';
import {Redirect, withRouter} from "react-router-dom";
import * as ROUTES from "../../routes";

class AddRedirectUrl extends React.PureComponent {
    render() {
        return (
            <Redirect to={ROUTES.SIGN_IN + (this.props.match.url !== "/" ? ("?redirect=" + this.props.match.url) : "")}/>
        );
    }
}

class RedirectHandler extends React.PureComponent  {
    render() {
        let redirectUrl = this.props.location.search;
        if(!!redirectUrl) {
            // Removing the "?redirect" as URL parameter to redirect
            // The weird syntax with "/%2F/g" is needed, so it replaces all occurences
            redirectUrl = redirectUrl.replace('?redirect=', '').replace(/%2F/g, '/');
        }

        return <Redirect to={redirectUrl}/>;
    }
}

const RouterRedirectHandler = withRouter(RedirectHandler);

export default AddRedirectUrl;
export { RouterRedirectHandler };