const UserStatus = {
    INVITED : 'invited',
    REQUESTED : 'requested',
    NONE : 'none',
    MEMBER : 'member',
    BANNED : 'banned',
};



export default UserStatus;
