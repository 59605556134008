import React from "react";
import {updateDummyPostForAuthUser} from "../models/post";
import imageCompression from "browser-image-compression";
import Modal from "../additional/modal";
import ExpandingText from "../additional/expanding_text";
import {WithFirebase} from "../firebase/firebase";
import LocalizationsWithLanguage from "../localization_wrapper";
import PostCache from "../caches/post_cache";

let strings = new LocalizationsWithLanguage({
    de:{
        editPost: "Post editieren",
        cancel: "Abbrechen",
        confirm: "Bestätigen",
        media: "Foto",
        postImageAlt: "Beitragsbild",
    },
    en: {
        editPost: "Edit Post",
        cancel: "Cancel",
        confirm: "Confirm",
        media: "Picture",
        postImageAlt: "post image"
    }
});

class EditPostModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            post: props.post,
            images: [],
            content: undefined,
        }
    }

    onUpdate = async () => {
        this.props.close();

        let _storageController = this.props.firebase.storageController;
        let _postController = this.props.firebase.postController;

        let dummyPost = updateDummyPostForAuthUser( {updatedPost: this.state.post, content: this.state.content});

        if (!!this.props.callback) {
            let localImageUrls = [];
            for (let i=0; i<this.state.images.length; i++) {
                localImageUrls.push(this.state.images[i].url);
            }
            dummyPost.images=localImageUrls;
            dummyPost.isSaving = true;
            PostCache.updateCacheEntry(dummyPost);
            this.props.callback();
        }
        let newImageUrls = [];
        for (let i=0; i<this.state.images.length; i++) {
            if (!!this.state.images[i].file) {
                newImageUrls.push(await _storageController.uploadImageForPost(this.state.images[i].file,dummyPost));
            } else if(!!this.state.images[i].url){
                newImageUrls.push(this.state.images[i].url);
            }
        }
        dummyPost.images = newImageUrls;
        await _postController.updatePost(dummyPost);
        if (!!this.props.callback) {
            this.props.callback();
        }

    };

    componentDidMount() {
        if (!!this.props.post) {
            this.props.post.images.forEach( (image) => this.state.images.push( {url: image, file: undefined}));
            this.setState(this.state);
        }

    }

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    onTextChange = event => {
        this.state.content = event.target.value;
    };

    openFilePicker = (e) => {
        e.preventDefault();
        this.refs["filePicker"].click();
    };

    compressFile = async (event) => {
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1200,
            useWebWorker: true,
            onProgress: () => null
        };

        let files = event.target.files;

        for (let i= 0; i < files.length; i++) {
            let file = files[i];
            let compressed = await imageCompression(file, options);
            let url = URL.createObjectURL(compressed);

            this.state.images.push({file: compressed, url: url});
            this.setState(this.state);
        }

    };

    removeImage = (url) => {
        for (let i=0; i<this.state.images.length; i++) {
            if (this.state.images[i].url === url) {
                this.state.images.splice(i, 1);
                break;
            }
        }
        this.setState(this.state);
    };


    render() {
        return (
            <Modal show={this.props.show} onClose={this.props.close}>
                <h2 className={"modal-header"}> {strings.editPost} </h2>

                <div className="row">
                    <ExpandingText
                        initValue={this.state.post.textContent}
                        onChange={this.onTextChange}
                        placeholder={strings.contentPlaceholder}
                    />

                </div>

                <input name="filePicker" accept="image/*" type="file" ref="filePicker" style={{display: "none"}}   onChange={(event)=> {this.compressFile(event)}} onClick={(event)=> {event.stopPropagation();event.target.value = null}} multiple/>

                { !!this.state.images && this.state.images.length > 0 ?
                    <div className={"selected-images-bar "}>
                        { this.state.images.map( (value) => {
                            return (
                                <div className={"post-selected-images-wrapper img-delete-wrapper"} key={value.url}>
                                    <img alt={strings.postImageAlt} src={value.url} onError={this.onImageLoadFail}/>
                                    <div className={"img-delete"} onClick={() => this.removeImage(value.url)}>×</div>
                                </div>
                            )
                        })}
                    </div>
                    : null
                }
                <div className={"row"}>
                    <div className={"post-toolbar-entry"} onClick={this.openFilePicker}>
                        <div className={"post-toolbar-entry-icon"}><img src="/img/icons/dark/photo.png" alt={"Icon to add pictures"}/></div>
                        <div className={"post-toolbar-entry-text"}>{strings.media}</div>
                    </div>
                </div>
                <div className="row modal-actions-wrapper">
                    <a onClick={this.props.close} className="btn-cancel">{strings.cancel}</a>
                    <button onClick={this.onUpdate}>{strings.confirm}</button>
                </div>
            </Modal>
        );
    }
}

export default WithFirebase(EditPostModal);