import * as React from "react";
import { Route } from "react-router-dom";
import ErrorBoundary from "./additional/error_boundary";

const AppRoute = ({ component: Component, layout: Layout, ...rest}) => (
  <Route
      {...rest}
      render={props => (
          <Layout {...rest}>
              <ErrorBoundary>
                  <Component {...props}/>
              </ErrorBoundary>
          </Layout>
      )}
  />
);

export default AppRoute;