import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import {createStableFromFirestoreDocument, Stable} from "../models/stable";
import axios from "axios";
import * as config from "../config";
import {getFullAddressFromStableLocation} from "../additional/helpers";
import {createShallowUserFromFirestoreDocument} from "../models/shallow_user";
import BanningPeriod from "../models/banning_period";
import {createStableUserFromFirestoreDocument} from "../models/stable_user";

const apiUrl = config.apiURL + '/stables';

class FirebaseStableController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }

    cachedStables = {};
    timeoutStables = {};
    timeout = 600000;

    /**
     * @param {string} uid
     * @param {string} path
     * @param {function} callbackAdded
     * @param {function} callbackRemoved
     */
    listenForStables = async (uid,path,callbackAdded, callbackRemoved) => {
        let stableReference = this.app.database().ref().child('users/' + uid + '/stables/'+path);

        stableReference.on("child_added", function(snapshot,prevChildKey) {
            callbackAdded(snapshot.key);
        });

        stableReference.on("child_removed", function(snapshot,prevChildKey) {
            callbackRemoved(snapshot.key);
        });
    };

    checkIfUserIsInvitedAsAdmin = async (stableId) => {
        let firebaseUser = await this.app.auth().currentUser;
        let data = (await this.app.firestore().collection('stables').doc(stableId).collection('adminspending').doc(firebaseUser.uid).get()).data();
        return !!data && !!data.uid
    };

    getStableByID = (sid) => {
        if (this.cachedStables.hasOwnProperty(sid) && this.timeoutStables.hasOwnProperty(sid)) {
            return this.cachedStables[sid];
        }

        return undefined;
    };

    loadCustomMemberColorsForStable = async (sid) => {
        let stable = this.cachedStables[sid];
        if(!!stable) {
            if(!!stable.customMemberColors && Object.keys(stable.customMemberColors).length > 0) {
                return stable.customMemberColors;
            }

            let colors = await this.app.firestore().collection('stables').doc(sid).collection('members').doc('_colors').get();

            stable.customMemberColors = (colors?.data()) ?? {};
            return stable.customMemberColors;
        }
    }

    saveCustomBookingColorForStable = async (sid, color) => {
        let stable = this.cachedStables[sid];
        if(!!stable) {
            let firebaseUser = await this.app.auth().currentUser;
            let idToken = await firebaseUser.getIdToken(true);
            let body = {
                'idToken': idToken,
                'stableId': sid,
                'color': color
            };

            if(!stable.customMemberColors || Object.keys(stable.customMemberColors).length <= 0) {
                stable.customMemberColors = await this.loadCustomMemberColorsForStable(sid);
            }

            stable.customMemberColors[firebaseUser.uid] = color;

            await axios.post(apiUrl + "/saveColor", body);

            return stable.customMemberColors;
        }
    };

    loadStableByID = async (sid, force = false) => {
        if (!force && this.cachedStables.hasOwnProperty(sid) && this.timeoutStables.hasOwnProperty(sid) && ( new Date().getTime() < this.timeoutStables[sid])) {
            return this.cachedStables[sid];
        }

        let firestoreDoc = await this.app.firestore().collection('stables').doc(sid).get();

        let stable = createStableFromFirestoreDocument(firestoreDoc);
        if (!!stable) {
            this.cachedStables[stable.sid] = stable;
            this.timeoutStables[stable.sid] = new Date().getTime() + this.timeout;
        }
        return stable;
    };

    createNewStable = async(username,stableName, locationData, permissions) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        locationData.fullAddress = getFullAddressFromStableLocation(locationData);

        let stable = new Stable({
            sid : "",
            name: stableName,
            location: locationData,
            permissions: permissions,
        });

        let body = stable.toJSON();


        body['idToken'] = idToken;
        body['userName'] = username;

        let response = await axios.post(apiUrl + "/create" ,body );

        if (response.status === 200) {
            //LogController.log(user.uid, ActionType.STABLE, stableId: response.body);
            stable.sid =  response.data;
            return stable;
        }
        else if (response.status === 471) {
            return null
        }
        else {
            return null;
        }

    };

    updateStable = async(stable) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = stable.toJSON();
        this.cachedStables[stable.sid] = stable;

        body['idToken'] = idToken;

        let response = await axios.post(apiUrl + "/update" ,body );

        if (response.status === 200) {
            return response.data;
        }
        else {
            return null;
        }

    };

    deleteStable = async(stable) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = stable.toJSON();
        stable.isRemoved = true;
        this.cachedStables[stable.sid] = stable;

        body['idToken'] = idToken;
        body['stableId'] = stable.sid;
        body['stableName'] = stable.name;
        body['stableHeader'] = stable.header;

        let response = await axios.post(apiUrl + "/delete" ,body );

        if (response.status === 200) {
            return response.data;
        }
        else {
            return null;
        }

    };

    isMemberOfStable= async(sid,uid) => {
        try {
            return !!(await this.app.firestore().collection('stables').doc(sid).collection('members').doc(uid).get()).data()
        } catch (e) {
            // user can not access members and therefore is not a member
            return false;
        }
    };

    isEmployeeOfStable= async(sid,uid) => {
        try {
            let data = (await this.app.firestore().collection('stables').doc(sid).collection('members').doc(uid).get()).data();

            return data && data.employee;
        } catch (e) {
            // user can not access members and therefore is not a member
            return false;
        }
    };

    isAdminOfStable= async(sid,uid) => {

        return !! (await this.app.firestore().collection('stables').doc(sid).collection('admins').doc(uid).get()).data()
    };

    loadAdminsForStable = async (sid) => {
        let openRequestsSnapshot = await this.app.firestore().collection('stables').doc(sid).collection('admins').get();

        let users = [];
        openRequestsSnapshot.docs.forEach(doc => {
            users.push(createStableUserFromFirestoreDocument(doc));
        });
        return users;
    };

    loadPendingAdminsForStable = async (sid) => {
        let openRequestsSnapshot = await this.app.firestore().collection('stables').doc(sid).collection('adminspending').get();

        let users = [];
        openRequestsSnapshot.docs.forEach(doc => {
            users.push(createShallowUserFromFirestoreDocument(doc));
        });

        let openInvitesSnapshot = await this.app.firestore().collection('stables').doc(sid).collection('invites').where('asAdmin', '==', true).get();
        openInvitesSnapshot.docs.forEach(doc => {
            users.push(createShallowUserFromFirestoreDocument(doc));
        });
        return users;
    };

    inviteAdminForStable = async (sid, invitedByUserName, invitedUid, invitedUserName ) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['stableId'] = sid;
        body['invitedBy'] = invitedByUserName;
        body['userName'] = invitedUserName;
        body['userId'] = invitedUid;


        await axios.post(apiUrl + '/admin/invite', body);
    };

    removeAdminInvite = async (sid, invitedUid ) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['stableId'] = sid;
        body['uid'] = invitedUid;

        await axios.post(apiUrl + '/admin/removeInvite', body);
    };

    removeAdminPosition = async (sid, userId, userName ) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['stableId'] = sid;
        body['userName'] = userName;
        body['uid'] = userId;

        let response = await axios.post(apiUrl + '/admin/remove', body);

        return response.status === 200;
    };

    acceptAdminInvite = async (sid, userName ) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['stableId'] = sid;
        body['userName'] = userName;

        let response = await axios.post(apiUrl + '/admin/accept', body);

        return response.status === 200;
    };

    refuseAdminInvite = async (sid, userName ) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['stableId'] = sid;
        body['userName'] = userName;

        let response = await axios.post(apiUrl + '/admin/refuse', body);

        return response.status === 200;
    };

    loadOpenRequestsForStable = async (sid) => {

        let openRequestsSnapshot = await this.app.firestore().collection('stables').doc(sid).collection('requests').get();

        let users = [];
        openRequestsSnapshot.docs.forEach(doc => {
            users.push(createShallowUserFromFirestoreDocument(doc));
        });
        return users;
    };

    loadOpenInvitesForStable = async (sid) => {
        let openInvitesSnapshot = await this.app.firestore().collection('stables').doc(sid).collection('invites').get();
        let users = [];
        openInvitesSnapshot.docs.forEach(doc => {
            users.push(createShallowUserFromFirestoreDocument(doc));
        });

        return users;
    };

    loadBansForStable = async (sid) => {
        let bansSnapshot = await this.app.firestore().collection('stables').doc(sid).collection('bans').get();
        let users = [];
        bansSnapshot.docs.forEach(doc => {
            users.push(createShallowUserFromFirestoreDocument(doc));
        });

        return users;
    };


    joinStable = async(sid,username) => {
            let firebaseUser = await this.app.auth().currentUser;
            let  idToken = await firebaseUser.getIdToken(true);

            let body = {
                'idToken': idToken,
                'stableId': sid,
                'userName': username
            };

            let response = await axios.post(apiUrl + "/join" ,body );

            if(response.status === 200) {
                // TODO Add logcontroller
                //LogController.log(user.uid, ActionType.STABLE, stableId: stableId);
                return response.data;
            }  else {
                return null;
            }
    };


    removeUserFromStable= async(sid, stableName, uid, banningPeriod) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
            'stableName': stableName,
            'userId': uid
        };

        if (banningPeriod != null && banningPeriod !== BanningPeriod.NEVER) {
            body['banPeriod'] = banningPeriod.toString();
        }

        axios.post(apiUrl + "/removeUser" ,body );
    };

    inviteUserToStable = async (sid, invitedByUsername ,invitedUid, invitedUsername, asAdmin = false) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['stableId'] = sid;
        body['invitedBy'] = invitedByUsername;
        body['userName'] = invitedUsername;
        body['userId'] = invitedUid;

        if(!!asAdmin) {
            body['asAdmin'] = asAdmin.toString();
        }

        let res =  await axios.post(apiUrl + '/invite', body);

        if (res.status === 200) {
            return res.body;
        }
        return null;
    };

    refuseInviteForStable = async (sid,username) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
            'userName': username
        };

        axios.post(apiUrl + "/refuseInvite" ,body );
    };

    refuseJoinRequest = async (sid, uid)  => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
            'userId': uid
        };

        axios.post(apiUrl + "/refuseRequest" ,body );

    };

    withdrawRequestForStable = async (sid) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
        };

        axios.post(apiUrl + "/withdrawJoinRequest" ,body );
    };

    leaveStable = async (sid) => {
        let firebaseUser = await this.app.auth().currentUser;
        let  idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'stableId': sid,
        };

        axios.post(apiUrl + "/leave" ,body );
    };

    /**
     * @param {string} uid
     */
    removeAllListeners = (uid) => {
        // let stableReference = this.app.database().child('users/' + uid + '/stables/active/');
        // stableReference.off();
    }
}

export default FirebaseStableController;