import * as React from "react";
import PropTypes from 'prop-types';
import * as ROUTES from "../routes";
import LocalizationsWithLanguage from "./localization_wrapper";
import {Link, withRouter} from "react-router-dom";

let strings = new LocalizationsWithLanguage({
    de:{
        jutta: "Jutta! App"
    },
    en: {
        jutta: "Jutta! App"
    }
});

class FullpageLayout extends React.PureComponent {
    render(){
        return <div className="fullPage">
            <div className="top-menu">
                <Link to={ROUTES.LANDING}>{strings.jutta}</Link>
            </div>
            <main>{this.props.children}</main>
            {/*<Footer/>*/}
        </div>
    }
}

FullpageLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default withRouter(FullpageLayout);