import React, {Component} from 'react';
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {Link, withRouter} from "react-router-dom";
import StableHeader from "../header";
import AreYouSureModal from "../../additional/are_your_sure_modal";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        settings: "Einstellungen",
        members: "Mitglieder",
        membersDesc: "Verwalte Deine Einsteller und neue Beitrittsanfragen!",
        boarders: "Einsteller",
        boardingRequests: "Beitrittsanfragen",
        management: "Verwaltung",
        managementDesc: "Verwalte alles rund um Deinen Stall!",
        admins: "Administratoren",
        permissions: "Berechtigungen",
        contacts: "Kontaktpersonen",
        share: "Stall teilen",
        delete: "Stall löschen"
    },
    en: {
        settings: "Settings",
        members: "Members",
        membersDesc: "Manage your boarders and joining requests!",
        boarders: "Boarder",
        boardingRequests: "Joining requests",
        management: "Management",
        managementDesc: "Manage information about your stable!",
        admins: "Administrators",
        permissions: "Permissions",
        contacts: "Contact persons",
        share: "Share stable",
        delete: "Delete stable"
    }
});

class SettingsIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stable: undefined,
            loading: true,
            showDelete: false,
        }
    }

    async loadDisplayedStable()  {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(stable => {
                this.setState({ stable : stable, loading: false });
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    componentDidMount() {
        this.loadDisplayedStable();
    }

    showDeleteStableDialog= () => {
        this.setState({showDelete: true});
    };

    deleteStable = async () => {
        this.props.firebase.stableController.deleteStable(this.state.stable);
        this.props.history.push("/stables/");
    };

    render() {
        const pathname = this.props.location.pathname;

        return (
            <div>
                {
                    !!this.state.showDelete ? <AreYouSureModal onConfirm={() => this.deleteStable()} onClose={() => this.setState({showDelete: false})}/> : null
                }

                <StableHeader name={this.state.stable == null ? "" : this.state.stable.name} stables={this.props.auth.stables == null ? true : this.props.auth.stables.active}/>

                <div className="card">
                    <div className="card-header">
                        <h4>{strings.members}</h4>
                        <small>{strings.membersDesc}</small>
                    </div>
                    <div className="card-body">
                        <div className="setting">
                            <Link to={pathname + "/boarders"}>
                                <div className="settings-icon">
                                    <img alt="Icon for boarders" src="/img/icons/white/groups.png"/>
                                </div>
                            </Link>
                            <Link to={pathname + "/boarders"}>{strings.boarders}</Link>
                        </div>

                        <div className="setting">
                            <Link to={pathname + "/requests"}>
                                <div className="settings-icon">
                                    <img alt="Icon for boarding requests" src="/img/icons/white/time.png"/>
                                </div>
                            </Link>
                            <Link to={pathname + "/requests"}>{strings.boardingRequests}</Link>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h4>{strings.management}</h4>
                        <small>{strings.managementDesc}</small>
                    </div>
                    <div className="card-body">
                        <div className="setting">
                            <Link to={pathname + "/admins"}>
                                <div className="settings-icon">
                                    <img alt="Icon for administrators" src="/img/icons/white/people.png"/>
                                </div>
                            </Link>
                            <Link to={pathname + "/admins"}>{strings.admins}</Link>
                        </div>

                        <div className="setting">
                            <Link to={pathname + "/permissions"}>
                                <div className="settings-icon">
                                    <img alt="Icon for permissions" src="/img/icons/white/secret.png"/>
                                </div>
                            </Link>
                            <Link to={pathname + "/permissions"}>{strings.permissions}</Link>
                        </div>

                        <div className="setting">
                            <Link to={pathname + "/contacts"}>
                                <div className="settings-icon">
                                    <img alt="Icon for contact persons" src="/img/icons/white/stable.png"/>
                                </div>
                            </Link>
                            <Link to={pathname + "/contacts"}>{strings.contacts}</Link>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <h4>{strings.management}</h4>
                        <small>{strings.managementDesc}</small>
                    </div>
                    <div className="card-body">
                        <div className="setting">
                            <button type="button" onClick={this.showDeleteStableDialog} className="warning-button">{strings.delete}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(WithAuthentication(WithFirebase(SettingsIndex)));