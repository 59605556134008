function configureBookingCache() {
    const cache = {
        booking: {
        },
        timeout: {
        }
    };

    const facility= {};
    const timoutSpan = 5000;

    cache.getBookingById = function(bid){
        if (cache.booking.hasOwnProperty(bid) && cache.timeout.hasOwnProperty(bid)){
            return cache.booking[bid];
        }
        return undefined;
    };

    cache.getBookingByIdIfValid = function(bid){
        if (cache.booking.hasOwnProperty(bid) && cache.timeout.hasOwnProperty(bid) && (Date.now() < cache.timeout[bid]) ){
            return cache.booking[bid];
        }
        return undefined;
    };

    cache.updateCacheEntry = function(booking){
        if (!!booking) {
            cache.booking[booking.bid] = booking;
            cache.timeout[booking.bid] = Date.now() + timoutSpan;
        }
    };

    cache.removeCacheEntry = function(bid){
        delete cache.booking[bid];
        delete cache.timeout[bid] ;
    };

    cache.updateCacheEntryWithoutTimeout = function(booking){
        if (!!booking) {
            cache.booking[booking.bid] = booking;
        }
    };

    cache.getBookingIdsByFacilityAndDay = function(fid,day){
        if (facility.hasOwnProperty(fid) && facility[fid].hasOwnProperty(day)){
            return facility[fid][day];
        }
        return [];
    };

    cache.appendBookingIdsForFacilityAndDay = function(fid,day,ids){
        if (!facility.hasOwnProperty(fid) ){
            facility[fid]= {};
        }
        if (!facility[fid].hasOwnProperty(day) ){
            facility[fid][day]= [];
        }
        facility[fid][day] = facility[fid][day].concat(ids);
    };

    cache.prependBookingIdsForFacilityAndDay = function(fid,day,ids){
        if (!facility.hasOwnProperty(fid) ){
            facility[fid]= {};
        }
        if (!facility[fid].hasOwnProperty(day) ){
            facility[fid][day]= [];
        }
        facility[fid][day] = ids.concat(facility[fid][day]);
    };


    cache.overwriteBookingIdsForFacilityAndDay = function(fid,day, ids) {
        if (!facility.hasOwnProperty(fid) ){
            facility[fid]= {};
        }
        facility[fid][day]  = ids;
    };

    cache.removeBookingForDayFromFacility = function(fid,day,bid){
        if (facility.hasOwnProperty(fid) && facility[fid].hasOwnProperty(day)){
            let entryForDay  = facility[fid][day];

            if (!!entryForDay) {
                for( let i = 0; i < entryForDay.length; i++){
                    if ( entryForDay[i] === bid) {
                        entryForDay.splice(i, 1);
                        break;
                    }
                }
                facility[fid][day]=entryForDay;
            }

        }
    };

    return cache;

}

const BookingCache = configureBookingCache();

export default BookingCache;