import {geopointFromFirestoreGeopoint} from "./geopoint";

export class UserSettings {
    language;
    email;
    geolocation;
    country;
    countryCode;
    city;
    state;
    fullAddress;
    birthday;
    birthdayChangedAt;

    /**
     * @param {String} language
     * @param {String} email
     * @param {Object} geolocation
     * @param {String} country
     * @param {String} city
     * @param {String} state
     * @param {String} fullAddress
     * @param {String} countryCode
     * @param {Date} birthday
     * @param {Date} birthdayChangedAt
     */
    constructor({language = "", email = "", geolocation = undefined
    , country = "", city = "", state = "", fullAddress = "", birthday = null, birthdayChangedAt = null, countryCode = null}={}){
        this.language = language;
        this.email = email;
        this.geolocation = geolocation;
        this.city = city;
        this.country = country;
        this.state = state;
        this.fullAddress = fullAddress;
        this.birthday = birthday;
        this.birthdayChangedAt = birthdayChangedAt;
        this.countryCode = countryCode;
    }

}


export function createUserSettingsFromFirestoreDocument(firestoreDocument){
    let userSettings = new UserSettings();
    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        if (!!data['language']) {
            userSettings.language = data['language'];
        }
        if (!!data['email']) {
            userSettings.email = data['email'];
        }
        if (!!data['location']){
            userSettings.geolocation = geopointFromFirestoreGeopoint(data['location']);
        }
        if(!!data['country'] && !!data['city']) {
            userSettings.country = data['country'];
            userSettings.city = data['city'];
            userSettings.state = data['state'];
            userSettings.fullAddress = data['city'] + ", " + (data['state'] != null ? (data['state'] + ", ") : "") + data['country'];
        }
        if(!!data['countryCode']) {
            userSettings.countryCode = data['countryCode'];
        }
        if(!!data['birthday']) {
            userSettings.birthday = new Date(data['birthday']);
        }
        if(!!data['birthdayChangedAt']) {
            userSettings.birthdayChangedAt = new Date(data['birthdayChangedAt']);
        }

    }
    return userSettings;
}
