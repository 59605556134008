export class Geopoint{
    lat;
    long;

    constructor(latitude,longitude) {
        this.lat = latitude;
        this.long = longitude;
    }
}

export function geopointFromFirestoreGeopoint(firestoreGepoint) {
    return new Geopoint(firestoreGepoint.latitude, firestoreGepoint.longitude);
}