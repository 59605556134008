import React from "react";
import ThumbnailGallery from "../media/thumbnail_gallery";

const PostContent = (props) =>
    <div className="post-content">
        <div className="post-content-text">
            {props.post.textContent}
        </div>
        <ThumbnailGallery media={props.post.videos.concat(props.post.images)} galleryId={"image" + props.post.pid}/>
    </div>;


export default PostContent;