import {firebaseConfig} from './firebase_config';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

import * as ROUTES from "../../routes";
import FirebaseUserController from "./firebase_user_controller";
import FirebaseStableController from "./firebase_stable_controller";
import FirebaseFacilityController from "./firebase_facility_controller";
import FirebasePostController from "./firebase_post_controller";
import FirebaseStorageController from "./firebase_storage_controller";
import FirebaseCommentController from "./firebase_comment_controller";
import FirebaseProController from "./firebase_pro_controller";

class FirebaseInstance {

    constructor() {
        firebase.initializeApp(firebaseConfig);
        this.auth = firebase.auth();
        this.userController = new FirebaseUserController(firebase);
        this.stableController = new FirebaseStableController(firebase);
        this.storageController = new FirebaseStorageController(firebase);
        this.facilityController = new FirebaseFacilityController(firebase);
        this.postController = new FirebasePostController(firebase);
        this.commentController = new FirebaseCommentController(firebase);
        this.proController = new FirebaseProController(firebase);
    }

    onAuthStateChanged = (onChange) => this.auth.onAuthStateChanged( authUser => onChange(authUser));

    createUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

    sendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

    sendVerificationEmail = () => this.auth.currentUser.sendEmailVerification();

    signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    signInWithFacebook = () => {
        let facebookProvider = new firebase.auth.FacebookAuthProvider();
        facebookProvider.addScope('email');
        return this.auth.signInWithPopup(facebookProvider);
    };

    signInWithGoogle = () => {
        let googleProvider = new firebase.auth.GoogleAuthProvider();
        googleProvider.addScope('email');
        googleProvider.addScope('profile');
        return this.auth.signInWithPopup(googleProvider);
    };

    signInWithCustomToken = (token) => {
       return this.auth.signInWithCustomToken(token);
    };

    logout = () => this.auth.signOut();

    passwordReset = email => this.auth.sendPasswordResetEmail(email);

    updatePassword = password =>
        this.auth.currentUser.updatePassword(password);

    userController = this.userController;
    stableController = this.stableController;
    facilityController = this.facilityController;
    postController = this.postController;
    storageController = this.storageController;
    commentController = this.commentController;
}

export default FirebaseInstance;