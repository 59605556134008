export class Color {
    value;

    // Value must be a 32-Bit int
    constructor(value) {
        this.value = value;
    }

    getAlpha = () => (0xff000000 & this.value) >> 24;
    getOpacity = () => (this.getAlpha() / 0xFF) === 0 ? 1 :  (this.getAlpha() / 0xFF) ;

    getRed = () => (0x00ff0000 & this.value) >> 16;
    getGreen = () => (0x0000ff00 & this.value) >> 8;
    getBlue = () => (0x000000ff & this.value) >> 0;
    getRGBAString = () => "rgba(" + this.getRed() + "," + this.getGreen() + "," + this.getBlue() + "," + this.getOpacity() + ")";

    static fromARGB(a, r, g, b) {
        let value = (((a & 0xff) << 24) |
            ((r & 0xff) << 16) |
            ((g & 0xff) << 8)  |
            ((b & 0xff) << 0)) & 0xFFFFFFFF;

        return new Color(value);
    }

    withAlpha(a) {
        return Color.fromARGB(a, this.getRed(), this.getGreen(), this.getBlue());
    }

    withOpacity(o) {
        return this.withAlpha(255 * o);
    }

    withRed(r) {
        return Color.fromARGB(this.getAlpha(), r, this.getGreen(), this.getBlue());
    }

    withGreen(g) {
        return Color.fromARGB(this.getAlpha(), this.getRed(), g, this.getBlue());
    }

    withBlue(b) {
        return Color.fromARGB(this.getAlpha(), this.getRed(), this.getGreen(), b);
    }
}