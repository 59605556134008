import LocalizedStrings from 'react-localization';

let localizedLanguages = {
    'de': 'Deutsch',
    'en': 'English'
};

class LocalizationsWithLanguage extends LocalizedStrings {
    constructor(props) {
        super(props);
        let language = localStorage.getItem('lang');
        if(!!language) {
            super.setLanguage(language);
        }
    }
}

export default LocalizationsWithLanguage;
export { localizedLanguages };