import React, { Component } from 'react';

class ExpandingText extends Component{

    constructor(props) {
        super(props);
        let randomNumber = Math.floor(Math.random() * 1000000);
        this.state = {
            ref: "ExpandingTextArea" + randomNumber,
            textContent: this.props.initValue
        };
    }

    componentDidMount() {
        this.refs[this.state.ref].style.height = 'auto';
        this.refs[this.state.ref].style.height = (this.refs[this.state.ref].scrollHeight) + 'px';
        this.refs[this.state.ref].addEventListener("input", () => {
            this.refs[this.state.ref].style.height = 'auto';
            this.refs[this.state.ref].style.height = (this.refs[this.state.ref].scrollHeight) + 'px';
        }, false);
    }

    onChange= (e) =>  {
        this.setState({
            textContent: e.target.value
        });
        this.props.onChange(e);
    };

    render(){
        return (
            <textarea
                name={this.props.name || "content"}
                className={"expanding"}
                ref={this.state.ref}
                value={this.state.textContent}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
            />
        );
    }
}

export default  ExpandingText;