import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import {StableContact} from "../../../models/stable_contact";
import Loading from "../../../loading";
import LocalizationsWithLanguage from "../../../localization_wrapper";
import DeletableImage from "../../../additional/deletable_image";

let strings = new LocalizationsWithLanguage({
    de:{
        contactPersons:"Kontaktpersonen",
        contactDescription:"Definiere Kontaktpersonen deines Stalls, damit Interessenten sich einfach informieren können. Kontaktpersonen benötigen keinen Jutta! Account, füge einfach einen Namen und optional eine Telefonnummer hinzu.",
        contactPerson:"Kontaktperson",
        save: "Speichern",
        namePlaceholder: "Name",
        positionPlaceholder: "Position",
        telephonePlaceholder: "Telefon",
        name: "Name",
        position: "Position",
        telephone: "Telefon",
        contactAvatarAlt: "Bild von der Kontaktperson",
    },
    en: {
        contactPersons:"Contact persons",
        contactDescription:"Define contact persons of your stable, so prospective clients can get more information. These persons don\\'t need a Jutta! account, just add their name and maybe a telephone number!",
        contactPerson:"Contact person",
        namePlaceholder: "Name",
        positionPlaceholder: "Position",
        telephonePlaceholder: "Phone",
        name: "Name",
        position: "Position",
        telephone: "Phone",
        save: "Save",
        contactAvatarAlt: "Avatar of the contact person"
    }
});

class ContactIndex extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            loading: false,
            stable : undefined,
            contactOne: {},
            imageContactOne: undefined,
            imageContactOneDelete: false,
            contactTwo:  {},
            imageContactTwo: undefined,
            imageContactTwoDelete: false,
            contactThree:  {},
            imageContactThree: undefined,
            imageContactThreeDelete: false,
        };
    }

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
        this.setState(this.state);
    };

    onSubmit = async (event) => {
        this.setState({loading: true});
        let _stableController = this.props.firebase.stableController;
        let _storageController = this.props.firebase.storageController;

        if (!!this.state.imageContactOne) {
            await _storageController.uploadAvatarForStableContact(this.state.imageContactOne, this.state.stable.sid,0);
        } else if (!!this.state.imageContactOneDelete) {
            _storageController.deleteContactImage( this.state.stable.sid,0);
        }
        if (!!this.state.imageContactTwo) {
            await _storageController.uploadAvatarForStableContact(this.state.imageContactTwo, this.state.stable.sid,1);
        } else if (!!this.state.imageContactTwoDelete) {
            _storageController.deleteContactImage( this.state.stable.sid,1);
        }
        if (!!this.state.imageContactThree) {
            await _storageController.uploadAvatarForStableContact(this.state.imageContactThree, this.state.stable.sid,2);
        } else if (!!this.state.imageContactThreeDelete) {
            _storageController.deleteContactImage( this.state.stable.sid,2);
        }
        this.state.stable.contacts = [];
        if (!!this.state.contactOne.name) {
            this.state.stable.contacts.push(this.state.contactOne);
        }
        if (!!this.state.contactTwo.name) {
            this.state.stable.contacts.push(this.state.contactTwo);
        }
        if (!!this.state.contactThree.name) {
            this.state.stable.contacts.push(this.state.contactThree);
        }


        _stableController.updateStable(this.state.stable);
        this.props.history.push("/stables/" + this.props.match.params.sid + "/settings");
    };

    async loadDisplayedStable() {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
                this.setState({
                    stable : stable,
                    contactOne: stable.contacts[0] || new StableContact(),
                    contactTwo: stable.contacts[1] || new StableContact(),
                    contactThree: stable.contacts[2] || new StableContact(),
                });
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    componentDidMount() {
        this.loadDisplayedStable();
    }

    onChange = event => {
        let contactInfo = event.target.name.split('.');
        this.state[contactInfo[0]][contactInfo[1]] = event.target.value;
        this.setState(this.state);
    };

    openFilePicker = (target) => {
        this.refs[target].click();
    };

    removeImage = (targetImageTag) => {
        this.setState({
            [targetImageTag+"Delete"]: true,
            [targetImageTag]: undefined
        });

        this.refs[targetImageTag].src = "/img/nopicture.png";
    };

    render() {

        if (this.state.loading) {
            return (<Loading></Loading>)
        }

        return (
            <div>
                <h1 className="mb-1">{strings.contactPersons}</h1>
                <form onSubmit={(event) => !!this.state.stable ? this.onSubmit(event) : null}>
                    <div id="contacts" className="card-column">
                        <div className="header">{"1." + strings.contactPerson}</div>
                        <div className="body row flex-start">
                                <DeletableImage image={this.state.contactOne.avatar} onImageChange={(image) => {
                                    this.setState({
                                        imageContactOne: image,
                                        imageContactOneDelete: !image
                                    });
                                }}/>

                                <div className="ml-2">
                                    <div className="form-row">
                                        <label htmlFor="name">{strings.name}</label>
                                        <input
                                            name="contactOne.name"
                                            value={this.state.contactOne.name}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder={strings.namePlaceholder}
                                        />
                                    </div>


                                    <div className="form-row">
                                        <label htmlFor="name">{strings.position}</label>
                                        <input
                                            name="contactOne.position"
                                            value={this.state.contactOne.position}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder={strings.positionPlaceholder}
                                        />
                                    </div>

                                    <div className="form-row">
                                        <label htmlFor="name">{strings.telephone}</label>
                                        <input
                                            name="contactOne.telephone"
                                            value={this.state.contactOne.telephone}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder={strings.telephonePlaceholder}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="card-column">
                        <div className="header">{"2." + strings.contactPerson}</div>
                        <div className="body row flex-start">
                            <DeletableImage image={this.state.contactTwo.avatar} onImageChange={(image) => {
                                this.setState({
                                    imageContactTwo: image,
                                    imageContactTwoDelete: !image
                                });
                            }}/>

                            <div className="ml-2">
                                <div className="form-row">
                                    <label htmlFor="name">{strings.name}</label>
                                    <input
                                        name="contactTwo.name"
                                        value={this.state.contactTwo.name}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder={strings.namePlaceholder}
                                    />
                                </div>


                                <div className="form-row">
                                    <label htmlFor="name">{strings.position}</label>
                                    <input
                                        name="contactTwo.position"
                                        value={this.state.contactTwo.position}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder={strings.positionPlaceholder}
                                    />
                                </div>

                                <div className="form-row">
                                    <label htmlFor="name">{strings.telephone}</label>
                                    <input
                                        name="contactTwo.telephone"
                                        value={this.state.contactTwo.telephone}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder={strings.telephonePlaceholder}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-column">
                        <div className="header">{"3." + strings.contactPerson}</div>
                        <div className="body row flex-start">
                            <DeletableImage image={this.state.contactThree.avatar} onImageChange={(image) => {
                                this.setState({
                                    imageContactThree: image,
                                    imageContactThreeDelete: !image
                                });
                            }}/>

                            <div className="ml-2">
                                <div className="form-row">
                                    <label htmlFor="name">{strings.name}</label>
                                    <input
                                        name="contactThree.name"
                                        value={this.state.contactThree.name}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder={strings.namePlaceholder}
                                    />
                                </div>


                                <div className="form-row">
                                    <label htmlFor="name">{strings.position}</label>
                                    <input
                                        name="contactThree.position"
                                        value={this.state.contactThree.position}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder={strings.positionPlaceholder}
                                    />
                                </div>

                                <div className="form-row">
                                    <label htmlFor="name">{strings.telephone}</label>
                                    <input
                                        name="contactThree.telephone"
                                        value={this.state.contactThree.telephone}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder={strings.telephonePlaceholder}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <label htmlFor="action">{}</label>
                <button className="width-50" onClick={()=> this.onSubmit()}>{strings.save}</button>
            </div>
        )
    }
}

export default withRouter(WithAuthentication(WithFirebase(ContactIndex)));