import React, {Component, Fragment} from 'react';
import {Footer, LogoTop} from "../navigation/Footer";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        dataProtection:"Datenschutz",
    },
    en: {
        dataProtection:"Data protection",
    }
});

const DataProtectionWithPage= () => {
    return (
        <DataProtection/>
    )
};

class DataProtection extends Component {
    render() {
        return (
           <div id="master">
               <h2 id="iallgemein">I. Allgemein</h2>

               <p><strong>Datenschutzerklärung</strong></p>

               <p>Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und behandeln diese vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Diese Datenschutzerklärung gilt für unsere mobilen iPhone- und Android-Apps (im Folgenden „APP“). In ihr werden Art, Zweck und Umfang der Datenerhebung im Rahmen der APP-Nutzung erläutert. Wir weisen darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>

               <p><strong>Verantwortliche Stelle</strong></p>

               <p>MANDARIN MEDIEN G.f.d.L. mbH</p>

               <p>Martin Klemkow \
                   Mueßer Bucht 1 \
                   19063 Schwerin</p>

               <p><strong>Datenschutzbeauftragter</strong></p>

               <p>Unseren Datenschutzbeauftragten erreichen Sie unter:</p>

               <p>info@jrs-Sicherheit.de</p>

               <p><strong>Allgemeine Speicherdauer personenbezogener Daten</strong></p>

               <p>Vorbehaltlich abweichender oder konkretisierender Angaben innerhalb dieser Datenschutzerklärung werden die von dieser APP erhobenen personenbezogenen Daten gespeichert, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Sofern eine gesetzliche Pflicht zur Aufbewahrung oder ein sonstiger gesetzlich anerkannter Grund zur Speicherung der Daten (z. B. berechtigtes Interesse) besteht, werden die betreffenden personenbezogenen Daten nicht vor Wegfall des jeweiligen Aufbewahrungsgrundes gelöscht. Rechtsgrundlagen zur Speicherung personenbezogener Daten. Die Verarbeitung personenbezogener Daten ist nur zulässig, wenn eine wirksame Rechtsgrundlage für die Verarbeitung dieser Daten besteht. Sofern wir Ihre Daten verarbeiten, geschieht dies regelmäßig auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO (z.B. bei freiwilliger Angabe Ihrer Daten in der Anmeldemaske oder im Rahmen des Kontaktformulars), zum Zwecke der Vertragserfüllung nach Art. 6 Abs. 1 DSGVO (Nutzung von In-APP-Käufen oder der Nutzung sonstiger kostenpflichtiger APP-Funktionen) oder aufgrund berechtigter Interessen nach Art. 6 Abs. 1 lit. f DSGVO, die stets mit Ihren Interessen abgewogen werden (z.B. im Rahmen von Werbemaßnahmen). Die jeweils einschlägigen Rechtsgrundlagen werden ggf. an gesonderter Stelle im Rahmen dieser Datenschutzerklärung konkretisiert.</p>

               <p><strong>Verschlüsselung</strong></p>

               <p>Diese APP nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als APP-Betreiber senden, oder der Kommunikation der APP-Nutzer untereinander, eine Verschlüsselung. Durch diese Verschlüsselung wird verhindert, dass die Daten, die Sie übermitteln, von unbefugten Dritten mitgelesen werden können.</p>

               <p><strong>Änderung dieser Datenschutzerklärung</strong></p>

               <p>Wir behalten uns das Recht vor, diese Datenschutzbestimmungen unter Einhaltung der gesetzlichen Vorgaben jederzeit zu ändern.</p>

               <h2 id="iiihrerechte">II. Ihre Rechte</h2>

               <p>Die DSGVO gewährt Betroffenen, deren personenbezogene Daten von uns verarbeitet werden, bestimmte Rechte, über die wir Sie an dieser Stelle aufklären möchten:</p>

               <p><em>*Widerruf Ihrer Einwilligung zur Datenverarbeitung. *</em></p>

               <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer Einwilligung möglich. Diese werden wir vor Beginn der Datenverarbeitung ausdrücklich bei Ihnen einholen. Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>

               <p><strong>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</strong></p>

               <p>Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen sie dieser Datenschutzerklärung.</p>

               <p>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet. Wenn die Datenverarbeitung auf Grundlage von Art.. 6 Abs. 1 lit. E oder F DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die Verarbeitung Sie betreffender personenbezogener Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>

               <p>Wenn Sie Widerspruch einlegen, werden wir die betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die ihre Interessen, Rechte und Freiheiten überwiegen oder die der Verarbeitung der Geltendmachung, wenn sie Widerspruch Ausübung oder Verteidigung von Rechtsansprüchen dienen.</p>

               <p><strong>Beschwerderecht bei einer Aufsichtsbehörde</strong></p>

               <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>

               <p>Auskunft, Löschung und Berichtigung. Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>

               <p><strong>Recht auf Einschränkung der Verarbeitung</strong></p>

               <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>

               <ul>
                   <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>

                   <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>

                   <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu ver- langen.</li>

                   <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>

                   <li>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</li>
               </ul>

               <p><strong>Recht auf Datenübertragbarkeit</strong></p>

               <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

               <h2 id="iiizugriffsrechtederapp">III. Zugriffsrechte der APP</h2>

               <p>Zur Bereitstellung unserer Dienste über die APP benötigen wir die im folgenden aufgezählten Zugriffsrechte, die es uns ermöglichen, auf bestimmte Funktionen Ihres Geräts zuzugreifen.</p>

               <p>Standortdaten</p>

               <p>Gerätenummer Ihres Smartphones.</p>

               <p>Fotos, Videos</p>

               <p>Kamera</p>

               <p>Mikrofon</p>

               <p>Der Zugriff auf die Gerätefunktionen ist erforderlich, um die Funktionalitäten der APP zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO oder – sofern ein Vertrag geschlossen wurde – die Erfüllung unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1 lit. b DSGVO).</p>

               <p>Die Speicherdauer für die so erfassten Daten ist wie folgt geregelt:</p>

               <p>Spätestens 4 Wochen nach Löschung Ihres Profils werden alle Ihre personenbezogenen  Daten gelöscht.</p>

               <h2 id="iverfassungpersonenbezogenerdatenimrahmenderappnutzung">IV. Erfassung personenbezogener Daten im Rahmen der APP-Nutzung</h2>

               <p><strong>Allgemein</strong></p>

               <p>Wenn Sie unsere APP nutzen, erfassen wir folgende personenbezogene Daten von Ihnen:</p>

               <p>• Vor- und Nachname</p>

               <p>• E-Mail-Adresse</p>

               <p>• Nutzungsdaten</p>

               <p>• IP-Adresse</p>

               <p>• Gerätekennung</p>

               <p>• Metadaten</p>

               <p>Die Verarbeitung dieser personenbezogenen Daten ist erforderlich, um die Funktionalitäten der APP zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO oder – sofern ein Vertrag geschlossen wurde – die Erfüllung unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1 lit. b DSGVO).</p>

               <p>Die Speicherdauer für die so erfassten Daten ist wie folgt geregelt:</p>

               <p><strong>Anfrage innerhalb der APP, per E-Mail, Telefon oder Telefax</strong></p>

               <p>Wenn Sie uns kontaktieren (z. B. via Kontaktformular innerhalb der App, per E-Mail, Telefon oder Telefax), wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (z. B. Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben. Die von Ihnen an uns per Kontaktanfrage übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt. Wir geben Ihre Daten nicht ohne Ihre Einwilligung weiter.</p>

               <p><strong>Newsletterdaten</strong></p>

               <p>Wenn Sie den in unserer APP angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben sie nicht an Dritte weiter. Der Newsletterversand geschieht auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Diese Einwilligung können Sie jederzeit widerrufen. Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Austragung gelöscht.</p>

               <h2 id="vdatenanalyse">V. Datenanalyse</h2>

               <p>Wenn Sie auf unsere APP zugreifen, kann Ihr Verhalten mit Hilfe bestimmter Analyse- Werkzeuge statistisch ausgewertet und zu Werbe- und Markforschungszwecken oder zur Verbesserung unserer Angebote analysiert werden. Bei der Verwendung derartiger Tools achten wir auf die Einhaltung der gesetzlichen Datenschutzbestimmungen. Beim Einsatz externer Dienstleister (Auftragsverarbeiter) stellen wir durch entsprechende Verträge mit den Dienstleistern sicher, dass die Datenverarbeitung den deutschen und europäischen Datenschutzstandards entspricht.</p>

               <p><strong>Google Analytics Firebase</strong></p>

               <p>Wir nutzen Google Analytics Firebase (nachfolgend Google Firebase) zur Analyse des Nutzerverhaltens. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>

               <p>Google Firebase umfasst verschiedene Funktionen, die uns eine Analyse Ihres In-APP- Verhaltens ermöglichen. Auf diese Weise können wir beispielsweise Ihre Bildschirmaufrufe, Betätigung von Buttons, In-APP-Käufe oder die Effektivität von Werbemaßnahmen analysieren. Wir können ferner feststellen, welche Funktionen innerhalb unserer APP häufig oder selten verwendet werden. Google Firebase speichert zu diesen Zwecken u. a. die Anzahl und Dauer der Sitzungen, Betriebssysteme, Gerätemodelle, Region und eine Reihe weiterer Daten. Eine detaillierte Übersicht über die von Google Firebase erfassten Daten finden Sie unter:</p>

               <p><a href="https://support.google.com/firebase/answer/6318039?hl=de">https://support.google.com/firebase/answer/6318039?hl=de</a></p>

               <p>Die Verwendung von Google Firebase setzt ggf. die Weiterleitung Ihrer personenbezogenen Daten in die USA voraus. Die Speicherdauer für die so erfassten Daten ist wie folgt geregelt:</p>

               <hr />

               <p>Die Verwendung von Google Firebase erfolgt zur Optimierung dieser APP und zur Verbesserung unserer Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar.</p>

               <p>Weitere Informationen zu Google Firebase finden Sie unter: <a href="https://firebase.google.com/">https://firebase.google.com/</a> <a href="https://www.firebase.com/terms/privacy-policy.html">https://www.firebase.com/terms/privacy-policy.html</a></p>
           </div>
        )
    }
}



export default DataProtection;

export {DataProtectionWithPage}
