
function configureUserCache() {
    const cache = {
        user: {

        },
        timeout: {

        }
    };

    const userNameCache = {

    };

    const timoutSpan = 300000;

    cache.getUserByUid = function(uid){
        if (cache.user.hasOwnProperty(uid) && cache.timeout.hasOwnProperty(uid)){
            return cache.user[uid];
        }
        return undefined;
    };

    cache.getUserByUidIfValid = function(uid){
        if (cache.user.hasOwnProperty(uid) && cache.timeout.hasOwnProperty(uid) && (Date.now() < cache.timeout[uid]) ){
            return cache.user[uid];
        }
        return undefined;
    };

    cache.updateCacheEntry = function(user){
       cache.user[user.uid] = user;
       cache.timeout[user.uid] = Date.now() + timoutSpan;
    };

    cache.updateCacheEntryWithoutTimeout = function(user){
        cache.user[user.uid] = user;
    };

    cache.getUserNameForUid = function(uid){
        if (cache.user.hasOwnProperty(uid)){
            return cache.user[uid].name;
        }
        if (userNameCache.hasOwnProperty(uid)) {
            return userNameCache[uid];
        }
        return undefined;
    };

    cache.setUserNameForUid = function (uid,name) {
        userNameCache[uid] = name;
    };

    return cache;
}

const UserCache = configureUserCache();

export default UserCache;