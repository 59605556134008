
import {getStableContactAvatarUrl} from "../additional/helpers";

export class StableContact {
    name;
    position;
    telephone;
    avatar;

    constructor({name="",position =undefined,telephone=undefined, avatar = undefined} ={}){
        this.name = name;
        this.position = position;
        this.telephone = telephone;
        this.avatar = avatar;
    }

    toJSON= () => {
        let data = {
            "name": this.name
        };

        if(!!this.position) {
            data['pos'] = this.position;
        }

        if(!!this.telephone) {
            data['tel'] = this.telephone;
        }
        return data;
    }

}

export function stableContactFromJSON(key,json, stableId) {
    if (!!json) {
        return new StableContact({
                name: json['name'],
                position: json['pos'],
                telephone: json['tel'],
                avatar: getStableContactAvatarUrl(stableId, key)
            }
        )
    }
    return undefined;
};