import {getUserAvatarUrl} from "../additional/helpers";

export class ShallowUser {

    name;
    avatar;
    uid;

    /**
     * @param {String} name
     * @param {String} avatar
     * @param {String} uid
     */
    constructor({uid = "",name = "",avatar=""} = {}){
        this.uid = uid;
        this.avatar = avatar;
        this.name = name;
    }
}


export function createShallowUserFromFirestoreDocument(firestoreDocument) {

    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        return new ShallowUser( {
            uid: firestoreDocument.id,
            name: data['fullName'] || data['businessName'] || data['name'] || "",
            avatar: getUserAvatarUrl(firestoreDocument.id),
        });
    }
    return null;

}