function configurePostCache() {
    const cache = {
        post: {
        },
        timeout: {
        }
    };

    const stableNews= {};
    const stablePublic = {};
    const timoutSpan = 300000;

    cache.getPostByPid = function(pid){
        if (cache.post.hasOwnProperty(pid) && cache.timeout.hasOwnProperty(pid)){
            return cache.post[pid];
        }
        return undefined;
    };

    cache.getPostByPidIfValid = function(pid){
        if (cache.post.hasOwnProperty(pid) && cache.timeout.hasOwnProperty(pid) && (Date.now() < cache.timeout[pid]) ){
            return cache.post[pid];
        }
        return undefined;
    };

    cache.updateCacheEntry = function(post){
       cache.post[post.pid] = post;
       cache.timeout[post.pid] = Date.now() + timoutSpan;
    };

    cache.updateCacheEntryWithoutTimeout = function(post){
        cache.post[post.uid] = post;
    };

    cache.removeCacheEntry = function(postId){
        delete cache.post[postId];
        delete cache.timeout[postId];
    };


    cache.getStableNewsPostIds = function(sid){
        if (stableNews.hasOwnProperty(sid)){
            return stableNews[sid];
        }
        return undefined;
    };

    cache.appendStableNewsPostIds = function(sid,ids){
        if (!stableNews.hasOwnProperty(sid) ){
            stableNews[sid]= [];
        }
        stableNews[sid] = stableNews[sid].concat(ids);
    };

    cache.prependStableNewsPostIds = function(sid,ids){
        if (!stableNews.hasOwnProperty(sid) ){
            stableNews[sid] = [];
        }
        stableNews[sid] = ids.concat(stableNews[sid]);
    };

    cache.getStablePublicPostIds = function(sid){
        if (stablePublic.hasOwnProperty(sid)){
            return stablePublic[sid];
        }
        return undefined;
    };

    cache.appendStablePublicPostIds = function(sid,ids){
        if (!stablePublic.hasOwnProperty(sid) ){
            stablePublic[sid] = [];
        }
        stablePublic[sid] = stablePublic[sid].concat(ids);
    };

    cache.prependStablePublicPostIds = function(sid,ids){
        if (!stablePublic.hasOwnProperty(sid) ){
            stablePublic[sid] = [];
        }
        stablePublic[sid] = ids.concat(stablePublic[sid]);
    };
    return cache
}

const PostCache = configurePostCache();

export default PostCache;