/**
 * @param {firebase.User} auth
 * @param {AuthUser} authUser
 */
import Privacy from "../models/privacy";
import Classes from "../models/classes";
import axios from 'axios';
import * as config from "../config";
import {elasticSearchUserFromElasticJSON} from "../models/elastic_search_user";
import {elasticSearchStableFromElasticJSON} from "../models/elastic_search_stable";
import {elasticSearchStableUserFromElasticJSON} from "../models/elastic_search_stable_user";
import {getIntValuesForRepetition, getWeekdayForInt} from "../additional/helpers";

const basicAuth = 'Basic ' + btoa(config.elasticAuthName + ':' + config.elasticAuthPass);

export async function updateUser(auth, authUser) {
    let idToken = await auth.getIdToken(true);
    let body = {};
    body['idToken'] = idToken;
    body['name'] = authUser.name;
    body['location'] = authUser.locationPrivacy === Privacy.PUBLIC ? (authUser.fullAddress || "") : "";
    body['type'] = Classes.USER;
    // TODO add clubs
    // body['clubs'] = jsonEncode(authUser.clubs);

    axios.post(config.apiURL + "/elastic/updateUser" ,body );
}

export async function searchStableUsers(sid, term, from = 0, size = 5, withoutHorses = false) {
    let response = await axios.post(
        config.elasticSearchURL + config.elasticSearchBasePath + 'stable_members/_search',
        JSON.stringify(withoutHorses ? _getUsersByStableWithoutHorses(sid, term.toLowerCase(), from, size) : _getUsersByStableWithHorses(sid, term.toLowerCase(), from, size)),
        getRequestConfig()
    );
    return searchStableUsersToResult(response.data);
}

export async function searchUser(term, from = 0) {
    let response = await axios.post(
        config.elasticSearchURL + config.elasticSearchBasePath +  'user/_search',
        JSON.stringify(getBasicUserBody(term.toLowerCase(),5, from)),
        getRequestConfig()
    );

    return searchUserToResult(response.data);
}

export async function searchStable(term, amount=5, from = 0) {
    let response = await axios.post(
        config.elasticSearchURL + config.elasticSearchBasePath +  'stable/_search',
        JSON.stringify(getBasicStableBody(term.toLowerCase(),amount, from)),
        getRequestConfig()
    );
    return searchStableToResult(response.data);
}

export async function searchBookingsForFacilityAndDay(fid, day) {
    let response = await axios.post(
        config.elasticSearchURL + config.elasticSearchBasePath +  'booking/_search',
        JSON.stringify(_getBookingsByDate(fid,day)),
        getRequestConfig()
    );
    return searchBookingsToResult(response.data);
}

function searchUserToResult(json) {
    let result = [];
    let total = 0;

    if (json != null && json['hits'] != null && json['hits']['total'] != null  && json['hits']['hits'] != null && json['hits']['hits'].length >0) {
        total = json['hits']['total'];
        let hits = json['hits']['hits'];
        hits.forEach( (hit) => {

            let searchResult = elasticSearchUserFromElasticJSON(hit["_source"]);
            if (!!searchResult) {
                result.push(searchResult);
            }
        });
    }
    return {users: result, total: total};
}

function searchStableUsersToResult(json) {
    let result = [];
    let total = 0;

    if (json != null && json['hits'] != null && json['hits']['total'] != null  && json['hits']['hits'] != null && json['hits']['hits'].length >0) {
        total = json['hits']['total'];
        let hits = json['hits']['hits'];

        hits.forEach( (hit) => {

            let searchResult = elasticSearchStableUserFromElasticJSON(hit["_source"]);
            if (!!searchResult) {
                result.push(searchResult);
            }
        });
    }
    return {users: result, total: total};
}

function searchStableToResult(json) {
    let result = [];
    let total = 0;

    if (json != null && json['hits'] != null && json['hits']['total'] != null  && json['hits']['hits'] != null && json['hits']['hits'].length >0) {
        total = json['hits']['total'];
        let hits = json['hits']['hits'];

        hits.forEach( (hit) => {

            let searchResult = elasticSearchStableFromElasticJSON(hit["_id"],hit["_source"]);
            if (!!searchResult) {
                result.push(searchResult);
            }
        });
    }
    return {stables: result, total: total};
}


function searchBookingsToResult(json) {
    let result = [];

    if (json != null && json['hits'] != null && json['hits']['total'] != null  && json['hits']['hits'] != null && json['hits']['hits'].length >0) {
        let hits = json['hits']['hits'];

        hits.forEach( (hit) => {

            let bookingId = hit["_source"]["bid"];
            if (!!bookingId) {
                result.push(bookingId);
            }
        });
    }
    return result;
}

function getRequestConfig() {
    // console.log(config.elasticAuthName + ':' + config.elasticAuthPass);
    return {
        headers: {
            'Content-type': 'application/json',
            'Authorization':basicAuth
        }
    }
}

function getBasicUserBody(term, size = 5, from = 0) {
    return {
        "_source": ["id","avatar","name","location"],
        "size": "" + size,
        "from": "" + from,
        "query": {
            "match": {
                "search": {
                    "query": term,
                    "operator": "and"
                }
            }
        }
    };
}

function getBasicStableBody(term, size = 5, from = 0) {
    return {
        "_source": ["id","name","location","count"],
        "size": "" + size,
        "from": "" + from,
        "query": {
            "match": {
                "search": {
                    "query": term,
                    "operator": "and"
                }
            }
        }
    };
}

function _getUsersByStableWithHorses(sid, term,from = 0, size = 5) {
    return {
        "_source": ["id", "name", "horses",],
        "size": size.toString(),
        "from": from.toString(),
        "query": {
            "bool": {
                "should": [
                    {
                        "match": {
                            "name": {
                                "query": term,
                                "operator": "and"
                            }
                        }
                    },
                    {
                        "match": {
                            "horses.name": {
                                "query": term,
                                "operator": "and"
                            }
                        }
                    }
                ],
                "must": [
                    {
                        "exists": {
                            "field": sid
                        }
                    }
                ],
                "minimum_should_match": !term ? 0 : 1
            }
        }
    };
}

function _getUsersByStableWithoutHorses(sid,term, from = 0, size = 5) {
    return {
        "_source": ["id", "name"],
        "size": size.toString(),
        "from": from.toString(),
        "query": {
            "bool": {
                "should": [
                    {
                        "match": {
                            "name": {
                                "query": term,
                                "operator": "and"
                            }
                        }
                    }
                ],
                "must": [
                    {
                        "exists": {
                            "field": sid
                        }
                    }
                ],
                "minimum_should_match": 1
            }
        },
        "sort" : [{
            "name" : {
                "order" : "desc",
                "mode" : "max"
            }
        }]
    };
}

/**
 * @param {string} fid
 * @param {Date} day
 */
function _getBookingsByDate(fid, day) {
    const _msPerDay = 1000 * 60 * 60 * 24;
    const dayInUTC = Date.UTC(day.getFullYear(), day.getMonth(), day.getDate());
    const beginningOfYear = Date.UTC(day.getFullYear(), 0, 1);
    const beginningOfHalfYear = Date.UTC(day.getFullYear(), 6, 1);
    let dayDifferenceFullYear = Math.floor( Math.abs(beginningOfYear-dayInUTC) / _msPerDay) + 1;
    let dayDifferenceHalfYear = dayDifferenceFullYear ;
    if (dayInUTC > (beginningOfHalfYear)) {
        dayDifferenceHalfYear = Math.floor( Math.abs(beginningOfHalfYear-dayInUTC) / _msPerDay) +1;
    }

    let query =  {
        "_source": ["bid"],
        "size": 1000,
        "query": {
            "bool" : {
                "must": [
                    {
                        "term" : {
                            "fid" : fid
                        }
                    }
                ],
                "should": [
                    {
                        "bool" : {
                            "must" : [
                                {
                                   "term" : {
                                     "day" : day.getTime()
                                   }
                                }
                            ],
                            "must_not": [
                                {
                                    "exists": {
                                        "field": "repInt"
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "bool" : {
                            "must" : [
                                {
                                    "range": {
                                        "day": {
                                            "lte":  day.getTime()
                                        }
                                    }
                                },
                                {
                                    "term" : {
                                        "repInt" : "RepetitionInterval.MONTHLY"
                                    }
                                },
                                {
                                    "term" : {
                                        "diff" : day.getDate()
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "bool" : {
                            "must" : [
                                {
                                    "range": {
                                        "day": {
                                            "lte":  day.getTime()
                                        }
                                    }
                                },
                                {
                                    "term" : {
                                        "repInt" : "RepetitionInterval.HALFYEARLY"
                                    }
                                },
                                {
                                    "term" : {
                                        "diff" : dayDifferenceHalfYear
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "bool" : {
                            "must" : [
                                {
                                    "range": {
                                        "day": {
                                            "lte":  day.getTime()
                                        }
                                    }
                                },
                                {
                                    "term" : {
                                        "repInt" : "RepetitionInterval.YEARLY"
                                    }
                                },
                                {
                                    "term" : {
                                        "diff" : dayDifferenceFullYear.toString()
                                    }
                                }
                            ]
                        }
                    },
                    {
                        "bool" : {
                            "must" : [
                                {
                                    "range": {
                                        "day": {
                                            "lte":  day.getTime()
                                        }
                                    }
                                },
                                {
                                    "term" : {
                                        "repInt" : "RepetitionInterval.DAILY"
                                    }
                                }
                            ]
                        }
                    }
                ],
                "must_not": [
                    {
                        "range": {
                            "lastAt": {
                                "lte":  day.getTime()
                            }
                        }
                    },
                    {
                        "term" : {
                            "except" : day.getTime()
                        }
                    }
                ],
                "minimum_should_match": 1
            }
        }
    };

    Object.keys(getIntValuesForRepetition()).forEach((repetition) => {
        query["query"]["bool"]["should"].push(getBookingsQueryForRepetitionWeek(fid, day,repetition));
    });

    return query;
}

function getBookingsQueryForRepetitionWeek(fid, day, week) {
    return (
        {
            "bool" : {
                "must" : [
                    {
                        "term" : {
                            "fid" : fid
                        }
                    },
                    {
                        "term" : {
                            "repDays" : getWeekdayForInt()[day.getDay()]
                        }
                    },
                    {
                        "range": {
                            "day": {
                                "lte":  day.getTime()
                            }
                        }
                    },
                    {
                        "term" : {
                            "repInt" : "RepetitionInterval.WEEKLY"
                        }
                    },
                    {
                        "term" : {
                            "repWeek" : week.toString()
                        }
                    }
                ],
                "filter" : [{
                    "script" : {
                        "script" : {
                            "source": "ZonedDateTime now = ZonedDateTime.ofInstant(Instant.ofEpochMilli(params['now']), ZoneId.of('Z')); ZonedDateTime start = ZonedDateTime.ofInstant(Instant.ofEpochMilli(doc['day'].value), ZoneId.of('Z')); long differenceInDays = ChronoUnit.DAYS.between(now, start); return ( ( (differenceInDays / 7 ) % params['weeklyRep']) == 0 ) ",
                            "lang": "painless",
                            "params": {
                                "now": day.getTime(),
                                "weeklyRep": getIntValuesForRepetition()[week],
                            }
                        }
                    }
                }]
            }
        }
    );
}
