import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import axios from 'axios';

import {agbFromFirestoreDocument} from "../models/agb";
import {apiURL} from "../config";

class FirebaseAGBController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }

    getLatestAGB = async () => {
        let agbDocuments = await this.app.firestore().collection('agbs').orderBy('createdAt', "desc").limit(1).get();

        for( let doc of agbDocuments.docs) {
            let agb = agbFromFirestoreDocument(doc);
            if (!!agb) {
                // let res = await axios.get(agb.url,{  headers: {'Content-Type': 'application/json'}});
                // agb.text =res.data;
                return agb;
            }
        }
        return null;
    };

    hasSignedAGB = async (agbId, uid) =>  {
        let agbdoc = await this.app.firestore().collection('agbs').doc(agbId).collection('users').document(uid).get();
        return agbdoc != null && agbdoc.data() != null && agbdoc.data()['signed'] > 0;
    };

    /**
     * @param {firebase.User} auth
     */
    signAGB = async (agbId, auth) => {

        let idToken = await auth.getIdToken(true);
        let body = {};
        body['idToken'] = idToken;
        body['agb'] = agbId;

        let response = await axios.post(apiURL+'/agb/sign',  body );
        return response.status === 200 ;
    };


}

export default FirebaseAGBController;