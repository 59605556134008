import {ShallowUser} from "./shallow_user";
import * as helpers from "../additional/helpers";

export class ElasticSearchUser extends ShallowUser{
    location;
    followers;
    isBusiness;

    constructor({name,avatar,uid, location="", followers=0, isBusiness = false} = {}){
        super({name: name, avatar :avatar, uid: uid});
        this.location = location;
        this.followers = followers;
        this.isBusiness = isBusiness;
    }
}

function validateElasticJSON(json) {
    return !!json
        && !!json["name"]
        && !!json["id"];
}

export function elasticSearchUserFromElasticJSON(json) {
    if (validateElasticJSON(json)) {
        let user = new ElasticSearchUser( {
            uid: json['id'],
            avatar: helpers.getUserAvatarUrl(json['id']),
            name: json['name'],
            location:  json['location'] || "",
            followers:  json['followers'] || 0
        });
        user.isBusiness  = !!json['business'];
        return user;
    }
    return null;
}