import {Link} from "react-router-dom";
import React,{Component} from "react";
import LocalizationsWithLanguage from "../localization_wrapper";
import ContextMenu from "../additional/context_menu";

let strings = new LocalizationsWithLanguage({
    de:{
        changeStable:"Stall wechseln",
        stableImageAlt: "Stallbild"
    },
    en: {
        changeStable:"Change stable",
        stableImageAlt: "Stable image"
    }
});

class StableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
        this.setState(this.state);
    };

    stopEvent = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    openCloseModal = () => {
        let showModal = !this.state.showModal;
        this.setState({showModal: showModal})
    };

    render(){

        let stables = [];

        if (this.props.stables) {
            for (let stable of Object.values(this.props.stables)) {
                stables.push(stable);
            }
        }

        return (
            <div className="stable-header">
                <h1> {this.props.name}</h1>
                { stables.length > 1 ? <ContextMenu overrideClassName="top-bar-contextmenu"
                                                    openingElement={<a onClick={this.openCloseModal}>{strings.changeStable}
                                                                    <img src="/img/icons/primary/dropdowntriangle.png" alt="Dropdown Icon" className="dropdown-triangle-stable"/></a>}
                                                    direction="down"
                                                    selectType="facility-select">
                    {
                        stables.map((stable, i) => {
                                return (
                                    <Link to={`/stables/${stable.sid}/latestposts`} className={this.props.name === stable.name ? "disabled-option" : ""}>
                                        <div className="option language-option" key={"changeStable" + stable.sid}>
                                            <div className="stable-preview-div">
                                                <img src={stable.header} onError={this.onImageLoadFail}
                                                     alt={strings.stableImageAlt}/>
                                            </div>
                                                {stable.name}
                                        </div>
                                    </Link>
                                )
                            }
                        )
                    }
                </ContextMenu> : "" }


            </div>
        )
    }
}

export default StableHeader;