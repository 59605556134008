import React, {Component} from 'react';
import {WithFirebase} from "../firebase/firebase";
import {withRouter} from "react-router-dom";
import RenderedComment from "./comment";
import {WithAuthentication} from "../firebase/auth_provider";
import CreateCommentField from "./comment/create";
import CommentCache from "../caches/comment_cache";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        loadMore: "Zeige weitere Kommentare"
    },
    en: {
        loadMore: "Show more comments"
    }
});

class ShowComments extends Component {
    batchSize = 20;

    loadMore = async () => {
        let newComments = [];
        if(this.state.comments.length > 0) {
            let comments = CommentCache.getPostCommentIds(this.props.post.pid);
            if (!this.state.didLoadCache && !!comments && comments.length > this.state.comments.length) {
                this.setState({
                    didLoadCache: true,
                    comments: comments,
                    showLoadMore: comments.length < this.props.post.commentCount,
                });
            } else {
                let lastComment = CommentCache.getCommentByCid( this.state.comments[this.state.comments.length-1]);
                newComments = await this.props.firebase.commentController.getCommentsOlderThanByPid(this.props.post.pid, lastComment.createdAt,this.rebuild);
                this.setState({
                    didLoadCache: true,
                    comments: this.state.comments.concat(newComments),
                    showLoadMore: newComments.length >= this.batchSize,
                });
            }
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            comments: [],
            showLoadMore: true,
            didLoadCache : false
        };
    }


    rebuild= () => {
        this.setState(this.state);
    };

    componentDidMount() {
        this.props.firebase.commentController.loadPreviewCommentByPid(this.props.post.pid,this.rebuild).then((commentId) => {
            this.state.comments.push(commentId);
            this.setState(this.state);
        });
    }

    onCommentCreated = (newComment) => {
        let newList = [
            newComment.cid
        ];

        this.setState({
            comments: newList.concat(this.state.comments),
        });
        this.props.onChange();
    };

    render() {
        const showLoadMore =  this.state.showLoadMore && this.props.post.commentCount > 0;
        let canReact = this.props.canReact === undefined ? true : this.props.canReact;

        return(
            <div className="comment-section">
                {canReact && <CreateCommentField post={this.props.post} onCommentCreated={this.onCommentCreated}/>}
                {
                    this.state.comments.map((commentId) => {
                        let comment = CommentCache.getCommentByCid(commentId);
                        if (!comment) {
                            return undefined;
                        }
                        return <RenderedComment key={commentId} comment={comment} canDelete={comment.uid === this.props.auth.user.uid} canReact={canReact}/>
                    })
                }
                { showLoadMore && <ShowMoreCommentsButton loadMore={this.loadMore}/> }
            </div>
        )
    }
}

const ShowMoreCommentsButton = (props) =>
    <div className="comments-load-more">
        <a onClick={props.loadMore}>{strings.loadMore}</a>
    </div>;

const PostComments = withRouter(WithAuthentication(WithFirebase(ShowComments)));
export default PostComments;

