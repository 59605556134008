import React, {Component} from 'react';
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import {withRouter} from "react-router-dom";
import * as elasticSearchController from "../../../elastic/elastic_controller";
import debounce from "lodash.debounce";
import UserCard from "../../../additional/user_card";
import LocalizationsWithLanguage from "../../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        searchPlaceholder:"Suche nach Nutzern, um sie als Administrator für diesen Stall hinzuzufügen!",
        invited: "Eingeladen",
        accept: "Annehmen",
        banned: "Gebannt",
        invite: "Einladen"
    },
    en: {
        searchPlaceholder:"Search for users to add them to your stable as administrator!",
        invited: "Invited",
        accept: "Accept",
        banned: "Banned",
        invite: "Invite"
    }
});

class AddStableAdminsIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userResult: [],
            admins: {},
            pending: {},
            stable: undefined,
            loading: false,
        }
    }

    triggerSearch = debounce((term) => {
        if (this.state.loading || term.length < 2 ) return;

        this.setState({
            loading: true
        });
        this.search(term);
    }, 200);

    search = async (term) =>   {
        this.setState(this.state);
        let userResult = await elasticSearchController.searchUser(term);
        this.setState({userResult: userResult.users,loading: false});
    };

    loadAdmins = async () => {
        let users = await this.props.firebase.stableController.loadAdminsForStable(this.props.match.params.sid);
        let that = this;
        users.forEach( function(user){
            that.state.admins[user.uid] =true;
        });
        this.setState(this.state);
    };

    loadPendingAdmins = async () => {
        let users = await this.props.firebase.stableController.loadPendingAdminsForStable(this.props.match.params.sid);
        let that = this;
        users.forEach( function(user){
            that.state.pending[user.uid] =true;
        });
        this.setState(this.state);
    };

    inviteUser= async(user) => {
        this.state.pending[user.uid] = true;
        this.setState(this.state);
        let isMember = await this.props.firebase.stableController.isMemberOfStable(this.state.stable.sid,user.uid);
        if (isMember) {
            this.props.firebase.stableController.inviteAdminForStable(this.state.stable.sid,this.props.auth.user.name,user.uid, user.name );
        } else {
            this.props.firebase.stableController.inviteUserToStable(this.state.stable.sid,this.props.auth.user.name,user.uid, user.name, true);
        }
    };

    removeInvite= async(user) => {
        delete this.state.pending[user.uid];
        this.setState(this.state);
        let isMember = await this.props.firebase.stableController.isMemberOfStable(this.state.stable.sid,user.uid);
        if (isMember) {
            this.props.firebase.stableController.removeAdminInvite(this.state.stable.sid,user.uid);
        } else {
            this.props.firebase.stableController.removeUserFromStable(this.state.stable.sid, this.state.stable.name, user.uid);
        }
    };

    loadDisplayedStable() {
        this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
            this.setState({ stable : stable});
        });
    }

    componentDidMount() {
        this.loadDisplayedStable();
        this.loadAdmins();
        this.loadPendingAdmins();
    }

    getOptions(user) {
        if (this.state.admins.hasOwnProperty(user.uid)) {
           return null;
        }

        if (this.state.pending.hasOwnProperty(user.uid)) {
            return (
                <button className="secondary-button" onClick={() => {this.removeInvite(user)}}>
                    {strings.invited}
                </button>
            )
        }

        return (
            <button onClick={() => this.inviteUser(user)}>
                {strings.invite}
            </button>
        )
    }

    render() {
        let searchResults = <div></div>;

        if(!!this.state.stable && !!this.state.userResult && this.state.userResult.length >0) {
            searchResults = <div className="search-results">

                {
                    this.state.userResult.map((user, i) => {
                        return (
                            <UserCard key={"user"+ user.uid} image={user.avatar} name={user.name} options={() => this.getOptions(user)}/>
                        )
                    })
                }

            </div>
        }

        return (
            <div>
                <input

                        name="search"
                        className="search"
                    type="text"
                    onChange={(event) => this.triggerSearch(event.target.value)}
                    placeholder={strings.searchPlaceholder}
                />

                {searchResults}

            </div>
        );
    }
};

export default withRouter(WithAuthentication(WithFirebase(AddStableAdminsIndex)));