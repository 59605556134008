import Environment from "./models/environment";

export const apiURL = "https://us-central1-jutta-live-913c1.cloudfunctions.net/api/v1_7";
export const storageUrl = "https://firebasestorage.googleapis.com/v0/b/jutta-live-913c1.appspot.com/o/";

export const elasticSearchURL = "https://a72da13b71ca4011a79def466bfcce1a.europe-west3.gcp.cloud.es.io:9243/";
export const elasticSearchBasePath = "live_" ;
export const elasticAuthName = "searchLive";
export const elasticAuthPass = "3PuTKAqtH5Crg2Cmn5F5";

export const environment = Environment.PRODUCTION;
