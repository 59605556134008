const Gender = {
    MALE : 'Gender.MALE',
    FEMALE : 'Gender.FEMALE',
    NEUTRAL : 'Gender.NEUTRAL',
    OTHER : 'Gender.OTHER',
};


export default Gender;


