import React from 'react';
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        ok: " Ok ",
    },
    en: {
        ok: " Ok ",
    }
});

class ErrorDialog extends React.PureComponent {
    render() {
        return (
            <div className="modal-column">
                <h3>{ this.props.title}</h3>
                <p>{this.props.description} {this.props.link && <a href="#" target="_blank" rel="noopener noreferrer" onClick={() => window.open(this.props.link, '_blank').focus()}>{this.props.link}</a>}</p>
                <button onClick={this.props.onClose}>{this.props.buttonLabel || strings.ok}</button>
            </div>
        );
    }
}

export default ErrorDialog;