import React from "react";

const CommentContent = (props) =>
    <div className="comment-content form-row">
        <div className="comment-image-wrapper">
            {!!props.comment.image && <img src={props.comment.image}/>}
        </div>
        <div className="comment-content-text">
            {props.comment.textContent}
        </div>
    </div>;

export default CommentContent;