import * as helpers from "../additional/helpers";

export class ElasticSearchStable {
    sid;
    name;
    header;
    address;
    memberCount;

    constructor({sid, name, header, address, memberCount = 0} = {}){
        this.sid = sid;
        this.name = name;
        this.header = header;
        this.address = address;
        this.memberCount = memberCount;
    }
}

function validateElasticJSON(json) {
    return !!json
        && !!json["name"];
}

export function elasticSearchStableFromElasticJSON(id, json) {
    if (validateElasticJSON(json) && !!id) {
        return new ElasticSearchStable( {
            sid: id,
            name: json['name'],
            header: helpers.getStableHeaderUrl(id),
            address:  json['location'] || "",
            memberCount:  json['count'] || 0
        });
    }
    return null;
}