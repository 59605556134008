import React from "react";
import LikeType from "../../models/live_type";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        like:"Gefällt mir",
        dislike:"Gefällt mir nicht",
        altLike:"Like-Button der Jutta! App",
        altDislike:"Dislike-Button"
    },
    en: {
        like:"Like",
        dislike:"Dislike",
        altLike:"Like button",
        altDislike:"Dislike button"
    }
});

function updateLikeType(props, likeType) {
    let comment = props.comment;
    comment = props.firebase.commentController.changeUserLikeTypeForComment(props.auth.user,comment,comment.userLikeType,likeType);
    props.auth.reactions.changeCommentReaction(comment.cid,comment.userLikeType);
    props.onChange(comment);
}


const CommentReactions = (props) =>
    <div className="comment-reaction">
        <div className="btn"  onClick={() => updateLikeType(props, LikeType.LIKE)}>
            <img className="icon"
                 alt={strings.altLike}
                 src={
                     props.comment.userLikeType === LikeType.LIKE
                         ?'/img/icons/primary/like.png' : '/img/icons/dark/like.png'}
            />
            <span>{strings.like}</span>
        </div>
        <div className="btn" onClick={() => updateLikeType(props, LikeType.DISLIKE)}>
            <img className="icon"
                 alt={strings.altLike}
                 src={
                     props.comment.userLikeType === LikeType.DISLIKE
                         ?'/img/icons/primary/dislike.png' : '/img/icons/dark/dislike.png'
                 }/>
            <span>{strings.dislike}</span>
        </div>
    </div>;

export default CommentReactions;