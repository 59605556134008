const BanningPeriod = {
    NEVER : 'BanningPeriod.NEVER',
    WEEK : 'BanningPeriod.WEEK',
    MONTH : 'BanningPeriod.MONTH',
    FOREVER : 'BanningPeriod.FOREVER'
};



export default BanningPeriod;
