import React, {Component} from 'react';
import {getWeekdays} from "./text_helpers";

class WeekdaySelector extends Component {
    chosenDays = this.props.chosenDays;

    toggleDay(weekday) {
        let weekdayIndex = this.chosenDays.indexOf(weekday);
        if(weekdayIndex > -1) {
            delete this.chosenDays[weekdayIndex];
        } else {
            this.chosenDays.push(weekday);
        }

        this.props.onChange(this.chosenDays);
        this.setState(this.state);
    }

    render() {
        let weekdays = getWeekdays();
        return (
            <div className="row">
                {
                    Object.keys(weekdays).map((weekday) => {
                        return(
                            <div key={weekday}
                                className={"weekday-bubble" + (this.chosenDays.includes(weekday) ? " selected" : "")}
                                onClick={() => this.toggleDay(weekday)}>
                                {weekdays[weekday].slice(0, 2).toUpperCase()}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default WeekdaySelector;