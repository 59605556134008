import React from "react";
import LocalizationsWithLanguage from "../localization_wrapper";
// THIS IS NOT UNUSED DO NOT REMOVE
import fslightbox from "fslightbox";

let strings = new LocalizationsWithLanguage({
    de:{
        more:"mehr",
    },
    en: {
        more:"more",
    }
});
function ThumbnailGallery (props) {
    let gallery;

    if(props.media.length === 0) {
        gallery = <div/>;
    }
    else if(props.media.length === 1) {
        gallery = _buildSingleImage(props,props.galleryId);
    }
    else if(props.media.length === 2) {
        gallery = _buildTwoTileRow(props,props.galleryId);
    }
    else if(props.media.length >= 3) {
        gallery = _buildTwoColumn(props,props.galleryId);
    }

    return (<div className="post-images">{gallery}</div>);
}

function _buildSingleImage(props, key, index = 0) {
    if (!!props.media[index] && !!props.media[index].url) {
        return (
            <a data-fslightbox={key} data-type="video" href={props.media[index].url}>
                <img className="main-image" alt="" src={props.media[index].thumbnail}/>
            </a>
        );
    }
    return (
        <a data-fslightbox={key} data-type="image" href={props.media[index]}>
            <img className="main-image" alt="" src={props.media[index]}/>
        </a>
    );
}

function _buildTwoTileRow(props, key, index1 = 0, index2 = 1) {
    return (
            <div className="thumbs">
                {_tileImage(props.media[index1],key, "two-tile")}
                {_tileImage(props.media[index2],key, "two-tile")}
            </div>
    );
}

function _buildThreeTileRow(props, key, index1 = 0, index2 = 1, index3 = 2) {
    let oddImagesCount = props.media.length-4;
    return (
            <div className="thumbs">
                {_tileImage(props.media[index1],key,  "three-tile")}
                {_tileImage(props.media[index2],key, "three-tile")}
                {oddImagesCount > 0 ? _oddImage(props.media[index3], key, oddImagesCount) : _tileImage(props.media[index3],key, "three-tile")}
            </div>
    );
}

function _buildTwoColumn(props, key) {
    return (
        <div>
            <div>{_buildSingleImage(props, key)}</div>
            <div>{props.media.length === 3 ? _buildTwoTileRow(props, key, 1, 2) : _buildThreeTileRow(props, key, 1, 2, 3)}</div>
        </div>
    );
}

function _tileImage(media, key, customClass) {
    if (!!media && !!media.url) {
        return (
            <a data-fslightbox={key} data-type="video" href={media.url}>
                <img className={"tile-image " + customClass} alt="" src={media.thumbnail}/>
            </a>
        );
    }
    return (
        <a data-fslightbox={key} href={media}>
            <img className={"tile-image " + customClass} alt="" src={media}/>
        </a>
    );
}

function _oddImage(media, key, oddImagesCount) {
    if (!!media && !!media.url) {
        return (
            <a data-fslightbox={key} data-type="video" href={media.url}>
                <div className="odd-image">
                    <img alt="" src={media.thumbnail}/>
                    <div className={"overlay"}> {(oddImagesCount) + " " + strings.more}</div>
                </div>
            </a>
        );
    }
    return (
        <a data-fslightbox={key} href={media}>
            <div className="odd-image">
                <img alt="" src={media}/>
                <div className={"overlay"}> {(oddImagesCount) + " " + strings.more}</div>
            </div>
        </a>
    );
}

export default ThumbnailGallery;