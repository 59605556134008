import React, {Component} from 'react';
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {withRouter} from "react-router-dom";
import {Booking} from "../../models/booking";
import {
    getEndOfToday,
    getIntValuesForRepetition,
    secondsToTimeOfDay,
    timeOfDayToSeconds
} from "../../additional/helpers";
import BookingCache from "../../caches/booking_cache";
import LocalizationsWithLanguage from "../../localization_wrapper";
import DatePicker from "react-datepicker";
import RepetitionInterval from "../../models/repetition_interval";
import ContextMenu from "../../additional/context_menu";
import {getTranslationsForRepetitionInterval} from "../../additional/text_helpers";
import RepetitionWeek from "../../models/repetition_week";
import WeekdaySelector from "../../additional/weekday_selector";
import StableBookingsRepeatingPermission from "../../models/stable_bookings_repeating_permissions";

let strings = new LocalizationsWithLanguage({
    de:{
        date: "Datum",
        startTime: "Startzeit",
        endTime: "Endzeit",
        notes: "Notizen",
        takeNotes: "Mach Dir Notizen!",
        slots: "Plätze",
        allSlots: "Alle Plätze",
        save: "Speichern",
        title: "Buchung hinzufügen",
        errorTitleNoOpenLanes: "Keine Slots verfügbar",
        errorDescriptionNoOpenLanes: "Keine verfügbaren Plätze für diesen Zeitraum. Bitte wähle eine andere Uhrzeit.",
        repeatLabel: "Wiederhole",
        weekRepetitionLabel: "Alle",
        week: "Woche",
        weeks: "Wochen",
        maxBookingDuration: "Die maximale Buchungsdauer beträgt xxx Minuten",
        errorTitleBookingDuration: "Buchungsdauer zu lang",
        errorDescriptionBookingDuration: "Die Buchungsdauer dieser Anlage ist beschränkt. Bitte wähle eine kürzere Buchungsdauer aus.",
        bookingsInAdvance: "Du kannst maximal $days Tage im Voraus buchen. Ein Admin muss deine Buchung bestätigen!",
        bookingsInAdvanceWithTime: "Du kannst maximal $days Tage im Voraus buchen, beginnend um $time. Ein Admin muss deine Buchung bestätigen!"
    },
    en: {
        date: "Date",
        startTime: "Start time",
        endTime: "End time",
        notes: "Notes",
        takeNotes: "Take some notes",
        slots: "Slots",
        allSlots: "All slots",
        save: "Save",
        title: "Add Booking",
        errorTitleNoOpenLanes: "No Slots avaiable",
        errorDescriptionNoOpenLanes: "There were no available slots for your requested time period. Please try another time frame.',\n",
        repeatLabel: "Repeat",
        weekRepetitionLabel: "Every",
        week: "Week",
        weeks: "Weeks",
        maxBookingDuration: "The maximum allowed booking duration is xxx minutes",
        errorTitleBookingDuration: "Booking duration to long",
        errorDescriptionBookingDuration: "The booking duration for this facility is limited. Please selected a shorter booking duration.",
        bookingsInAdvance: "You can only book $days days in advance. An admin needs to approve this booking!",
        bookingsInAdvanceWithTime: "You can only book $days days in advance, starting at $time. An admin needs to approve this booking!"
    }
});

class CreateNewBooking extends Component {
    dateFormatOptions = {year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' };

    constructor(props) {
        super(props);

        this.state = {
            stable: props.stable,
            facility: props.facility,
            loading: true,
            saving: false,
            repeatingAllowed:  props.stable.bookingsRepeatingPermission !== StableBookingsRepeatingPermission.DISABLED,
            canBookInFuture: true,
            date: props.date,
            start: props.start,
            end: props.end,
            slots: (props.slots && props.slots.length) || 1,
            allSlots: false,
            notes: "",
            maxDurationError: false,
            repetitionInterval: RepetitionInterval.NEVER,
            repetitionWeek: RepetitionWeek.ONEWEEK,
            repetitionDays: []
        }
    }

    componentDidMount() {
        this.initializePermissions();
    }

    initializePermissions = async () => {
        if (this.props.stable.bookingsRepeatingPermission === StableBookingsRepeatingPermission.DISABLED) {
            let canBookRepeating = await this.props.auth.isEmployeeOfStable(this.props.match.params.sid) || await this.props.auth.isAdminOfStable(this.props.match.params.sid);
            this.setState( { repeatingAllowed: canBookRepeating});
        }

        if(!!this.props.facility.maxFutureBookingDays) {
            let canBookInFuture = await this.props.auth.isEmployeeOfStable(this.props.match.params.sid) || await this.props.auth.isAdminOfStable(this.props.match.params.sid);
            this.setState( { canBookInFuture: canBookInFuture});
        }
    }

    onChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    onCreate = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.refs['create-booking-form'].reportValidity();
        this.state.maxDurationError = false;

        if (!!this.state.start && !!this.state.end && this.state.facility && this.state.facility.maxBookingDuration) {
            if ( (timeOfDayToSeconds(this.state.end) - timeOfDayToSeconds(this.state.start) )> this.state.facility.maxBookingDuration) {
                this.state.maxDurationError = true;
                if (!!this.props.onError) {
                    this.props.onError(strings.errorTitleBookingDuration, strings.errorDescriptionBookingDuration);
                }
            }
        }

        if(!this.state.saving && (!!this.state.slots || !!this.state.allSlots) && !!this.state.start && !!this.state.end && !!this.state.date && !this.state.maxDurationError) {
            this.setState({ saving: true });
            let lanes;

            this.props.onCreate();

            if(!!this.state.allSlots) {
                lanes = Array.from(Array(parseInt(this.state.facility.maxBookers)), (x, index) => index);
            } else if(!!this.props.slots && this.state.slots === this.props.slots.length) {
                lanes = this.props.slots;
            } else {
                lanes = Array.from(Array(parseInt(this.state.slots)), (x, index) => index);
            }

            let chosenDate = new Date(this.state.date);
            chosenDate.setUTCHours(12);

            let booking = new Booking({
                sid: this.props.match.params.sid,
                fid: this.props.match.params.fid,
                uid: this.props.auth.user.uid,
                day: chosenDate,
                start: timeOfDayToSeconds(this.state.start),
                end: timeOfDayToSeconds(this.state.end),
                notes: this.state.notes,
                userName: this.props.auth.user.name,
                horseIds: [],
                lanes: lanes,
                repetitionInterval: this.state.repeatingAllowed ? this.state.repetitionInterval : undefined,
                repetitionWeek: this.state.repeatingAllowed ? this.state.repetitionWeek  : undefined,
                repetitionDays: this.state.repeatingAllowed ? this.state.repetitionDays  : undefined
            });

            let returnData = await this.props.firebase.facilityController.createBookingForFacility(this.props.match.params.sid, this.props.match.params.fid, booking, false, this.state.canBookInFuture);

            if (!!returnData) {
                booking.bid = returnData['id'];
                booking.lanes = returnData['lanes'];

                BookingCache.updateCacheEntry(booking);
                BookingCache.appendBookingIdsForFacilityAndDay(booking.fid, booking.day.getTime(), [booking.bid]);
                this.setState({ saving: false });
                this.props.auth.rebuildApp();
            } else {
                if (!!this.props.onError) {
                    this.props.onError(strings.errorTitleNoOpenLanes, strings.errorDescriptionNoOpenLanes);
                }
            }

        }
    };

    onDateChange = (date) => {
        this.setState({
            date: date
        });
    };

    onChosenDaysChange = (chosenDays) => {
        this.setState({
            repetitionDays: chosenDays
        })
    };

    onRepetitionIntervalChange = (event) => {
        this.setState({
            repetitionInterval: event.target.value
        })
    };

    onRepetitionWeekChange = (event) => {
        this.setState({
            repetitionWeek: event.target.value
        })
    };

    getDateButton() {
        return (
            <div className="select-date-input">
                <span>{this.state.date.toLocaleString(strings.locale, this.dateFormatOptions)}</span>
            </div>
        )
    }

    getWeekSelection() {
        if(this.state.repetitionInterval === RepetitionInterval.WEEKLY) {
            let repetitionWeekValues = getIntValuesForRepetition();
            return(
                <>
                    <div className="modal-row">
                        <label htmlFor="repetitionInterval">{strings.weekRepetitionLabel}</label>
                        <select className="repetition-type-input" name="repetitionInterval" onChange={this.onRepetitionWeekChange}>
                            {
                                Object.values(RepetitionWeek).map((week) => {
                                    return(
                                        <option key={week} value={week}>{repetitionWeekValues[week]} {repetitionWeekValues[week] === 1 ? strings.week : strings.weeks}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="modal-row">
                        <WeekdaySelector chosenDays={this.state.repetitionDays} onChange={this.onChosenDaysChange}/>
                    </div>
                </>
            )
        }
    }

    getBookingFutureWarning = () => {
        let endOfToday = getEndOfToday();
        let maxBookingDate = new Date(endOfToday.getTime());
        maxBookingDate.setDate(maxBookingDate.getDate() + this.state.facility.maxFutureBookingDays);

        if(!!this.state.facility.futureDaysBookingStart) {
            let now = new Date();
            let timeOfDayInSeconds = (now.getHours() * 60 + now.getMinutes()) * 60;

            if(this.state.facility.futureDaysBookingStart > timeOfDayInSeconds) {
                maxBookingDate.setDate(maxBookingDate.getDate() - 1);
            }
        }

        if(this.state.date > maxBookingDate) {
            return (
                <p className="mr-1 bookingDaysWarningMessage">
                    {
                        !!this.state.facility.futureDaysBookingStart
                            ? strings.bookingsInAdvanceWithTime.replace('$days', this.state.facility.maxFutureBookingDays).replace('$time', secondsToTimeOfDay(this.state.facility.futureDaysBookingStart))
                            : strings.bookingsInAdvance.replace('$days', this.state.facility.maxFutureBookingDays)
                    }
                </p>
            );
        }
    }

    render() {
        const { date, start, end, notes, slots, allSlots } = this.state;
        const maxBookingDuration = !!this.state.facility ?  this.state.facility.maxBookingDuration : null ;

        let isInvalid = !start || !end || (!allSlots && !slots) || (!allSlots && slots <= 0);
        if (!isInvalid && !!this.state.start && !!this.state.end && this.state.facility && this.state.facility.maxBookingDuration) {
            if ( (timeOfDayToSeconds(this.state.end) - timeOfDayToSeconds(this.state.start) )> this.state.facility.maxBookingDuration) {
                isInvalid = true;
            }
        }

        return (
            <div className="modal-column">
                <h3>{strings.title}</h3>
                <form ref="create-booking-form">
                    <div className="modal-row">
                        <label htmlFor="date">{strings.date}</label>
                        <DatePicker selected={date} onChange={(date) => this.onDateChange(date)} customInput={this.getDateButton()}/>
                    </div>

                    {
                        !!this.state.stable && this.state.stable.hasPro() && !!this.state.facility.maxFutureBookingDays && !this.state.canBookInFuture &&
                        this.getBookingFutureWarning()
                    }

                    <div className="modal-row">
                        <label htmlFor="start">{strings.startTime}</label>
                        <input
                            name="start"
                            value={start}
                            onChange={this.onChange}
                            type="time"
                            placeholder={strings.startTime}
                            required
                        />
                    </div>
                    <div className="modal-row">
                        <label htmlFor="end">{strings.endTime}</label>
                        <input
                            name="end"
                            value={end}
                            onChange={this.onChange}
                            type="time"
                            placeholder={strings.endTime}
                            required
                        />
                    </div>
                    { !!maxBookingDuration &&
                        <div className="modal-row">
                            <label/>
                            <p className="maxBookingDurationInfo">{strings.maxBookingDuration.replace('xxx', Math.floor(maxBookingDuration/ 60))}</p>
                        </div>
                    }
                    {
                        this.state.repeatingAllowed &&    <div className="modal-row">
                            <label htmlFor="repetitionInterval">{strings.repeatLabel}</label>
                            <select id="repetition-input" className="repetition-type-input" name="repetitionInterval" onChange={this.onRepetitionIntervalChange}>
                                {
                                    Object.values(RepetitionInterval).map((interval) => {
                                        return(
                                            <option key={interval} value={interval}>{getTranslationsForRepetitionInterval()[interval]}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }

                    {
                        this.state.repeatingAllowed &&  this.getWeekSelection()
                    }
                    <div className="modal-row">
                        <label htmlFor="notes">{strings.notes}</label>
                        <input
                            name="notes"
                            value={notes}
                            onChange={this.onChange}
                            type="text"
                            placeholder={strings.takeNotes}
                        />
                    </div>
                    <div className="modal-row">
                        <label htmlFor="slots">{strings.slots}</label>
                        <input
                            name="slots"
                            value={slots}
                            onChange={this.onChange}
                            type="number"
                            placeholder={strings.slots}
                            disabled={this.state.allSlots}
                        />
                    </div>
                    <div className="checkbox-row">
                        <input
                            key={new Date().getTime()}
                            name="allSlots"
                            checked={allSlots}
                            onChange={this.onChange}
                            type="checkbox"
                        />
                        <label htmlFor="allSlots">{strings.allSlots}</label>
                    </div>
                    <button disabled={isInvalid} onClick={this.onCreate}>{strings.save}</button>
                </form>
            </div>
        );
    }
}

const CreateNewBookingPage = withRouter(WithAuthentication(WithFirebase(CreateNewBooking)));
export default CreateNewBookingPage;