import React,{Component} from "react";
import PostContent from "./content";
import PostTile from "./tile";
import PostReactions from "./reactions";
import PostComments from "./post_comments";
import {WithAuthentication} from "../firebase/auth_provider";
import EditPostModal from "./edit";
import {WithFirebase} from "../firebase/firebase";
import * as ROUTES from "../../routes";
import {withRouter} from "react-router-dom";
import UserCache from "../caches/user_cache";
import PostCache from "../caches/post_cache";

class RenderedPost extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            post: this.props.post,
            edit: false,
        };
    }

    onChange = () => {
        if (!!this.state.post) {
            let updatedPost = PostCache.getPostByPid(this.state.post.pid);
            this.setState({post : updatedPost});
        }
    };

    showUser = () => {
        this.props.history.push(ROUTES.USER.replace(":uid", this.state.post.uid));
    };

    componentDidMount() {
        if (!!this.state.post) {
            let name = UserCache.getUserNameForUid(this.state.post.uid);
            if (!!name) {
                this.state.post.userName = name;
            }
        }
    }

    render() {
        if (!this.props.post || !!this.props.post.deleted || !this.props.auth || !this.state.post || !!this.state.post.deleted) {
            return null;
        }
        if (this.props.auth.reactions.posts.hasOwnProperty(this.state.post.pid)) {
            this.state.post.userLikeType = this.props.auth.reactions.posts[this.state.post.pid];
        }

        let canEdit = this.props.canEdit || this.state.post.uid === this.props.auth.firebaseUser.uid;
        let canDelete = this.props.canDelete || this.state.post.uid === this.props.auth.firebaseUser.uid;
        let canReact = this.props.canReact === undefined ? true : this.props.canReact;

        return(
            <div>
                <div className="post-container">
                    <PostTile showUser={this.showUser} post={this.state.post} onChange={() => this.onChange()} onEdit={() => this.setState({edit: true})} canEdit={canEdit} canDelete={canDelete}/>
                    <PostContent post={this.state.post}/>
                    {canReact && <PostReactions firebase={this.props.firebase} auth={this.props.auth} onChange={() => this.onChange()} post={this.state.post}/> }
                    <PostComments onChange={() => this.onChange()} post={this.state.post} canReact={canReact}/>
                    { !!this.state.post.isSaving && <div className={"overlay"}/>}
                </div>
                <EditPostModal callback={() => this.onChange()} close={() => this.setState({edit: false})} post={this.state.post} show={this.state.edit}/>
            </div>
        );
    }
}


export default withRouter(WithAuthentication(WithFirebase(RenderedPost)));