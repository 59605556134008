import React, {Component, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import ReactPDF, {Document, Page, Text, View, StyleSheet, PDFRenderer, PDFViewer, Image} from '@react-pdf/renderer';
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import * as Helpers from "../../additional/helpers";
import FacilityCache from "../../caches/facility_cache";
import BookingCache from "../../caches/booking_cache";
import LocalizationsWithLanguage from "../../localization_wrapper";
import * as ROUTES from "../../../routes";
import StableHeader from "../../stables/header";
import ContextMenu from "../../additional/context_menu";
import DateSelector from "../bookings/date_selector";
import Loading from "../../loading";

let strings = new LocalizationsWithLanguage({
    de:{
        loadFacility: "Anlage wird geladen...",
        goBack: "Zurück zum Stall",
        changeFacility: "Anlage wechseln",
        facility: "Anlage",
        business: "Betrieb",
        from: "Von",
        to: "Bis",
        date: "Datum",
        enterFacility: "Betreten der Anlage",
        leaveFacility: "Verlassen der Anlage",
        reasonOfPresence: "Grund der Anwesenheit",
        person: "Person",
        signature: "Unterschrift",
        exportBookingPlan: "Buchungsplan exportieren",
        exportDescription: "Sie können den Buchungsplan einer Anlage (wie Reithalle oder Dressurplatz) für einen " +
            "beliebigen Zeitraum als PDF-Datei exportieren. Je nach Auswahl des Exportzeitraums kann dies einige Zeit in Anspruch nehmen.",
        export: "Exportieren",
        isExporting: "Buchungsplan wird exportiert...",
        dontLeave: "Bitte verlassen Sie diese Seite nicht.",
        noEntries: "Für den ausgewählten Zeitraum sind keine Einträge vorhanden.",
    },
    en: {
        loadFacility: "Loading facility...",
        goBack: "Go back to stable",
        changeFacility: "Change facility",
        facility: "Facility",
        business: "Business",
        from: "From",
        to: "To",
        date: "Date",
        enterFacility: "Enter the facility",
        leaveFacility: "Leaving the facility",
        reasonOfPresence: "Reason of presence",
        person: "Person",
        signature: "Signature",
        exportBookingPlan: "Export booking plan",
        exportDescription: "You can export the booking plan for any period as PDF file. Please make sure that this takes some time, depending on the chosen period.",
        export: "Export",
        isExporting: "Exporting booking plan...",
        dontLeave: "Please do not leave this page.",
        noEntries: "No entries for the given period.",
    }
});

class ExportBookingPlan extends Component{

     pdfStyles= StyleSheet.create({
        page: {
            margin: 10,
            fontSize: 12
        },
        headlineSection: {
            flexDirection: "row",
            marginBottom: 15
        },
        headlineInfoSection: {
            width: "75%",
            flexDirection: "row",
        },
        headlineLogoSection: {
            width: "25%",
            justifyContent: "center",
        },
        headlineLabelSection: {
            width: "20%",
            flexDirection: "column"
        },
        headlineTextSection: {
            width: "80%",
            flexDirection: "column",
        },
        headlineLabel: {
            margin: 5,
            fontSize: 14,
        },
        headlineText: {
            margin: 5,
            fontSize: 14,
            maxLines: 1
        },
        headlineImage: {
            width: "100px",
            height: "auto",
        },
        table: {
            display: "table",
            width: "95%",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            //margin: 5,
        },
        tableRow: {
            flexDirection: "row",
        },
        tableCol: {
            width: "20%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColDate: {
            width: "14%",
        },
        tableColFromTo: {
            width: "13%",
        },
        tableCell: {
            textAlign: "center",
            marginTop: 5,
            marginBottom: 5,
            fontSize: 10
        }
    });
    
    constructor(props) {
        super(props);
        let startDate = new Date();
        let endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7);
        this.state = {
            numPages: null,
            pageNumber: 1,
            bookings: [],
            showLoading: false,
            pdfDataLoaded: false,
            pdfRendered: false,
            start: Helpers.dateToDatePickerValue(startDate),
            end: Helpers.dateToDatePickerValue(endDate),
            facilities: []
        };
    }

    loadBookingsForDay = async (day) => {
        return await this.props.firebase.facilityController.loadBookingsForFacilityAndDay(this.props.match.params.sid, this.state.facility.fid, day);
    };

    correctDate(date) {
        date.setUTCHours(12);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);

        return date;
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            showLoading : false,
            pdfDataLoaded : false,
            pdfRendered : false,
        });
    };

    async getPDFData() {
        this.setState({
            showLoading: true,
            pdfRendered: false,
            pdfDataLoaded: false,
        });


        let loop = new Date(this.state.start);
        let endDate = new Date( this.state.end);

        let bookings = [];

        while(loop <= endDate){
            let additionalBookingIds = await this.loadBookingsForDay(this.correctDate(loop));
            for( let bookingId of additionalBookingIds) {
                let cachedBooking = BookingCache.getBookingById(bookingId);

                if(!!cachedBooking) {
                    // We need to clone the bookings otherwise repeating bookings will all be at the same day
                    let booking = {...cachedBooking};
                    booking.day = new Date(loop);
                    bookings.push(booking);
                }
            }
            let newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        }

        bookings.sort( function (a,b) {
            if (a.day.getTime() === b.day.getTime()) {
                if( a.start < b.start )
                    return -1;

                if( a.start > b.start )
                    return 1;

                return 0;
            }

            if( a.day.getTime() < b.day.getTime() )
                return -1;

            if( a.day.getTime() > b.day.getTime() )
                return 1;

            return 0;
        });

        this.setState({
            bookings: bookings,
            pdfDataLoaded: true,
        })

    }

    loadStableAndFacility = async () => {
        if (await this.props.auth.isMemberOfStable(this.props.match.params.sid)) {
            let isMemberOfStable = this.props.auth.stables.active[this.props.match.params.sid];
            if (isMemberOfStable) {
                let stable = await this.props.firebase.stableController.loadStableByID(this.props.match.params.sid);
                this.setState({
                    stable: stable
                });
            }

            let facility = FacilityCache.getFacilityById(this.props.match.params.fid);
            if(!facility) {
                await this.props.firebase.facilityController.loadFacilityByID(this.props.match.params.sid, this.props.match.params.fid);
                facility = FacilityCache.getFacilityById(this.props.match.params.fid);
            }
            this.setState({
                facility: facility,
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    };

    componentDidMount() {
        this.loadStableAndFacility();
        this.loadFacilities();
    }
    
    loadFacilities = async () => {
        await this.props.firebase.facilityController.loadFacilitiesForStable(this.props.match.params.sid);
        let facilityIds = FacilityCache.getFacilityIdsForStable(this.props.match.params.sid);

        let facilities = [];
        if (!!facilityIds) {
            facilityIds.forEach( function(id) {
                let facility = FacilityCache.getFacilityById(id);
                if (!!facility) {
                    facilities.push(facility );
                }
            });

            facilities = facilities.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        }
        this.setState({
            facilities: facilities
        });
    };

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
    };

    renderHeaders = () =>
        <View style={this.pdfStyles.headlineSection} >
            <View style={this.pdfStyles.headlineInfoSection}>
                <View style={this.pdfStyles.headlineLabelSection}>
                    <View  >
                        <Text style={this.pdfStyles.headlineLabel}>{strings.business + ':'}</Text>
                    </View>
                    <View  >
                        <Text style={this.pdfStyles.headlineLabel}>{strings.facility + ':'}</Text>
                    </View>
                    <View>
                        <Text style={this.pdfStyles.headlineLabel}>{strings.from + ':'} </Text>
                    </View>
                    <View>
                        <Text style={this.pdfStyles.headlineLabel}>{strings.to + ':'}</Text>
                    </View>
                </View>
                <View style={this.pdfStyles.headlineTextSection}>
                    <View  >
                        <Text style={this.pdfStyles.headlineText}>{this.state.stable.name }</Text>
                    </View>
                    <View  >
                        <Text style={this.pdfStyles.headlineText}>{this.state.facility.name } </Text>
                    </View>
                    <View>
                        <Text style={this.pdfStyles.headlineText}>{ (new Date(this.state.start) ).toLocaleDateString(strings.getLanguage()) } </Text>
                    </View>
                    <View>
                        <Text style={this.pdfStyles.headlineText}>{ (new Date(this.state.end) ).toLocaleDateString( strings.getLanguage()) } </Text>
                    </View>
                </View>

            </View>
            <View style={this.pdfStyles.headlineLogoSection} >
                <Image style={this.pdfStyles.headlineImage} src={"/img/jutta-logo-mobile.png"} />
            </View>
        </View>;
    
    renderTableHeader = () => 
        <View style={this.pdfStyles.tableRow}>
            <View style={{ ...this.pdfStyles.tableCol, ...this.pdfStyles.tableColDate}}>
                <Text style={this.pdfStyles.tableCell}>{strings.date}</Text>
            </View>
            <View style={{ ...this.pdfStyles.tableCol, ...this.pdfStyles.tableColFromTo}}>
                <Text style={this.pdfStyles.tableCell}>{strings.enterFacility}</Text>
            </View>
            <View style={{ ...this.pdfStyles.tableCol, ...this.pdfStyles.tableColFromTo}}>
                <Text style={this.pdfStyles.tableCell}>{strings.leaveFacility}</Text>
            </View>
            <View style={this.pdfStyles.tableCol}>
                <Text style={this.pdfStyles.tableCell}>{strings.reasonOfPresence}</Text>
            </View>
            <View style={this.pdfStyles.tableCol}>
                <Text style={this.pdfStyles.tableCell}>{strings.person}</Text>
            </View>
            <View style={this.pdfStyles.tableCol}>
                <Text style={this.pdfStyles.tableCell}>{strings.signature}</Text>
            </View>
        </View>;

    renderTableRow = (booking) =>
        <View style={this.pdfStyles.tableRow}>
            <View style={{ ...this.pdfStyles.tableCol, ...this.pdfStyles.tableColDate}}>
                <Text style={this.pdfStyles.tableCell}>{booking.day.toLocaleDateString(strings.getLanguage())}</Text>
            </View>
            <View style={{ ...this.pdfStyles.tableCol, ...this.pdfStyles.tableColFromTo}}>
                <Text style={this.pdfStyles.tableCell}>{Helpers.secondsToTimeOfDay( booking.start )}</Text>
            </View>
            <View style={{ ...this.pdfStyles.tableCol, ...this.pdfStyles.tableColFromTo}}>
                <Text style={this.pdfStyles.tableCell}>{Helpers.secondsToTimeOfDay( booking.end )}</Text>
            </View>
            <View style={this.pdfStyles.tableCol}>
                <Text style={this.pdfStyles.tableCell}>{ !!booking.notes ? booking.notes : ""}</Text>
            </View>
            <View style={this.pdfStyles.tableCol}>
                <Text style={this.pdfStyles.tableCell}>{ booking.userName}</Text>
            </View>
            <View style={this.pdfStyles.tableCol}>
                <Text style={this.pdfStyles.tableCell}> </Text>
            </View>
        </View>;

    renderPDFComplete= (event) => {
        this.setState({
            showLoading: false,
            pdfRendered: true,
        })

    };

    renderPDF = (bookings) => {
        // Anzahl an Einträgen die auf eine Seite passt
        let countPerPage = 32;

        let exportPages = [];
        let bookingsPerPage = [];

        // Anzahl an Einträgen die auf eine Seite passt mit Header ist um x kleiner x = counter Startwert
        let counter = 10;
        for (let bookingId of bookings) {
            bookingsPerPage.push(bookingId);
            counter++;
            if (counter === countPerPage) {
                counter = 0;
                exportPages.push(bookingsPerPage);
                bookingsPerPage = [];
            }
        }

        if (bookingsPerPage.length !==0) {
            exportPages.push(bookingsPerPage);
        }

        if (exportPages.length === 0 ) {
            return <Document onRender={this.renderPDFComplete}>
                <Page  style={this.pdfStyles.page}>
                    <View>
                        {this.renderHeaders()}
                        <View style={this.pdfStyles.table}>
                            {this.renderTableHeader()}
                            <Text>{strings.noEntries}</Text>
                        </View>
                    </View>
                </Page>
            </Document>;
        }

        return  <Document onRender={this.renderPDFComplete}>
                {
                    exportPages.map( (bookings, counter) => {
                        return (
                            <Page style={this.pdfStyles.page}>
                                <View>
                                    {counter === 0 && this.renderHeaders()}
                                </View>
                                <View style={this.pdfStyles.table}>
                                    {this.renderTableHeader()}
                                    {
                                        bookings.map((booking, i) => {
                                            return (
                                                <View>
                                                    {this.renderTableRow(booking)}
                                                </View>
                                            );
                                        })
                                    }
                                </View>
                            </Page>
                        );
                    })
                }
        </Document>
    };

    renderPDFWindow = () => (
        <PDFViewer className={this.state.pdfRendered ? "pdf-window" : "pdf-window hidden"}>
            {this.renderPDF(this.state.bookings)}
        </PDFViewer>
    );

    render() {
        let facility = FacilityCache.getFacilityById(this.props.match.params.fid);

        if(!this.state.facility) {
            return (
                <p className="ml-2">{strings.loadFacility}</p>
            );
        }

        if (facility.fid !== this.state.facility.fid) {
            this.state.facility = facility;
            this.state.bookings = [];
            this.state.showLoading = false;
            this.state.pdfDataLoaded = false;
            this.state.pdfRendered = false;
        }

        let { start, end, facilities } = this.state;

        return (
            <div>
                <div className="booking-plan-header">
                    <div className="stable-facility">
                        <b>{!this.state.stable ? "" : this.state.stable.name}</b>
                        <span className="dot"/>
                        <b>{!this.state.facility ? "" : this.state.facility.name}</b>
                        {facilities.length > 1 ?
                            <ContextMenu overrideClassName="top-bar-contextmenu" selectType="facility-select" direction="down" openingElement={
                                <a onClick={this.openCloseModal}>{strings.changeFacility}
                                    <img src="/img/icons/primary/dropdowntriangle.png" alt="Dropdown Icon" className="dropdown-triangle-stable"/>
                                </a>}>
                            {
                                facilities.map((facility, i) => {
                                        return (
                                            <Link key={facility.fid} to={`/stables/${!this.state.stable ? "" : this.state.stable.sid}/facility/${facility.fid}/export`} className={this.state.facility.fid === facility.fid ? "disabled-option" : ""}>
                                                <div className="option language-option" key={"changeStable" + facility.fid}>
                                                    <div className="stable-preview-div">
                                                        <img src={facility.header} onError={this.onImageLoadFail} alt={strings.facilityImageAlt}/>
                                                    </div>
                                                    {facility.name}
                                                </div>
                                            </Link>
                                        )
                                    }
                                )
                            }
                        </ContextMenu> : ""
                        }
                    </div>
                    <div className="booking-selector">
                        <button className="secondary-button facility-go-back-button" onClick={() => this.props.history.push(ROUTES.FACILITY.replace(":sid", this.props.match.params.sid))}>
                            <img src="/img/icons/primary/backIcon.png" alt="Back Icon"/>
                            {strings.goBack}
                        </button>
                    </div>
                </div>

                <div className="card ml-1">
                    <div className="card-header">
                        <h3>{strings.exportBookingPlan}</h3>
                        <small>{strings.exportDescription}</small>
                    </div>
                    <div id="edit-profile" className="card-body">
                        <h3>{!this.state.facility ? "" : this.state.facility.name}</h3>
                        <div className="form-row">
                            <label htmlFor="start">{strings.from}</label>
                            <input
                                name="start"
                                onChange={this.onChange}
                                type="date"
                                value={start}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <label htmlFor="end">{strings.to}</label>
                            <input
                                name="end"
                                onChange={this.onChange}
                                type="date"
                                value={end}
                                required
                            />
                        </div>

                        <button  onClick={(e)=> this.getPDFData()}>{strings.export}</button>
                    </div>
                </div>

                {
                    this.state.showLoading &&
                    <div className="card-column align-items-center">
                        { Loading() }
                        <p>{strings.isExporting}</p>
                        <small>{strings.dontLeave}</small>
                    </div>

                }

                {this.state.pdfDataLoaded && this.renderPDFWindow()}

            </div>
        )
    }
}


const ExportBookingPlanPage = withRouter(WithAuthentication(WithFirebase(ExportBookingPlan)));
export default ExportBookingPlanPage;