export class AGB{

    id;
    text;
    url;
    createdAt;

    constructor ({id = "", text = "", url ="", createdAt = undefined} = {}) {
        this.id = id;
        this.text = text;
        this.url = url;
        this.createdAt = createdAt;
    }

}

export function  agbFromFirestoreDocument(doc) {
    if (!!doc && !!doc.data() && !!doc.data().url && !!doc.data().createdAt) {

        let data = doc.data();

        return new AGB ({
           id: doc.id,
           url: data.url,
           createdAt: new Date(data.createdAt)
        });
    }
    return undefined;
}