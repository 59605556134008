
function configureCommentCache() {
    const cache = {
        comment: {
        },
        timeout: {
        }
    };

    const postComments= {};
    const timoutSpan = 300000;

    cache.getCommentByCid = function(cid){
        if (cache.comment.hasOwnProperty(cid) && cache.timeout.hasOwnProperty(cid)){
            return cache.comment[cid];
        }
        return undefined;
    };

    cache.getCommentByPidIfValid = function(cid){
        if (cache.comment.hasOwnProperty(cid) && cache.timeout.hasOwnProperty(cid) && (Date.now() < cache.timeout[cid]) ){
            return cache.comment[cid];
        }
        return undefined;
    };

    cache.updateCacheEntry = function(comment){
       cache.comment[comment.cid] = comment;
       cache.timeout[comment.cid] = Date.now() + timoutSpan;
    };

    cache.updateCacheEntryWithoutTimeout = function(comment){
        cache.comment[comment.cid] = comment;
    };

    cache.overwritePostCommentIds = function(pid, ids) {
        if (!postComments.hasOwnProperty(pid) ){
            postComments[pid]= [];
        }
        postComments[pid] = ids;
    };

    cache.getPostCommentIds = function(pid){
        if ( postComments.hasOwnProperty(pid)){
            return postComments[pid];
        }
        return undefined;
    };

    cache.appendPostCommentIds = function(pid,ids){
        if (!postComments.hasOwnProperty(pid) ){
            postComments[pid]= [];
        }
        postComments[pid] = postComments[pid].concat(ids);
    };

    cache.prependPostCommentIds = function(pid,ids){
        if (!postComments.hasOwnProperty(pid) ){
            postComments[pid]= [];
        }
        postComments[pid] = ids.concat( postComments[pid]);
    };

    return cache
}

const CommentCache = configureCommentCache();

export default CommentCache;