import React, { Component } from "react";
import {WithFirebase} from "../../firebase/firebase";
import {Comment} from "../../models/comment";
import {WithAuthentication} from "../../firebase/auth_provider";
import imageCompression from "browser-image-compression";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        placeholder: "Was möchtest Du dazu sagen?",
        save: "S",
        addPhotoIcon: "Icon um Bilder hinzuzufügen"
    },
    en: {
        placeholder: "What do you have to say?",
        save: "S",
        addPhotoIcon: "Icon to add pictures"
    }
});

class CreateCommentField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            textContent: "",
            commentImage: undefined
        }
    }

    onCreate = async () => {
        if(!!this.state.textContent || this.state.commentImage) {
            let imageUrl;

            if(!!this.state.commentImage) {
                imageUrl = await this.props.firebase.storageController.uploadImageForPostComment(this.state.commentImage, this.props.post);
            }

            let comment = new Comment({
                pid: this.props.post.pid,
                userName: this.props.auth.user.name,
                textContent: this.state.textContent,
                image: imageUrl
            });

            this.setState({
                commentImage: undefined,
                textContent: ""
            });

            comment = await this.props.firebase.commentController.createComment(comment);

            if(!!this.props.onCommentCreated) {
                this.props.onCommentCreated(comment);
            }
        }
    };

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    compressFile = async (event, targetImageTag) => {
        let file = event.target.files[0];

        if (!!file) {
            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1200,
                useWebWorker: true,
                onProgress: () => null
            };

            let compressed = await imageCompression(file, options);

            this.setState({
                [targetImageTag]: compressed
            });

            this.refs[targetImageTag].src = URL.createObjectURL(compressed);
        }
    };

    openFilePicker = (target) => {
        this.refs[target].click();
    };

    removeImage = () => {
        this.setState({
            commentImage: undefined
        });
    };

    render() {
        let { textContent } = this.state;

        return(
            <div className="create-comment">
                <div className="row">
                    <input accept="image/*" type="file" id="commentImage" ref="commentImageFile" style={{display: "none"}} onChange={(event) => {this.compressFile(event, "commentImage")}} onClick={(event)=> {event.target.value = null}}/>
                    <input name="textContent" value={textContent} placeholder={strings.placeholder} type="text" className="create-comment-field" onChange={this.onChange}/>
                    <div className="comment-toolbar-entry" onClick={() => this.openFilePicker("commentImageFile")}>
                        <div className="comment-toolbar-entry-icon"><img src="/img/icons/dark/photo.png" alt={strings.addPhotoIcon}/></div>
                    </div>
                    <div className="comment-toolbar-entry" onClick={this.onCreate}>
                        <div className="comment-toolbar-entry-icon"><img src="/img/icons/primary/send.png" alt={strings.addPhotoIcon}/></div>
                    </div>
                </div>

                { !!this.state.commentImage &&
                    <div className="img-delete-wrapper thumbs-wrapper" key={this.state.commentImage}>
                        <img ref="commentImage" className="thumb" src={this.state.commentImage}/>
                        <div className="img-delete" onClick={this.removeImage}>×</div>
                    </div>
                }
            </div>
        )
    }
}

export default WithAuthentication(WithFirebase(CreateCommentField));