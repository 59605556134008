import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import {createFacilityFromFirestoreDocument} from "../models/facility";
import {createBookingFromFirestoreDocument} from "../models/booking";
import axios from "axios/index";
import * as config from "../config";
import FacilityCache from "../caches/facility_cache";
import BookingCache from "../caches/booking_cache";
import * as elasticSearchController from "../elastic/elastic_controller";

const apiUrl = config.apiURL + '/facilities';

class FirebaseFacilityController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }

    /**
     * @param {string} sid
     * @param {boolean} force
     */
    loadFacilitiesForStable = async (sid, force = false) => {
        let updateNeeded = false;

        let facilityIds = FacilityCache.getFacilityIdsForStable(sid);
        if (!!facilityIds && (facilityIds.length >0) ) {
            for (let i=0; i<facilityIds.length; i++) {
                if (!FacilityCache.getFacilityByIdIfValid(facilityIds[i])) {
                    updateNeeded = true;
                }
            }
        } else {
            updateNeeded = true;
        }

        if (force || updateNeeded) {
            let facilitySnapshot = await this.app.firestore().collection('facilities').where('stableId', '==', sid).get();
            let facilities = [];

            facilitySnapshot.docs.forEach(doc => {
                let facility = createFacilityFromFirestoreDocument(doc);

                if(!!facility) {
                    facilities.push(facility.fid);
                    FacilityCache.updateCacheEntry(facility);
                }
            });
            FacilityCache.overwriteFacilityIdsForStable(sid,facilities);
            return facilities;
        } else {
            return FacilityCache.getFacilityIdsForStable(sid);
        }
    };

    /**
     * @param {Facility} facility
     */
    createFacilityForStable = async (facility) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = facility.toJSON();

        body['idToken'] = idToken;

        let response = await axios.post(apiUrl + "/create", body);

        facility.fid = response.data;

        FacilityCache.updateCacheEntry(facility);
        FacilityCache.appendFacilityIdsForStable(facility.sid,[facility.fid]);

        return response.data;
    };

    /**
     * @param {Facility} facility
     */
    updateFacility = async (facility) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        FacilityCache.updateCacheEntry(facility);

        let body = facility.toJSON();
        body['idToken'] = idToken;

        await axios.post(apiUrl + "/update", body);
    };

    /**
     * @param {Facility} facility
     */
    deleteFacility = async (facility) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = facility.toJSON();

        body['idToken'] = idToken;

        await axios.post(apiUrl + "/delete", body);

       FacilityCache.removeCacheEntry(facility.fid);
    };

    /**
     * @param {Facility} facility
     * @param {Booking} booking
     */
    deleteBookingFromFacility = async (booking, facility) => {
        BookingCache.removeCacheEntry(booking.bid);
        BookingCache.removeBookingForDayFromFacility(booking.fid,booking.day.getTime(),booking.bid);

        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            idToken: idToken,
            bid: booking.bid,
            facilityName: facility.name
        };

        await axios.post(apiUrl + "/bookings/delete", body);
    };

    /**
     * @param {string} sid
     * @param {string} fid
     * @param {boolean} force
     */

    loadFacilityByID = async (sid, fid, force = false) => {
        if (!force && !!FacilityCache.getFacilityByIdIfValid(fid)) {
            return fid;
        }
        let facilityDoc = await this.app.firestore().collection('facilities').doc(fid).get();
        let facilityId;
        if (!!facilityDoc) {
            let facility = createFacilityFromFirestoreDocument(facilityDoc);

            if (!!facility) {
                facilityId = facility.fid;
                FacilityCache.updateCacheEntry(facility);
            }
        }
        return facilityId;
    };

    /**
     * @param {string} sid
     * @param {string} fid
     * @param {Date} day
     * @param {boolean} force
     */
    loadBookingsForFacilityAndDay = async (sid, fid, day, force = false) => {
        let updateNeeded = false;

        let bookingIds = BookingCache.getBookingIdsByFacilityAndDay(fid, day.getTime());
        if (!!bookingIds && (bookingIds.length > 0)) {
            for (let i = 0; i < bookingIds.length; i++) {
                if (!BookingCache.getBookingByIdIfValid(bookingIds[i])) {
                    updateNeeded = true;
                }
            }
        } else {
            updateNeeded = true;
        }

        if (force || updateNeeded) {
            let bookingIds = await elasticSearchController.searchBookingsForFacilityAndDay(fid,day);
            let promises = [];
            for (let i =0; i< bookingIds.length; i++) {
                if (!BookingCache.getBookingByIdIfValid(bookingIds[i])) {
                    promises.push(this.app.firestore().collection('bookings').doc(bookingIds[i]).get())
                }
            }
            let docs = await Promise.all(promises);
            docs.forEach(doc => {
                let booking = createBookingFromFirestoreDocument(doc);
                if (!!booking){
                    BookingCache.updateCacheEntry(booking);
                }
            });
            BookingCache.overwriteBookingIdsForFacilityAndDay(fid,day.getTime(),bookingIds);
            return bookingIds;
        } else {
            return BookingCache.getBookingIdsByFacilityAndDay(fid,day);
        }
    };

    /**
     * @param {Booking} booking
     * @param {number} oldDate
     */
    updateBooking = async (booking, oldDate) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = booking.toJSON();

        body['idToken'] = idToken;

        try {
            let response = await axios.post(apiUrl + "/bookings/update", body);

            if (response.status === 200) {
                booking.lanes = response.data.lanes;
                BookingCache.updateCacheEntryWithoutTimeout(booking);
                if (oldDate !== booking.day.getTime()) {
                    BookingCache.removeBookingForDayFromFacility(booking.fid, booking.day.getTime(), booking.bid);
                    BookingCache.appendBookingIdsForFacilityAndDay(booking.fid, booking.day.getTime(), [booking.bid]);
                }
                return true
            }
            else {
                return null;
            }
        } catch(ex){
            return null
        }
    };

    /**
     * @param {string} sid
     * @param {string} fid
     * @param {Booking} booking
     * @param {boolean} createTraining
     * @param {boolean} isAdminOrEmployee
     */
    createBookingForFacility = async (sid, fid, booking, createTraining, isAdminOrEmployee) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = booking.toJSON();

        if(!!createTraining) {
            body['createTraining'] = createTraining.toString();
        }

        if(!!isAdminOrEmployee) {
            body['saysIsAdmin'] = isAdminOrEmployee.toString();
        }

        body['idToken'] = idToken;

        try {
            let response = await axios.post(apiUrl + "/bookings/create", body)
            if (response.status === 200) {
                return response.data;
            } else {
                return null;
            }
        } catch(ex){
            return null
        }
    };
}

export default FirebaseFacilityController;