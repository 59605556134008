import * as React from "react";
import {Footer, LogoTop} from "./navigation/Footer";
import PropTypes from 'prop-types';
class UnauthorizedLayout extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return <div className={"fullPage"}>
        {this.props.withTopBar ? <LogoTop text={this.props.title} abortSignUp={this.props.abortSignUp}/> : <div/>}
        <main>{this.props.children}</main>
        <Footer/>
        </div>
    }
}

UnauthorizedLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default UnauthorizedLayout;