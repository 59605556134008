import React from "react";
import ContextMenu from "../additional/context_menu"
import {WithFirebase} from "../firebase/firebase";
import PostCache from "../caches/post_cache";
import LocalizationsWithLanguage from "../localization_wrapper";
import {getStableHeaderUrl} from "../additional/helpers";

let strings = new LocalizationsWithLanguage({
    de:{
        minutesAgo:"vor $time Minuten",
        hoursAgo:"vor $time Stunden",
        edit:"Bearbeiten",
        delete:"Löschen",
    },
    en: {
        minutesAgo:"$time minutes ago",
        hoursAgo:"$time hours ago",
        edit:"Edit",
        delete:"Delete",
    }
});

const PostTile = (props) =>
    <div className="post-tile">
        <img onClick={props.showUser} src={props.post.asStable ? getStableHeaderUrl(props.post.stableId) : props.post.userAvatar} className="user-avatar" onError={ (e) => {e.target.onError=null;e.target.src='/img/nopicture.png';}}/>
        <div className="post-tile-information">
            <div onClick={props.showUser} className="post-tile-name"> {props.post.asStable ? props.post.stableName : props.post.userName}</div>
            <PostTileDate date={props.post.createdAt}/>
        </div>
        {
            ( props.canEdit || props.canDelete)  &&
            <ContextMenu>
                {
                    props.canEdit && <div className={"option"} onClick={() => props.onEdit()}> {strings.edit}</div>
                }
                {
                    props.canDelete && <div className={"option"} onClick={() => {
                        props.post.deleted = true;
                        PostCache.updateCacheEntry(props.post);
                        props.firebase.postController.deletePost(props.post);
                        if (!!props.onChange) {
                            props.onChange();
                        }
                    } }> {strings.delete}</div>
                }
            </ContextMenu>
        }
    </div>;

const PostTileDate = (props) => {
    let difference = Date.now() - props.date.getTime();

    if (difference < 3600000) {
        return (
            <div className="post-tile-date">{ strings.minutesAgo.replace("$time", Math.round(difference / 60000))}</div>
        );
    } else if (difference < 86400000) {
        return (
            <div className="post-tile-date">{ strings.hoursAgo.replace("$time", Math.round(difference / 3600000))}</div>
        )
    } else {
        let timeString =  props.date.toLocaleTimeString();
        let index = timeString.lastIndexOf(":");
        if (index >= 0) {
            timeString = timeString.substr(0,index);
        }
        return (
            <div className="post-tile-date">{props.date.toLocaleDateString()+ ", " + timeString}</div>
        )
    }
};

export default WithFirebase(PostTile);

