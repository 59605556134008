import React, {Component} from 'react';
import {WithFirebase} from "../firebase/firebase";
import GooglePlacesAutocomplete, {getLatLng, geocodeByPlaceId} from "react-google-places-autocomplete";
import {WithAuthentication} from "../firebase/auth_provider";
import Privacy from "../models/privacy";
import {Geopoint} from "../models/geopoint";
import LocalizationsWithLanguage from "../localization_wrapper";
import * as ROUTES from "../../routes";
import {Link, withRouter} from "react-router-dom";
import LoadingIndicator from "../additional/loading_indicator";

let strings = new LocalizationsWithLanguage({
    de:{
        welcome:"Wir sind Jutta. Wer bist du?",
        welcomeDescription:"Wir möchten Dich besser kennenlernen. Mach es Deinen Freunden und Stallkollegen einfacher, Dich auf Jutta! zu finden!",
        firstNamePlaceholder:"Vorname",
        lastNamePlaceholder:"Nachname",
        addressPlaceholder: "Adresse",
        birthday:"Geburtstag",
        invalidBirthday:"Das eingetragene Geburtsdatum ist nicht gültig, du musst mindestens 16 Jahre alt sein um Jutta! zu nutzen",
        invalidLocation:"Die eingetragene Adresse ist nicht gültig, bitte gib mindestens eine Stadt an.",
        continue:"Fortfahren",
        agbDescription:"Wenn du fortfährst, stimmst Du unseren Allgemeinen Geschäftbedingungen zu.",
        changeAccount:"Account wechseln",
        givenDataInvalidError:"Die angegebenen Informationen sind ungültig.",
        changePrivacyBirthday: "Stelle Deinen Geburtstag auf öffentlich oder geheim",
        changePrivacyLocation: "Stelle Deinen Standort auf öffentlich oder geheim",
        tooltipPublic: "Privatsphäre: Öffentlich\n Deine Follower können diese Information sehen",
        tooltipPrivate: "Privatsphäre: Privat\n Nur du kannst diese Information sehen",
        mandatoryFields: "* Pflichtfelder",
        landingPage: "Startseite",
        signUp: "Registrierung"
    },
    en: {
        welcome:"We are Jutta!. Who are you?",
        welcomeDescription:"We want to get to know you better. Make it easier for your friends to find you on Jutta!",
        firstNamePlaceholder:"first name",
        lastNamePlaceholder:"last name",
        addressPlaceholder: "address",
        birthday:"Birthday",
        invalidBirthday:"The provided birthday is not valid, you must be at least 16 years old to use Jutta!.",
        invalidLocation:"The provided address is not valid, please provide at least a city.",
        continue:"continue",
        agbDescription:"If you continue you agree to our terms and services",
        changeAccount:"Change account",
        givenDataInvalidError:"The given data is invalid.",
        changePrivacyBirthday: "Set your birthday public or secret.",
        changePrivacyLocation: "Set your location public or secret.",
        tooltipPublic: "Privacy: Public\n Your follower can see this information",
        tooltipPrivate: "Privacy: Private\n Only you can see this information",
        mandatoryFields: "* Mandatory fields",
        landingPage: "Home",
        signUp: "Sign Up"
    }
});


class AdditionalInformationPage extends React.PureComponent{
    render() {
        return (
            <AdditionalInformation {...this.props}/>
        )
    }
}

const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    birthday: '',
    loading: false,
    location: undefined,
    accepttermsandconditions: false,
    birthdayPrivacy: Privacy.PUBLIC,
    locationPrivacy: Privacy.PUBLIC,
    error: null,
    birthdayError: "",
    locationError: ""
};

class AdditionalInformationFrom extends Component {

    constructor(props) {
        super(props);

        this.state =  {...INITIAL_STATE } ;
    }

    onSubmit = async (event) =>  {
        if (!this.state.loading &&
            !!this.state.firstname &&
            !!this.state.lastname   &&
            !!this.state.birthday  &&
            !!this.state.birthdayPrivacy  &&
            !!this.state.location   &&
            !!this.state.locationPrivacy   &&
            !!this.state.location.geopoint   &&
            !!this.state.location.city   &&
            !!this.state.location.country   &&
            !!this.state.location.countryCode   &&
            !!this.state.accepttermsandconditions) {

            let minAge = new Date();
            minAge.setFullYear(minAge.getFullYear() -16);
            this.setState({error: null});

            if (isNaN(new Date(this.state.birthday).getTime()) || !new Date(this.state.birthday) || new Date(this.state.birthday).getTime() >  minAge.getTime()) {
                this.state.birthdayError = strings.invalidBirthday;
                this.setState(this.state);
                return;
            }

            try {
                this.setState({loading: true});
                let authUser = await this.props.firebase.userController.createUserInformation(
                    this.props.auth.firebaseUser,
                    this.state.firstname,
                    this.state.lastname,
                    new Date(this.state.birthday),
                    this.state.birthdayPrivacy,
                    this.state.location.geopoint,
                    this.state.location.country,
                    this.state.location.countryCode,
                    this.state.location.city,
                    this.state.location.state,
                    this.state.locationPrivacy
                );


                if (!!authUser) {
                    this.props.auth.setAuthUser(authUser);
                    this.setState({loading: false});
                    this.props.history.push(ROUTES.HOME);
                }
            } catch (ex) {
                this.setState({
                    loading: false,
                    error: ex.givenDataInvalidError
                });
            }

        }

        this.setState({
            loading: false,
            error: strings.givenDataInvalidError
        });

    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onCheckBoxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    onBirthdayPrivacyChange = event => {
        if (this.state.birthdayPrivacy === Privacy.PUBLIC) {
            this.setState({birthdayPrivacy: Privacy.SECRET});
        } else {
            this.setState({birthdayPrivacy: Privacy.PUBLIC});
        }
    };

    onLocationPrivacyChange = event => {
        if (this.state.locationPrivacy === Privacy.PUBLIC) {
            this.setState({locationPrivacy: Privacy.SECRET});
        } else {
            this.setState({locationPrivacy: Privacy.PUBLIC});
        }
    };

    abortSignUp = async () => {
        this.props.auth.firebaseUser.delete();
        this.props.firebase.logout();
    };

    render() {
        const isInvalid =
            !this.state.firstname ||
            !this.state.lastname ||
            !this.state.birthday ||
            !this.state.location ||
            !this.state.accepttermsandconditions ;

        return (
            <div className="sign-in-container-wrapper">
                <div className={"sign-in-container"}>
                    <div className="breadcrumbs">
                        <Link to={ROUTES.SIGN_IN} onClick={this.abortSignUp}>{strings.landingPage}</Link>
                        <p className="crumb">/</p>
                        <p>{strings.signUp}</p>
                    </div>
                    <h3>{strings.welcome}</h3>
                    <div className={"input-description"}>{strings.welcomeDescription}</div>

                    {this.state.loading ? <div className="mt-2"><LoadingIndicator/></div> : <form onSubmit={this.onSubmit} className="width-100">
                        <label htmlFor="firstname">{strings.firstNamePlaceholder + "*"}</label>
                        <input
                            name="firstname"
                            onChange={this.onChange}
                            type="text"
                            placeholder={strings.firstNamePlaceholder}
                            required
                        />
                        <label htmlFor="lastname">{strings.lastNamePlaceholder + "*"}</label>
                        <input
                            name="lastname"
                            onChange={this.onChange}
                            type="text"
                            placeholder={strings.lastNamePlaceholder}
                            required
                        />

                        <label htmlFor="birthday">{strings.birthday + "*"}</label>
                        <div className="row">
                            <div className={'google-places-autocomplete'}>
                                <input
                                    name="birthday"
                                    className="google-places-autocomplete__input"
                                    placeholder={strings.birthday}
                                    onChange={this.onChange}
                                    type="date"
                                    min='1900-01-01'
                                    max={ (() => {
                                        let current = new Date();
                                        current.setFullYear(current.getFullYear() - 16);
                                        return current.toISOString().split("T")[0]
                                    })() }
                                />
                            </div>

                            <div className={this.state.birthdayPrivacy !== Privacy.SECRET ? "privacy-toggle-wrapper secret" : "privacy-toggle-wrapper public"} onClick={this.onBirthdayPrivacyChange}>
                                <div className="privacy-toggle">
                                    {this.state.birthdayPrivacy !== Privacy.SECRET ?  <img src="/img/icons/dark/secret.png" alt="Secret Setting Icon"/> : <img src="/img/icons/dark/public.png" alt="Public Setting Icon"/>}
                                </div>
                                <div className="tooltip">
                                    <h4>
                                        {this.state.birthdayPrivacy !== Privacy.SECRET ?  strings.tooltipPrivate : strings.tooltipPublic}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {
                            !!this.state.birthdayError &&
                            <label htmlFor="birthdayError">
                                <small className="error-message mb-1">
                                    {this.state.birthdayError}
                                </small>
                            </label>
                        }
                        <label htmlFor="location">{strings.addressPlaceholder + "*"}</label>
                        <div className="row">
                            <GooglePlacesAutocomplete
                                onSelect={async (result) =>  {
                                    let geocodeResult = await geocodeByPlaceId(result.place_id);
                                    if (!!geocodeResult && !!geocodeResult[0] && !!geocodeResult[0].address_components) {
                                        let addressComponents = geocodeResult[0].address_components;

                                        let city;
                                        let country;
                                        let countryCode;
                                        let state;

                                        addressComponents.forEach( (comp) =>  {
                                            if (comp.types.includes("locality")) {
                                                city = comp.long_name;
                                            } else if ( comp.types.includes("administrative_area_level_1")) {
                                                state = comp.short_name;
                                            } else if ( comp.types.includes("country")){
                                                country = comp.long_name;
                                                countryCode = comp.short_name;
                                            }

                                        });

                                        if (!!city && !!country) {
                                            let latLong = await getLatLng(geocodeResult[0]);
                                            this.setState(
                                                {
                                                    location: {
                                                        city : city,
                                                        country : country,
                                                        countryCode : countryCode,
                                                        state : state,
                                                        geopoint: new Geopoint(latLong.lat,latLong.lng),
                                                    },
                                                    locationError:""
                                                }
                                            );
                                        } else {
                                            this.state.locationError = strings.invalidLocation;
                                            this.setState({location: undefined});
                                        }

                                    } else {
                                        this.setState({location: undefined});
                                    }
                                }}
                            />
                            <div className={this.state.locationPrivacy !== Privacy.SECRET ? "privacy-toggle-wrapper secret" : "privacy-toggle-wrapper public"} onClick={this.onLocationPrivacyChange}>
                                <div className="privacy-toggle">
                                    {this.state.locationPrivacy !== Privacy.SECRET ? <img src="/img/icons/dark/secret.png" alt="Secret Setting Icon"/> : <img src="/img/icons/dark/public.png" alt="Public Setting Icon"/>}
                                </div>
                                <div className="tooltip">
                                    <h4>
                                        {this.state.locationPrivacy !== Privacy.SECRET ?  strings.tooltipPrivate : strings.tooltipPublic}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {
                            !!this.state.locationError &&
                            <label htmlFor="locationError">
                                <small className="error-message mb-1">
                                    {this.state.locationError}
                                </small>
                            </label>
                        }


                        <div className="form-row mt-1 mb-1">
                            <input
                                name="accepttermsandconditions"
                                onChange={this.onCheckBoxChange}
                                type="checkbox"
                                className="terms-checkbox"
                            />
                            <Link to={ROUTES.TERMSOFUSE} target="_blank" rel="noopener noreferrer"> {strings.agbDescription}</Link>
                        </div>

                        {this.state.error &&
                        <div className="error-message mt-1">
                            {this.state.error.message}
                        </div>
                        }
                        <small>{strings.mandatoryFields}</small>
                        <button onClick={this.onSubmit} disabled={isInvalid}>{strings.continue}</button>

                    </form> }
                </div>
            </div>
        );
    }
}

const AdditionalInformation = withRouter(WithFirebase(WithAuthentication(AdditionalInformationFrom)));

export default AdditionalInformationPage;


