import React from 'react';
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        areYouSure: "Bist du sicher?",
        description: "Du kannst diese Operation nicht rückgängig machen.",
        cancel: "Abbrechen",
        confirm: "Bestätigen",
    },
    en: {
        areYouSure: "Are you sure?",
        description: "You cant undo this operation.",
        cancel: "Cancel",
        confirm: "Confirm",
    }
});

class AreYouSureModal extends React.Component {

    stopEvent = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    render() {
        return (
            <div className="modal" onClick={this.props.onClose}>
                <div className="modal-content are-you-sure" onClick={this.stopEvent}>
                    <h2 className={"modal-header"}> {strings.areYouSure}  </h2>
                    <div> {this.props.description || strings.description} </div>
                    <div className="row modal-actions-wrapper">
                        <a onClick={this.props.onClose} className="btn-cancel">{strings.cancel}</a>
                        <button onClick={this.props.onConfirm}>{strings.confirm}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AreYouSureModal;