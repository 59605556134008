import React, {Component} from 'react';
import Loading from "../../../loading";
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import {withRouter} from "react-router-dom";
import UserCard from "../../../additional/user_card";
import StableHeader from "../../header";
import LocalizationsWithLanguage from "../../../localization_wrapper";
import LoadingIndicator from "../../../additional/loading_indicator";

let strings = new LocalizationsWithLanguage({
    de:{
        requests:"Anfragen",
        noOpenRequests: "Keine offenen Anfragen",
        accept: "Annehmen",
        refuse: "Ablehnen"
    },
    en: {
        requests:"Requests",
        noOpenRequests: "No open requests",
        accept: "Annehmen",
        refuse: "Ablehnen"
    }
});


class StableRequestsIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: undefined,
            loading: true,
            stable: undefined,
        }
    }

    async loadDisplayedStable() {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
                this.setState({ stable : stable});
            });
            if (this.props.auth.stables.admin.hasOwnProperty(this.props.match.params.sid)) {
                this.props.firebase.stableController.loadOpenRequestsForStable(this.props.match.params.sid).then(users => {
                    this.setState({ users : users, loading: false });
                });
            } else {
                this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
            }
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    refuseRequest(user) {
        this.props.firebase.stableController.refuseJoinRequest(this.props.match.params.sid, user.uid);
        var users = this.state.users.filter(function(item, idx) {
            return item.uid !== user.uid;
        });
        this.setState({ users : users});
    }

    acceptRequest(user) {
        this.props.firebase.stableController.inviteUserToStable(this.props.match.params.sid,this.props.auth.user.name, user.uid,user.name);
        var users = this.state.users.filter(function(item, idx) {
            return item.uid !== user.uid;
        });
        this.setState({ users : users});
    }

    getOptions(user) {
        return (
            <div className="list-tile-options">
                <button className="secondary-button" onClick={() => this.refuseRequest(user)}>
                    {strings.refuse}
                </button>
                <button onClick={() => this.acceptRequest(user)}>
                   {strings.accept}
                </button>
            </div>
        )
    }

    getUserContent() {
        if (this.state.users.length === 0) {
            return (
                <div className="mt-1">
                    <small>{strings.noOpenRequests}</small>
                </div>
            )
        }

        return (
            <div className="mt-1">
                {
                    this.state.users.map((user, i) => {
                        return (
                            <UserCard name={user.name} image={user.avatar} link={"/user/" + user.uid} options={() => this.getOptions(user)}/>
                        )
                    })
                }
            </div>
        )
    }

    componentDidMount() {
        this.loadDisplayedStable();
    }


    render() {

        return (
            <div>
                <StableHeader name={this.state.stable == null ? "" : this.state.stable.name} stables={this.props.auth.stables == null ? true : this.props.auth.stables.active} currentStable={this.state.stable}/>

                {this.state.loading ? <div className="mt-2"><LoadingIndicator/></div> : this.getUserContent()}
            </div>
        );
    }
};

export default withRouter(WithAuthentication(WithFirebase(StableRequestsIndex)));