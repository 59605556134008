import * as ROUTES from "../routes";
import StablePage from "./stables";
import ComingSoon from "./comingsoon";
import FacilityPage from "./facilities";
import LatestPostsPage from "./stables/latestposts";
import InternalNewsPage from "./stables/internalnews";
import InformationPage from "./stables/information";
import CreateStablePage from "./stables/create";
import SettingsPage from "./stables/settings";
import {Switch, withRouter} from "react-router-dom";
import React,{Component} from "react";
import UserInformation from "./user/show";
import {WithAuthentication} from "./firebase/auth_provider";
import BookingOverviewPage from "./facilities/bookings";
import ExportBookingPlanPage from "./facilities/export_booking_plan/index";
import CreateNewBookingPage from "./facilities/bookings/create";
import StableRequests from "./stables/settings/requests";
import CreateFacilityPage from "./facilities/create";
import BoardersPage from "./stables/settings/boarders";
import EditFacilityPage from "./facilities/edit";
import AddBoardersPage from "./stables/settings/boarders/add";
import EditBookingPage from "./facilities/bookings/edit";
import StableAdminsPage from "./stables/settings/admins";
import StablePermissions from "./stables/settings/permissions";
import StableContact from "./stables/settings/contacts";
import AuthEditStable from "./stables/edit";
import EditUserInformation from "./user/edit";
import {RouterRedirectHandler} from "./auth/redirect_handler";
import MasterLayout from "./master_layout";
import FullpageLayout from "./fullpage_layout";
import AppRoute from "./app_route";
import {TermsOfUseWithPage} from "./auth/terms_of_use";
import UnauthorizedLayout from "./unauthorized_layout";
import {DataProtectionWithPage} from "./auth/data_protection";
import LocalizationsWithLanguage from "./localization_wrapper";

let strings = new LocalizationsWithLanguage({
        de:{
                titleDpd: "Datenschutzerklärung",
                titleTerms: "Allgemeine Geschäftsbedingungen",
        },
        en: {
                titleDpd: "Data protection declaration",
                titleTerms: "Terms of use",
        }
});

class Authorized extends Component {
    render() {
        return (
            <Switch>
                {/* If the auth state suddenly changes from unauthorized to authorized (because the user logged in)
                    these three routes will redirect the user to the initial location they wanted
                    (or to "/" if no location is specified)*/}
                <AppRoute exact path={ROUTES.SIGN_IN} component={RouterRedirectHandler} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.EMAIL_SIGN_IN} component={RouterRedirectHandler} layout={MasterLayout}/>

                {/*<AppRoute exact path={ROUTES.COMMUNITY} component={ComingSoon} layout={MasterLayout}/>*/}
                {/*<AppRoute exact path={ROUTES.GROUPS} component={ComingSoon} layout={MasterLayout}/>*/}
                {/*<AppRoute exact path={ROUTES.HORSES} component={ComingSoon} layout={MasterLayout}/>*/}

                <AppRoute exact path={ROUTES.LANDING} component={StablePage} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.STABLE} component={StablePage} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.CREATESTABLE} component={CreateStablePage} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.EDITSTABLE} component={AuthEditStable} layout={MasterLayout}/>

                <AppRoute exact path={ROUTES.FACILITY} component={FacilityPage} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.CREATEFACILITY} component={CreateFacilityPage} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.EDITFACILITY} component={EditFacilityPage} layout={MasterLayout}/>

                <AppRoute key={this.props.location.pathname + "LatestPostsPage"} exact path={ROUTES.LATESTPOSTS} component={LatestPostsPage} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "InternalNewsPage"} exact path={ROUTES.INTERNALNEWS} component={InternalNewsPage} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "InformationPage"} exact path={ROUTES.INFORMATION} component={InformationPage} layout={MasterLayout}/>

                <AppRoute key={this.props.location.pathname + "SettingsPage"} exact path={ROUTES.SETTINGS} component={SettingsPage} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "StableRequests"} exact path={ROUTES.STABLEREQUESTS} component={StableRequests} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "BoardersPage"} exact path={ROUTES.STABLEBOARDER} component={BoardersPage} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "AddBoardersPage"} exact path={ROUTES.ADDSTABLEBOARDERS} component={AddBoardersPage} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "StableAdminsPage"} exact path={ROUTES.STABLEADMINS} component={StableAdminsPage} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "StablePermissions"} exact path={ROUTES.STABLEPERMISSIONS} component={StablePermissions} layout={MasterLayout}/>
                <AppRoute key={this.props.location.pathname + "StableContact"} exact path={ROUTES.STABLECONTACTS} component={StableContact} layout={MasterLayout}/>

                {/*<AppRoute exact path={ROUTES.MARKET} component={ComingSoon} layout={MasterLayout}/>*/}

                <AppRoute exact path={ROUTES.USER} component={UserInformation} layout={MasterLayout}/>
                <AppRoute exact path={ROUTES.EDITUSER} component={EditUserInformation} layout={MasterLayout}/>

                <AppRoute exact path={ROUTES.BOOKINGS} component={BookingOverviewPage} layout={FullpageLayout}/>
                <AppRoute exact path={ROUTES.EXPORTBOOKINGPLAN} component={ExportBookingPlanPage} layout={FullpageLayout}/>
                <AppRoute exact path={ROUTES.ADDBOOKING} component={CreateNewBookingPage} layout={FullpageLayout}/>
                <AppRoute exact path={ROUTES.EDITBOOKING} component={EditBookingPage} layout={FullpageLayout}/>

                <AppRoute withTopBar={true} title={strings.titleTerms} exact path={ROUTES.TERMSOFUSE} component={TermsOfUseWithPage} layout={UnauthorizedLayout}/>
                <AppRoute withTopBar={true} title={strings.titleDpd} exact path={ROUTES.DATAPROTECTION} component={DataProtectionWithPage} layout={UnauthorizedLayout}/>
            </Switch>
        );
    };
}

const AuthorizedSwitch = withRouter(WithAuthentication(Authorized));
export default AuthorizedSwitch;