import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {updateUser as elasticUpdateUser} from "../elastic/elastic_controller"
import {AuthUser, createAuthUserFromFirestoreDocument} from "../models/auth_user";
import {createUserSettingsFromFirestoreDocument} from "../models/user_settings";
import FirebaseAGBController from "./firebase_agb_controller";
import Gender from "../models/gender";
import Privacy from "../models/privacy";
import {toVagueGeolocation} from "../additional/helpers";
import AuthType from "../models/authtype";
import {createUserFromFirestoreDocument} from "../models/user";
import UserCache from "../caches/user_cache";
import * as config from "../config";
import axios from "axios";


class FirebaseUserController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.agbController = new FirebaseAGBController(app);
        this.app = app;
    }

    /**
     * @param {String} uid
     */
    loadUserByUid = async (uid) => {
        let user = UserCache.getUserByUid(uid);

        if (!!user) {
            return user;
        }
        let firestoreDoc = await this.app.firestore().collection('users').doc(uid).get();

        return createUserFromFirestoreDocument(firestoreDoc);
    };

    /**
     * @param {String} uid
     */
    loadUserFollowerCountByUid = async (uid) => {
        return (await this.app.database().ref('watchers/' + uid + '/watchedByCount').once('value')).val() || 0;
    };

    /**
     * @param {firebase.User} auth
     * @param {AuthType} lastAuthType
     */
    loadUserInformation = async (auth,lastAuthType) => {
        let firestoreDoc = await this.app.firestore().collection('users').doc(auth.uid).get();

        let user = createAuthUserFromFirestoreDocument(firestoreDoc);

        if (!!user) {
            UserCache.setUserNameForUid(user.uid,user.name);
            let firestoreDoc = await this.app.firestore().collection('users').doc(auth.uid).collection('private').doc('settings').get();

            user.userSettings = createUserSettingsFromFirestoreDocument(firestoreDoc);

        } else {
            user = new AuthUser({uid: auth.uid, authType: lastAuthType, firstName: "", lastName: "", fullName: "", avatar: "", });
        }

        return user;
    };

    loadUserPostInteractions = async (auth) => {
        let reactionDocs = await this.app.firestore().collection('users').doc(auth.uid).collection('postInteractions').get();
        let reactions = {};

        for( let doc of reactionDocs.docs) {
            if (!!doc.data().likeType) {
                reactions[doc.id]= doc.data().likeType;
            }
        }
        return reactions;
    };

    loadUserCommentInteractions = async (auth) => {
        let reactionDocs = await this.app.firestore().collection('users').doc(auth.uid).collection('commentInteractions').get();
        let reactions = {};

        for( let doc of reactionDocs.docs) {
            if (!!doc.data().likeType) {
                reactions[doc.id]= doc.data().likeType;
            }
        }
        return reactions;
    };

    saveUserPostReaction = async (auth, postId, reaction) => {
        this.app.firestore().collection('users').doc(auth.uid).collection('postInteractions').doc(postId).set({
            'likeType': reaction
        });
    };

    saveUserCommentReaction = async (auth, commentId, reaction) => {
        this.app.firestore().collection('users').doc(auth.uid).collection('commentInteractions').doc(commentId).set({
            'likeType': reaction
        });
    };


    /**
     * @param {firebase.User} auth
     * @param {string} firstName
     * @param {string} lastName
     * @param {Date} birthday
     * @param {string} birthdayPrivacy
     * @param {Geopoint} geolocation
     * @param {string} country
     * @param {string} countryCode
     * @param {string} city
     * @param {string} state
     * @param {string} locationPrivacy
     */
    async createUserInformation (auth, firstName, lastName, birthday, birthdayPrivacy, geolocation, country, countryCode, city, state,locationPrivacy)  {
        let agb = await this.agbController.getLatestAGB();
        if (!!agb) {
            await this.agbController.signAGB(agb.id,auth);
            let fullName = (firstName || "") + " " + (lastName || "");

            let authType = AuthType.FACEBOOK;

            auth.providerData.forEach( (provider)  => {
                switch (provider.providerId) {
                    case "password":
                        authType= AuthType.EMAIL;
                        break;
                    case "facebook.com":
                        authType= AuthType.FACEBOOK;
                        break;
                    case "google.com":
                        authType= AuthType.GOOGLE;
                        break;
                }
            });

            let userData = {
                'authtype' : authType,
                'gender' : Gender.FEMALE,
                'firstName' : firstName,
                'lastName' : lastName,
                'fullName' : fullName,
                'birthday' : birthday.getTime(),
            };

            let authUser = new AuthUser({
                uid : auth.uid,
                firstName : firstName,
                lastName : lastName,
                name : fullName,
            });

            await this.app.firestore().collection('users').doc(auth.uid).set(
               userData
                ,{merge: true}
            );
            UserCache.setUserNameForUid(authUser.uid,authUser.name);

            await this.app.firestore().collection('users').doc(auth.uid).collection('private').doc('settings').set({
                'email': auth.email,
                'language': countryCode,
                }
                ,{merge: true}
            );

            authUser.userSettings.email = auth.email;
            authUser.userSettings.language = countryCode.toLowerCase();

            authUser = this.updateBirthday(authUser,birthday,birthdayPrivacy, new Date(0));
            authUser = this.updateGeolocation(auth,authUser, locationPrivacy, geolocation, country, countryCode, city, state);


            return authUser;
        } else {
            console.log("error");
        }

     }

    /**
     * @param {AuthUser} authUser
     * @param {Date} birthday
     * @param {string} birthdayPrivacy
     * @param {Date} birthdayChangedAt
     */
    updateBirthday(authUser, birthday, birthdayPrivacy, birthdayChangedAt) {
        authUser.userSettings.birthday = birthday;
        authUser.userSettings.birthdayChangedAt = birthdayChangedAt || new Date();
        authUser.birthdayPrivacy = birthdayPrivacy;

        if (birthdayPrivacy === Privacy.PUBLIC) {
            authUser.birthday = birthday;
        } else {
            authUser.birthday = undefined;
        }

        this.app.firestore().collection('users').doc( authUser.uid).collection('private').doc('settings').set(
            {
                'birthday': birthday.getTime(),
                'birthdayChangedAt':  authUser.userSettings.birthdayChangedAt.getTime()
            }, {merge: true}
        );
        let data = {};
        data['birthdayPrivacy'] = authUser.birthdayPrivacy;

        if (authUser.birthdayPrivacy === Privacy.PUBLIC) {
            data['birthday'] = authUser.userSettings.birthday.getTime();
            data['birthdayMonth'] = authUser.userSettings.birthday.getMonth();
            data['birthdayDay'] = authUser.userSettings.birthday.getDay();
        } else {
            data['birthday'] = this.app.firestore.FieldValue.delete();
            data['birthdayMonth'] = this.app.firestore.FieldValue.delete();
            data['birthdayDay'] = this.app.firestore.FieldValue.delete();
            data['birthdayChangedAt'] = this.app.firestore.FieldValue.delete();
        }
        this.app.firestore().collection('users').doc(authUser.uid).set(
            data, {merge: true}
        );

        return authUser;
    }

    /**
     * @param {firebase.User} auth
     * @param {AuthUser} authUser
     * @param {String} locationPrivacy
     * @param {Geopoint} geolocation
     * @param {String} country
     * @param {String} countryCode
     * @param {String} city
     * @param {String} state
     */
    updateGeolocation(auth, authUser, locationPrivacy, geolocation, country, countryCode, city, state) {

        authUser.userSettings.geolocation = toVagueGeolocation(geolocation);
        authUser.userSettings.country = country;
        authUser.userSettings.countryCode = countryCode;
        authUser.locationPrivacy= locationPrivacy;
        authUser.countryCode = countryCode;
        authUser.userSettings.city = city;
        authUser.userSettings.state = state;
        authUser.fullAddress = city + ", " + (!!state ? (state + ", ") : "") + country;
        authUser.userSettings.fullAddress = city + ", " + (!!state? (state + ", ") : "") + country;

        let privateData = {
            'country': authUser.userSettings.country,
            'city': authUser.userSettings.city,
            'state': authUser.userSettings.state,
            'countryCode': authUser.countryCode,
            'location': new this.app.firestore.GeoPoint(geolocation.lat,geolocation.long)
        };

        let publicData = {
            'countryCode': authUser.countryCode,
            'fullAddress': authUser.locationPrivacy === Privacy.PUBLIC? authUser.userSettings.fullAddress: "",
            'locationPrivacy': authUser.locationPrivacy,
        };

        this.app.firestore().collection('users').doc(authUser.uid).set(publicData, {merge: true});
        this.app.firestore().collection('users').doc(authUser.uid).collection('private').doc('settings').set(privateData, {merge:  true});

        elasticUpdateUser(auth, authUser);

        this.setLastChangeTimeForUser(authUser.uid);
        return authUser;
    }

    /**
     * @param {AuthUser} authUser
     * @param {String} locationPrivacy
     * @param {Geopoint} geolocation
     * @param {String} country
     * @param {String} countryCode
     * @param {String} city
     * @param {String} state
     * @param {String} fullAddress
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} job
     * @param {String} employer
     * @param {String} birthdayPrivacy
     * @param {Date} birthday
     */
    async updateUser(authUser, geolocation, country, countryCode, city, state, fullAddress, firstName, lastName, birthday, job, employer, birthdayPrivacy, locationPrivacy) {
        let auth = await this.app.auth().currentUser;

        authUser.locationPrivacy = locationPrivacy;
        authUser.birthdayPrivacy = birthdayPrivacy;

        let birthdayChanged = false;
        let nameChanged = false;

        if(authUser.userSettings.birthday.getTime() !== birthday.getTime()) {
            birthdayChanged = true;
            authUser.userSettings.birthdayChangedAt = (new Date()).getTime();
        }

        if(authUser.firstName !== firstName || authUser.lastName !== lastName) {
            nameChanged = true;
            authUser.nameChangedAt = (new Date()).getTime();
        }

        authUser.userSettings.geolocation = geolocation;
        authUser.userSettings.country = country;
        authUser.userSettings.city = city;
        authUser.userSettings.state = state;
        authUser.countryCode = countryCode;
        authUser.userSettings.fullAddress = fullAddress;

        authUser.userSettings.birthday = birthday;

        authUser.name = (!!firstName ? firstName : "") + " " + (!!lastName ? lastName : "");
        authUser.firstName = firstName;
        authUser.lastName = lastName;

        authUser.job = job;
        authUser.employer = employer;

        if(authUser.birthdayPrivacy === Privacy.PUBLIC) {
            authUser.birthday = birthday;
        }

        if(authUser.locationPrivacy === Privacy.PUBLIC) {
            authUser.fullAddress = fullAddress;
        }

        let privateData = {
            'country': authUser.userSettings.country,
            'city': authUser.userSettings.city,
            'state': authUser.userSettings.state,
            'countryCode': authUser.countryCode,
            'birthday': authUser.userSettings.birthday.getTime(),
            'location': new this.app.firestore.GeoPoint(authUser.userSettings.geolocation.lat, authUser.userSettings.geolocation.long)
        };

        if(birthdayChanged) {
            privateData['birthdayChangedAt'] = authUser.userSettings.birthdayChangedAt;
        }

        let publicData = {
            'birthdayPrivacy': authUser.birthdayPrivacy,
            'locationPrivacy': authUser.locationPrivacy,
            'countryCode': authUser.countryCode,
            'fullName': authUser.name,
            'firstName': firstName,
            'lastName': lastName
        };

        if(!!authUser.job) {
            publicData['job'] = authUser.job;
        }

        if(!!authUser.employer) {
            publicData['employer'] = authUser.employer;
        }

        if(nameChanged) {
            publicData['nameChangedAt'] = authUser.nameChangedAt;
        }

        if(authUser.locationPrivacy === Privacy.PUBLIC) {
            publicData['fullAddress'] = authUser.userSettings.fullAddress;
        } else {
            publicData['fullAddress'] = "";
        }

        if(authUser.birthdayPrivacy === Privacy.PUBLIC) {
            publicData['birthday'] = authUser.userSettings.birthday.getTime();
            publicData['birthdayMonth'] = authUser.userSettings.birthday.getMonth();
            publicData['birthdayDay'] = authUser.userSettings.birthday.getDay();
        } else {
            publicData['birthday'] = this.app.firestore.FieldValue.delete();
            publicData['birthdayMonth'] = this.app.firestore.FieldValue.delete();
            publicData['birthdayDay'] = this.app.firestore.FieldValue.delete();
            publicData['birthdayChangedAt'] = this.app.firestore.FieldValue.delete();
        }

        // Firestore Updates
        this.app.firestore().collection('users').doc(authUser.uid).set(publicData, {merge: true});
        this.app.firestore().collection('users').doc(authUser.uid).collection('private').doc('settings').set(privateData, {merge:  true});

        //Realtime DB Updates
        this.app.database().ref('users/' + authUser.uid + '/userinfo').set({
            'cAt': (new Date()).getTime(),
            'name': authUser.name
        });

        //Elastic Updates
        elasticUpdateUser(auth, authUser);

        //Cache Updates
        UserCache.setUserNameForUid(authUser.uid, authUser.name);

        return authUser;
    }

    async setLastChangeTimeForUser(uid)  {
        await this.app.database().ref('users/' + uid + '/userinfo/cAt').set(new Date().getTime());
    }

    async deleteUserAccount() {
        let apiUrl =  config.apiURL + '/delete/user/validateUserForDeletion';
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body =  {};
        body['idToken'] = idToken;
        body['env'] = config.environment;
        let response = await axios.post(apiUrl ,body );

        return response.status === 200 ;
    }

}

export default FirebaseUserController;