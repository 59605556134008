import Privacy from "./privacy";
import {ShallowUser} from "./shallow_user";
import {getUserAvatarUrl} from "../additional/helpers";

export class User extends ShallowUser{

    name;
    avatar;
    uid;

    birthday;

    fullAddress;
    locationPrivacy;
    birthdayPrivacy;


    nameChangedAt;
    watchedByCount;
    watchingHorsesCount;
    watchingUserCount;


    job;
    employer;
    gender;

    description;

    business;


    /**
     * @param {String} name
     * @param {String} avatar
     * @param {String} uid
     * @param {String} fullAddress
     * @param {Date} birthday
     * @param {Date} nameChangedAt
     * @param {Privacy} birthdayPrivacy
     * @param {Privacy} locationPrivacy
     * @param {number} watchedByCount
     * @param {number} watchingHorsesCount,
     * @param {number} watchingUserCount
     */
    constructor( {uid="",name ="",avatar=""
                     ,birthday= null,birthdayPrivacy = Privacy.SECRET,fullAddress ="",locationPrivacy = Privacy.SECRET
                     ,nameChangedAt = null, watchedByCount = 0, watchingHorsesCount=0,watchingUserCount=0} = {}
                     ){
        super( {uid,name ,avatar} );
        this.birthday = birthday;
        this.birthdayPrivacy = birthdayPrivacy;
        this.locationPrivacy = locationPrivacy;
        this.nameChangedAt = nameChangedAt;
        this.watchedByCount = watchedByCount;
        this.watchingHorsesCount = watchingHorsesCount;
        this.watchingUserCount = watchingUserCount;
    }
}

export function createUserFromFirestoreDocument(firestoreDocument){
    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        let user = new User( {
            uid: firestoreDocument.id,
            firstName: data['firstName'] || "",
            lastName: data['lastName'] || "",
            name: data['fullName'] || data['name'] || data['businessName'] || "",
            avatar: getUserAvatarUrl(firestoreDocument.id)
        });

        if (!!data['countryCode']) {
            user.countryCode = data['countryCode'];
        }
        if (!!data['description']){
            user.description = data['description'];
        }
        if (!!data['watchingCount']){
            user.watchingUserCount = data['watchingCount'];
        }
        if (!!data['watchingHorsesCount']){
            user.watchingHorsesCount = data['watchingHorsesCount'];
        }
        if (!!data['gender']){
            user.gender = data['gender'];
        }

        user.locationPrivacy = data['locationPrivacy'] || Privacy.PUBLIC;

        user.birthdayPrivacy = data['birthdayPrivacy'] || Privacy.PUBLIC;

        if(!!data['birthday']) {
            user.birthday = new Date(data['birthday']);
        }

        if(!!data['nameChangedAt']) {
            user.nameChangedAt = new Date(data['nameChangedAt']);
        }
        if(!!data['fullAddress']) {
            user.fullAddress = data['fullAddress'];
        }

        if(!!data['job']) {
            user.job = data['job'];
        }

        if(!!data['employer']) {
            user.employer = data['employer'];
        }

        if(!!data['business']) {
            user.business = data['business'];
        } else {
            user.business = false;
        }

        return user;
    }
    return undefined;
}