import React, {Component} from 'react';
class SearchResultEntry extends Component {

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        return (
            <div className="search-result" onClick={ this.props.onClick}>
                {
                    this.props.children && <div className={"actions"}> {this.props.children} </div>
                }
                <img src={this.props.image} onError={this.onImageLoadFail}/>
                <div> {this.props.name}</div>

            </div>
        )

    }
}

export default SearchResultEntry;