import React from 'react';
import {getUserAvatarUrl} from "../../additional/helpers";
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {withRouter} from "react-router-dom";
import ContextMenu from "../../additional/context_menu";
import AreYouSureModal from "../../additional/are_your_sure_modal";
import EditBookingPage from "./edit";
import Modal from "../../additional/modal";
import LocalizationsWithLanguage from "../../localization_wrapper";
import CreateNewBookingPage from "./create";
import {getTranslationsForRepetitionInterval} from "../../additional/text_helpers";

let strings = new LocalizationsWithLanguage({
    de:{
        date: "Datum",
        locale: "de-DE",
        startTime: "Startzeit",
        endTime: "Endzeit",
        description: "Beschreibung",
        close: "Schließen",
        delete: "Löschen",
        edit: "Bearbeiten",
        userImageAlt: "Profilbild",
        oClock: "Uhr",
        since: "seit"
    },
    en: {
        date: "Date",
        locale: "en-US",
        startTime: "Start time",
        endTime: "End time",
        description: "Description",
        close: "Close",
        delete: "Delete",
        edit: "Edit",
        userImageAlt: "User image",
        oClock: "o clock",
        since: "since"
    }
});

class BookingModal extends React.Component {
    dateFormatOptions = {weekday: "long", year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' };
    timeFormatOptions = {hour: "2-digit", minute: "2-digit"};

    stopEvent = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    constructor() {
        super();

        this.state = {
            showDelete: false,
            showEditBooking: false
        };

        this.escFunction = this.escFunction.bind(this);
    }

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    deleteBooking = async () => {
        this.props.firebase.facilityController.deleteBookingFromFacility(this.props.booking, this.props.facility);
        this.props.onClose();
        this.setState({
            showDelete: false
        });
    };

    editBooking = () => {
        this.setState({
            showEditBooking: true
        });
    };

    setShowDelete = (show) => {
        this.setState({
            showDelete: show
        });
    };

    escFunction(event){
        if(event.keyCode === 27) {
            this.props.onClose();
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    closeEditModal = (changed) => {
        if (changed) {
            this.props.onClose();
        }
        this.props.auth.rebuildApp();
        this.setState({
            showEditBooking: false
        });
    };

    render() {
        if(!this.props.booking) {
            return null;
        }

        let date = new Date();
        let timeZoneOffset = date.getTimezoneOffset();

        let jan = new Date(date.getFullYear(), 0, 1);
        let jul = new Date(date.getFullYear(), 6, 1);
        let stdTimezoneOffset =  Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

        if (timeZoneOffset < stdTimezoneOffset) {
            timeZoneOffset= timeZoneOffset+60;
        }
        let showContextMenu = this.props.booking.uid === this.props.auth.user.uid || this.props.auth.stables.admin.hasOwnProperty(this.props.booking.sid) || this.props.auth.stables.employee.hasOwnProperty(this.props.booking.sid);

        return (
            <div className="modal" onClick={this.props.onClose}>
                <div className="modal-content" onClick={this.stopEvent}>
                    <div className="row mb-1">
                        <img src={getUserAvatarUrl(this.props.booking.uid)} className="modal-profile-image" onError={this.onImageLoadFail} alt={strings.userImageAlt}/>
                        <span>{this.props.booking.userName}</span>
                        {
                            showContextMenu &&
                            <ContextMenu>
                                {
                                    this.props.booking.uid === this.props.auth.user.uid && <div className={"option"} onClick={() => this.editBooking()}> {strings.edit}</div>
                                }
                                {
                                    (this.props.booking.uid === this.props.auth.user.uid || this.props.auth.stables.admin.hasOwnProperty(this.props.booking.sid)) &&
                                    <div className={"option"} onClick={() => this.setShowDelete(true)}> {strings.delete}</div>
                                }
                            </ContextMenu>
                        }
                        {
                            !showContextMenu && <div></div>
                        }
                    </div>
                    <div className="booking-modal-row">
                        <label>{strings.date}</label>
                        <div className="booking-modal-row-date">{ !!this.props.booking.repetitionInterval ? getTranslationsForRepetitionInterval()[this.props.booking.repetitionInterval]  + " " + strings.since + " - ": "" }{this.props.booking.day.toLocaleString(strings.locale, this.dateFormatOptions)}</div>
                    </div>

                    <div className="booking-modal-row">
                        <label>{strings.startTime}</label>
                        <div className="booking-modal-row-date">{(new Date((this.props.booking.start + timeZoneOffset * 60) * 1000)).toLocaleString(strings.locale, this.timeFormatOptions) + " " + strings.oClock}</div>
                    </div>

                    <div className="booking-modal-row">
                        <label>{strings.endTime}</label>
                        <div className="booking-modal-row-date">{(new Date((this.props.booking.end + timeZoneOffset * 60) * 1000)).toLocaleString(strings.locale, this.timeFormatOptions) + " " + strings.oClock}</div>
                    </div>

                    {
                        !!this.props.booking.notes &&
                        <div className="booking-modal-row">
                            <label>{strings.description}</label>
                            <div>{this.props.booking.notes}</div>
                        </div>
                    }

                    <button className="mt-1" onClick={this.props.onClose}>{strings.close}</button>

                    { this.state.showDelete && <AreYouSureModal onClose={() => this.setShowDelete(false)} onConfirm={this.deleteBooking}/> }
                    <Modal onClose={() => this.setState({showEditBooking: false})} show={this.state.showEditBooking}>
                        <EditBookingPage
                            booking={this.props.booking}
                            stable={this.props.stable}
                            facility={this.props.facility}
                            onClose={(changed) => this.closeEditModal(changed)}
                            onError={this.props.onError}
                        />
                    </Modal>
                </div>
            </div>
        );
    }
}

const BookingModalFull = withRouter(WithAuthentication(WithFirebase(BookingModal)));
export default BookingModalFull;