const PostPrivacy = {
    PUBLIC : 'PostPrivacy.PUBLIC',
    WATCH : 'PostPrivacy.WATCH',
    HORSERELATIONS : 'PostPrivacy.HORSERELATIONS',
    CHANNEL : 'PostPrivacy.CHANNEL',
    STABLE : 'PostPrivacy.STABLE',
};


export default PostPrivacy;


