import {getUserAvatarUrl} from "../additional/helpers";

export class Comment {
    cid;
    pid;
    uid;

    userName;
    userAvatar;

    createdAt;
    deletedAt;

    textContent;
    likes;
    dislikes;
    commentCount;
    userLikeType;

    image;

    constructor({ cid = "", pid = "", uid = "", userName = "", createdAt = new Date(), deletedAt = undefined, textContent = "", image } = {}) {
        this.cid = cid;
        this.pid = pid;
        this.uid = uid;
        this.userName = userName;
        this.userAvatar = getUserAvatarUrl(uid);
        this.createdAt = createdAt;
        this.deletedAt = deletedAt;
        this.textContent = textContent;
        this.likes = 0;
        this.dislikes = 0;
        this.commentCount = 0;
        this.image = image;
    }

    toHttpBody = () => {
        let jsonMap = {};

        jsonMap['textContent'] = this.textContent;
        jsonMap['userName'] = this.userName;

        if (!!this.image) {
            jsonMap['image'] = this.image;
        }

        if(!!this.cid) {
            jsonMap['cid'] = this.cid;
        }

        if(!!this.pid) {
            jsonMap['pid'] = this.pid;
        }

        return jsonMap;
    };
}

export function createCommentFromFirestoreDocument(firestoreDoc) {
    if (_validateDocument(firestoreDoc)) {
        let data = firestoreDoc.data();
        return createCommentFromData(firestoreDoc.id, data);
    }

    return null;
}

export function createCommentFromHttps(data) {
    if (_validateHttps(data)) {
        return createCommentFromData(data.documentID, data);
    }

    return null;
}

function createCommentFromData(commentId, data){
    let comment = new Comment({
        cid: commentId,
        pid: data['pid'],
        uid: data['uid'],
        userName: data['userName'],
        userAvatar: getUserAvatarUrl(data['uid']),
        createdAt: new Date(data['createdAt']),
        textContent: data['textContent'],
    });

    if (data['image']) {
        comment.image = data['image'];
    }

    if(data['deleted'] != null) {
        comment.deletedAt = new Date(data['deleted']);
    }

    if(data['likes'] != null) {
        comment.likes = data['likes'];
    }

    if(data['dislikes'] != null) {
        comment.dislikes = data['dislikes'];
    }

    return comment;
}

function _validateDocument(doc) {
    return !!doc
        && !!doc.data()
        && !!doc.id
        && !!doc.data()['uid']
        && !!doc.data()['pid']
        && !!doc.data()['userName']
        && !!doc.data()['createdAt']
        && ((!!doc.data()['textContent'] && doc.data()['textContent'] !== "") || (!!doc.data()['image'] && doc.data()['image'] !== ""));
}

function _validateHttps(data) {
    return !!data
        && !!data.documentID
        && !!data['uid']
        && !!data['pid']
        && !!data['userName']
        && !!data['createdAt']
        && ((!!data['textContent'] && data['textContent'] !== "") || (!!data['image'] && data['image'] !== ""));
}