import React, {Component} from 'react';
import {WithAuthentication} from "../../firebase/auth_provider";
import {withRouter} from "react-router-dom";
import GooglePlacesAutocomplete, {getLatLng, geocodeByPlaceId} from "react-google-places-autocomplete";
import {Geopoint} from "../../models/geopoint";
import SearchResultEntry from "../../elastic/search_result";
import * as elasticSearchController from "../../elastic/elastic_controller";
import StablePermission from "../../models/stable_permissions";
import {StableLocation} from "../../models/stable_location";
import {WithFirebase} from "../../firebase/firebase";
import LocalizationsWithLanguage from "../../localization_wrapper";
import {WithBlockRoute} from "../../navigation/block_route_provider";

let strings = new LocalizationsWithLanguage({
    de:{
        title:"Stall erstellen",
        information:"Jutta! hilft dir, euren Stall besser zu koordinieren. Füge einfach den Stallnamen, die Adresse und deine Stallkollegen hinzu.",
        namePlaceholder:"Name des Stalls",
        addressPlaceholder:"Adresse des Stalls",
        permissions:"Berechtigungen",
        permissionWriteNewsText:"Mitglieder können Ankündigungen in „interne News“ erstellen",
        permissionReactToNewsText:"Mitglieder können auf Ankündigungen in „interne News“ reagieren",
        search:"Suchen",
        invitedMembers:"Bereits eingeladen",
        cantCreateMoreStables:"Du hast bereits die maximale Anzahl an Ställen angelegt. Bitte lösche einen deiner Ställe um einen weiteren Stall anzulegen.",
        searchPlaceholder:"Lade andere Reiter in diesen Stall ein",
        invite: "Einladen",
        remove: "Entfernen",
        nobodyInvited: "Du hast noch niemanden eingeladen.",
        suggestion: "Diese Ställe gibt es schon: "

    },
    en: {
        title:"Create new stable",
        information:'Jutta! helps you to coordinate your stable better. Just add the name of your stable, its address and some stable colleagues.',
        namePlaceholder:"Name of stable",
        addressPlaceholder:"Address of stable",
        permissions:"Permissions",
        permissionWriteNewsText:"Clients can write posts in „News“",
        permissionReactToNewsText:"Clients can react to posts in „News“",
        search:"Search",
        invitedMembers:"Invited",
        cantCreateMoreStables:"You created the maximum amount of stables. Please revoke your admin position in some of your created stables to create more stables.",
        searchPlaceholder:"Search for other users",
        invite: "Invite",
        remove: "Remove",
        nobodyInvited: "You have not invited anybody.",
        suggestion: "These stables already exist: "
    }
});

class CreateStable extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            name: undefined,
            location: undefined,
            membersCanWriteNews: false,
            membersCanReactToNews: false,
            loading: false,
            creating: false,
            invitedMembers: {},
            searchUserResults: undefined,
            showInvited: false,
            term: "",
            searchStableResults: undefined
        };
    }

    onSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.refs['create-stable-form'].reportValidity();

        if (!this.state.creating && !!this.state.name && !!this.state.location && !!this.state.location.geolocation) {
            this.props.blockRoute.setCanLeaveRoute(() => true);
            this.setState({
                creating: true
            });

            let _stableController = this.props.firebase.stableController;

            let permissions = [];
            if (this.state.membersCanWriteNews) {
                permissions.push(StablePermission.CLIENTSCANWRITENEWS)
            }
            if (this.state.membersCanReactToNews) {
                permissions.push(StablePermission.CLIENTSCANREACTTONEWS)
            }

            let stable = await _stableController.createNewStable(this.props.auth.user.name, this.state.name, this.state.location, permissions);

            for (let user of Object.values(this.state.invitedMembers)) {
                _stableController.inviteUserToStable(stable.sid, this.props.auth.user.name,user.uid,user.name);
            }
            this.props.history.push("/stables/");
            this.setState( {creating : false});
        }
    };

    componentDidMount() {
        this.props.blockRoute.setCanLeaveRoute(() => (!this.state.name && !this.state.location && Object.keys(this.state.invitedMembers).length === 0 && !this.state.membersCanWriteNews && !this.state.membersCanReactToNews));
    }

    addUserToInviteList = (user) => {
        this.state.invitedMembers[user.uid] = user;
        this.setState(this.state);
    };

    removeUserFromInviteList = (user) => {
        delete this.state.invitedMembers[user.uid];
        this.setState(this.state);
    };

    search = async (event) =>   {
        let val = event.target.value;
        this.setState({
            term: val
        });
        if (!!val && val.length >= 2) {
            let userResult = await elasticSearchController.searchUser(val);

            this.setState({searchUserResults: userResult});

        } else {
            this.setState({searchUserResults: undefined});
        }
    };

    searchStables = async (event) =>   {
        let val = event.target.value;
        this.setState({[event.target.name]: val});
        if (!!val && val.length >= 2) {
            let stableResult = await elasticSearchController.searchStable(val);

            this.setState({searchStableResults: stableResult});
        } else {
            this.setState({searchStableResults: []});
        }
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onClick = async (link) =>  {
        this.props.history.push(link);
        this.setState({
            term: "",
            searchStableResults: undefined
        });
    };

    getGoogleAutoComplete = () =>  <GooglePlacesAutocomplete
        renderInput={(props) => <input {...props} />}
        required
        onSelect={async (result) =>  {
            let geocodeResult = await geocodeByPlaceId(result.place_id);
            if (!!geocodeResult && !!geocodeResult[0] && !!geocodeResult[0].address_components) {
                let addressComponents = geocodeResult[0].address_components;

                let city;
                let country;
                let countryCode;
                let state;

                addressComponents.forEach( (comp) =>  {
                    if (comp.types.includes("locality")) {
                        city = comp.long_name;
                    } else if ( comp.types.includes("administrative_area_level_1")) {
                        state = comp.short_name;
                    } else if ( comp.types.includes("country")){
                        country = comp.long_name;
                        countryCode = comp.short_name;
                    }

                });

                if (!!city && !!country) {
                    let latLong = await getLatLng(geocodeResult[0]);
                    this.setState({
                        location: new StableLocation({
                            city : city,
                            country : country,
                            countryCode : countryCode,
                            state : state,
                            geolocation: new Geopoint(latLong.lat,latLong.lng),
                        })
                    });
                } else {
                    this.setState({location: undefined});
                }

            } else {
                this.setState({location: undefined});
            }
        }}
    />;

    render() {

        let createdStableCount = 0;

        if (this.props.auth.stables) {
            for (let stable of Object.values(this.props.auth.stables.active)) {
                if (stable.creator === this.props.auth.firebaseUser.uid) {
                    createdStableCount ++;
                }
            }
        }

        if (createdStableCount >= 3) {
            return (
                <div> {strings.cantCreateMoreStables}</div>
            )
        }

        let isValid = !!this.state.name && !!this.state.location && !!this.state.location.geolocation;
        let usersToRender = !!this.state.searchUserResults && !!this.state.searchUserResults.users ? this.state.searchUserResults.users : [];

        let invitedMembers = [];

        for (let user of Object.values(this.state.invitedMembers)) {
            invitedMembers.push(user);
        }

        let stablesToRender = !!this.state.searchStableResults && !!this.state.searchStableResults.stables ? this.state.searchStableResults.stables : [];
        let searchResults;

        if(stablesToRender.length !== 0) {
            searchResults = <div className="search-results">
                {
                    stablesToRender.length > 0 ? <h4> {strings.suggestion} </h4> : ""
                }

                {
                    stablesToRender.map((stable, i) => {
                        return (
                            <SearchResultEntry key={"stable" + stable.sid} image={stable.header} name={stable.name}
                                               onClick={(e) => this.onClick(`/stables/${stable.sid}/information`)}/>
                        )
                    })
                }

            </div>
        }

        return (
            <div className="edit-stable-page">
                <h1>{strings.title}</h1>
                <p>{strings.information}</p>

                <form ref="create-stable-form">
                    <input
                        name="name"
                        //value={this.state.email}
                        onChange={this.searchStables}
                        type="text"
                        placeholder={strings.namePlaceholder}
                        required
                    />
                    {searchResults}

                    {this.getGoogleAutoComplete()}

                    <div className="create-stable-subheader"> {strings.permissions}</div>

                    <div className="create-stable-permission">
                        <input
                            name="membersCanWriteNews"
                            onChange={this.onChange}
                            type="checkbox"
                        />

                        <div className="create-stable-permission-info">{strings.permissionWriteNewsText}</div>
                    </div>
                    <div className="create-stable-permission">
                        <input
                            name="membersCanReactToNews"
                            onChange={this.onChange}
                            type="checkbox"
                        />

                        <div className="create-stable-permission-info">{strings.permissionReactToNewsText}</div>
                    </div>

                    <div className={"switch-container"}>
                        <div className={(!this.state.showInvited ? "primary-button tab-button-left" : "secondary-button tab-button-left")} onClick={() => this.setState({showInvited: false})}>{strings.search}</div>
                        <div className={(this.state.showInvited ? "primary-button tab-button" : "secondary-button tab-button")} onClick={() => this.setState({showInvited: true})}>{strings.invitedMembers}</div>
                    </div>

                    {
                        !this.state.showInvited && <div className="create-stable-search">
                            <input

                        name="search"
                        className="search"
                                type="text"
                                onChange={this.search}
                                value={this.state.term}
                                placeholder={strings.searchPlaceholder}
                            />
                            <div className="create-stable-search-results">
                                {
                                    usersToRender.length>0 ? <div> {strings.user} </div> : ""
                                }

                                {
                                    usersToRender.map((user, i) => {
                                        return (
                                            <SearchResultEntry key={"user"+ user.uid} image={user.avatar} name={user.name} onClick={() => {}}>
                                                {
                                                    this.state.invitedMembers.hasOwnProperty(user.uid) ?
                                                        (
                                                            <button className={"secondary-button"} type={"button"} onClick={ () => this.removeUserFromInviteList(user)}> {strings.remove} </button>
                                                        )
                                                        : (
                                                            <button type={"button"} onClick={ () => this.addUserToInviteList(user)}> {strings.invite} </button>
                                                        )
                                                }
                                            </SearchResultEntry>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    }

                    {
                        this.state.showInvited && invitedMembers.length > 0 ?
                            (
                                <div>
                                    {
                                        invitedMembers.map((user, i) => {
                                            return (
                                                <SearchResultEntry key={"user"+ user.uid} image={user.avatar} name={user.name}  onClick={() => this.removeUserFromInviteList(user)}>
                                                    <button className={"secondary-button"} type={"button"} onClick={ () => this.removeUserFromInviteList(user)}> {strings.remove} </button>
                                                </SearchResultEntry>
                                            )
                                        })
                                    }
                                </div>
                            )
                            :
                            this.state.showInvited &&   <div> {strings.nobodyInvited} </div>

                    }

                    <button disabled={!isValid || this.state.creating} className={ "mt-2"} onClick={(e) => this.onSubmit(e)}>
                        {strings.title}
                    </button>
                </form>
            </div>
        )
    }
}

export default withRouter(WithBlockRoute(WithAuthentication(WithFirebase(CreateStable))));