import React, {Component} from 'react';
import HorseCard from "./horse_card";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        stableMemberImageAlt: "Bild von Stallmitglied"
    },
    en: {
        stableMemberImageAlt: "Image of stable member"
    }
});

class StableMemberCard extends Component {

    imgHasFailed = false;

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        return (
            <div className="user">
                <div className="list-tile">
                        <img className="user-avatar" src={this.props.image} onError={this.onImageLoadFail} alt={strings.stableMemberImageAlt}/>
                        <p>{this.props.name}</p>
                        {!!this.props.options  ? this.props.options() : ""}
                </div>
                    {
                        !!this.props.horses  ? (
                            <div className="horses">
                                {
                                    this.props.horses.map( ( horse, i)=> {
                                        return (
                                            <HorseCard key={"horse" + horse.id} name={horse.name} image={horse.avatar}/>
                                        )
                                    })
                                }
                            </div>
                        ): ""
                    }

            </div>
        )

    }
}

export default StableMemberCard;