import * as ROUTES from "../routes";
import {Switch} from "react-router-dom";
import React from "react";
import {TermsOfUseWithPage} from "./auth/terms_of_use";
import {DataProtectionWithPage} from "./auth/data_protection";
import AppRoute from "./app_route";
import UnauthorizedLayout from "./unauthorized_layout";
import LocalizationsWithLanguage from "./localization_wrapper";
import AdditionalInformationPage from "./auth/additional_information";

let strings = new LocalizationsWithLanguage({
    de:{
        titleDpd: "Datenschutzerklärung",
        titleTerms: "Allgemeine Geschäftsbedingungen",
        titleAddInfo: "Fast geschafft!"
    },
    en: {
        titleDpd: "Data protection declaration",
        titleTerms: "Terms of use",
        titleAddInfo: "Almost there..."
    }
});

const Incomplete = () =>
    <Switch>
        <AppRoute withTopBar={true} title={strings.titleTerms} exact path={ROUTES.TERMSOFUSE} component={TermsOfUseWithPage} layout={UnauthorizedLayout}/>
        <AppRoute withTopBar={true} title={strings.titleDpd} exact path={ROUTES.DATAPROTECTION} component={DataProtectionWithPage} layout={UnauthorizedLayout}/>
        <AppRoute path={"*"}  withTopBar={true} title={strings.titleAddInfo} component={AdditionalInformationPage} layout={UnauthorizedLayout} abortSignUp={true}/>
    </Switch>
;

export default Incomplete;