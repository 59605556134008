import React, {Component} from 'react';
import {WithAuthentication} from "../../firebase/auth_provider";
import {withRouter} from "react-router-dom";
import GooglePlacesAutocomplete, {getLatLng, geocodeByPlaceId} from "react-google-places-autocomplete";
import {Geopoint} from "../../models/geopoint";
import {StableLocation} from "../../models/stable_location";
import {WithFirebase} from "../../firebase/firebase";
import imageCompression from "browser-image-compression";
import ExpandingText from "../../additional/expanding_text";
import {getFullAddressFromStableLocation} from "../../additional/helpers";
import Loading from "../../loading";
import LocalizationsWithLanguage from "../../localization_wrapper";
import {WithBlockRoute} from "../../navigation/block_route_provider";

let strings = new LocalizationsWithLanguage({
    de:{
        title:"Stall bearbeiten",
        namePlaceholder:"Name des Stalls",
        addressPlaceholder:"Adresse des Stalls",
        additional: "Zusatzinformationen",
        aboutPlaceholder: "Über diesen Stall",
        offersPlaceholder: "Unsere Angebote",
        farmingSystemsPlaceholder: "Unsere Haltungssysteme",
        save:"Speichern",
        addImage:"Foto \n hinzufügen",
        saveChanges: "Änderungen speichern",
        stableImageAlt: "Stallbild"
    },
    en: {
        title:"Create new stable",
        namePlaceholder:"Name of stable",
        addressPlaceholder:"Address of stable",
        additional: "Additional",
        aboutPlaceholder: "About this stable",
        offersPlaceholder: "Our offers",
        farmingSystemsPlaceholder: "Our farming systems",
        save:"Save",
        addImage:"Add \n media",
        saveChanges: "Save changes",
        stableImageAlt: "Stable image"
    }
});

class EditStable extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            stable: undefined,
            location: undefined,
            loading: false,
            updating: false,
            images: [],
            imagesToDelete: [],
            name: undefined,
            address: undefined,
            about: undefined,
            offer: undefined,
            farmingSystem: undefined,
            highestImageKey: 0,
        };
    }

    onSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.refs['stable-edit-form'].reportValidity();

        if (!this.state.updating && !!this.state.name && !!this.state.location && !!this.state.location.geolocation) {
            this.props.blockRoute.setCanLeaveRoute(() => true);
            this.setState({
                updating: true
            });
            let _stableController = this.props.firebase.stableController;
            let _storageController = this.props.firebase.storageController;

            this.state.stable.name = this.state.name;
            this.state.stable.locationData = this.state.location;
            this.state.stable.about = this.state.about;
            this.state.stable.offer =  this.state.offer;
            this.state.stable.farmingSystem =  this.state.farmingSystem;

            let newImages = {};
            for (let i=0; i<this.state.images.length; i++) {
                if (!!this.state.images[i].file) {
                    this.state.highestImageKey +=  1;
                    newImages[this.state.highestImageKey] = await _storageController.uploadImageForStable(this.state.images[i].file,this.state.stable.sid,this.state.highestImageKey);
                } else if(!!this.state.images[i].url){
                    newImages[this.state.images[i].key] =this.state.images[i].url;
                }
            }

            if (!!this.state.imagesToDelete) {
                for (let i=0; i<this.state.imagesToDelete.length; i++) {
                    if (!!this.state.imagesToDelete[i].key) {
                        _storageController.deleteImageForStable(this.state.stable.sid, this.state.imagesToDelete[i].key)
                    }
                }
            }

            await this.updateStableHeader();
            this.state.stable.images= newImages;

            await _stableController.updateStable(this.state.stable);

            this.props.history.push("/stables/" + this.state.stable.sid + "/information");
            this.setState( {creating : false});
        }
    };

    updateStableHeader = async () => {
        let _storageController = this.props.firebase.storageController;

        if( Object.keys(this.state.stable.images).length === 0 && this.state.images.length > 0) {
           if(!!this.state.images[0].file)
            await _storageController.uploadHeaderForStable(this.state.images[0].file,this.state.stable.sid);
            return;
        }

        if(this.state.images.length === 0 ){
            _storageController.deleteStableHeaderBySid(this.state.stable.sid);
            this.state.stable.header= undefined;
            return;
        }

        let lowestKey = Object.keys(this.state.stable.images).sort()[0];

        if(this.state.images[0].key  !== lowestKey) {
            if (!!this.state.images[0].file) {
                await _storageController.uploadHeaderForStable(this.state.images[0].file,this.state.stable.sid);
            } else {
                await _storageController.uploadHeaderForStableByUrl(this.state.images[0].url,this.state.stable.sid);
            }
            return;
        }
    };


    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    getGoogleAutoComplete = () =>  <GooglePlacesAutocomplete
        renderInput={(props) => <input {...props} />}
        initialValue={this.state.address}
        required
        onSelect={async (result) =>  {
            let geocodeResult = await geocodeByPlaceId(result.place_id);
            if (!!geocodeResult && !!geocodeResult[0] && !!geocodeResult[0].address_components) {
                let addressComponents = geocodeResult[0].address_components;

                let city;
                let country;
                let countryCode;
                let state;

                addressComponents.forEach( (comp) =>  {
                    if (comp.types.includes("locality")) {
                        city = comp.long_name;
                    } else if ( comp.types.includes("administrative_area_level_1")) {
                        state = comp.short_name;
                    } else if ( comp.types.includes("country")){
                        country = comp.long_name;
                        countryCode = comp.short_name;
                    }
                });

                if (!!city && !!country) {
                    let latLong = await getLatLng(geocodeResult[0]);
                    let location = new StableLocation({
                        city : city,
                        country : country,
                        countryCode : countryCode,
                        state : state,
                        geolocation: new Geopoint(latLong.lat,latLong.lng),
                    });

                    location.fullAddress = getFullAddressFromStableLocation(location);

                    this.setState({
                        location: location,
                        address: location.fullAddress
                    });
                } else {
                    this.setState({
                        location: undefined,
                        address: undefined
                    });
                }
            } else {
                this.setState({
                    location: undefined,
                    address: undefined
                });
            }
        }}
    />;

    openFilePicker = (e) => {
        if (!!e) {
            e.preventDefault();
        }
        this.refs["filePicker"].click();
    };

    compressFile = async (event) => {
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1200,
            useWebWorker: true,
            onProgress: () => null
        };

        let files = event.target.files;

        for (let i= 0; i < files.length; i++) {
            let file = files[i];
            let compressed = await imageCompression(file, options);
            let url = URL.createObjectURL(compressed);

            this.state.images.push({file: compressed, url: url});
            this.setState(this.state);
        }
    };

    removeImage = (url) => {
        for (let i=0; i<this.state.images.length; i++) {
            if (this.state.images[i].url === url) {
                this.state.imagesToDelete.push(this.state.images[i]);
                this.state.images.splice(i, 1);
                break;
            }
        }
        this.setState(this.state);
    };

    loadDisplayedStable() {
        this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(stable => {
            this.props.blockRoute.setCanLeaveRoute(() => (this.state.name.trim() === stable.name
                && this.state.location === stable.locationData
                && this.state.about.trim() === stable.about
                && this.state.offer.trim() === stable.offer
                && this.state.farmingSystem.trim() === stable.farmingSystem));

            this.setState({
                stable : stable,
                name: stable.name,
                location: stable.locationData,
                address: stable.locationData.fullAddress,
                about: stable.about,
                offer: stable.offer,
                farmingSystem: stable.farmingSystem,
            });

            Object.keys(stable.images).forEach( (key) => {
                if (key > this.state.highestImageKey) {
                    this.state.highestImageKey = key;
                }
                this.state.images.push( {url: stable.images[key], file: undefined, key: key})
            });
            this.setState(this.state);
        });
    }
    
    getStableBySid = async () => {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(stable => {
                this.setState({ stable : stable, loading: false });
            });

            let stable = this.props.firebase.stableController.getStableByID(this.props.match.params.sid);

            if (!stable) {
                this.loadDisplayedStable();
            } else {
                this.props.blockRoute.setCanLeaveRoute(() => (this.state.name.trim() === stable.name
                    && this.state.location === stable.locationData
                    && this.state.about.trim() === stable.about
                    && this.state.offer.trim() === stable.offer
                    && this.state.farmingSystem.trim() === stable.farmingSystem));

                this.setState({
                    stable: stable,
                    name: stable.name,
                    location: stable.locationData,
                    address: stable.locationData.fullAddress,
                    about: stable.about,
                    offer: stable.offer,
                    farmingSystem: stable.farmingSystem
                });

                Object.keys(stable.images).forEach( (key) => {
                    if (parseInt(key) > this.state.highestImageKey) {
                        this.setState({
                            highestImageKey: parseInt(key)
                        });
                    }
                    this.state.images.push( {url: stable.images[key], file: undefined, key: key})
                });
            }
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }

    };

    render() {
        let isValid = !!this.state.name && !!this.state.location && !!this.state.location.geolocation;

        if (!this.state.stable) {
            this.getStableBySid();
        }

        if (!this.state.stable) {
            return (<Loading/>);
        }
        
        return (
            <div className={"edit-stable-page"}>
                <div className="create-stable-header">
                    <h2>{strings.title}</h2>
                </div>

                <form ref="stable-edit-form">
                    <input name="filePicker" accept="image/*" type="file" ref="filePicker" style={{display: "none"}} onChange={(event)=> {this.compressFile(event)}} onClick={(event)=> {event.stopPropagation();event.target.value = null}} multiple/>

                    <div className={"selected-images-bar  stable-selected-images"}>

                        <div className={"stable-selected-image-wrapper"}>
                            <div className={"stable-new-image-wrapper"} onClick={this.openFilePicker}>
                                <div className={"stable-new-image-text"}>
                                    {strings.addImage}
                                </div>
                            </div>
                        </div>

                        { !!this.state.images && this.state.images.length > 0 ?

                            this.state.images.map( (value) => {
                                return (
                                    <div className={"stable-selected-image-wrapper img-delete-wrapper"} key={value.url}>
                                        <img src={value.url} onError={this.onImageLoadFail} alt={strings.stableImageAlt}/>
                                        <div className={"img-delete"} onClick={() => this.removeImage(value.url)}>×</div>
                                    </div>
                                )
                            }) : null
                        }
                    </div>

                    <input
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        type="text"
                        placeholder={strings.namePlaceholder}
                        required
                    />

                    {this.getGoogleAutoComplete()}

                    <div className="create-stable-subheader"> {strings.additional}</div>

                    <ExpandingText
                        name="about"
                        initValue={this.state.about}
                        onChange={this.onChange}
                        placeholder={strings.aboutPlaceholder}
                    />

                    <ExpandingText
                        name="offer"
                        initValue={this.state.offer}
                        onChange={this.onChange}
                        placeholder={strings.offersPlaceholder}
                    />

                    <ExpandingText
                        name="farmingSystem"
                        initValue={this.state.farmingSystem}
                        onChange={this.onChange}
                        placeholder={strings.farmingSystemsPlaceholder}
                    />

                    <button disabled={!isValid || this.state.updating} className="width-50x^" onClick={(e) => this.onSubmit(e)}>
                        {strings.saveChanges}
                    </button>
                </form>
            </div>
        )
    }
}

export default withRouter(WithBlockRoute(WithAuthentication(WithFirebase(EditStable))));