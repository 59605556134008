import React, {Component} from 'react';
import {WithFirebase} from '../firebase/firebase';
import {WithAuthentication} from "../firebase/auth_provider";
import {withRouter} from "react-router-dom";
import Privacy from "../models/privacy";
import {dateToDatePickerValue, getUserAvatarLargeUrl} from "../additional/helpers";
import {Geopoint} from "../models/geopoint";
import GooglePlacesAutocomplete, {getLatLng, geocodeByPlaceId} from "react-google-places-autocomplete";
import * as ROUTES from "../../routes";
import imageCompression from "browser-image-compression";
import LocalizationsWithLanguage from "../localization_wrapper";
import AreYouSureModal from "../additional/are_your_sure_modal";
import {WithBlockRoute} from "../navigation/block_route_provider";
import * as helpers from "../additional/helpers";

let strings = new LocalizationsWithLanguage({
    de: {
        editProfile: "Profil bearbeiten",
        firstName: "Vorname",
        lastName: "Nachname",
        birthday: "Geburtstag",
        location: "Standort",
        save: "Speichern",
        job: "Job",
        employer: "Arbeitgeber",
        deleteAccount: "Account löschen",
        accountInfo: "Accountinformationen",
        accountInfoDescription: "Bitte achte darauf, dass Du Deinen Namen nur alle fünf Wochen ändern kannst.",
        additionalInfo: "Zusätzliche Informationen",
        additionalInfoDescription: "Erweitere Dein Profil, damit andere Nutzer mehr über Dich erfahren!",
        editPicture: "Foto bearbeiten",
        deletePicture: "Foto löschen",
        profilePicture: "Profilbild",
        tooltipPublic: "Privatsphäre: Öffentlich\n Deine Follower können diese Information sehen",
        tooltipPrivate: "Privatsphäre: Privat\n Nur du kannst diese Information sehen",
    },
    en: {
        editProfile: "Edit profile",
        firstName: "First name",
        lastName: "Last name",
        birthday: "Birthday",
        location: "Location",
        save: "Save",
        job: "Job",
        employer: "Employer",
        deleteAccount: "Delete account",
        accountInfo: "Account information",
        accountInfoDescription: "Please keep in mind that you can change your name only every five weeks.",
        additionalInfo: "Additional information",
        additionalInfoDescription: "Keep your profile on track so other users get to know you better!",
        editPicture: "Edit media",
        deletePicture: "Delete media",
        profilePicture: "Profile picture",
        tooltipPublic: "Privacy: Public\n Your follower can see this information",
        tooltipPrivate: "Privacy: Private\n Only you can see this information",
    }
});

class EditUserInformation extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDeleteAccount: false,
            user: props.auth.user,
            userAvatarImage: {
                url: getUserAvatarLargeUrl(props.auth.user.uid)
            },
            userAvatarImage_large: undefined,
            firstName: props.auth.user.firstName,
            lastName: props.auth.user.lastName,
            job: props.auth.user.job,
            employer: props.auth.user.employer,
            birthday: dateToDatePickerValue(props.auth.user.userSettings.birthday),
            nameChangedAt: props.auth.user.nameChangedAt,
            birthdayPrivacy: props.auth.user.birthdayPrivacy,
            locationPrivacy: props.auth.user.locationPrivacy,
            location: {
                fullAddress: props.auth.user.userSettings.fullAddress,
                city: props.auth.user.userSettings.city,
                country: props.auth.user.userSettings.country,
                countryCode: props.auth.user.userSettings.countryCode,
                state: props.auth.user.userSettings.state,
                geopoint: props.auth.user.getGeolocation()
            }
        }
    }

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    componentDidMount() {
        this.props.blockRoute.setCanLeaveRoute(() => (
            !this.state.userAvatarImage || !this.state.userAvatarImage.file)
            && ( this.state.firstName.trim() === this.props.auth.user.firstName.trim() )
            && ( this.state.lastName.trim() === this.props.auth.user.lastName.trim() )
            && ( !this.state.job || (this.state.job.trim() === this.props.auth.user.job.trim())  )
            && ( !this.state.employer || (this.state.employer.trim() === this.props.auth.user.employer.trim()) )
            && ( this.state.birthdayPrivacy.trim() === this.props.auth.user.birthdayPrivacy.trim())
            && ( this.state.locationPrivacy.trim() === this.props.auth.user.locationPrivacy.trim())
            && ( this.state.location.fullAddress.trim() === this.props.auth.user.userSettings.fullAddress.trim())
            && ( this.state.birthday === dateToDatePickerValue(this.props.auth.user.userSettings.birthday))
        );
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onBirthdayPrivacyChange = event => {
        if (this.state.birthdayPrivacy === Privacy.PUBLIC) {
            this.setState({birthdayPrivacy: Privacy.SECRET});
        } else {
            this.setState({birthdayPrivacy: Privacy.PUBLIC});
        }
    };

    onLocationPrivacyChange = event => {
        if (this.state.locationPrivacy === Privacy.PUBLIC) {
            this.setState({locationPrivacy: Privacy.SECRET});
        } else {
            this.setState({locationPrivacy: Privacy.PUBLIC});
        }
    };

    deleteAccount= async () => {
        await this.props.firebase.userController.deleteUserAccount();
        this.props.firebase.logout();
        this.props.history.push(ROUTES.SIGN_IN);
    };

    compressAvatar = async (event, targetImageTag) => {
        let file = event.target.files[0];

        if (!!file) {
            this.setState({loading: true});
            const largeOptions = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1000,
                useWebWorker: true,
                onProgress: () => null
            };

            const smallOptions = {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 256,
                useWebWorker: true,
                onProgress: () => null
            };

            let smallAvatar = await imageCompression(file, smallOptions);
            let localUrl = URL.createObjectURL(smallAvatar);

            this.setState({
                [targetImageTag]: {file: smallAvatar, url: localUrl}
            });
            this.state[targetImageTag + "_large"] = await imageCompression(file, largeOptions);
            this.setState({loading: false});
        }
    };

    openFilePicker = (target) => {
        this.refs[target].click();
    };

    onSave = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.refs['user-edit-form'].reportValidity();

        if(!this.state.loading &&
            !!this.state.firstName &&
            !!this.state.lastName &&
            !!this.state.birthday &&
            !!this.state.birthdayPrivacy &&
            !!this.state.location.city &&
            !!this.state.location.country &&
            !!this.state.location.fullAddress &&
            !!this.state.location.geopoint &&
            !!this.state.location.state &&
            !!this.state.locationPrivacy) {

            let minAge = new Date();
            minAge.setFullYear(minAge.getFullYear() -16);
            if (isNaN(new Date(this.state.birthday).getTime()) || !new Date(this.state.birthday) || new Date(this.state.birthday).getTime() >  minAge.getTime()) {
                return;
            }

            this.props.blockRoute.setCanLeaveRoute(() => true);
            this.setState({loading: true});

            if(!this.state.userAvatarImage && !this.state.userAvatarImage_large) {
                await this.props.firebase.storageController.deleteUserAvatar(this.props.auth.user.uid);
                // Otherwise the browser will cache the image and still show it
                this.props.auth.user.avatar = this.props.auth.user.avatar + "xyz";
            } else if(!!this.state.userAvatarImage && (this.state.userAvatarImage.url !== this.props.auth.user.avatar) && !!this.state.userAvatarImage.file) {
                this.props.auth.user.hasAvatar = true;
                this.props.auth.user.avatar = helpers.getUserAvatarUrl( this.props.auth.user.uid);
                await this.props.firebase.storageController.uploadUserAvatars(this.props.auth.user.uid, this.state.userAvatarImage.file, this.state.userAvatarImage_large);
            }

            await this.props.firebase.userController.updateUser(
                this.props.auth.user,
                this.state.location.geopoint,
                this.state.location.country,
                this.state.location.countryCode,
                this.state.location.city,
                this.state.location.state,
                this.state.location.fullAddress,
                this.state.firstName,
                this.state.lastName,
                new Date(this.state.birthday),
                this.state.job,
                this.state.employer,
                this.state.birthdayPrivacy,
                this.state.locationPrivacy
            );

            this.props.auth.rebuildAuthUserComponents();
            this.props.history.push(ROUTES.USER.replace(':uid', this.props.auth.user.uid));
        }
    };

    getUserImage() {
        return (
            <div className="card">
                <div className="card-header">
                    <h4>{strings.profilePicture}</h4>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* The Extra div is needed around the img, because the image would just collapse despite having a fixed size */}
                        <div className="user-image">
                            <img className="user-image" ref="userAvatarImage" src={!!this.state.userAvatarImage ? this.state.userAvatarImage.url : "/img/nopicture.png"} onClick={() => this.openFilePicker("userAvatarFile")} onError={this.onImageLoadFail}/>
                        </div>
                        <input accept="image/*" type="file" id="userAvatar" ref="userAvatarFile" style={{display: "none"}} onChange={(event)=> {this.compressAvatar(event, "userAvatarImage")}} />

                        <div className="column">
                            <a onClick={() => this.openFilePicker("userAvatarFile")}>{strings.editPicture}</a><br/>
                            <a onClick={() => this.setState({userAvatarImage: undefined, userAvatarImage_large: undefined})}>{strings.deletePicture}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getUserAccountInfoForm() {
        let { firstName, lastName, birthday, birthdayPrivacy } = this.state;
        let canNotChangeName = Date.now() < (!!this.state.nameChangedAt ? this.state.nameChangedAt.getTime() + 35 * 24 * 60 * 60 * 1000 : 0 );

        return (
                <div className="card">
                    <div className="card-header">
                        <h4>{strings.accountInfo}</h4>
                        <small>{strings.accountInfoDescription}</small>
                    </div>
                    <div id="edit-profile" className="card-body">
                        <div className="form-row">
                            <label htmlFor="firstName">{strings.firstName}</label>
                            <input disabled={ canNotChangeName } name="firstName" type="text" value={firstName} onChange={this.onChange} required/>
                        </div>
                        <div className="form-row">
                            <label htmlFor="lastName">{strings.lastName}</label>
                            <input disabled={ canNotChangeName } name="lastName" type="text" value={lastName} onChange={this.onChange} required/>
                        </div>

                        <div className="form-row">
                            <label htmlFor="birthday">{strings.birthday}</label>
                            <div className="row flex-start">
                                <input
                                    name="birthday"
                                    className="birthdayandlocation"
                                    onChange={this.onChange}
                                    type="date"
                                    value={birthday}
                                    max={ (() => {
                                        let current = new Date();
                                        current.setFullYear(current.getFullYear() - 16);
                                        return current.toISOString().split("T")[0]
                                    })() }
                                    required
                                />
                                <div className={this.state.birthdayPrivacy === Privacy.SECRET ? "privacy-toggle-wrapper secret" : "privacy-toggle-wrapper public"} onClick={this.onBirthdayPrivacyChange}>
                                    <div className="privacy-toggle">
                                        {this.state.birthdayPrivacy === Privacy.SECRET ?  <img src="/img/icons/dark/secret.png" alt="Secret Setting Icon"/> : <img src="/img/icons/dark/public.png" alt="Public Setting Icon"/>}
                                    </div>
                                    <div className="tooltip">
                                        <h4>
                                            {this.state.birthdayPrivacy === Privacy.SECRET ?  strings.tooltipPrivate : strings.tooltipPublic}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {this.getGooglePlaces()}

                    </div>
                </div>
        );
    }

    getAdditionalInfoForm() {
        let { job, employer } = this.state;

        return (
            <div className="card">
                <div className="card-header">
                    <h4>{strings.additionalInfo}</h4>
                    <small>{strings.additionalInfoDescription}</small>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <label htmlFor="job">{strings.job}</label>
                        <input name="job" type="text" value={job} onChange={this.onChange}/>
                    </div>
                    <div className="form-row">
                        <label htmlFor="employer">{strings.employer}</label>
                        <input name="employer" type="text" value={employer} onChange={this.onChange}/>
                    </div>
                </div>
            </div>
        );
    }

    getGooglePlaces() {
        let { locationPrivacy, location } = this.state;

        return(
            <div className="form-row">
                <label id="label-google-places" htmlFor="google-places-autocomplete-input">{strings.location}</label>
                <div className="row flex-start">
                    <GooglePlacesAutocomplete
                        initialValue={location.fullAddress}
                        placeholder=""
                        onSelect={async (result) =>  {
                            let geocodeResult = await geocodeByPlaceId(result.place_id);
                            if (!!geocodeResult && !!geocodeResult[0] && !!geocodeResult[0].address_components) {
                                let addressComponents = geocodeResult[0].address_components;

                                let city;
                                let country;
                                let countryCode;
                                let state;

                                addressComponents.forEach( (comp) =>  {
                                    if (comp.types.includes("locality")) {
                                        city = comp.long_name;
                                    } else if ( comp.types.includes("administrative_area_level_1")) {
                                        state = comp.short_name;
                                    } else if ( comp.types.includes("country")){
                                        country = comp.long_name;
                                        countryCode = comp.short_name;
                                    }

                                });

                                if (!!city && !!country) {
                                    let latLong = await getLatLng(geocodeResult[0]);
                                    this.setState(
                                        {
                                            location: {
                                                city : city,
                                                country : country,
                                                countryCode : countryCode,
                                                state : state,
                                                geopoint: new Geopoint(latLong.lat, latLong.lng),
                                                fullAddress: city + ", " + (!!state ? (state + ", ") : "") + country
                                            }
                                        }
                                    );
                                } else {
                                    this.setState({location: undefined});
                                }

                            } else {
                                this.setState({location: undefined});
                            }
                        }}
                    />
                    <div className={locationPrivacy === Privacy.SECRET ? "privacy-toggle-wrapper secret" : "privacy-toggle-wrapper public"} onClick={this.onLocationPrivacyChange}>
                        <div className="privacy-toggle">
                            {locationPrivacy === Privacy.SECRET ? <img src="/img/icons/dark/secret.png" alt="Secret Setting Icon"/> : <img src="/img/icons/dark/public.png" alt="Public Setting Icon"/>}
                        </div>
                        <div className="tooltip">
                            <h4>
                                {locationPrivacy === Privacy.SECRET ?  strings.tooltipPrivate : strings.tooltipPublic}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <div className="user-page">

                {!!this.state.showDeleteAccount &&
                    <AreYouSureModal onConfirm={() => this.deleteAccount()} onClose={() => this.setState({showDeleteAccount: false})}/>
                }

                <div className="row">
                    <h1>{strings.editProfile}</h1>
                    <a onClick={()=> this.setState({showDeleteAccount: true})}>{strings.deleteAccount}</a>
                </div>

                <form ref="user-edit-form">
                    {this.getUserImage()}
                    {this.getUserAccountInfoForm()}
                    {this.getAdditionalInfoForm()}

                    <button disabled={this.state.loading} onClick={(e)=> this.onSave(e)}>{strings.save}</button>
                </form>
            </div>
        );
    }
}

const EditUserInformationPage = withRouter(WithBlockRoute(WithAuthentication(WithFirebase(EditUserInformation))));
export default EditUserInformationPage;