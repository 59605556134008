class Share {
    shareId;
    shareType;
    sharedPostUid;

    constructor({ shareId : shareId, sharedType: shareType, sharedPostUid: sharedPostUid } = {}){
        this.shareId = shareId;
        this.shareType = shareType;
        this.sharedPostUid = sharedPostUid;
    };

    toJson = () => {
        let body = {};
        body['sharedInstanceId'] = this.shareId;
        body['sharedInstanceType'] = this.shareType;

        return body;
    }
}


export function createShareFromHttps (json) {
    if(_validateHTTPS(json)) {
        return new Share({shareId: json['sharedInstanceId'], sharedType: json['sharedInstanceType']});
    }
    return undefined;
}

function _validateHTTPS(json){
    return !!json
        && !!json['sharedInstanceId']
        && !!json['sharedInstanceType'];
};
