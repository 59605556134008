import React from 'react';
import { NavLink, withRouter} from "react-router-dom";
import * as ROUTES from "../../routes";
import {WithAuthentication} from "../firebase/auth_provider";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        community:"Community",
        groups:"Gruppen",
        horses:"Deine Pferde",
        market:"Marktplatz",
        stables:"Reitställe",
        posts:"Aktuelle Beiträge",
        news:"Interne News",
        facility:"Anlagenverwaltung",
        information:"Informationen",
        settings:"Einstellungen",
        logoAlt:"Logo der Jutta! Pferdeapp",
        userProfileImageAlt: "Dein Profilbild"
    },
    en: {
        community:"Community",
        groups:"Groups",
        horses:"Horses",
        market:"Market",
        stables:"Stables",
        posts:"Current posts",
        news:"Internal news",
        facility:"Facility management",
        information:"Information",
        settings:"Settings",
        logout:"Logout",
        logoAlt:"Logo of the Jutta! horse app",
        userProfileImageAlt: "your profile image"
    }
});

class SideBarComponent extends React.PureComponent {

    switchToUserProfile= () => {
        this.props.history.push(ROUTES.USER.replace(':uid', this.props.auth.user.uid));
    };

    render() {
        let currentRoute = this.props.location.pathname;

        return (
            <nav>
                <div>
                    <img alt={strings.logoAlt} className="logo" src={"/img/jutta-mit-kreis-ohne-claim.png"} onClick={() => this.props.history.push(ROUTES.HOME)}/>
                    <div className={"sidebar"}>
                        {/*<NavLink className={"navLink"} to={ROUTES.COMMUNITY}>{strings.community}</NavLink>*/}
                        {/*<NavLink className={"navLink"} to={ROUTES.GROUPS}>{strings.groups}</NavLink>*/}
                        {/*<NavLink className={"navLink"} to={ROUTES.HORSES}>{strings.horses}</NavLink>*/}
                        <NavLink className={"navLink"} activeClassName={"active"} to={ROUTES.STABLE}>{strings.stables}</NavLink>
                        <NavItemsStable router={this.props.router} currentRoute={currentRoute} auth={this.props.auth} match={this.props.match}/>
                        {/*<NavLink className={"navLink"} to={ROUTES.MARKET}>{strings.market}</NavLink>*/}
                    </div>
                </div>
            </nav>
        )
    }
}

const NavItemsStable = (props) => {
    if(!!props.match.params.sid) {
        if (!!props.auth.stables.active[props.match.params.sid]) {
            return (
                <div className={"subMenu"}>
                    <h5>{props.auth.stables.active[props.match.params.sid].name}</h5>
                    <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.LATESTPOSTS.replace(":sid", props.match.params.sid)} >{strings.posts}</NavLink>
                    <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.INTERNALNEWS.replace(":sid", props.match.params.sid)}>{strings.news}</NavLink>
                    <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.FACILITY.replace(":sid", props.match.params.sid)} >{strings.facility}</NavLink>
                    <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.INFORMATION.replace(":sid", props.match.params.sid)}>{strings.information}</NavLink>
                    {props.auth.stables.admin.hasOwnProperty(props.match.params.sid) &&   <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.SETTINGS.replace(":sid", props.match.params.sid)}>{strings.settings}</NavLink>}
                </div>
            );
        }

        return (
            <div className={"subMenu"}>
                <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.LATESTPOSTS.replace(":sid", props.match.params.sid)} >{strings.posts}</NavLink>
                <NavLink className="navLinkSub" activeClassName="navLinkSub-active" to={ROUTES.INFORMATION.replace(":sid", props.match.params.sid)}>{strings.information}</NavLink>
            </div>
        );
    }
    return null;
};

const SideBar = withRouter(WithAuthentication(SideBarComponent));
export default SideBar;
