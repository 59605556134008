import React, { Component } from 'react';
import {withRouter} from "react-router-dom";

class ContextMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {
        if (!!this.contextMenu && event.target !== this.contextMenu) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }

    render() {
        return (
            <div className={this.props.selectType ? this.props.selectType : "language-select"} onClick={this.showMenu}>
                {this.props.openingElement &&
                    this.props.openingElement
                }
                {this.props.infoText &&
                    <span >{this.props.infoText}</span>
                }
                <div className={this.props.overrideClassName || "contextmenu"}>
                    {
                        !this.props.openingElement &&
                        <img src={this.props.overrideIcon || "/img/icons/dark/contextmenu.png"}/>
                    }
                    {
                        this.state.showMenu &&
                        <div className={"drop_menu " + this.props.direction || "drop_menu"}
                             ref={(element) => {
                                 this.contextMenu = element;
                             }}>
                            {this.props.children}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ContextMenu);