import RepetitionInterval from "./repetition_interval";
import {getIntValuesForRepetition, getWeekdayForInt, isLeapYear} from "../additional/helpers";

export class Booking{
    bid;
    fid;
    sid;
    uid;

    deleted;
    approved;

    lanes;
    day;
    lastAt;
    start;
    end;

    notes;
    userName;

    horseIds;
    // trainingIds;

    repetitionInterval;
    repetitionWeek;
    repetitionDays;

    /**
     * @param {String} bid
     * @param {String} sid
     * @param {String} fid
     * @param {String} uid
     * @param {Date} day
     * @param {number} start
     * @param {number} end
     * @param {String} notes
     * @param {String} userName
     * @param {Array} horseIds
     * @param {Array} lanes
     * @param {String} repetitionInterval
     * @param {String} repetitionWeek
     * @param {Array} repetitionDays
     * @param {boolean} approved
     */
    constructor({bid = "", fid = "", sid = "", uid = "", day = 0, start = 0, end = 0, lastAt, notes = "", userName = "", horseIds = [], lanes = [], repetitionInterval,repetitionWeek, repetitionDays=[], approved} ={}) {
        this.bid = bid;
        this.sid = sid;
        this.fid = fid;
        this.uid = uid;
        this.day = day;
        this.start = start;
        this.end = end;
        this.lastAt = lastAt;
        this.notes = notes;
        this.userName = userName;
        this.horseIds = horseIds;
        this.lanes = lanes;
        this.deleted = false;
        this.repetitionInterval = repetitionInterval;
        this.repetitionWeek = repetitionWeek;
        this.repetitionDays = repetitionDays;
        this.approved = approved;
    }

    toJSON = () => {
        let data = {};

        if(!!this.bid) {
            data['bid'] = this.bid;
        }

        if(!!this.fid) {
            data['fid'] = this.fid;
        }

        if(!!this.sid) {
            data['sid'] = this.sid;
        }

        if(!!this.uid) {
            data['uid'] = this.uid;
        }

        if(!!this.lanes) {
            data['lanes'] = JSON.stringify(this.lanes.map((lane, i) => lane.toString()));
        }

        if(!!this.horseIds) {
            data['horseIds'] = JSON.stringify(this.horseIds);
        }

        if(!!this.day) {
            data['day'] = this.day.getTime().toString();
        }

        if(this.start !== undefined) {
            data['start'] = this.start.toString();
            data['startUtc'] = this.start.toString();
        }

        if(this.end !== undefined) {
            data['end'] = this.end.toString();
        }

        if(!!this.notes) {
            data['notes'] = this.notes;
        }

        if(!!this.userName) {
            data['userName'] = this.userName;
        }

        if(!!this.repetitionInterval && this.repetitionInterval !== RepetitionInterval.NEVER) {
            data['repInterval'] = this.repetitionInterval;
        }

        if(!!this.repetitionWeek) {
            data['repWeek'] = this.repetitionWeek;
        }

        if(!!this.repetitionDays) {
            data['repDays'] = JSON.stringify(this.repetitionDays);
        }

        return data;
    }

    /**
     * @param {Date} checkDate
     */
    isActiveAtDay= (checkDate) => {
        let start = new Date(this.day.getFullYear(), this.day.getMonth(), this.day.getDate());

        if (this.lastAt != null && (checkDate > this.lastAt)) {
            return false;
        }

        if (!this.repetitionInterval) {
            let startDayIsEqual =  checkDate.getDate() === start.getDate();
            let startMonthIsEqual =  checkDate.getMonth() === start.getMonth();
            let startYearIsEqual =  checkDate.getFullYear() === start.getFullYear();
            return (startDayIsEqual && startMonthIsEqual && startYearIsEqual);
        }
        return this._checkInterval(checkDate,start);
    };

    /**
     * @param {Date} checkDate
     * @param {Date} startDate
     */
    _checkInterval= (checkDate,startDate) =>  {
        if (this.repetitionInterval != null) {
            let euclidean = 1;

            switch (this.repetitionInterval) {
                case RepetitionInterval.NEVER:
                    let startDayIsEqual =  checkDate.getDate() === startDate.getDate();
                    let startMonthIsEqual =  checkDate.getMonth() === startDate.getMonth();
                    let startYearIsEqual =  checkDate.getFullYear() === startDate.getFullYear();
                    return (startDayIsEqual && startMonthIsEqual && startYearIsEqual);
                case RepetitionInterval.DAILY:
                    return checkDate > startDate ;
                case RepetitionInterval.WEEKLY:
                    euclidean =  getIntValuesForRepetition()[this.repetitionWeek];
                    const _msPerDay = 1000 * 60 * 60 * 24;
                    let dayDifference = Math.floor( Math.abs(checkDate-startDate) / _msPerDay);
                    let  weeks =  Math.floor(dayDifference/7);
                    return weeks % euclidean === 0 && this._checkWeekday(checkDate);
                case RepetitionInterval.MONTHLY:
                    return startDate.getDate() === checkDate.getDate() && checkDate > startDate;
                case RepetitionInterval.HALFYEARLY:
                    if (startDate.getMonth() === 2 && startDate.getDate() === 29 && !isLeapYear(checkDate.getFullYear()) && checkDate.getMonth() === 3 && checkDate.getDate() === 1 ) {
                        return true;
                    }
                    if(startDate.getMonth() === 8 && ((isLeapYear(checkDate.getFullYear()) && startDate.getDate() > 29) || startDate.getDate() > 28) && checkDate.getMonth() === 3 && checkDate.getDate() === 1) {
                        return true;
                    }
                    return startDate.getDate() === checkDate.getDate() && startDate.getMonth() % 6 === checkDate.getMonth() % 6 ;
                case RepetitionInterval.YEARLY:
                    if (startDate.getMonth() === 2 && startDate.getDate() === 29 && !isLeapYear(checkDate.getFullYear()) && checkDate.getMonth() === 3 && checkDate.getDate() === 1 ) {
                        return true;
                    }
                    return startDate.getDate() === checkDate.getDate() && startDate.getMonth() === checkDate.getMonth() ;
            }

            return false;
        }
        return false;
    };

    _checkWeekday= (checkDate) =>  {
        return this.repetitionDays.includes(getWeekdayForInt()[checkDate.getDay()]);
    }
}

export function createBookingFromFirestoreDocument(firestoreDocument){
    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        let booking = new Booking({
            bid: firestoreDocument.id,
            fid: data['facilityId'],
            sid: data['stableId'],
            uid: data['uid'],
            userName: data['userName'],
        });

        if (!!data['lanes']) {
            booking.lanes = data['lanes'];
        }
        if (!!data['day']) {
            booking.day = new Date(data['day']);
        }
        if (!!data['start']) {
            booking.start = data['start'];
        }
        if (!!data['end']){
            booking.end = data['end'];
        }
        if (!!data['notes']){
            booking.notes = data['notes'];
        }
        if (!!data['userName']){
            booking.userName = data['userName'];
        }
        if (!!data['horseIds']){
            booking.horseIds = data['horseIds'];
        }
        if (!!data['repInt']){
            booking.repetitionInterval = data['repInt'];
        }
        if (!!data['repWeek']){
            booking.repetitionWeek = data['repWeek'];
        }
        if (!!data['repDays']){
            booking.repetitionDays = data['repDays'];
        }
        if(data['approved'] !== undefined) {
            booking.approved = data['approved'];
        }

        return booking;
    }
    return undefined;
}
