import React from "react";
function Dot (props) {
    return (
        <span className="carousel-dots" style={{
            opacity: props.selected ? '1' : '0.3',
        }}
        />
    )
}

export  function IndicatorDots (props) {
    if (props.total < 2) {
        // Hide dots when there is only one dot.
        return <div className="carousel-dots-wrapper" />
    } else {
        return (
            <div className="carousel-dots-wrapper">{
                Array.apply(null, Array(props.total)).map((x, i) => {
                    return <Dot key={i} selected={props.index === i} />
                })}
                </div>
        )
    }
}


const styles = {
    left: {
        left: '0'
    },
    right: {
        right: '0'
    }
}

export function Buttons (props) {
    const { index, total, loop, prevHandler, nextHandler } = props;
    return (
        <div className="carousel-button-wrapper">
            { (loop || index !== 0) && (
                <div className="carousel-button left"  onClick={prevHandler}>
                    <img src="/img/icons/white/carouselButton.png" alt="Carousel Button" />
                </div>
            )}
            { (loop || index !== total - 1) && (
                <div className="carousel-button right" onClick={nextHandler}>
                    <img src="/img/icons/white/carouselButton.png" alt="Carousel Button" />
                </div>
            )}
        </div>
    )
}
