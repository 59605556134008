import React, {Component} from 'react';
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import {withRouter} from "react-router-dom";
import * as elasticSearchController from "../../../elastic/elastic_controller";
import debounce from "lodash.debounce";
import StableMemberCard from "../../../additional/stable_member_card";
import * as ROUTES from "../../../../routes";
import AreYouSureModal from "../../../additional/are_your_sure_modal";
import StableHeader from "../../header";
import LocalizationsWithLanguage from "../../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        searchPlaceholder:"Durchsuche $number Einsteller",
        boarders:"Einsteller",
        addBoarders:"Einsteller hinzufügen",
        remove: "Entfernen"
    },
    en: {
        searchPlaceholder:"Search through $number boarders",
        boarders:"Boarders",
        addBoarders:"Add boarders",
        remove: "Remove"
    }
});

class BoardersIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            boarderResults: undefined,
            loading: false,
            stable: undefined,
            admins: {},
            adminsLoaded: false,
            userToDelete: undefined,
            searchTerm: undefined,
            noMoreBoarders: false
        };

        window.onscroll = () => {
            this.triggerSearch(this.state.searchTerm);
        };
    }

    triggerSearch = debounce((term) => {
        if (this.state.loading || term.length === 1) return;
        this.setState({
            loading: true,
            searchTerm: term
        });
        this.search(term);
    }, 200);

    search = async (term) =>   {
        if(!this.state.noMoreBoarders) {
            this.setState(this.state);
            let loadedBoarderCount = 0;
            if(!!this.state.boarderResults) {
                loadedBoarderCount = this.state.boarderResults.users.length;
            }

            let userResult = await elasticSearchController.searchStableUsers(this.props.match.params.sid, term, loadedBoarderCount, 20);

            if(userResult.users.length >= 1) {
                if(!!this.state.boarderResults && this.state.boarderResults.users.length >= 1) {
                    userResult.users = this.state.boarderResults.users.concat(userResult.users);
                }

                this.setState({boarderResults: userResult, loading: false});
            } else {
                this.setState({noMoreBoarders: true, loading: false});
            }
        } else {
            this.setState({loading: false});
        }
    };

    loadAdminsForStable = async () => {
        let users = await this.props.firebase.stableController.loadAdminsForStable(this.props.match.params.sid);
        let that = this;
        users.forEach( function(user){
            that.state.admins[user.uid] =true;
        });
        this.setState({adminsLoaded:true});
    };

    removeUser = async (user) => {
        await this.props.firebase.stableController.removeUserFromStable( this.state.stable.sid, this.state.stable.name, user.uid );
        this.setState({userToDelete: undefined});
        this.state.boarderResults.users = this.state.boarderResults.users.filter(function(item, idx) {
            return item.uid !== user.uid;
        });
        this.setState(this.state);
    };

    async loadDisplayedStable() {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
                this.setState({ stable : stable});
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    componentDidMount() {
        this.loadAdminsForStable();
        this.loadDisplayedStable();
        this.triggerSearch("");
    }

    getUserOptions = (user) => {
        if (!this.state.stable || !this.state.adminsLoaded || this.state.admins.hasOwnProperty(user.uid)) {
            return null;
        }
        return (
            <a className="ml-1" onClick={() =>  this.setState({userToDelete: user})}>
                {strings.remove}
            </a>
        )
    };


    render() {
        let searchResults = <div></div>;

        if(!!this.state.boarderResults && this.state.boarderResults.total >0) {
            searchResults = <div className="list search-results">

                {
                    this.state.boarderResults.users.map((user, i) => {
                        return (
                            <StableMemberCard key={"user"+ user.uid} image={user.avatar} name={user.name} horses={user.horses} options={() => this.getUserOptions(user)}/>
                        )
                    })
                }

            </div>
        }

        return (
            <div>
                {
                    !!this.state.userToDelete ? <AreYouSureModal onConfirm={() => this.removeUser(this.state.userToDelete)} onClose={() => this.setState({userToDelete: undefined})}/> : null
                }

                <StableHeader name={!this.state.stable ? "" : this.state.stable.name} stables={this.props.auth.stables == null ? true : this.props.auth.stables.active}/>

                <div className="row mt-1">
                    <input

                        name="search"
                        className="search"
                        type="text"
                        onChange={(event) => this.triggerSearch(event.target.value)}
                        placeholder={!this.state.stable ? "" : strings.searchPlaceholder.replace("$number",this.state.stable.memberCount)}
                    />

                    <button className="width-50 ml-1" onClick={() => this.props.history.push(ROUTES.ADDSTABLEBOARDERS.replace(":sid", this.props.match.params.sid))}>
                        {strings.addBoarders}
                    </button>
                </div>

                {searchResults}
            </div>
        );
    }
};

export default withRouter(WithAuthentication(WithFirebase(BoardersIndex)));