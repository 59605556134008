import {getUserAvatarUrl} from "../additional/helpers";
import {elasticSearchHorseFromElasticJSON} from "./elastic_search_stable_horse";
import {ShallowUser} from "./shallow_user";

export class ElasticSearchStableUser extends ShallowUser{
    horses;
    isMember;

    constructor({name,uid, horses, isMember = true} = {}){
        super({name: name, avatar : getUserAvatarUrl(uid), uid: uid});
        this.horses = this.horses || [];
        this.isMember = isMember;
    }

}


function validateElasticJSON(json) {
    return !!json
        && !!json["name"]
        && !!json["id"];
}

export function elasticSearchStableUserFromElasticJSON(json) {
    if (validateElasticJSON(json)) {
        let user = new ElasticSearchStableUser( {
            uid: json['id'],
            name: json['name']
        });
        if( !!json['horses'] ) {
            json['horses'].forEach( function(horse) {
                user.horses.push(elasticSearchHorseFromElasticJSON(horse));
            });
        }
        return user;
    }
    return null;
}