import React,{Component} from "react";
import LocalizationsWithLanguage from "./localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        comingSoon:"Diese Funktion ist bald verfügbar!",
    },
    en: {
        comingSoon:"This page is coming soon. Stay tuned!",
    }
});

class ComingSoon extends Component {
    render() {
        return(
            <p>{strings.comingSoon}</p>
        );
    }
}

export default ComingSoon;