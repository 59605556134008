import React from "react";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de: {
        errorMessage: "Es tut uns leid, es ist ein unbekannter Fehler aufgetreten.",
        tryAgainLater: "Bitte versuche es zu einem späteren Zeitpunkt erneut."
    },
    en: {
        errorMessage: "We're sorry, an unknown error has occured.",
        tryAgainLater: "Please try again later."
    }
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: "" };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, errorInfo: info });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-page">
                    <h1>{strings.errorMessage}</h1>
                    <p>{strings.tryAgainLater}</p>
                    <small className="error-message">{this.state.errorInfo.componentStack}</small>
                    <img className="error-image" src={"/img/jutta-stopp.png"}/>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;