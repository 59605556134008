import React, {Component} from 'react';
import {WithAuthentication} from "../firebase/auth_provider";
import StableCard from "../stables/stable_card";
import {withRouter} from "react-router-dom";
import * as ROUTES from '../../routes'
import AuthSearchStables from "./search_stables";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        requested:"Angefragt",
        invited:"Eingeladen",
        stableOnboardingHeader:"Du bist noch keinem Stall beigetreten!",
        stableOnboardingText:"Werde jetzt Mitglied eines Stalls und lass die Kreidetafel hinter dir. Ob schwarzes Brett oder Anlagenplan, Jutta! ist euer täglicher Helfer.",
        stableEmptySearchHeader:"Kein Ergebnis!",
        stableEmptySearchText:"Wir konnten leider nicht finden wonach du suchst.",
        createStable:"Stall anlegen",
        showStable: "Stall ansehen",
        stableOnboardingImageAlt: "Stall Einführungsbild",
        results: "Folgende Ställe haben wir gefunden",
        addstable: "Dein Stall ist nicht dabei?",
        search: "Suchen"
    },
    en: {
        requested:"Requested",
        invited:"Invited",
        stableOnboardingHeader:"Let's go!",
        stableOnboardingText:"Start by joining a stable and leave the chalk board behind!",
        stableEmptySearchHeader:"No result found!",
        stableEmptySearchText:"We couldn't find what you are looking for.",
        createStable:"Create stable",
        showStable: "Show stable",
        stableOnboardingImageAlt: "Stable onboarding image",
        results: "We found the following stables",
        addstable: "Didn't find your stable?",
        search: "Search"
    }
});


class StableOverview extends Component{

    constructor(props) {
        super(props);
        this.state = {
            showStables: true,
            searchResults: {},
        }
    }

    getAuthStables = (stables,requestedStables,invitedStables) => {
        let hasStables = stables.length >0;

        return (
            <div>
                <div className="stables-overview">
                    {stables.map((stable, i) => {
                        return (
                            <StableCard key={"stable"+ stable.sid} image={stable.header} title={stable.name} linktext={strings.showStable} link={`/stables/${stable.sid}/latestposts`}/>
                        )
                    })}
                </div>

                {invitedStables.length > 0 ? <h3 className="stable-overview-header"> {strings.invited} </h3> : ""}
                <div className="stables-overview">
                    {invitedStables.map((stable, i) => {
                        return (
                            <StableCard key={"invitedStable"+ stable.sid} image={stable.header} title={stable.name} linktext={strings.showStable} link={`/stables/${stable.sid}/information`}/>
                        )
                    })}
                </div>

                {requestedStables.length > 0 ? <h3 className="stable-overview-header"> {strings.requested} </h3> : ""}

                <div className="stables-overview">
                    {requestedStables.map((stable, i) => {
                        return (
                            <StableCard key={"requestedStable"+ stable.sid} image={stable.header} title={stable.name} linktext={strings.showStable} link={`/stables/${stable.sid}/information`}/>
                        )
                    })}
                </div>

                {!hasStables &&
                <div className="stable-onboarding">
                    <img src="/img/jutta-stall.png" alt={strings.stableOnboardingImageAlt}/>
                    <b>{strings.stableOnboardingHeader}</b>
                    <p>{strings.stableOnboardingText}</p>
                </div>
                }
            </div>
        )
    };

    getSearchResults= () =>  {
        if (!!this.state.searchResults && !!this.state.searchResults.stables && this.state.searchResults.stables.length > 0) {
            return (<div>
                <h3 className="stable-overview-header"> {strings.results} </h3>
                <div className="stables-overview">
                {this.state.searchResults.stables.map((stable, i) => {
                    return (
                        <StableCard key={"requestedStable"+ stable.sid} image={stable.header} title={stable.name} linktext="Stall ansehen" link={`/stables/${stable.sid}/information`}/>
                    )
                })}
                </div>
            </div>)
        }
        if (!!this.state.searchResults && !!this.state.searchResults.stables ) {
            return (<div className="stable-onboarding">
                <img src="/img/jutta-stall.png" alt={strings.stableOnboardingImageAlt}/>
                <b>{strings.stableEmptySearchHeader}</b>
                <p>{strings.stableEmptySearchText}</p>
            </div>);
        }

        return null;
    };

    render() {
        let stables = [];
        let createdStableCount = 0;

        let requestedStables = [];
        let invitedStables = [];
        if (this.props.auth.stables) {
            for (let stable of Object.values(this.props.auth.stables.active)) {
                stables.push(stable);
                if (stable.creator === this.props.auth.firebaseUser.uid) {
                    createdStableCount++;
                }
            }
        }

        let content = this.getSearchResults();
        if (!content) {
            for (let stable of Object.values(this.props.auth.stables.requested)) {
                requestedStables.push(stable);
            }

            for (let stable of Object.values(this.props.auth.stables.invited)) {
                invitedStables.push(stable);
            }
            content = this.getAuthStables(stables,requestedStables,invitedStables);
        }

        return (
            <div>
                <div className="row mb-1 mt-1">
                    <AuthSearchStables hideResults={true} amount={8} onSearch={(results) => this.setState({searchResults: results })}/>
                </div>
                {content}
                { createdStableCount < 3 && !!this.state.searchResults.stables ?
                    <div>
                        <h3 className="stable-overview-header"> {strings.addstable} </h3>
                        <div className="stables-overview">
                            <StableCard linktext="Stall hinzufügen" link={ROUTES.CREATESTABLE} addcard={true}/>
                        </div>
                    </div>
                    : ""
                }
            </div>
        )
    }

}

export default withRouter(WithAuthentication(StableOverview));