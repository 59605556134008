import React from "react";
import LocalizationsWithLanguage from "../localization_wrapper";

const BlockRoute = React.createContext(null);

let strings = new LocalizationsWithLanguage({
    de:{
        unsavedChanges: "Du hast ungespeicherte Änderungen. Willst Du die Seite verlassen?",
    },
    en: {
        unsavedChanges: "You have unsaved changes. Do you want to leave this page?",
    }
});

function BlockRouteProvider(WrappedComponent) {
    return class extends React.PureComponent {
        canLeaveRoute;
        unblock;
        leaveRouteMessage;

        /**
         * @param {Function} func
         * */
        setCanLeaveRoute = (func) => {
            this.canLeaveRoute = func;
        };

        setLeaveRouteMessage = (message) => {
            this.leaveRouteMessage = message;
        };

        componentDidMount() {
            this.props.history.listen((location, action) => {
                 this.canLeaveRoute = undefined;
                 this.leaveRouteMessage = undefined;
            });

            this.unblock = this.props.history.block(() => {
                if (!!this.canLeaveRoute && !this.canLeaveRoute()) {
                    if (!!this.leaveRouteMessage) {
                        return this.leaveRouteMessage;
                    } else {
                        return strings.unsavedChanges;
                    }
                }
            });
        }

        componentWillUnmount() {
            if (!!this.unblock) {
                this.unblock();
            }
        }

        render() {
            return (
                <BlockRoute.Provider value = { {
                    'setCanLeaveRoute': this.setCanLeaveRoute,
                    'setLeaveRouteMessage': this.setLeaveRouteMessage,
                } }>
                    <WrappedComponent {...this.props} />
                </BlockRoute.Provider>
            )
        }
    };
}

function WithBlockRoute(WrappedComponent) {
    return class extends React.PureComponent {
        render() {
            return (
                <BlockRoute.Consumer>
                    {blockRoute => <WrappedComponent {...this.props} blockRoute={blockRoute}/>}
                </BlockRoute.Consumer>
            )
        }
    };
}

export default BlockRouteProvider;

export {WithBlockRoute};