import React from "react";

const LoadingIndicator= (props) => {
    return (
        <div className={(!!props.backgroundClass ? props.backgroundClass : "")}>
            <div className={"loading-indicator " + (!!props.small ? "loading-indicator-small" : "" )}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default LoadingIndicator;