import React from "react";
import LikeType from "../models/live_type";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        like:"Gefällt mir",
        dislike:"Gefällt mir nicht",
        comments:"Kommentare",
        comment:"Kommentieren",
        altLike:"Like-Button der Jutta! App",
        altDislike:"Dislike-Button"
    },
    en: {
        like:"Like",
        dislike:"Dislike",
        comments:"comments",
        comment:"Comment",
        altLike:"Like button",
        altDislike:"Dislike button"
    }
});

function updateLikeType(props, likeType) {
    let post = props.post;
    post = props.firebase.postController.changeUserLikeTypeForPost(props.auth.user,post,post.userLikeType,likeType);
    props.auth.reactions.changePostReaction(post.pid,post.userLikeType);
    props.onChange();
}


const PostReactions= (props) =>
    <div className="post-reaction">
        <div className="btn"  onClick={() => updateLikeType(props, LikeType.LIKE)}>
            <img className="icon"
                 alt={strings.altLike}
                 src={
                     props.post.userLikeType === LikeType.LIKE
                     ?'/img/icons/primary/like.png' : '/img/icons/dark/like.png'}
            />
            <span>{strings.like}</span>
        </div>
        <div className="btn" onClick={() => updateLikeType(props, LikeType.DISLIKE)}>
            <img className="icon"
                 alt={strings.altLike}
                 src={
                     props.post.userLikeType === LikeType.DISLIKE
                     ?'/img/icons/primary/dislike.png' : '/img/icons/dark/dislike.png'
                 }/>
            <span>{strings.dislike}</span>
        </div>
        <div className="btn">
            <img className="icon" alt={strings.altLike} src='/img/icons/dark/comments.png'/>
            <span>{props.post.commentCount > 0 ? props.post.commentCount + " " + strings.comments : strings.comment}</span>
        </div>
    </div>;

export default PostReactions;