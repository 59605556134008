import {
    getStableHeaderUrl,
    getStableImageUrl,
} from "../additional/helpers";
import {stableContactFromJSON} from "./stable_contact";
import {stableLocationDataFromJSON} from "./stable_location";
import StablePermission from "./stable_permissions";

export class Stable{
    sid;
    name;
    header;
    creator;
    memberCount;
    requestCount;

    about;
    offer;
    farmingSystem;
    contacts;
    permissions;
    features;
    images;
    pro;
    proUntil;
    newPro;

    locationData;

    facilities;
    customMemberColors;
    // Map<String, StableContact> contacts;
    // Map<int, ContentMedia> images; // Map<unique ID, Image>
    //
    // LocationData locationData;
    // Map<String, StableUser> admins;
    // Map<String, StableUser> pendingAdmins;
    // Map<String, StableUser> members;
    // List<StableUser> orderedMembers;


    isRemoved;
    // DateTime joined;

    bookingsPermission;
    bookingsRepeatingPermission;

    /**
     * @param {String} sid
     * @param {String} name
     * @param {String} header
     * @param {String} creator
     * @param {number} memberCount
     * @param {number} requestCount
     * @param {String} about
     * @param {String} offer
     * @param {String} farmingSystem
     * @param {Object} facilities
     * @param {Array} features
     * @param {Object} location
     * @param {String} bookingsPermission
     * @param {String} bookingsRepeatingPermission
     * @param {boolean} pro
     * @param {Date} proUntil
     */
    constructor( {sid="",name ="",header="", creator = "", memberCount = 0,requestCount= 0,
                     about = "",offer ="",farmingSystem = "", facilities = {}, permissions = [], bookingsPermission = undefined, bookingsRepeatingPermission = undefined, location = undefined, customMemberColors = {}, pro = false, proUntil = undefined, newPro = false} ={}) {
        this.sid = sid;
        this.name = name;
        this.header = header;
        this.creator = creator;
        this.memberCount = memberCount;
        this.requestCount = requestCount;
        this.locationData = location;
        this.permissions = permissions;
        this.bookingsPermission = permissions;
        this.bookingsRepeatingPermission = permissions;
        this.about = about;
        this.offer = offer;
        this.farmingSystem = farmingSystem;
        this.facilities = facilities;
        this.customMemberColors = customMemberColors;
        this.pro = pro;
        this.proUntil = proUntil;
        this.newPro = newPro;
    }

   hasPro = () => this.pro && (!this.proUntil || this.proUntil > new Date());

   toJSON = () => {
        let data = this.locationData.toJSON();

       data['name'] = this.name;

        if(!!this.sid) {
            data['stableId'] = this.sid;
        }

        if(!!this.about) {
            data['about'] = this.about;
        }

        if(!!this.offer) {
            data['offer'] = this.offer;
        }

        if(!!this.farmingSystem) {
            data['farmingSystem'] = this.farmingSystem;
        }

        if (!!this.permissions) {
            data['permissions'] = JSON.stringify(this.permissions);
        }


       if (!!this.bookingsPermission) {
           data['bookingsPermission'] = this.bookingsPermission;
       }

       if (!!this.bookingsRepeatingPermission) {
           data['bookingsRepeatingPermission'] = this.bookingsRepeatingPermission;
       }

        if(!!this.contacts) {
            let contacts = {};
            for (const [key, contact] of Object.entries(this.contacts)) {
                contacts[parseInt(key)] = contact.toJSON();
            }
            data['contacts'] = JSON.stringify(contacts);
        }

        if(!!this.images ) {
            let images = [];

            for(const imageKey of Object.keys(this.images)) {
                images.push(parseInt(imageKey));
            }

            data['images'] =  JSON.stringify(images);
        }

        return data;
    };

    membersCanPostNews = () => {
        return this.permissions.includes(StablePermission.CLIENTSCANWRITENEWS);
    };

    membersCanReactToNews = () => {
        return this.permissions.includes(StablePermission.CLIENTSCANREACTTONEWS);
    };

}

export function createStableFromFirestoreDocument(firestoreDocument){
    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        let stable = new Stable( {
            sid: firestoreDocument.id,
            name: data['name'] || "",
            header: getStableHeaderUrl(firestoreDocument.id),
            facilities: {}
        });

        if (data['memberCount'] != null) {
            stable.memberCount = data['memberCount'];
        }

        if (data['creator'] != null) {
            stable.creator = data['creator'];
        }

        if (!!data['requestCount']) {
            stable.requestCount = data['requestCount'];
        }
        if (!!data['about']){
            stable.about = data['about'];
        }
        if (!!data['offer']){
            stable.offer = data['offer'];
        }
        if (!!data['system']){
            stable.farmingSystem = data['system'];
        }

        if(!!data['features']) {
            stable.features = data['features'];
        }

        if(!!data['permissions'] ) {
            stable.permissions = data['permissions'];
        }

        if (!!data['bookingsPermission']) {
            stable.bookingsPermission = data['bookingsPermission'];
        }

        if (!!data['bookingsRepeatingPermission']) {
            stable.bookingsRepeatingPermission =  data['bookingsRepeatingPermission'];
        }

        if (!!data['fullAddress'] ) {
            stable.locationData = stableLocationDataFromJSON( data);
        }

        stable.contacts = [];
        if(!!data['contacts']) {

            for (let [key, contact] of Object.entries( data['contacts'])) {
                stable.contacts.push(stableContactFromJSON(key,contact,stable.sid));
            }
        }

        stable.images = {};
        if(!!data['images']) {
            for (let image of Object.values( data['images'])) {
                stable.images[image] = getStableImageUrl(stable.sid,image);
            }
        }

        if(!!data['pro']) {
            stable.pro = data['pro'];
        }

        if(!!data['proUntil']) {
            stable.proUntil = new Date(data['proUntil']);
        }

        if(!!data['newPro']) {
            stable.newPro = data['newPro'];
        }

        return stable;
    }
    return undefined;
}


