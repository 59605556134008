class SharedLink {
    title;
    description;
    image;

    constructor({title: title, description: description, image: image} = {}){
        this.title = title;
        this.description = description;
        this.image = image;
    };

    toJSON= () =>  {
        return {
            'title' : this.title,
            'description' : this.description,
            'image': this.image
        };
    };

}

export function createSharedLinkFromJSON(json){
    return new SharedLink({
        title: json['title'],
        description: json['description'],
        image: json['image'],
    });
};