import React from 'react';
class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state =  {
            shownOnce: false,
        };
    }

    stopEvent = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    closeEvent= (event) => {
        this.props.onClose();
        this.stopEvent(event);
    };

    render() {

        if(!this.props.show && !this.state.shownOnce) {
            return null;
        }
        this.state.shownOnce = true;

        return (
            <div className="modal" onClick={this.closeEvent} style={this.props.show ? null : {display: "none"} }>
                <div className={"modal-close-wrapper"}>
                </div>
                <div className="modal-content" onClick={this.stopEvent}>
                    <div className="modal-close" onClick={this.closeEvent}>×</div>

                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Modal;