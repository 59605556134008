import React, {Component} from 'react';

class HorseCard extends Component {

    imgHasFailed = false;

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        return (
                <div className="list-tile">
                    <img className="horse-avatar" src={this.props.image} onError={this.onImageLoadFail}/>
                    <span>{this.props.name}</span>
                </div>
        )

    }
}

export default HorseCard;