import React, {Component} from 'react';
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import {withRouter} from "react-router-dom";
import * as elasticSearchController from "../../../elastic/elastic_controller";
import debounce from "lodash.debounce";
import UserStatus from "../../../models/user_status";
import UserCard from "../../../additional/user_card";
import StableHeader from "../../header";
import LocalizationsWithLanguage from "../../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        searchPlaceholder:"Suche nach Nutzern, die du deinem Stall hinzufügen möchtest!",
        invited: "Eingeladen",
        accept: "Annehmen",
        banned: "Gebannt",
        invite: "Einladen",
        noResults: "Keine Resultate",
    },
    en: {
        searchPlaceholder:"Search for users to add them to your stable!",
        invited: "Invited",
        accept: "Accept",
        banned: "Banned",
        invite: "Invite",
        noResults: "No results",
    }
});

class AddBoardersIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userResult: [],
            userStatus: {},
            stable: undefined,
            loading: false,
        }
    }

    triggerSearch = debounce((term) => {
        if (this.state.loading || term.length < 2 ) return;

        this.setState({
            loading: true
        });
        this.search(term);
    }, 200);

    search = async (term) =>   {
        this.setState(this.state);
        let userResult = await elasticSearchController.searchUser(term);
        this.setState({userResult: userResult.users,loading: false});

        let that = this;
        userResult.users.forEach(async function (user){
           if (!that.state.userStatus.hasOwnProperty(user.uid)) {
               let isMember = await that.props.firebase.stableController.isMemberOfStable(that.props.match.params.sid, user.uid);
               that.state.userStatus[user.uid] = isMember ? UserStatus.MEMBER : UserStatus.NONE;
               that.setState(that.state);
           }
        });

    };

    loadInvites =  async () => {
        let users = await this.props.firebase.stableController.loadOpenInvitesForStable(this.props.match.params.sid);
        let that = this;
        users.forEach( function(user){
            that.state.userStatus[user.uid] = UserStatus.INVITED;
        });
    };

    loadRequests = async () => {
        let users = await this.props.firebase.stableController.loadOpenRequestsForStable(this.props.match.params.sid);
        let that = this;
        users.forEach( function(user){
            that.state.userStatus[user.uid] = UserStatus.REQUESTED;
        });
    };

    loadBans = async () => {
        let users = await this.props.firebase.stableController.loadBansForStable(this.props.match.params.sid);
        let that = this;
        users.forEach( function(user){
            that.state.userStatus[user.uid] = UserStatus.BANNED;
        });
    };

    removeUser = async (user) => {
        await this.props.firebase.stableController.removeUserFromStable( this.props.match.params.sid, this.state.stable.name, user.uid );
        this.state.userStatus[user.uid] = UserStatus.NONE;
        this.setState(this.state);
    };


    acceptRequest(user) {
        this.props.firebase.stableController.inviteUserToStable(this.props.match.params.sid,this.props.auth.user.name,user.uid, user.name );
        this.state.userStatus[user.uid] = UserStatus.MEMBER;
        this.setState(this.state);
    }

    inviteUser(user) {
        this.props.firebase.stableController.inviteUserToStable(this.props.match.params.sid,this.props.auth.user.name,user.uid, user.name );
        this.state.userStatus[user.uid] = UserStatus.INVITED;
        this.setState(this.state);
    }

    loadDisplayedStable() {
        this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
            this.setState({ stable : stable});
        });
    }

    componentDidMount() {
        this.loadInvites();
        this.loadRequests();
        this.loadBans();
        this.loadDisplayedStable();
    }

    getOptions(user) {

        if (!this.state.stable || this.state.userStatus[user.uid] === UserStatus.MEMBER) {
            return null;
        }


        if (this.state.userStatus[user.uid] === UserStatus.NONE) {
            return (
                <button onClick={() => this.inviteUser(user)}>
                    {strings.invite}
                </button>
            )
        }

        if (this.state.userStatus[user.uid] === UserStatus.BANNED) {
            return (
                <button className="primary-button-invalid" onClick={() => {}}>
                    {strings.banned}
                </button>
            )
        }

        if (this.state.userStatus[user.uid] === UserStatus.REQUESTED) {
            return (
                <button onClick={() => this.acceptRequest(user)}>
                    {strings.accept}
                </button>
            )
        }

        if (this.state.userStatus[user.uid] === UserStatus.INVITED) {
            return (
                <button className="secondary-button" onClick={() => {this.removeUser(user)}}>
                    {strings.invited}
                </button>
            )
        }

    }

    render() {
        let searchResults = <div className="mt-1">
            <small>{strings.noResults}</small>
        </div>;

        if(!!this.state.userResult && this.state.userResult.length >0) {
            searchResults = <div className="search-results">

                {
                    this.state.userResult.map((user, i) => {
                        return (
                            <UserCard key={"user"+ user.uid} image={user.avatar} name={user.name} options={() => this.getOptions(user)}/>
                        )
                    })
                }

            </div>
        }

        return (
            <div>
                <StableHeader name={this.state.stable == null ? "" : this.state.stable.name} stables={this.props.auth.stables == null ? true : this.props.auth.stables.active}/>

                <div className="top-bar-search">
                    <input
                        autoFocus

                        name="search"
                        className="search"
                        type="text"
                        onChange={(event) => this.triggerSearch(event.target.value)}
                        placeholder={strings.searchPlaceholder}
                    />

                    {searchResults}

                </div>
            </div>
        );
    }
};

export default withRouter(WithAuthentication(WithFirebase(AddBoardersIndex)));