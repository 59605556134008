import React from 'react';
import {getUserAvatarUrl, hourNumberToTimeOfDay, secondsToHours} from "../../additional/helpers";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        date: "Datum",
        locale: "de-DE",
        startTime: "Startzeit",
        endTime: "Endzeit",
        description: "Beschreibung",
        close: "Schließen",
        delete: "Löschen",
        edit: "Bearbeiten",
        userImageAlt: "Profilbild"
    },
    en: {
        date: "Date",
        locale: "en-US",
        startTime: "Start time",
        endTime: "End time",
        description: "Description",
        close: "Close",
        delete: "Delete",
        edit: "Edit",
        userImageAlt: "User image"
    }
});

class BookingPreview extends React.Component {
    dateFormatOptions = {weekday: "long", year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' };
    timeFormatOptions = {hour: "2-digit", minute: "2-digit"};

    stopEvent = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    constructor(props) {
        super(props);

        this.state = {
            showDelete: false,
            showEditBooking: false
        };
    }

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        if(!this.props.booking) {
            return null;
        }

        let date = new Date();
        let timeZoneOffset = date.getTimezoneOffset();

        let jan = new Date(date.getFullYear(), 0, 1);
        let jul = new Date(date.getFullYear(), 6, 1);
        let stdTimezoneOffset =  Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

        if (timeZoneOffset < stdTimezoneOffset) {
            timeZoneOffset= timeZoneOffset+60;
        }


        return (
            <div id={"bookingPreview" + this.props.booking.bid} className="booking-preview" style={{borderColor: this.props.color, left: (this.props.offsetY || 0 )+ "px"}}>
                <div className="booking-preview-row">
                    <img src={getUserAvatarUrl(this.props.booking.uid)} className="modal-profile-image" onError={this.onImageLoadFail} alt={strings.userImageAlt}/>
                    <div className="booking-preview-info ">
                        <div className="row mb-05">
                            <img src={"/img/icons/dark/person.png"}/>
                            <span>{this.props.booking.userName}</span>
                        </div>
                        <span className="booking-preview-info-grey">{(new Date((this.props.booking.start + timeZoneOffset * 60) * 1000)).toLocaleString(strings.locale, this.timeFormatOptions)} - {(new Date((this.props.booking.end + timeZoneOffset * 60) * 1000)).toLocaleString(strings.locale, this.timeFormatOptions)} ({hourNumberToTimeOfDay(secondsToHours(this.props.booking.end) - secondsToHours(this.props.booking.start))}h)</span>
                    </div>
                </div>
                { this.props.booking.notes.length > 0 ? <div className="booking-preview-row mt-1">
                    <div>{this.props.booking.notes}</div>
                </div> : <div></div> }
            </div>
        );
    }
}

export default BookingPreview;