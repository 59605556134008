import RepetitionInterval from "../models/repetition_interval";
import LocalizationsWithLanguage from "../localization_wrapper";
import Weekday from "../models/weekday";

let strings = new LocalizationsWithLanguage({
    de:{
        repetitionIntervalNever: "Nie",
        repetitionIntervalDaily: "Täglich",
        repetitionIntervalWeekly: "Wöchentlich",
        repetitionIntervalMonthly: "Monatlich",
        repetitionIntervalHalfYearly: "Halbjährlich",
        repetitionIntervalYearly: "Jährlich",

        monday: "Montag",
        tuesday: "Dienstag",
        wednesday: "Mittwoch",
        thursday: "Donnerstag",
        friday: "Freitag",
        saturday: "Samstag",
        sunday: "Sonntag"
    },
    en: {
        repetitionIntervalNever: "Never",
        repetitionIntervalDaily: "Daily",
        repetitionIntervalWeekly: "Weekly",
        repetitionIntervalMonthly: "Monthly",
        repetitionIntervalHalfYearly: "Halfyearly",
        repetitionIntervalYearly: "Yearly",

        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday"
    }
});

export function getTranslationsForRepetitionInterval() {
    return {
        [RepetitionInterval.NEVER]: strings.repetitionIntervalNever,
        [RepetitionInterval.DAILY]: strings.repetitionIntervalDaily,
        [RepetitionInterval.WEEKLY]: strings.repetitionIntervalWeekly,
        [RepetitionInterval.MONTHLY]: strings.repetitionIntervalMonthly,
        [RepetitionInterval.HALFYEARLY]: strings.repetitionIntervalHalfYearly,
        [RepetitionInterval.YEARLY]: strings.repetitionIntervalYearly,
    }
}

export function getWeekdays() {
    return {
        [Weekday.MONDAY]: strings.monday,
        [Weekday.TUESDAY]: strings.tuesday,
        [Weekday.WEDNESDAY]: strings.wednesday,
        [Weekday.THURSDAY]: strings.thursday,
        [Weekday.FRIDAY]: strings.friday,
        [Weekday.SATURDAY]: strings.saturday,
        [Weekday.SUNDAY]: strings.sunday,
    }
}