import React, {Component} from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {dateIsSameDayAs} from "../../additional/helpers";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        today: "Heute",
        locale: "de-DE"
    },
    en: {
        today: "Today",
        locale: "en-US"
    }
});

class DateSelector extends Component {
    dateFormatOptions = {year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' };
    today = new Date();

    changeDate(days) {
        let newDate = this.props.date;
        newDate.setDate(newDate.getDate() + days);
        this.props.onDateChange(newDate);
    }

    getDateButton() {
        return (
            <div className="button-select-day">
                <span>{this.props.date.toLocaleString(strings.locale, this.dateFormatOptions)}</span>
            </div>
        )
    }

    render() {
        return (
            <div className="date-selector">
                <div className="button-change-day" onClick={() => this.changeDate(-1)}>
                    <span>{"<"}</span>
                </div>
                <div className={dateIsSameDayAs(this.props.date, this.today) ? "button-today-active" : "button-today-inactive"} onClick={() => this.props.onDateChange(new Date())}>
                    <span>{strings.today}</span>
                </div>
                <DatePicker selected={this.props.date} onChange={(date) => this.props.onDateChange(date)} customInput={this.getDateButton()}/>
                <div className="button-change-day" onClick={() => this.changeDate(1)}>
                    <span>{">"}</span>
                </div>
            </div>
        )
    }
}

export default DateSelector;