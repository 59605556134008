import React, {Component, Fragment} from 'react';
import {SignUpButton} from "./sign_up";
import * as ROUTES from "../../routes";
import {WithFirebase} from "../firebase/firebase";
import {WithAuthentication} from "../firebase/auth_provider";
import AuthType from "../models/authtype";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        title:"Jutta! ist dein täglicher Stallbegleiter.",
        signIn:"Anmelden",
        emailPlaceholder:"Email",
        passwordPlaceholder:"Passwort",
        welcomeBack:"Willkommen zurück!",
        welcomeBackDescription:"Melde Dich jetzt mit Deiner Email-Addresse und Deinem Passwort an, um Jutta! weiter zu nutzen.",
        createAccount:"Erstelle jetzt kostenlos einen Account!",
        login:"Anmelden",
        noAccount:"Noch nicht bei Jutta?",
        useSocialNetwork:"Oder mit einem sozialen Netzwerk anmelden!",
        description: "Melde Dich jetzt kostenlos an und verwalte Deine Pferde zusammnen mit Deinen Mitreitern.",
        download: "Herunterladen",
        downloadHint: "Hol dir Jutta! jetzt kostenlos für Android und iOS!",
        installApp: "App installieren",
        firstTime: "Zum ersten mal hier?",
        register: "Kostenlos registrieren",
        forgotPassword: "Passwort vergessen?",
        resetPassword: "Passwort zurücksetzen",
    },
    en: {
        title:"Jutta! is your stable companion.",
        signIn:"Sign in",
        emailPlaceholder:"Email address",
        passwordPlaceholder:"password",
        welcomeBack:"Welcome back!",
        welcomeBackDescription:"Login now with your email and password to continue using Jutta",
        createAccount:"Create one for free!",
        login:"Login",
        noAccount:"You don't have an account?",
        useSocialNetwork:"Or use a social network account!",
        description: "Register for free and manage your horses together with your friends.",
        download: "Download",
        downloadHint: "Get Jutta! for free for your smartphone!",
        installApp: "Install app",
        firstTime: "Your first time here?",
        register: "Register for free",
        forgotPassword: "Forgot password?",
        resetPassword: "Reset password",
    }
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInWrapper extends Component {
    render() {
        return (
            <Fragment>
                { this.showMobile() }
                { this.showDesktop() }
            </Fragment>
        )
    }

    showMobile() {
        return (
            <div className={"mobile"}>
                <div className={"download-hint"}>
                    <p>{strings.downloadHint}</p>
                    <button>{strings.download}</button>
                </div>

                <div className="mobile-content">
                    <img className="sign-in-logo" src={"/img/jutta-logo-mobile.png"} alt="Jutta! Pferdeapp Logo"/>
                    <h1>{strings.title}</h1>
                    <p>{strings.description}</p>

                    <SignUpButton/>

                    <div id={"mobile-options"}>
                        <div className="hr-sect">{strings.alreadyAccount}</div>
                        <SignInOptions {...this.props}/>
                    </div>
                </div>
            </div>
        );
    }

    showDesktop() {
        return (<div className="auth desktop">
            <div className="sign-in-options" id="sign-in-options-login">
                <div id={"loginwrapper"}>
                    <img className="sign-in-logo" src={"/img/jutta-mit-kreis-ohne-claim.png"} alt="Jutta! Pferdeapp Logo"/>

                    <div className="createNew">
                        <h4>{strings.title}</h4>
                        <SignInWithEmailForm {...this.props}/>
                    </div>


                    <div className={"link-text"} onClick={() => this.props.history.push(ROUTES.RESET_PASSWORD)}>{strings.forgotPassword}</div>

                    <div className="sign-in-options-wrapper">
                        <div className="hr-sect">{strings.useSocialNetwork}</div>
                        <SignInOptions {...this.props}/>
                    </div>
                </div>

                <div className="sign-in-options-wrapper" id="registerwrapper">
                    <div className="hr-sect">{strings.firstTime}</div>
                    <button onClick={() => this.props.history.push(ROUTES.SIGN_UP)}>{strings.register}</button>
                </div>

            </div>
            <div className="sign-in-options" id="smartphoneapphint">
            <img className="image"
                 srcSet="/img/sm/trainingsmanagement.jpg 320w,
                     /img/md/trainingsmanagement.jpg 580w,
                     /img/lg/trainingsmanagement.jpg 800w,
                     /img/xl/trainingsmanagement.jpg 1200w"
                 sizes="(max-width: 576px) 576px,
                    (max-width: 768px) 768px,
                    (max-width: 992px) 992px,
                    1200px"
                 src="/img/xl/trainingsmanagement.jpg" alt="Training and appointments"/>
            <div className="hr-sect">{strings.installApp}</div>
            <div className="storebuttons">
                <a href="https://play.google.com/store/apps/details?id=app.jutta&hl=de" target="_blank"><img className="storebutton storebuttonleft" src="/img/play-store-button.png" alt="Get Jutta! on Google Play Store"/></a>
                <a href="https://itunes.apple.com/us/app/jutta/id1398870886?l=de&ls=1&mt=8" target="_blank"><img className="storebutton" src="/img/app-store-button.png" alt="Get Jutta! on Apple App Store"/></a>
            </div>
            </div>
        </div>);
    }
}

class SignInWithEmailForm extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    onLogin = event => {
        this.props.auth.setLastUsedAuthType(AuthType.EMAIL);
        const { email, password } = this.state;
        this.props.firebase
            .signInWithEmailAndPassword(email, password)
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    render() {
        const { email, password, error } = this.state;
        let isInvalid = !email || !password;

        return (
            <div className={"sign-in-container"}>
                <form id="sign-in-form" onSubmit={this.onSubmit}>
                    <input
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        placeholder={strings.emailPlaceholder}
                    />
                    <input
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        placeholder={strings.passwordPlaceholder}
                    />
                    <button onClick={this.onLogin}>{strings.login}</button>

                    {this.state.error &&
                    <div className="error-message mt-1">
                        {this.state.error.message}
                    </div>
                    }
                </form>
            </div>
        );
    }
}
const SignInWithMail = WithAuthentication(WithFirebase(SignInWithEmailForm));

class SignInWithEmailPage extends Component {
    render() {
        return (
            <SignInWithMail {...this.props}/>
        );
    }
}

class SignInOptionsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        }
    }

    facebookLogin = event => {
        this.props.auth.setLastUsedAuthType(AuthType.FACEBOOK);
        this.props.firebase
            .signInWithFacebook()
            .catch(error => {
                console.log(error);
                this.setState({ error });
            });
        event.preventDefault();
    };

    googleLogin = event => {
        this.props.auth.setLastUsedAuthType(AuthType.GOOGLE);
        this.props.firebase
            .signInWithGoogle()
            .catch(error => {
                console.log(error);
                this.setState({ error });
            });
        event.preventDefault();
    };

    render() {
        return (
            <div className="sign-in-social">
                <div className="social-btns">
                    <div className="icon-social mr-2" onClick={this.facebookLogin}>
                        <img src={"/img/icons/white/facebook.png"} alt="Facebook Login Icon"/>
                    </div>
                    <div className="icon-social" onClick={this.googleLogin}>
                        <img src={"/img/icons/white/google.png"} alt="Google Login Icon"/>
                    </div>
                </div>
                {this.state.error &&
                <div className="input-description error-message">
                    {this.state.error.message}
                </div>
                }
            </div>
        );
    }
}
const SignInOptions = WithAuthentication(WithFirebase(SignInOptionsComponent));


const SignInPage = WithAuthentication(WithFirebase(SignInWrapper));
export default SignInPage;
export {  SignInOptions , SignInWithEmailPage };
