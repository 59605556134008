import * as ROUTES from "../routes";
import {Switch} from "react-router-dom";
import React from "react";
import SignInPage, {SignInWithEmailPage} from "./auth/sign_in";
import SignUpPage from "./auth/sign_up";
import AddRedirectUrl from "./auth/redirect_handler";
import {TermsOfUseWithPage} from "./auth/terms_of_use";
import {DataProtectionWithPage} from "./auth/data_protection";
import AppRoute from "./app_route";
import UnauthorizedLayout from "./unauthorized_layout";
import LocalizationsWithLanguage from "./localization_wrapper";
import AdditionalInformationPage from "./auth/additional_information";
import ResetPasswordPage from "./auth/reset_password";

let strings = new LocalizationsWithLanguage({
    de:{
        titleDpd: "Datenschutzerklärung",
        titleTerms: "Allgemeine Geschäftsbedingungen",
        titleSignIn: "Login",
        titleSignUp: "Neues Benutzerkonto erstellen",
        titleSignEmail: "Sign in with mail",
        titleAddInfo: "Fast geschafft!",
        titleResetPassword: "Passwort zurücksetzen"
    },
    en: {
        titleDpd: "Data protection declaration",
        titleTerms: "Terms of use",
        titleSignIn: "Sign in",
        titleSignUp: "Sign up",
        titleSignEmail: "Sign in with mail",
        titleAddInfo: "Almost there...",
        titleResetPassword: "Reset password"
    }
});

const
    Unauthorized = () =>
    <Switch>
        <AppRoute withTopBar={true} title={strings.titleTerms} exact path={ROUTES.TERMSOFUSE} component={TermsOfUseWithPage} layout={UnauthorizedLayout}/>
        <AppRoute withTopBar={true} title={strings.titleDpd} exact path={ROUTES.DATAPROTECTION} component={DataProtectionWithPage} layout={UnauthorizedLayout}/>
        <AppRoute exact path={ROUTES.SIGN_IN} component={SignInPage} layout={UnauthorizedLayout}/>
        <AppRoute withTopBar={true} title={strings.titleSignUp} path={ROUTES.SIGN_UP} component={SignUpPage} layout={UnauthorizedLayout} />
        <AppRoute withTopBar={true} title={strings.titleResetPassword} path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} layout={UnauthorizedLayout} />

        <AppRoute withTopBar={true} title={strings.titleAddInfo} exact path={ROUTES.ADDITIONALINFORMATION} component={AdditionalInformationPage} layout={UnauthorizedLayout}/>

        <AppRoute withTopBar={true} path={ROUTES.EMAIL_SIGN_IN} title={strings.titleSignIn} component={SignInWithEmailPage} layout={UnauthorizedLayout}/>
        <AppRoute  path={"*"} component={AddRedirectUrl} layout={UnauthorizedLayout}/>
    </Switch>
;

export default Unauthorized;