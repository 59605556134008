import React, {Component} from 'react';
import Firebase from "./firebase";
import * as ROUTES from "../../routes";

const AuthUser = React.createContext(null);

function WithAuthentication(WrappedComponent) {
    return class extends React.PureComponent {
        render() {
            return (
                <AuthUser.Consumer>
                    {authUser => <WrappedComponent {...this.props} auth={authUser}/>}
                </AuthUser.Consumer>
            )
        }
    };
}

class AuthenticationProvider extends Component {

    stablesListenersInited = false;

    constructor(props) {
        super(props);
        this.state = {
            firebaseUser: null,
            loading: true,
            user: null,
            reactions: {
                posts: {},
                comments: {}
            },
            stables : {
                active: {},
                admin: {},
                invited: {},
                requested: {},
                employee: {},
                banned: {},
                adminInvites: {},
            }
        }
    }

    lastUsedAuthType;

    changePostReaction= (pid,likeType) => {
        this.props.firebase.userController.saveUserPostReaction(this.state.firebaseUser,pid,likeType);
        this.state.reactions.posts[pid] = likeType;
    };

    changeCommentReaction= (cid,likeType) => {
        this.props.firebase.userController.saveUserCommentReaction(this.state.firebaseUser,cid,likeType);
        this.state.reactions.comments[cid] = likeType;
    };

    componentDidMount() {
        this.authStateListener = this.props.firebase.onAuthStateChanged(async authUser => {
            if (authUser) {
                let user = await this.props.firebase.userController.loadUserInformation(authUser,this.lastUsedAuthType);
                user.lastChangedAt = Date.now();
                // if (!user.userSettings.geolocation) {
                //     this.props.history.push(ROUTES.ADDITIONALINFORMATION);
                // }

                this.setState({ user : user, firebaseUser: authUser, loading: false  });
                this.listenForStableChanges();

                this.state.reactions.posts = await this.props.firebase.userController.loadUserPostInteractions(authUser);
                this.setState(this.state);

                this.state.reactions.comments = await this.props.firebase.userController.loadUserCommentInteractions(authUser);
                this.setState(this.state);

            } else {
                if (this.state.user && this.stablesListenersInited) {
                    this.props.firebase.stableController.removeAllListeners(this.state.user.uid);
                    this.stablesListenersInited = false;
                }
                this.setState({
                    user : null,
                    firebaseUser: null,
                    loading: false,
                    reactions: {
                        posts: {},
                        comments: {}
                    },
                    stables : {
                        active: {},
                        admin: {},
                        invited: {},
                        requested: {},
                        banned: {},
                        employee: {},
                        adminInvites: {},
                    }
                });
            }
        });
    }

    listenForStableChanges() {
        if (this.state.user && !this.stablesListenersInited) {

            this.stablesListenersInited = true;
            this.props.firebase.stableController.listenForStables(this.state.user.uid,'active/',
                async (event) => {
                    if (event) {
                        let stable = await this.props.firebase.stableController.loadStableByID(event);
                        if (stable) {
                            delete this.state.stables.requested[stable.id];
                            delete this.state.stables.invited[stable.id];
                            delete this.state.stables.employee[stable.id];
                            delete this.state.stables.adminInvites[stable.id];
                            delete this.state.stables.banned[stable.id];
                            this.state.stables.active[stable.sid] = stable;
                            this.setState( this.state);
                        }

                        this.state.stables.adminInvites[stable.sid] =  await this.props.firebase.stableController.checkIfUserIsInvitedAsAdmin(event);
                        this.setState( this.state);
                    }
                },
                async (event) => {
                    if (event) {
                        delete this.state.stables.active[event];
                        delete this.state.stables.adminInvites[event];
                        this.setState( this.state);
                    }
                }
            );

            this.props.firebase.stableController.listenForStables(this.state.user.uid,'invites/',
                async (event) => {
                    if (event) {
                        let stable = await this.props.firebase.stableController.loadStableByID(event);
                        if (stable && !this.state.stables.active.hasOwnProperty(stable.sid)) {
                            this.state.stables.invited[event] = stable;
                            this.setState(this.state);
                        }
                    }

                },
                async (event) => {
                    if (event) {
                        delete this.state.stables.invited[event];
                        this.setState( this.state);
                    }
                }
            );

            this.props.firebase.stableController.listenForStables(this.state.user.uid,'admin/',
                async (event) => {
                    if (event) {
                        this.state.stables.admin[event] = true;
                        delete this.state.stables.invited[event];
                        delete this.state.stables.adminInvites[event];
                        this.setState( this.state);
                    }
                },
                async (event) => {
                    if (event) {
                        delete this.state.stables.admin[event];
                        this.setState( this.state);
                    }
                }
            );
            this.props.firebase.stableController.listenForStables(this.state.user.uid,'requests/',
                async (event) => {
                    if (event) {
                        let stable = await this.props.firebase.stableController.loadStableByID(event);
                        if (stable && !this.state.stables.active.hasOwnProperty(stable.sid)) {
                            const currentState = {...this.state};
                            currentState.stables.requested[event] = stable;
                            this.setState( currentState);
                        }
                    }
                },
                async (event) => {
                    if (event) {
                        delete this.state.stables.requested[event];
                        this.setState( this.state);
                    }
                }
            );

            this.props.firebase.stableController.listenForStables(this.state.user.uid,'bans/',
                async (event) => {
                    if (event) {
                        this.state.stables.banned[event] = true;
                        this.setState( this.state);
                    }
                },
                async (event) => {
                    if (event) {
                        delete this.state.stables.banned[event];
                        this.setState( this.state);
                    }
                }
            );
        }
    }

    isAdminOfStable= async (sid) => {
        if (this.state.stables.admin.hasOwnProperty(sid)) {
            return true
        } else {
            let isAdmin = await  this.props.firebase.stableController.isAdminOfStable(sid,this.state.user.uid);

            if (isAdmin) {
                this.state.stables.admin[sid] = true;
            }
            return isAdmin;
        }

    };


    isMemberOfStable= async (sid) => {
        if (this.state.stables.active.hasOwnProperty(sid)) {
            return true
        } else {
            let isMember = await  this.props.firebase.stableController.isMemberOfStable(sid,this.state.user.uid);

            if (isMember) {
                this.state.stables.active[sid] = true;
            }
            return isMember;
        }

    };

    isEmployeeOfStable= async (sid) => {
        if (this.state.stables.employee.hasOwnProperty(sid)) {
            return true
        } else {
            let isEmployee = await  this.props.firebase.stableController.isEmployeeOfStable(sid,this.state.user.uid);

            if (isEmployee) {
                this.state.stables.employee[sid] = true;
                this.state.stables.active[sid] = true;
            }
            return isEmployee ;
        }

    };

    componentWillUnmount() {
        this.authStateListener();
    }

    setLastUsedAuthType= (authType) =>{
        this.lastUsedAuthType = authType;
    };

    setAuthUser= (authUser) => {
        this.listenForStableChanges();
        this.setState({
            user : authUser,
            stables : {
                active: {},
                admin: {},
                invited: {},
                requested: {},
                banned: {},
                adminInvites: {},
            } })
    };

    rebuildAuthUserComponents= () => {
        if (!!this.state.user) {
            this.state.user.lastChangedAt = Date.now();
            this.setState(this.state);
        }
    };

    rebuildApp= () => {
        this.setState(this.state)
    };

    render() {
        return (
            <AuthUser.Provider value = { {
                'user': this.state.user,
                'firebaseUser': this.state.firebaseUser,
                'loading': this.state.loading,
                'stables': this.state.stables,
                'isAdminOfStable': (sid) => this.isAdminOfStable(sid),
                'isMemberOfStable': (sid) => this.isMemberOfStable(sid),
                'isEmployeeOfStable': (sid) => this.isEmployeeOfStable(sid),
                'lastUsedAuthType' : this.lastUsedAuthType,
                'setLastUsedAuthType': this.setLastUsedAuthType,
                'setAuthUser' : this.setAuthUser,
                'rebuildApp' : this.rebuildApp,
                'rebuildAuthUserComponents' : this.rebuildAuthUserComponents,
                'reactions': {
                    'posts':  this.state.reactions.posts,
                    'comments':  this.state.reactions.comments,
                    'changePostReaction':  this.changePostReaction,
                    'changeCommentReaction':  this.changeCommentReaction,
                }
            } }>
                {this.props.children}
            </AuthUser.Provider>
        )
    }
}

class AuthenticationProviderWrapper extends React.PureComponent {

    render() {
        return (
            <Firebase.Consumer>
                {
                    firebase =>
                        <AuthenticationProvider firebase={ firebase} >
                            {this.props.children}
                        </AuthenticationProvider>
                }
            </Firebase.Consumer>
        )
    }
}


export default AuthenticationProviderWrapper;

export {  WithAuthentication};