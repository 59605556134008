import React, {Component} from 'react';
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        stableContactImageAlt: "Bild von Kontaktperson"
    },
    en: {
        stableContactImageAlt: "Image of contact person"
    }
});

class StableContact extends React.PureComponent {

    imgHasFailed = false;

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        return (
            <div className="stable-contact">
                <div className="stable-contact-role">
                    <div className="stable-contact-person">
                        <img src={this.props.image} onError={this.onImageLoadFail} alt={strings.stableContactImageAlt}/>
                        <div className="stable-contact-person-info">
                            <p>{this.props.name}</p>
                            <p className="stable-about-grey">{this.props.telephone}</p>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default StableContact;