export const LANDING = '/';
export const SIGN_IN = '/login';
export const SIGN_UP = '/signup';
export const RESET_PASSWORD = '/resetPassword';
export const NONE = '/none';
export const EMAIL_SIGN_IN = '/signin';
export const HOME = '/stables';
export const STABLE = '/stables';
export const GROUPS = '/groups';
export const COMMUNITY = '/community';
export const MARKET = '/market';
export const HORSES = '/horses';
export const HORSE = '/horse/:hid';

export const CREATESTABLE = '/stables/create';
export const EDITSTABLE = '/stables/:sid/edit';
export const ADDITIONALINFORMATION = '/additionalInformation';
// export const SINGLESTABLE = '/stables/:id';
export const LATESTPOSTS = '/stables/:sid/latestposts';
export const INTERNALNEWS = '/stables/:sid/internalnews';
export const INFORMATION = '/stables/:sid/information';
export const FACILITY = '/stables/:sid/facility';
export const CREATEFACILITY = '/stables/:sid/facilities/create';
export const EDITFACILITY = '/stables/:sid/facilities/:fid/edit';
export const BOOKINGS = '/stables/:sid/facility/:fid';
export const ADDBOOKING = '/stables/:sid/facility/:fid/addBooking';
export const EDITBOOKING = '/stables/:sid/facility/:fid/booking/:bid/edit';
export const SETTINGS = '/stables/:sid/settings';
export const STABLEREQUESTS = '/stables/:sid/settings/requests';
export const STABLEBOARDER= '/stables/:sid/settings/boarders';
export const ADDSTABLEBOARDERS= '/stables/:sid/settings/boarders/add';
export const STABLEBANS= '/stables/:sid/settings/bans';
export const STABLEADMINS = '/stables/:sid/settings/admins';
export const STABLEPERMISSIONS = '/stables/:sid/settings/permissions';
export const STABLECONTACTS = '/stables/:sid/settings/contacts';
export const STABLEDELETE = '/stables/:sid/settings/deletes';
export const USER = '/users/:uid';
export const EDITUSER = '/user/edit';
export const TERMSOFUSE = '/termsofuse';
export const DATAPROTECTION = '/dataprotection';
export const EXPORTBOOKINGPLAN = '/stables/:sid/facility/:fid/export';


