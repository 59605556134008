import React, {Component} from 'react';
import StableHeader from "../header";
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {withRouter} from "react-router-dom";
import Loading from "../../loading";
import RenderedPost from "../../posts/index";
import debounce from "lodash.debounce";
import CreatePost from "../../posts/create";
import PostCache from "../../caches/post_cache";
import LocalizationsWithLanguage from "../../localization_wrapper";
import StablePermission from "../../models/stable_permissions";

let strings = new LocalizationsWithLanguage({
    de:{
        stableNoNewsHeader:"Keine News gefunden!",
        stableNoNewsText:"Interne Beiträge sind nur für Stallmitglieder sichtbar.",
        stableNoNewsImageAlt: "Keine News Bild"
    },
    en: {
        stableNoNewsHeader:"No news found!",
        stableNoNewsText:"The stable administrator can post on behalf of the stable, visible for everyone on Jutta.",
        stableNoNewsImageAlt: "no news image"
    }
});

class InternalNews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createPostFromKey: Date.now(),
            stable: undefined,
            init: true,
            loadingStable: true,
            loadingPosts: true,
            loadingNewerPosts: false,
            hasMorePosts: true,
            posts: []
        };

        window.onscroll = debounce(() => {
            const {
                loadOlderPostsForStable,
                state: {
                    init,
                    loadingPosts,
                    hasMorePosts,
                },
            } = this;

            if (init || loadingPosts || !hasMorePosts) return;

            if (
                window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
            ) {
                loadOlderPostsForStable(true);
            }
        }, 100);
    }

    async loadDisplayedStable() {
        if (await this.props.auth.isMemberOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
                let hasLoadedPosts = this.props.firebase.postController.hasOldNewsForStableCached(stable.sid);
                if (hasLoadedPosts) {
                    let posts = await this.props.firebase.postController.loadOldNewsForStable(stable.sid, Date.now(),this.rebuild);
                    this.setState({ stable : stable, posts: posts,loadingStable: false, init: false });
                } else {
                    this.setState({ stable : stable, loadingStable: false, init: false });
                }

                if (hasLoadedPosts) {
                    this.loadNewerPostsForStable();
                } else {
                    let posts = await this.props.firebase.postController.loadOldNewsForStable(stable.sid, Date.now(),this.rebuild);
                    this.setState({ posts: posts,loadingPosts: false});
                }
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    rebuild = () =>  {
        this.setState(this.state);
    };

    loadOlderPostsForStable = async (force = false) => {
        if (!!this.state.stable) {
            this.setState({loadingPosts: true});
            let lastPostId = this.state.posts.slice(-1)[0];
            let lastPost = this.props.firebase.postController.getPostByPid(lastPostId);
            if (!lastPost) {
                this.setState({hasMorePosts: false});
            } else {
                let posts = await this.props.firebase.postController.loadOldNewsForStable(this.state.stable.sid,lastPost.createdAt.getTime(),this.rebuild,force);
                let newPostArray = this.state.posts.concat(posts);

                this.setState({posts : newPostArray, loadingPosts: false});
            }

        }
    };

    loadNewerPostsForStable = async () => {
        if (!!this.state.stable) {
            this.setState({loadingNewerPosts: true});
            if (this.state.posts.length > 0) {
                let lastPostId = this.state.posts.slice(0)[0];
                let lastPost = this.props.firebase.postController.getPostByPid(lastPostId);

                if (!!lastPost) {
                    let posts = await this.props.firebase.postController.loadLatestNewsForStable(this.state.stable.sid,lastPost.createdAt.getTime(),this.rebuild);
                    let newPostArray = posts.concat(this.state.posts);

                    this.setState({posts : newPostArray, loadingNewerPosts: false});
                }
            } else {
                let posts = await this.props.firebase.postController.loadLatestNewsForStable(this.state.stable.sid,0,this.rebuild);
                let newPostArray = posts.concat(this.state.posts);

                this.setState({posts : newPostArray, loadingNewerPosts: false});
            }
        }
    };

    addNewPost(post) {
        this.setState({
            createPostFromKey: Date.now()
        });
        if (!!post) {
            let posts = [post.pid];
            PostCache.prependStableNewsPostIds(this.state.stable.sid,posts);
            posts =  posts.concat(this.state.posts);
            this.setState( {posts: posts});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.refreshFsLightbox();
    }

    componentDidMount() {
        this.loadDisplayedStable();
    }

    getContent = () => {
        if (!this.state.loadingPosts && (!this.state.posts || this.state.posts.length === 0) ) {
            return (
                <div className="stable-onboarding">
                    <img src="/img/jutta-groups.png" alt={strings.stableNoNewsImageAlt}/>
                    <b>{strings.stableNoNewsHeader}</b>
                    <p>{strings.stableNoNewsText}</p>
                </div>
            )
        }

        return (
            <div className="post-list">
                {
                    this.state.posts.map((postId, i) => {
                        let key = !!postId ? postId + i : i;
                        let post = this.props.firebase.postController.getPostByPid(postId);
                        return <RenderedPost key={"stableNewsStream" + key } post={post} canDelete={this.props.auth.stables.admin.hasOwnProperty(this.state.stable.sid)} canReact={this.state.stable.permissions.includes(StablePermission.CLIENTSCANREACTTONEWS)}/>
                    })
                }

            </div>
        )
    };


    render() {
        return (
            <div>
                <StableHeader name={!!this.state.stable ? this.state.stable.name : ""} stables={this.props.auth.stables.active}/>
                {
                    (!!this.state.stable && (this.state.stable.membersCanPostNews() || this.props.auth.stables.admin.hasOwnProperty(this.props.match.params.sid))) &&
                    <CreatePost key={this.state.createPostFromKey} sid={this.state.stable.sid} asStable={false} callback={(post) => this.addNewPost(post)}/>

                }
                {!this.state.loadingStable ? this.getContent() : <Loading />}
            </div>
        );
    }


}
export default withRouter(WithFirebase(WithAuthentication(InternalNews)));