import * as React from "react";
import TopBar from "./navigation/topbar";
import {Footer} from "./navigation/Footer";
import PropTypes from 'prop-types';
import SideBar from "./navigation/sidebar";

class MasterLayout extends React.Component {
    render(){
        return <div className="fullPage">
        <div className="corner-ribbon top-right sticky blue">BETA</div>
        <TopBar/>
        <div className="content">
            <SideBar/>
            <main id="master">{this.props.children}</main>
        </div>
        <Footer/>
    </div>
    }
}
MasterLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default MasterLayout;