import React, {Component} from 'react';
import imageCompression from "browser-image-compression";

class DeletableImage extends Component {
    constructor(props) {
        super(props);
    }

    openFilePicker = (target) => {
        this.refs[target].click();
    };

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
        this.setState(this.state);
    };

    compressFile = async (event, targetImageTag) => {
        let file = event.target.files[0];

        if (!!file) {
            const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 512,
                useWebWorker: true,
                onProgress: () => null
            };

            let compressed = await imageCompression(file, options);

            this.setState({
                [targetImageTag]: compressed
            });

            this.refs[targetImageTag].src = URL.createObjectURL(compressed);
            this.props.onImageChange(compressed);
        }
    };

    removeImage = (targetImageTag) => {
        this.setState({
            [targetImageTag]: null
        });

        this.refs[targetImageTag].src = "/img/nopicture.png";
        this.props.onImageChange(undefined);
    };

    render() {
        return (
            <div className="img-delete-wrapper">
                <input accept="image/*" type="file" ref="imageFile" style={{display: "none"}} onChange={(event)=> {this.compressFile(event, "chosenImage")}} onClick={(event)=> {event.target.value = null}}/>
                <img className="deletable-img" ref="chosenImage" src={this.props.image || "/img/nopicture.png"} onClick={() => this.openFilePicker("imageFile")} onError={this.onImageLoadFail} alt={this.props.altText}/>
                <div className="img-delete" onClick={() => this.removeImage("chosenImage")}>
                    <img src="/img/icons/white/delete.png"/>
                </div>
            </div>
        );
    }
}

export default DeletableImage;