import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import {getStartOfToday} from "../additional/helpers";

class FirebaseProController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }

    cachedProStatus;

    loadProStatus = async () => {
        let firebaseUser = await this.app.auth().currentUser;
        let snap = await this.app.firestore().collection('users').doc(firebaseUser.uid).collection('payments').orderBy('cAt', 'desc').limit(1).get();

        if(snap.docs.length > 0) {
            let lastDoc = snap.docs[0];
            let hasPro = lastDoc.data()['until'].toDate().getTime() >= getStartOfToday().getTime();

            this.cachedProStatus = hasPro;

            return hasPro;
        }

        return false;
    };

    getCachedProStatus = () => {
        return this.cachedProStatus;
    }

    hasReachedCutoffDate = () => {
        return (new Date()).getTime() > (new Date(2023, 8, 1)).getTime();
    }
}

export default FirebaseProController;
