import React from "react";
import ContextMenu from "../../additional/context_menu";
import {withRouter} from "react-router-dom";
import {WithFirebase} from "../../firebase/firebase";
import CommentCache from "../../caches/comment_cache";
import LocalizationsWithLanguage from "../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        minutesAgo:"vor $time Minuten",
        hoursAgo:"vor $time Stunden",
        edit:"Bearbeiten",
        delete:"Löschen",
    },
    en: {
        minutesAgo:"$time minutes ago",
        hoursAgo:"$time hours ago",
        edit:"Edit",
        delete:"Delete",
    }
});

const CommentTile = (props) => {
    return (
        <div className="comment-tile">
            <img onClick={props.showUser} src={props.comment.userAvatar} onError={ (e) => {e.target.onError=null; e.target.src='/img/nopicture.png';}}/>
            <div className="comment-tile-information">
                <div onClick={props.showUser} className="comment-tile-name"> {props.comment.userName}</div>
                <CommentTileDate date={props.comment.createdAt}/>
            </div>
            {
                (props.canEdit || props.canDelete)  &&
                <ContextMenu>
                    {
                        props.canDelete && <div className={"option"} onClick={() => {
                            props.comment.deletedAt = new Date();
                            CommentCache.updateCacheEntry(props.comment);
                            props.firebase.commentController.deleteComment(props.comment);
                            if (!!props.onChange) {
                                props.onChange();
                            }
                        }}> {strings.delete}</div>
                    }
                </ContextMenu>
            }
        </div>
    );
};

const CommentTileDate = (props) => {
    let difference = Date.now() - props.date.getTime();

    if (difference < 3600000) {
        return (
            <div className="comment-tile-date">{ strings.minutesAgo.replace("$time", Math.round(difference / 60000))}</div>
        );
    } else if (difference < 86400000) {
        return (
            <div className="comment-tile-date">{ strings.hoursAgo.replace("$time", Math.round(difference / 3600000))}</div>
        )
    } else {
        let timeString =  props.date.toLocaleTimeString();
        let index = timeString.lastIndexOf(":");
        if (index >= 0) {
            timeString = timeString.substr(0,index);
        }
        return (
            <div className="comment-tile-date">{props.date.toLocaleDateString()+ ", " + timeString}</div>
        )
    }
};

export default withRouter(WithFirebase(CommentTile));

