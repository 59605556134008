import React, {Component} from 'react';
import {WithFirebase} from '../firebase/firebase';
import {WithAuthentication} from "../firebase/auth_provider";
import Loading from "../loading";
import {Link, withRouter} from "react-router-dom";
import * as ROUTES from "../../routes";
import {getUserAvatarLargeUrl} from "../additional/helpers";
import LocalizationsWithLanguage from "../localization_wrapper";
import LoadingIndicator from "../additional/loading_indicator";

let strings = new LocalizationsWithLanguage({
    de:{
        yourProfile: "Dein Profil",
        information: "Information",
        job: "Job",
        employer: "Arbeitgeber",
        follower: "Follower",
        following: "Folge ich",
        edit: "Bearbeiten",
        editPicture: "Foto bearbeiten",
        userNotFound: "Es tut uns leid, dieser Nutzer konnte nicht gefunden werden."
    },
    en: {
        yourProfile: "Your profile",
        information: "Information",
        job: "Job",
        employer: "Employer",
        follower: "Followers",
        following: "Following",
        edit: "Edit",
        editPicture: "Edit picture",
        userNotFound: "We're sorry, this user could not be found."
    }
});

class UserInformation extends Component{
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            loading: true
        }
    }

    onImageLoadFail = (event) => {
        this.imgHasFailed = true;
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
    };

    loadDisplayedUser() {
        if (this.props.match.params.uid=== this.props.auth.firebaseUser.uid) {
            this.setState({ user : this.props.auth.user, loading: false });
            this.loadWatchedByCountForUser(this.props.auth.user);
        } else  {
            this.props.firebase.userController.loadUserByUid(this.props.match.params.uid).then(user => {
                this.setState({ user : user, loading: false });
                this.loadWatchedByCountForUser(user);
            });
        }
    }

    loadWatchedByCountForUser(user) {
        this.props.firebase.userController.loadUserFollowerCountByUid(user.uid).then(count => {
            user.watchedByCount = count;
            this.setState({ user : user, loading: false});
        })
    }

    componentDidMount() {
        this.loadDisplayedUser();
    }

    getUserImage() {
        if(this.state.loading) {
            return (<div></div>);
        }

        if (this.state.user.uid === this.props.auth.firebaseUser.uid) {
            return (
                <Link to={ROUTES.EDITUSER}>
                    <div className="container">
                        <div className="overlay">
                            <span>{strings.editPicture}</span>
                        </div>
                        <img className="user-image" src={this.state.user.avatar} onError={this.onImageLoadFail}/>
                    </div>
                </Link>
            );
        }

        return (
            <div className="container">
                <img className="user-image" src={getUserAvatarLargeUrl(this.state.user.uid)} onError={this.onImageLoadFail}/>
            </div>
        );
    }

    getUserMainData() {
        if(this.state.loading) {
            return (<div></div>);
        }

        return (
            <div className="user-information">
                <h3>{this.state.user.name}</h3>
                <small>{this.state.user.fullAddress}</small>

                <span>{this.state.user.description}</span>
                {this.showStatistic()}
            </div>
        );
    }

    showStatistic() {
        if(this.state.loading) {
            return (<div></div>);
        }

        return (
            <div className="statistic">
                <div>
                    <h4>{this.state.user.watchedByCount}</h4>
                    {strings.follower}
                </div>

                <div>
                    <h4>{this.state.user.watchingUserCount}</h4>
                    {strings.following}
                </div>
            </div>
        );
    }

    getHeader() {
        if(this.state.loading) {
            return (<div></div>);
        }

        if (  this.state.user.uid === this.props.auth.firebaseUser.uid) {
            return (
                <div className="row mb-1">
                   <h1>{strings.yourProfile}</h1>
                   <button onClick={this.editUser}>{strings.edit}</button>
                </div>
            );
        }

        return (
            <div className="row mb-1">
                <h1>{ this.state.user.name }</h1>
            </div>
        );
    }

    editUser = () => {
        this.props.history.push(ROUTES.EDITUSER);
    };

    render() {
        if(!!this.state.user && this.state.user.uid !== this.props.match.params.uid) {
            this.state.loading = true;
            this.loadDisplayedUser();
        }

        if(this.state.loading) {
            return (
                <LoadingIndicator/>
            )
        }

        if (!this.state.loading && !this.state.user) {
            return(
                <div className="user-page">
                    {strings.userNotFound}
                </div>
            )
        }

        return (
            <div className="user-page">
                {this.getHeader()}
                <div className="main-data">
                    {this.getUserImage()}
                    {this.getUserMainData()}
                </div>


            </div>
        );
    }
}

const UserInformationPage = withRouter(WithAuthentication(WithFirebase(UserInformation)));
export default UserInformationPage;