import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/storage';
import axios from "axios";

class FirebaseStorageController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }


    uploadAvatarForStableContact = async (file, stableId, contactKey)  =>  {
        let storageRef = firebase.storage().ref().child('stables').child(stableId).child('contact' + contactKey);
        return storageRef.put(file);
    };

    uploadHeaderForFacility = async (file, facilityId)  =>  {
        let storageRef = firebase.storage().ref().child('facilities').child(facilityId).child('header');
        return storageRef.put(file);
    };

    deleteFacilityHeaderImage = (facilityId) => {
        return firebase.storage().ref().child('facilities').child(facilityId).child('header').delete();
    };

    deleteContactImage = (stableId, contactKey) => {
        return firebase.storage().ref().child('stables').child(stableId).child('contact' + contactKey).delete();
    };

    uploadImageForPost = async (file, post) => {
        let randomNumber = Math.floor(Math.random() * 1000000);
        let  fileName = randomNumber + (new Date().getTime());
        let storageRef =  firebase.storage().ref().child('posts').child("" + post.uid).child('images').child("" +post.pid).child("" +fileName);
        await storageRef.put(file);
        return storageRef.getDownloadURL();
    };

    uploadImageForPostComment = async (file, post) => {
        let randomNumber = Math.floor(Math.random() * 1000000);
        let  fileName = randomNumber + (new Date().getTime());
        let storageRef =  firebase.storage().ref().child('posts').child("" + post.uid).child('images').child("" +post.pid).child("" +fileName);
        await storageRef.put(file);
        return storageRef.getDownloadURL();
    };

    uploadImageForStable = async (file, stableId, imageKey) => {
        let storageRef =  firebase.storage().ref().child('stables').child("" + stableId).child('image').child("" +imageKey);
        await storageRef.put(file);
        return storageRef.getDownloadURL();
    };

    deleteImageForStable = async (stableId, imageKey) => {
        firebase.storage().ref().child('stables').child("" + stableId).child('image').child("" +imageKey).delete();
    };

    uploadHeaderForStable = async (file, stableId) => {
        let storageRef =  firebase.storage().ref().child('stables').child("" + stableId).child('header');
        await storageRef.put(file);
        return storageRef.getDownloadURL();
    };

    deleteStableHeaderBySid = (sid) => {
        firebase.storage().ref().child('stables').child(sid).child('header').delete();
    };

    uploadHeaderForStableByUrl = async (url, stableId) => {
        let response = await axios.get(url,{ responseType: 'arraybuffer'});
        const buffer = Buffer.from(response.data, 'binary');
        let newMetadata = {
            contentLanguage: 'en'
        };
        let storageRef =  firebase.storage().ref().child('stables').child("" + stableId).child('header');
        await storageRef.put(buffer,newMetadata);
    };

    uploadUserAvatars = async (uid, smallAvatar, largeAvatar) => {
        let smallStorageRef = firebase.storage().ref().child('users').child('avatars').child(uid);
        let largeStorageRef = firebase.storage().ref().child('users').child('avatars').child(uid + "_large");

        await smallStorageRef.put(smallAvatar);
        await largeStorageRef.put(largeAvatar);
    };

    deleteUserAvatar = async (uid) => {
        try {
            await firebase.storage().ref().child('users').child('avatars').child(uid).delete();
            await firebase.storage().ref().child('users').child('avatars').child(uid + "_large").delete();
        } catch (ex) {
            // Tried to delete but they dont exist
        }
    }
}



export default FirebaseStorageController;