import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {WithAuthentication} from "../firebase/auth_provider";
import {WithFirebase} from "../firebase/firebase";
import imageCompression from 'browser-image-compression';
import {createDummyPostForAuthUser} from "../models/post";
import PostPrivacy from "../models/post_privacy";
import ExpandingText from "../additional/expanding_text";
import Modal from "../additional/modal";
import LocalizationsWithLanguage from "../localization_wrapper";
import LoadingIndicator from "../additional/loading_indicator";
import PostCache from "../caches/post_cache";
import {WithBlockRoute} from "../navigation/block_route_provider";

let strings = new LocalizationsWithLanguage({
    de:{
        send: "Senden",
        cancel: "Abbrechen",
        title: "Neuen Beitrag erstellen",
        addImage: "Bild hinzufügen",
        contentPlaceholder: "Wie geht es dir?",
        media: "Foto hinzufügen",
        userProfileImageAlt: "Dein Profilbild",
        postImageAlt: "Beitragsbild",
    },
    en: {
        send: "send",
        cancel: "cancel",
        title: "Create new post",
        addImage: "Add image",
        contentPlaceholder: "How are you?",
        media: "Add picture",
        userProfileImageAlt: "Dein Profilbild",
        postImageAlt: "post image"
    }
});

class PostCreate extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            images: [],
            content: "",
            showModal: false,
            openImagePicker: false,
        };
    }

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
        this.setState(this.state);
    };


    showModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showModal: true, openImagePicker: false});
    };

    showModalAndOpenImagePicker = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showModal: true, openImagePicker: true});
    };

    onChange = (content,images) => {
        this.setState({content: content, images: images});
    };

    render() {
        return (

            <div className="create-post" onClick={this.showModal}>
                <div className="header">{strings.title}</div>
                <div className="body">
                    <div className="textarea">
                        <img src={this.props.auth.user.avatar} alt={strings.userProfileImageAlt} onError={this.onImageLoadFail}/>
                        {!!this.state.content && this.state.length !== 0 ? <span>{this.state.content}</span> : <span className="placeholder">{strings.contentPlaceholder}</span>}
                    </div>

                    { !!this.state.images && this.state.images.length > 0 ?
                        <div className={"selected-images-bar-create"}>
                            { this.state.images.map( (value) => {
                                return (
                                    <div className={"post-selected-images-wrapper img-delete-wrapper"} key={value.url}>
                                        <img alt={strings.postImageAlt} src={value.url} onError={this.onImageLoadFail}/>
                                        <div className={"img-delete"} onClick={() => this.removeImage(value.url)}>×</div>
                                    </div>
                                )
                            })}
                        </div>
                        : null
                    }
                </div>
                <div className="actions-create">
                    <div className="action" onClick={this.showModalAndOpenImagePicker}>
                        <img src="/img/icons/dark/photo.png" alt={"Icon to add pictures"}/>
                        <span>{strings.media}</span>
                    </div>
                </div>
                <CreatePostModal {...this.props} openImagePicker={this.state.openImagePicker} onChange={(content,images) => this.onChange(content,images)} close={() => this.setState({showModal: false})} show={this.state.showModal}/>
            </div>
        )
    }
}


class CreatePostModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            content: undefined,
            imagePickerOpened: false,
            uploadingImageCount: 0
        }
    }

    onSubmit = async (event) => {
        event.preventDefault();
        let content = this.state.content;
        if(!!content) {
            content = content.trim();
        }

        this.setState({loading: true});

        let _storageController = this.props.firebase.storageController;
        let _postController = this.props.firebase.postController;

        let dummyPost = createDummyPostForAuthUser( {user: this.props.auth.user, content: content, images: this.state.images});

        let imageCopy = this.state.images;
        this.state.images= [];
        this.state.content = undefined;
        this.setState(this.state);
        this.onClose();

        if (!!this.state.stable) {
            if (this.state.asStable) {
                dummyPost.postPrivacy = [PostPrivacy.STABLE,PostPrivacy.PUBLIC,PostPrivacy.WATCH];
            } else {
                dummyPost.postPrivacy = [PostPrivacy.STABLE, PostPrivacy.WATCH];
            }
            dummyPost.stableId = this.state.stable.sid;
            dummyPost.stableName = this.state.stable.name;
            dummyPost.asStable = this.state.asStable;
        }
        if (!!this.props.callback) {
            let localImageUrls = [];
            for (let i=0; i<imageCopy.length; i++) {
                localImageUrls.push( imageCopy[i].url);
            }
            dummyPost.images = localImageUrls;
            dummyPost.createdAt = new Date();
            dummyPost.pid = ""+Date.now();
            dummyPost.isSaving = true;
            PostCache.updateCacheEntry(dummyPost);
            this.props.callback(dummyPost);
        }

        let newImageUrls = [];
        for (let i=0; i<imageCopy.length; i++) {
            newImageUrls.push(  await _storageController.uploadImageForPost(imageCopy[i].file,dummyPost));
        }
        dummyPost.images = newImageUrls;

        let post = await _postController.createNewPost(dummyPost);
        if (!!this.props.callback) {
            this.props.callback(post);
        }

    };

    loadDisplayedStable(sid) {
        this.props.firebase.stableController.loadStableByID(sid).then(async (stable) => {
            this.setState({
                stable : stable
            });
        });
    }

    componentDidMount() {
        this.props.blockRoute.setCanLeaveRoute(() => (!this.state.images || this.state.images.length === 0) && ( !this.state.content || this.state.content.trim().length === 0));

        if (!!this.props.sid) {
            this.loadDisplayedStable(this.props.sid);
        }

        if (!!this.props.asStable) {
            this.state.asStable = this.props.asStable;
        }
    }
    onChange = event => {
        this.state[event.target.name] = event.target.value;
        this.setState(this.state);
    };

    onTextChange = event => {
        this.setState( {content : event.target.value});
    };

    openFilePicker = (e) => {
        if (!!e) {
            e.preventDefault();
        }
        this.refs["filePicker"].click();
    };

    compressFile = async (event) => {
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1200,
            useWebWorker: true,
            onProgress: () => null
        };

        let files = event.target.files;

        this.setState({uploadingImageCount: files.length});

        for (let i= 0; i < files.length; i++) {
            let file = files[i];
            let compressed = await imageCompression(file, options);
            let url = URL.createObjectURL(compressed);

            this.state.images.push({file: compressed, url: url});
            this.state.uploadingImageCount--;
            this.setState(this.state);
        }
        this.setState({uploadingImageCount: 0});
    };

    removeImage = (url) => {
        for (let i=0; i<this.state.images.length; i++) {
            if (this.state.images[i].url === url) {
                this.state.images.splice(i, 1);
                break;
            }
        }
        this.setState(this.state);
    };

    onClose = () => {
        this.state.imagePickerOpened= false;
        this.props.onChange(this.state.content,this.state.images);
        this.props.close();
    };

    componentDidUpdate= () => {
        if (this.props.show && this.props.openImagePicker && !this.state.imagePickerOpened) {
            this.openFilePicker();
            this.state.imagePickerOpened= true;
        }
    };


    render() {

        let canPost = (!!this.state.content && this.state.content.trim().length > 0) || (!!this.state.images && this.state.images.length>0);
        return (
            <Modal show={this.props.show} onClose={this.onClose}>
                <h2 className={"modal-header"}>{strings.title}</h2>

                <ExpandingText
                    onChange={this.onTextChange}
                    placeholder={strings.contentPlaceholder}
                />


                <input name="filePicker" accept="image/*" type="file" ref="filePicker" style={{display: "none"}}   onChange={(event)=> {this.compressFile(event)}} onClick={(event)=> {event.stopPropagation();event.target.value = null}} multiple/>

                { !!this.state.images && (this.state.images.length > 0 || this.state.uploadingImageCount > 0 ) ?
                    <div className={"selected-images-bar"}>
                        { this.state.images.map( (value) => {
                            return (
                                <div className={"post-selected-images-wrapper img-delete-wrapper"} key={value.url}>
                                    <img src={value.url} onError={this.onImageLoadFail}/>
                                    <div className={"img-delete"} onClick={() => this.removeImage(value.url)}>×</div>
                                </div>
                            )
                        })}
                        { [...Array(this.state.uploadingImageCount )].map((e, i) => <LoadingIndicator key={i} small={true} backgroundClass={"loading-indicator-post"}/>)}
                    </div>
                    : null
                }

                <div className={"actions"} onClick={this.openFilePicker}>
                    <div className="action">
                        <img src="/img/icons/dark/photo.png" alt={"Icon to add pictures"}/>
                        <span>{strings.media}</span>
                    </div>
                </div>

                <div className="row modal-actions-wrapper">
                    <a onClick={this.onClose} className="btn-cancel">{strings.cancel}</a>
                    <button disabled={!canPost} onClick={ this.onSubmit}>{strings.send}</button>
                </div>
            </Modal>
        );
    }
}

export default withRouter(WithBlockRoute(WithAuthentication(WithFirebase(PostCreate))));