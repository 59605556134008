const Weekday = {
    MONDAY : 'Weekday.MONDAY',
    TUESDAY : 'Weekday.TUESDAY',
    WEDNESDAY : 'Weekday.WEDNESDAY',
    THURSDAY : 'Weekday.THURSDAY',
    FRIDAY : 'Weekday.FRIDAY',
    SATURDAY : 'Weekday.SATURDAY',
    SUNDAY : 'Weekday.SUNDAY',
};

export default Weekday;