import {Geopoint} from "./geopoint";

export class StableLocation {

    country;
    city;
    state;
    street;
    streetNumber;
    postalCode;
    fullAddress;
    placeName;
    geolocation;

    constructor({country = "", city = "", state = "", street = "", streetNumber= "", postalCode= "", fullAddress= "", geolocation = undefined, placeName= ""} = {}){
        this.country = country;
        this.city = city;
        this.state = state;
        this.street = street;
        this.streetNumber = streetNumber;
        this.postalCode = postalCode;
        this.fullAddress = fullAddress;
        this.geolocation = geolocation;
        this.placeName = placeName;
    };

    toJSON = () => {
        let data = {};

        if(!!this.geolocation) {
            data['_latitude'] = this.geolocation.lat;
            data['_longitude'] = this.geolocation.long;
        }

        if(!!this.country) {
            data['country'] = this.country;
        }

        if (!!this.city) {
            data['city'] = this.city;
        }

        if (!!this.state) {
            data['state'] = this.state;
        }

        if (!!this.street) {
            data['street'] = this.street;
        }

        if (!!this.streetNumber) {
            data['streetNumber'] = this.streetNumber;
        }

        if (!!this.postalCode) {
            data['postalCode'] = this.postalCode;
        }

        if (!!this.fullAddress) {
            data['fullAddress'] = this.fullAddress;
        }

        if(!!this.placeName) {
            data['placeName'] = this.placeName;
        }

        return data;
    }

}


export function stableLocationDataFromJSON (json) {
    let location = new StableLocation({
        country: json['country'],
        city: json['city'],
        state: json['state'],
        street: json['street'],
        streetNumber: json['streetNumber'],
        postalCode: json['postalCode'],
        fullAddress: json['fullAddress'],
        placeName: json['placeName'],
    });

    if (!!json['location'] && !!json['location'].latitude && !!json['location'].longitude) {
        location.geolocation = new Geopoint(  json['location'].latitude, json['location'].longitude)
    }
    return location;

}
