import React, {Component} from 'react';
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import { withRouter} from "react-router-dom";
import UserCard from "../../../additional/user_card";
import AreYouSureModal from "../../../additional/are_your_sure_modal";
import AddStableAdmins from "./add";
import StableHeader from "../../header";
import LocalizationsWithLanguage from "../../../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        admins:"Administratoren",
        addAdmin:"Administrator hinzufügen",
        remove:"Entfernen",
        currentAdmins: "Aktuelle Administratoren",

    },
    en: {
        admins:"admins",
        addAdmin:"Add admin",
        remove:"Remove",
        currentAdmins: "Current administrators",

    }
});


class StableAdminsIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            admins: [],
            stable: undefined,
            authAdmin: undefined,
            adminToDelete: undefined,
        }
    }

    loadAdminsForStable = async () => {
        let users = await this.props.firebase.stableController.loadAdminsForStable(this.props.match.params.sid);
        let that = this;
        users.forEach(function(user) {
           if (user.uid === that.props.auth.firebaseUser.uid) {
               that.state.authAdmin = user;
           }
        });
        this.setState({admins: users});
    };

    removeUser = async (user) => {
        await this.props.firebase.stableController.removeAdminPosition( this.state.stable.sid, user.uid, user.name );
        this.setState({adminToDelete: undefined});
        this.state.admins = this.state.admins.filter(function(item, idx) {
            return item.uid !== user.uid;
        });
        this.setState(this.state);
    };

    async loadDisplayedStable() {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
                this.setState({ stable : stable});
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    componentDidMount() {
        this.loadDisplayedStable();
        this.loadAdminsForStable();
    }

    getUserOptions = (user) => {

        if (!this.state.stable || !this.state.authAdmin ) {
            return null;
        }

        if ( (this.state.authAdmin.uid === user.uid) || (this.state.authAdmin.joined > user.joined) ) {
            return null;
        }

        return (
            <button onClick={() =>  this.setState({adminToDelete: user})}>
                {strings.remove}
            </button>
        )
    };


    render() {

        return (
            <div>
                {
                    !!this.state.adminToDelete ? <AreYouSureModal onConfirm={() => this.removeUser(this.state.adminToDelete)} onClose={() => this.setState({adminToDelete: undefined})}/> : null
                }

                <StableHeader name={this.state.stable == null ? "" : this.state.stable.name} stables={this.props.auth.stables == null ? true : this.props.auth.stables.active}/>

                 <AddStableAdmins/>

                <div className="mt-1">
                    <h4>{strings.currentAdmins}</h4>
                    {
                        !!this.state.admins ? this.state.admins.map((user, i) => {
                                return (
                                    <UserCard key={"user_admin" + user.uid} name={user.name} image={user.avatar} options={() => this.getUserOptions(user)}/>
                                )
                            })
                            : null
                    }
                </div>
            </div>
        );
    }
};

export default withRouter(WithAuthentication(WithFirebase(StableAdminsIndex)));