import {getFacilityHeaderUrl} from "../additional/helpers";

export class Facility{
    fid;
    sid;
    name;
    header;
    description;
    maxBookers;

    openingStart;
    openingEnd;
    maxBookingDuration;

    maxFutureBookingDays;
    futureDaysBookingStart;

    // blockedFrom;
    // blockedTo;
    // blockedNotes;

    hasImage;

    /**
     * @param {String} sid
     * @param {String} fid
     * @param {String} name
     * @param {String} header
     * @param {String} description
     * @param {number} maxBookers
     * @param {boolean} hasImage
     * @param {number} openingStart
     * @param {number} openingEnd
     * @param {number} maxBookingDuration
     * @param {number} maxFutureBookingDays
     * @param {number} futureBookingDaysStart
     */
    constructor({sid = "", fid = "", name = "", header = "", description = "", maxBookers = 0,
                     hasImage = true, openingStart = 0, openingEnd = 0, maxBookingDuration = 0, maxFutureBookingDays = undefined, futureDaysBookingStart = undefined} ={}) {
        this.sid = sid;
        this.fid = fid;
        this.name = name;
        this.header = header;
        this.description = description;
        this.maxBookers = maxBookers;
        this.hasImage = hasImage;
        this.openingStart = openingStart;
        this.openingEnd = openingEnd;
        this.maxBookingDuration = maxBookingDuration;
        this.maxFutureBookingDays = maxFutureBookingDays;
        this.futureDaysBookingStart = futureDaysBookingStart;
    }

    toJSON = () => {
        let data = {
            hasImage: this.hasImage.toString()
        };

        if(!!this.sid) {
            data['stableId'] = this.sid;
        }

        if(!!this.fid) {
            data['facilityId'] = this.fid;
        }

        if(!!this.maxBookers) {
            data['lanes'] = this.maxBookers;
        }

        if(!!this.maxBookingDuration) {
            data['maxBookingDuration'] = this.maxBookingDuration;
        }

        if(!!this.name) {
            data['name'] = this.name;
        }

        if(!!this.openingStart && !!this.openingEnd) {
            data['openingStart'] = this.openingStart;
            data['openingEnd'] = this.openingEnd;
        }

        if(!!this.name) {
            data['name'] = this.name;
        }

        if(!!this.description) {
            data['description'] = this.description;
        }

        if(!!this.maxFutureBookingDays) {
            data['maxFutureBookingDays'] = this.maxFutureBookingDays;
        }

        if(!!this.futureDaysBookingStart) {
            data['futureDaysBookingStart'] = this.futureDaysBookingStart;
        }

        return data;
    }
}

export function createFacilityFromFirestoreDocument(firestoreDocument){
    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        let facility = new Facility({
            fid: firestoreDocument.id,
            sid: data['stableId'],
            header: getFacilityHeaderUrl(firestoreDocument.id),
            hasImage: data['hasImage']
        });

        if (!!data['description']) {
            facility.description = data['description'];
        }
        if (!!data['hasImage']) {
            facility.hasImage = data['hasImage'];
        }
        if (!!data['name']){
            facility.name = data['name'];
        }
        if (!!data['lanes']){
            facility.maxBookers = data['lanes'];
        }
        if (!!data['openingStart']){
            facility.openingStart = data['openingStart'];
        }
        if (!!data['openingEnd']){
            facility.openingEnd = data['openingEnd'];
        }
        if (!!data['maxBookingDuration']){
            facility.maxBookingDuration = data['maxBookingDuration'];
        }
        if (!!data['maxFutureBookingDays']) {
            facility.maxFutureBookingDays = data['maxFutureBookingDays'];
        }
        if (!!data['futureDaysBookingStart']) {
            facility.futureDaysBookingStart = data['futureDaysBookingStart'];
        }
        return facility;
    }
    return undefined;
}
