import Privacy from "./privacy";
import {getUserAvatarUrl, stringToAuthType} from "../additional/helpers";
import {User} from "./user";
import {UserSettings} from "./user_settings";

export class AuthUser extends User{

    firstName;
    lastName;
    userSettings;

    rivals;
    job;
    employer;
    gender;

    countryCode;

    /**
     *  Only used inside the webapp to rerender components
     * */
    lastChangedAt;

    relatedHorses;

    hasAvatar=true;

    business;

    /**
     * @param {String} name
     * @param {String} avatar
     * @param {String} uid
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} fullAddress
     * @param {String} countryCode
     * @param {Date} birthday
     * @param {Date} nameChangedAt
     * @param {Privacy} birthdayPrivacy
     * @param {Privacy} locationPrivacy
     * @param {number} watchedByCount
     * @param {number} watchingHorsesCount,
     * @param {number} watchingUserCount
     * @param {UserSettings} userSettings
     */
    constructor( {uid="",name ="",avatar="", firstName = "", lastName = ""
                     ,birthday= null,birthdayPrivacy = Privacy.SECRET,fullAddress ="",locationPrivacy = Privacy.SECRET
                     ,nameChangedAt = null, watchedByCount = 0, watchingHorsesCount=0,watchingUserCount=0,
        userSettings=new UserSettings(), countryCode = null} ={}) {
        super( {uid,name ,avatar} );
        this.birthday = birthday;
        this.firstName = firstName;
        this.lastName = lastName;
        this.birthdayPrivacy = birthdayPrivacy;
        this.locationPrivacy = locationPrivacy;
        this.nameChangedAt = nameChangedAt;
        this.watchedByCount = watchedByCount;
        this.watchingHorsesCount = watchingHorsesCount;
        this.watchingUserCount = watchingUserCount;
        this.userSettings = userSettings;
        this.countryCode = countryCode;
    }

    getGeolocation = () => {
        return !!this.userSettings ? this.userSettings.geolocation : undefined
    }
}

export function createAuthUserFromFirestoreDocument(firestoreDocument){
    if (!!firestoreDocument && !!firestoreDocument.data()) {
        const data = firestoreDocument.data();

        let user = new AuthUser( {
            uid: firestoreDocument.id,
            firstName: data['firstName'] || "",
            lastName: data['lastName'] || "",
            name: data['fullName'] || data['name'] || data['businessName'] || "",
            avatar: getUserAvatarUrl(firestoreDocument.id)
        });

        if (data['authtype'] != null) {
            let authType = stringToAuthType(data['authtype']);

            if(!authType) {
                authType = data['authtype'];
            }
            user.authType = authType;
        }

        if (!!data['countryCode']) {
            user.countryCode = data['countryCode'];
        }
        if (!!data['description']){
            user.description = data['description'];
        }


        if (!!data['watchingCount']){
            user.watchingUserCount = data['watchingCount'];
        }
        if (!!data['watchingHorsesCount']){
            user.watchingHorsesCount = data['watchingHorsesCount'];
        }
        if (!!data['gender']){
            user.gender = data['gender'];
        }

        user.locationPrivacy = data['locationPrivacy'] || Privacy.PUBLIC;

        user.birthdayPrivacy = data['birthdayPrivacy'] || Privacy.PUBLIC;

        if(!!data['birthday']) {
            user.birthday = new Date(data['birthday']);
        }

        if(!!data['nameChangedAt']) {
            user.nameChangedAt = new Date(data['nameChangedAt']);
        }
        if(!!data['fullAddress']) {
            user.fullAddress = data['fullAddress'];
        }

        if(!!data['job']) {
            user.job = data['job'];
        }

        if(!!data['employer']) {
            user.employer = data['employer'];
        }

        if(!!data['business']) {
            user.business = data['business'];
        } else {
            user.business = false;
        }

        return user;
    }
    return undefined;
}
