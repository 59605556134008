import React, {Component} from 'react';
import FacilityCard from "./facility_card";
import {WithFirebase} from "../firebase/firebase";
import {WithAuthentication} from "../firebase/auth_provider";
import StableHeader from "../stables/header";
import * as ROUTES from "../../routes";
import {withRouter} from "react-router-dom";
import FacilityCache from "../caches/facility_cache";
import LocalizationsWithLanguage from "../localization_wrapper";
import Modal from "../additional/modal";
import ChromePicker from './chrome_picker';
import Loading from "../loading";
import {getColorFromString} from "../additional/helpers";

let strings = new LocalizationsWithLanguage({
    de:{
        createFacility: "Anlage erstellen",
        facilityOnboarding: "Mit Jutta! kannst du Plätze in den Anlagen buchen. Sieh dir die Anlage an und reserviere dir deine Plätze mit wenigen Klicks!",
        changeColor: "Buchungsfarbe ändern",
        changeColorDescription: "Hier kannst du deine Buchungsfarbe ändern. Deine neue Buchungsfarbe wird sowohl auf der Website als auch in der App angepasst. Du kannst" +
            " für jeden Stall eine neue Buchungsfarbe auswählen.",
        save: "Speichern",
        saveBookingColor: "Neue Buchungsfarbe wird gespeichert..."
    },
    en: {
        createFacility: "Create facility",
        facilityOnboarding: "Book slots in a facility with Jutta! Look at the facility and reserve your slots with a few clicks!",
        changeColor: "Change booking color",
        changeColorDescription: "Here you can change your booking color. Your new color will be adapted for the website and for the app. You can select a special" +
            " booking color for every of your stables.",
        save: "Save",
        saveBookingColor: "Save new booking color..."
    }
});

class StableFacilityOverview extends Component{

    constructor(props) {
        super(props);
        this.state = {
            stable : undefined,
            showColorModal: false,
            selectedColor: undefined,
            authUserColor: undefined,
            saving: false
        }
    }

    componentDidMount() {
        this.loadFacilities();
    }

    loadFacilities = async () => {
        if (await this.props.auth.isMemberOfStable(this.props.match.params.sid)) {
            let stable = await this.props.firebase.stableController.loadStableByID(this.props.match.params.sid);
            stable.customMemberColors = await this.props.firebase.stableController.loadCustomMemberColorsForStable(this.props.match.params.sid);

            let authUserColor;

            if(!!stable.customMemberColors && !!stable.customMemberColors[this.props.auth.firebaseUser.uid]) {
                authUserColor = stable.customMemberColors[this.props.auth.firebaseUser.uid];
            } else {
                authUserColor = getColorFromString(this.props.auth.firebaseUser.uid);
            }

            this.setState({
                stable: stable,
                selectedColor: authUserColor,
                authUserColor: authUserColor
            });

            await this.props.firebase.facilityController.loadFacilitiesForStable(this.props.match.params.sid);
            this.setState(this.state);
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    };

    toggleBookingModal = () => {
        this.setState({
            showColorModal: !this.state.showColorModal
        });
    };

    saveNewColor = async () => {
        this.setState({
            saving: true
        });

        this.state.stable.customMemberColors = await this.props.firebase.stableController.saveCustomBookingColorForStable(this.props.match.params.sid, this.state.selectedColor);

        this.setState({
            showColorModal: false,
            saving: false,
            stable: this.state.stable,
            authUserColor: this.state.selectedColor
        });
    };

    render() {

        let facilityIds = FacilityCache.getFacilityIdsForStable(this.props.match.params.sid);

        let facilities = [];
        if (!!facilityIds) {
            facilityIds.forEach( function(id) {
                let facility = FacilityCache.getFacilityById(id);
                if (!!facility) {
                    facilities.push(facility );
                }
            });

            facilities = facilities.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        }

        return (
            <div>
                <Modal show={this.state.showColorModal} onClose={this.toggleBookingModal}>
                    {
                        this.state.saving &&
                            <div>
                                <h4>{ strings.saveBookingColor }</h4>
                            </div>
                    }
                    {
                        !this.state.saving &&
                        <div>
                            <h1>{strings.changeColor}</h1>
                            <p className={"mb-2"}>{strings.changeColorDescription}</p>
                            <ChromePicker
                                color={this.state.selectedColor}
                                onChange={(color) => this.setState({selectedColor: color['hex'].substring(1)})}
                                disableAlpha={true}
                            />

                            <button className="width-50 mt-2" onClick={() => this.saveNewColor()}>{ strings.save }</button>
                        </div>
                    }
                </Modal>

                <div className="row">
                    <StableHeader name={this.state.stable == null ? "" : this.state.stable.name} stables={this.props.auth.stables.active}/>

                    <button className="width-75 ml-1 secondary-button" onClick={() => this.toggleBookingModal()}>
                        <div className="circle" style={{backgroundColor: "#" + this.state.authUserColor}}/>
                        <span>{ strings.changeColor }</span>
                    </button>

                    {this.props.auth.stables.admin.hasOwnProperty(this.props.match.params.sid) ? <button className="width-50 ml-1" onClick={() => this.props.history.push(ROUTES.CREATEFACILITY.replace(":sid", this.props.match.params.sid))}>{strings.createFacility}</button> : <div/>}
                </div>


                {!facilities ? <div/> : <p>{strings.facilityOnboarding}</p>}

                <div className="facilities">
                    {facilities.map((facility, i) => {
                        return (
                            <FacilityCard
                                key={"facility"+ facility.fid}
                                image={facility.header}
                                title={facility.name}
                                fid={facility.fid}
                                sid={this.props.match.params.sid}
                                description={facility.description}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}

const StableFacilityOverviewPage = withRouter(WithAuthentication(WithFirebase(StableFacilityOverview)));
export default StableFacilityOverviewPage;