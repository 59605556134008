import React, { Component } from "react";
import CommentTile from "./tile";
import CommentContent from "./content";
import CommentReactions from "./reactions";
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import * as ROUTES from "../../../routes";
import {withRouter} from "react-router-dom";
import UserCache from "../../caches/user_cache";
import CommentCache from "../../caches/comment_cache";

class RenderedComment extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            comment: this.props.comment,
            edit: false,
        };
    }

    onChange = () => {
        if (!!this.state.comment) {
            let comment = CommentCache.getCommentByCid(this.state.comment.cid);
            this.setState({comment : comment});
        }
    };

    showUser = () => {
        this.props.history.push(ROUTES.USER.replace(":uid", this.state.comment.uid));
    };

    componentDidMount() {
        if (!!this.state.comment) {
            let name = UserCache.getUserNameForUid(this.state.comment.uid);
            if (!!name) {
                this.state.comment.userName = name;
            }
        }
    }

    render() {
        if(!this.state.comment || !!this.state.comment.deletedAt || !this.props.auth) {
            return null;
        }
        if (this.props.auth.reactions.comments.hasOwnProperty(this.state.comment.cid)) {
            this.state.comment.userLikeType = this.props.auth.reactions.comments[this.state.comment.cid];
        }
        let canEdit = this.props.canEdit || this.state.comment.uid === this.props.auth.firebaseUser.uid;
        let canDelete = this.props.canDelete || this.state.comment.uid === this.props.auth.firebaseUser.uid;
        let canReact = this.props.canReact === undefined ? true : this.props.canReact;

        return(
            <div className="comment-container">
                <CommentTile showUser={this.showUser} comment={this.state.comment} canEdit={canEdit} canDelete={canDelete} onChange={this.onChange}/>
                <CommentContent comment={this.state.comment}/>
                { canReact && <CommentReactions firebase={this.props.firebase} auth={this.props.auth} onChange={this.onChange} comment={this.state.comment}/> }
            </div>
        )
    }
}

export default withRouter(WithAuthentication(WithFirebase(RenderedComment)));