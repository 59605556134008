const RepetitionWeek = {
    ONEWEEK : 'RepetitionWeek.ONEWEEK',
    TWOWEEKS : 'RepetitionWeek.TWOWEEKS',
    THREEWEEKS : 'RepetitionWeek.THREEWEEKS',
    FOURWEEKS : 'RepetitionWeek.FOURWEEKS',
    FIVEWEEKS : 'RepetitionWeek.FIVEWEEKS',
    SIXWEEKS : 'RepetitionWeek.SIXWEEKS',
    SEVENWEEKS : 'RepetitionWeek.SEVENWEEKS',
    EIGHTWEEKS : 'RepetitionWeek.EIGHTWEEKS',
    NINEWEEKS : 'RepetitionWeek.NINEWEEKS',
    TENWEEKS : 'RepetitionWeek.TENWEEKS',
    ELEVENWEEKS : 'RepetitionWeek.ELEVENWEEKS',
    TWELVEWEEKS : 'RepetitionWeek.TWELVEWEEKS',
};

export default RepetitionWeek;