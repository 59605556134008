import {getHorseAvatarUrl} from "../additional/helpers";

export class ElasticSearchStableHorse{
    name;
    id;
    avatar;

    constructor({id,name} = {}){
        this.name = name;
        this.id = id;
        this.avatar = getHorseAvatarUrl(id);
    }

}

function validateElasticJSON(json) {
    return !!json
        && !!json["name"]
        && !!json["id"];
}

export function elasticSearchHorseFromElasticJSON(json) {
    if (validateElasticJSON(json)) {
        return new ElasticSearchStableHorse({
            id: json['id'],
            name: json['name']
        })
    }
    return null;
}