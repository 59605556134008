import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import axios from "axios";
import * as config from "../config";
import {createCommentFromFirestoreDocument, createCommentFromHttps} from "../models/comment";
import LikeType from "../models/live_type";
import CommentCache from "../caches/comment_cache";
import PostCache from "../caches/post_cache";

const apiUrl = config.apiURL + '/posts/comments';

class FirebaseCommentController {
    /**
     * @param {firebase} app
     */
    constructor(app) {
        this.app = app;
    }

    /**
     * @param {Comment} comment
     */
    createComment = async (comment) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = comment.toHttpBody();
        body['idToken'] = idToken;

        let response = await axios.post(apiUrl, body);

        let updatedComment = createCommentFromHttps(response.data);

        if (!!updatedComment) {
            CommentCache.updateCacheEntry(updatedComment);
            CommentCache.prependPostCommentIds(comment.pid,[updatedComment.cid])

            let post = PostCache.getPostByPid(comment.pid);
            post.commentCount++;
            PostCache.updateCacheEntryWithoutTimeout(post);
        }
        return updatedComment;
    };

    /**
     * @param {Comment} comment
     */

    deleteComment = async (comment) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            pid: comment.pid,
            cid: comment.cid,
            idToken: idToken
        };

        await axios.post(apiUrl + '/delete', body);
    };

    /**
     * @param {string} pid
     * @param {Date} dateTime
     * @param {function} callback
     */
    getCommentsOlderThanByPid = async (pid, dateTime, callback) => {
        let comments = [];

        if(!!pid && !!dateTime) {

            let commentsSnap = await this.app.firestore()
                .collection('posts')
                .doc(pid)
                .collection('comments')
                .where("deleted","==",null)
                .orderBy('createdAt', 'desc')
                .startAfter(dateTime.getTime())
                .limit(20).get();

            for(let i = 0; i < commentsSnap.docs.length; i++) {

                let comment = createCommentFromFirestoreDocument(commentsSnap.docs[i]);
                if (!!comment) {
                    CommentCache.updateCacheEntry(comment);
                    comments.push(comment.cid);
                    this.updatePostCountForComment(comment.pid, comment.cid,callback);
                }

            }

            CommentCache.appendPostCommentIds(comments);
        }

        return comments;
    };

    /**
     * @param {string} pid
     * @param {function} callback
     */

    loadPreviewCommentByPid = async (pid, callback) => {
        let comment = undefined;
        if(!!pid) {
            let commentSnap = await this.app.firestore().collection('posts')
                .doc(pid)
                .collection('comments')
                .where("deleted","==",null)
                .orderBy('createdAt', 'desc')
                .limit(1)
                .get();

            for(let i = 0; i < commentSnap.docs.length; i++) {
                comment = createCommentFromFirestoreDocument(commentSnap.docs[i]);
            }

            if(!!comment) {
                CommentCache.updateCacheEntry(comment);
                this.updatePostCountForComment(pid, comment.cid,callback);

                let oldComments = CommentCache.getPostCommentIds(pid);
                if (!oldComments || oldComments.length === 0 || oldComments[0].cid !== comment.cid ) {
                    CommentCache.overwritePostCommentIds(pid,[ comment.cid]);
                }
                return comment.cid;
            }
        }
        return undefined;
    };

    updatePostCountForComment = async (pid, cid,callback) => {
        if (!!pid && !!cid && !!CommentCache.getCommentByCid(cid)) {
            let countsRef = this.app.database().ref().child('posts/' + pid + '/comment/' + cid + '/counts');
            let counts = (await countsRef.once('value')).val();

            let comment = CommentCache.getCommentByCid(cid);
            if(counts != null) {
                if (counts.hasOwnProperty('lC')) {
                    comment.likes = counts['lC'];
                }
                if (counts.hasOwnProperty('dC')) {
                    comment.dislikes = counts['dC'];
                }
                if (counts.hasOwnProperty('aC')) {
                    comment.commentCount = counts['aC'];
                }
                CommentCache.updateCacheEntryWithoutTimeout(comment);
                if (!!callback) {
                    callback()
                }
            }
        }
    };

    commentSaveUserLikeType = async (auth, comment, likeType) => {
        let firebaseUser = await this.app.auth().currentUser;
        let idToken = await firebaseUser.getIdToken(true);

        let body = {
            'idToken': idToken,
            'cid': comment.cid,
            'pid': comment.pid,
            'likeType': likeType,
        };
        await axios.post(apiUrl + '/saveLikeType', body );
    };


    changeUserLikeTypeForComment = (auth,comment,oldLikeType,newLikeType) => {
        switch (newLikeType) {
            case LikeType.DISLIKE:
                if (oldLikeType === LikeType.LIKE) {
                    comment.likes --;
                    comment.dislikes ++;
                    comment.userLikeType = LikeType.DISLIKE;
                }
                else if (oldLikeType === LikeType.DISLIKE) {
                    comment.dislikes --;
                    comment.reactionCount--;
                    comment.userLikeType = LikeType.NEUTRAL;
                }
                else {
                    comment.dislikes ++;
                    comment.reactionCount++;
                    comment.userLikeType = LikeType.DISLIKE;
                }
                break;
            case LikeType.LIKE:
                if (oldLikeType === LikeType.LIKE) {
                    comment.likes --;
                    comment.reactionCount--;
                    comment.userLikeType = LikeType.NEUTRAL;
                }
                else if (oldLikeType === LikeType.DISLIKE) {
                    comment.likes ++;
                    comment.dislikes --;
                    comment.userLikeType = LikeType.LIKE;
                }
                else {
                    comment.likes ++;
                    comment.reactionCount++;
                    comment.userLikeType = LikeType.LIKE;
                }
                break;
            default:
                break;
        }
        this.commentSaveUserLikeType(auth,comment.pid,newLikeType);
        CommentCache.updateCacheEntryWithoutTimeout(comment);
        return comment;
    };



}

export default FirebaseCommentController;