import React, {Component, Fragment} from 'react';
import {Footer, LogoTop} from "../navigation/Footer";
import LocalizationsWithLanguage from "../localization_wrapper";

const TermsOfUseWithPage= () => {
    return (
        <TermsOfUse/>
    )
};

class TermsOfUse extends Component {
    render() {
        return (
           <div id="master">
               <p>„Jutta“ wird betrieben von MANDARIN MEDIEN Gesellschaft für digitale Lösungen mbH Mueßer Bucht 1, 19063 Schwerin. „Jutta“ ist sowohl über das Internet, als auch über mobile Applikationen   erreichbar. Diese Nutzungsbedingungen gelten für beide Anwendungsbereiche.</p>

               <h4 id="1vertragsgegenstand">1. Vertragsgegenstand</h4>

               <p>1.1 Jutta ist eine werbefinanzierte, für den Nutzer kostenfreie Social Media Plattform rund um den Themenbereich Pferde und Pferdesport. </p>

               <p>1.2 Insbesondere bietet Jutta seinen Nutzern eine Plattform für </p>

               <ul>
                   <li>Inhalte (Bilder, Videos, Texte, Kleinanzeigen), welche durch andere Nutzer oder aber Werbetreibende auf Jutta zur Verfügung gestellt werden, </li>

                   <li>Kommunikation der Jutta-Nutzer untereinander im Rahmen von Chats, privaten Nachrichten, Reaktionen zu Inhalten anderer Nutzer (Likes, Dislikes, Kommentare)</li>

                   <li>private, nichtgewerblichen Kleinanzeige (Kaufgesuche und Verkaufangebote)</li>

                   <li>allgemeine oder personalisierter Werbung.</li>
               </ul>

               <p>1.3 Vom Nutzer erstellte Inhalte können durch andere Nutzer weiterverbreitet, kopiert und kommentiert werden. Hierbei handelt es sich beispielsweise um</p>

               <ul>
                   <li>Kommentare zu Post von Nutzern</li>

                   <li>Likes/Dislikes</li>

                   <li>geteilte Inhalte innerhalb der Jutta! Plattform als auch außerhalb (per E-Mail, andere Plattformen wie WhatsApp, Twitter)</li>
               </ul>

               <p>1.4 Grundsätzlich ist für die Nutzung von Jutta eine Registrierung erforderlich. Die im sozialen Netzwerk erfolgte Außendarstellung ist für andere Nutzer von Jutta sichtbar. Jutta bietet auch die Möglichkeit, mit anderen Nutzern und Dritten auch in Echtzeit zu interagieren. Die Registrierung bei Jutta ist kostenfrei. </p>

               <h4 id="2nutzungsvoraussetzungen">2. Nutzungsvoraussetzungen</h4>

               <p>2.1 Sofern der Nutzer sich bei Jutta registriert, wird ein Nutzerkonto für den Nutzer angelegt. Dadurch kommt der Vertrag zur Nutzung von Jutta zwischen dem Nutzer und Mandarin Medien zu Stande. Bei dem Nutzerkonto handelt es sich um ein Nutzerkonto, das nicht im sozialen Netzwerk sichtbar ist. Durch seine Inhalte, die Kommunikation mit anderen Jutta Nutzern oder das Schalten von Kleinanzeigen tritt der Nutzer für andere in Erscheinung. Auch ermöglicht eine Suchfunktion das Auffinden des Profilnamen des Nutzers. </p>

               <p>2.2 Die Möglichkeit zur Nutzung von Jutta stellt kein Angebot dar, sondern nur eine Aufforderung zur Abgabe eines Angebots. Durch den Abschluss der Registrierung gibt der Nutzer ein Angebot zum Abschluss des Vertrages über die Nutzung von Jutta ab. Jutta nimmt dieses Angebot des Nutzers durch Bereitstellung der entsprechenden Dienstes an. Erst durch diese Annahme kommt der Vertrag zwischen dem Nutzer und Mandarin Medien zustande. Jutta ist nicht zum Vertragsschluss verpflichtet. </p>

               <p>2.3 Der Nutzer sichert zu, dass alle von ihm angegebenen Daten im Rahmen der Registrierung wahr und vollständig sind und er diese Daten aktuell hält. Bei Registrierung hat der Nutzer insofern seinen Vor- und Nachnamen, seine E-Mail Adresse sowie ein Passwort anzugeben. Mithin ist es dem Nutzer nur erlaubt, ein Nutzerkonto anzulegen. Das Anlegen mehrerer Nutzerkonten ist verboten. </p>

               <p>2.4 Der Nutzer sichert zu, dass er das das sechzehnte Lebensjahr vollendet hat.</p>

               <p>2.5 Jutta kann technisch nicht mit Sicherheit feststellen, ob ein bei Jutta registrierter Nutzer tatsächlich diejenige Person darstellt, die der Nutzer vorgibt zu sein. Jutta leistet daher keine Gewähr für die tatsächliche Identität eines Nutzers.</p>

               <p>2.6 Der Nutzer ist verpflichtet, das von ihm gewählte Passwort geheim zu halten, um so den Zugriff Unberechtigter auf sein Nutzerkonto zu vermeiden. Jutta wird das Passwort nicht an Dritte weitergeben, nur für den Anmeldevorgang benutzen und den Nutzer – vom Anmeldevorgang abgesehen – zu keinem Zeitpunkt nach dem Passwort fragen. Aus Sicherheitsgründen und um Missbrauch vorzubeugen wird dem Nutzer empfohlen, sein Passwort in regelmäßigen Abständen zu ändern. Die Sicherung und Aufbewahrung der Zugangsdaten zum Jutta Mitgliedskonto (insbesondere des Passworts) fällt in den ausschließlichen Verantwortungsbereich des Nutzers. </p>

               <h4 id="3allgemeinepflichtendesnutzers">3. Allgemeine Pflichten des Nutzers</h4>

               <p>3.1 Der Nutzer ist verpflichtet,</p>

               <p>(a) ausschließlich wahre und nicht irreführende Angaben zu machen sowie seinen Klarnamen und keine Pseudonyme oder Künstlernamen zu verwenden;</p>

               <p>(b) bei der Nutzung von Jutta und der Inhalte auf den Jutta Websites geltendes Recht
                   sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer insbesondere Folgendes untersagt:</p>

               <ul>
                   <li>Verwendung beleidigender oder verleumderischer Inhalte, unabhängig davon, ob diese Inhalte andere Nutzer, Jutta-Mitarbeiter oder andere Personen oder Unternehmen betreffen;</li>

                   <li>Verwendung pornografischer, gewaltverherrlichender, missbräuchlicher, sittenwidriger oder Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder Vertrieb von pornografischen, gewaltverherrlichenden, missbräuchlichen, sittenwidrigen oder Jugendschutzgesetze verletzende Waren oder Dienstleistungen;</li>

                   <li>unzumutbare Belästigungen anderer Nutzer, insbesondere durch Spam (vgl. § 7 Gesetz gegen den unlauteren Wettbewerb – UWG);</li>

                   <li>Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-, Geschmacksmuster- oder Gebrauchsmusterrecht) geschützten Inhalten, ohne dazu berechtigt zu sein, oder Bewerbung, Angebot und/oder Vertrieb von gesetzlich geschützten Waren oder Dienstleistungen, ebenfalls ohne dazu berechtigt zu sein; oder; sowie</li>

                   <li>Vornahme von anzüglicher oder sexuell geprägter Kommunikation (explizit oder implizit).</li>
               </ul>

               <p>3.2 Folgende Handlungen sind dem Nutzer untersagt:
                   (a) Verbreitung und öffentliche Wiedergabe von Inhalten anderer Nutzer, außer diese Verbreitung und öffentliche Wiedergabe ist im Rahmen der jeweiligen Jutta-Anwendung vorgesehen oder der andere Nutzer hat der Verbreitung und öffentlichen Wiedergabe zugestimmt; und/oder</p>

               <p>(b) jede Handlung, die geeignet ist, die Funktionalität der Jutta Infrastruktur zu beeinträchtigen, insbesondere diese übermäßig zu belasten.</p>

               <p>(c) vom Betreiber nicht genehmigte Bewerbung von Waren und Dienstleistungen außerhalb dafür vorgesehener Werbemöglichkeiten, insbesondere in Gruppen, Gruppenchats, Privatnachrichten, oder Streams.</p>

               <p>3.3 Jutta ist nicht verpflichtet, vom Nutzer bereitgestellte Daten und/oder Informationen anderen Nutzern zur Verfügung zu stellen, wenn diese Daten und/oder Informationen nicht im Einklang mit den Ziffern 3.1 und 3.2 stehen. Jutta ist berechtigt, nach den Ziffern 3.1 und 3.2 unzulässige Daten und/oder Informationen ohne Vorankündigung gegenüber dem Nutzer von den Jutta Websites zu entfernen.</p>

               <h4 id="4nderungenderleistungen">4. Änderungen der Leistungen</h4>

               <p>4.1 Jutta behält sich vor, die auf den Jutta Websites angebotenen Leistungen zu ändern oder abweichende Leistungen anzubieten.</p>

               <p>4.2 Jutta behält sich darüber hinaus vor, die auf den Jutta Websites angebotenen Leistungen zu ändern oder abweichende Leistungen anzubieten. </p>

               <p>4.3 Jutta behält sich ferner vor, diese AGB jederzeit ohne Nennung von Gründen zu ändern. Jutta wird den Nutzer über Änderungen der AGB rechtzeitig benachrichtigen. Widerspricht der Nutzer der Geltung der neuen AGB nicht innerhalb von vier (4) Wochen nach der Benachrichtigung, gelten die geänderten AGB als vom Nutzer angenommen. Jutta wird den Nutzer in der Benachrichtigung auf sein Widerspruchsrecht und die Bedeutung der Widerspruchsfrist hinweisen.</p>

               <h4 id="5entgelte">5. Entgelte</h4>

               <p>Die Nutzung von Jutta ist für den Nutzer unentgeltlich. </p>

               <h4 id="6laufzeitsperrung">6. Laufzeit, Sperrung</h4>

               <p>6.1 Wenn im Einzelfall keine besonderen Bestimmungen zur Laufzeit und Kündigung einzelner Anwendungen oder Jutta Mitgliedschaften entgegenstehen, kann das Vertragsverhältnis von beiden Seiten jederzeit beendet werden.</p>

               <p>6.2 Auch bei Vorliegen besonderer Bestimmungen zur Laufzeit und Kündigung haben beide Parteien das Recht, das Vertragsverhältnis bei Vorliegen eines wichtigen Grundes jederzeit ohne Einhaltung einer Frist außerordentlich zu kündigen. Ein wichtiger Grund zur außerordentlichen Kündigung liegt vor, wenn die Fortsetzung des Vertragsverhältnisses bis zum Ablauf der gesetzlichen Kündigungsfrist für die kündigende Partei unter Berücksichtigung aller Umstände des Einzelfalls und unter Abwägung der Interessen beider Parteien nicht zumutbar ist. Wichtige Gründe sind für Jutta insbesondere die folgenden Ereignisse:</p>

               <p>(a) Nichteinhaltung gesetzlicher Vorschriften durch den Nutzer;</p>

               <p>(b) Verstoß des Nutzers gegen seine vertraglichen Pflichten;</p>

               <p>(c) der Ruf der auf den Jutta Websites angebotenen Dienste wird durch die Präsenz des Nutzers erheblich beeinträchtigt (z. B., wenn sich nach Registrierung des Nutzers herausstellt, dass der Nutzer wegen einer vorsätzlichen Straftat rechtskräftig verurteilt ist und anderen Nutzern diese Verurteilung bekannt ist);</p>

               <p>(d) der Nutzer wirbt für Vereinigungen oder Gemeinschaften – oder deren Methoden oder Aktivitäten –, die von Sicherheits- oder Jugendschutzbehörden beobachtet werden;</p>

               <p>(e) der Nutzer schädigt einen oder mehrere andere Nutzer; oder</p>

               <p>6.3 Bei Vorliegen eines wichtigen Grundes nach Ziffer 6.2 kann Jutta unabhängig von einer Kündigung nach Ziffer 6.2 auch die folgenden Sanktionen gegen den Nutzer verhängen:</p>

               <p>(a) Löschung von Inhalten, die der Nutzer eingestellt hat;</p>

               <p>(b) Sperrung des Zugangs zum Dienst der Jutta Websites oder einzelner Anwendungen; oder</p>

               <p>(c) Ausspruch einer Abmahnung.</p>

               <p>Im Falle der Sperrung des Nutzerkontos ist der Nutzer nicht berechtigt, ein neues Nutzerkonto anzulegen. </p>

               <h4 id="7verantwortlichkeitfrinhalteverfgbarkeit">7. Verantwortlichkeit für Inhalte, Verfügbarkeit</h4>

               <p>7.1 Jutta übernimmt keine Verantwortung für die von den Nutzern der Jutta Websites bereitgestellten Inhalte, Daten und/oder Informationen sowie für Inhalte auf verlinkten externen Websites. Jutta gewährleistet insbesondere nicht, dass diese Inhalte wahr sind, einen bestimmten Zweck erfüllen oder einem solchen Zweck dienen können.</p>

               <p>7.2 Soweit der Nutzer eine gesetzes- oder vertragswidrige Benutzung der Jutta Websites und Apps (einschließlich der Verwendung von Pseudonymen oder täuschenden Identitäten) bemerkt, kann er diese melden.</p>

               <p>7.3 Hinsichtlich Daten oder Inhalten, die gemäß den AGB, den Datenschutzbestimmungen von Jutta oder den Einstellungen des Nutzers öffentlich verfügbar sind oder waren und durch einen Dritten gespeichert wurden, trifft Jutta keine Pflicht zur Unterbindung einer solchen Speicherung beim Dritten.</p>

               <p>7.4 Jutta bietet dem Nutzer lediglich eine Plattform an und beteiligt sich grundsätzlich nicht inhaltlich an der Kommunikation der Nutzer untereinander. </p>

               <p>7.5 Der Nutzer erkennt an, dass eine 100%ige Verfügbarkeit der Jutta Websites technisch nicht zu realisieren ist. Jutta bemüht sich jedoch, die Jutta Websites möglichst konstant verfügbar zu halten. Insbesondere Wartungs-, Sicherheits- oder Kapazitätsbelange sowie Ereignisse, die nicht im Machtbereich von Jutta stehen (wie z. B. Störungen von öffentlichen Kommunikationsnetzen, Stromausfälle etc.), können zu Störungen oder zur vorübergehenden Einstellung des Dienstes auf den Jutta Websites führen.</p>

               <h4 id="8nutzungsrechte">8. Nutzungsrechte</h4>

               <p>Der Nutzer räumt Mandarin Medien mit dem Einstellen bzw. Hochladen von Inhalten in Jutta ein einfaches, räumlich und zeitlich unbeschränktes Recht ein, die Inhalte in Jutta (Internet und Apps) vervielfältigen, verbreiten und öffentlich wiedergeben zu dürfen. Die Nutzungseinräumung erstreckt sich auf alle - auch unter anderen Top-Level- Domains (TLD) als der TLD de - von Mandarin Medien betriebenen Internetangebote. Als Urhebernennung bezüglich der Inhalte wird der bei der Registrierung gewählte Vor- und Nachname verwendet, es sei denn, der Nutzer hat zusätzlich für die Dienste einen Nicknamen gewählt. Eine Vergütungspflicht von Mandarin Medien wird durch die Nutzungsrechtsüberlassung nicht begründet.</p>

               <h4 id="9haftungvonjutta">9. Haftung von Jutta</h4>

               <p>9.1 Mandarin Medien haftet unbeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer Pflichtverletzung von Mandarin Medien, eines gesetzlichen Vertreters oder Erfüllungsgehilfen von Mandarin Medien beruhen sowie für Schäden, die durch Fehlen einer von Mandarin Medien  garantierten Beschaffenheit hervorgerufen wurden oder bei arglistigem Verhalten von Mandarin Medien . Des Weiteren haftet Mandarin Medien unbeschränkt für Schäden, die durch Mandarin Medien oder einem ihrer gesetzlichen Vertreter oder Erfüllungsgehilfen vorsätzlich oder durch grobe Fahrlässigkeit verursacht wurden.</p>

               <p>9.2 Bei der leicht fahrlässig verursachten Verletzung wesentlicher Vertragspflichten haftet Mandarin Medien  außer in den Fällen der Ziffer 9.1 oder 9.3 der Höhe nach begrenzt auf den vertragstypisch vorhersehbaren Schaden. Wesentliche Vertragspflichten sind abstrakt solche Pflichten, deren Erfüllung die ordnungsgemäße Durchführung eines Vertrages überhaupt erst ermöglicht und auf deren Einhaltung die Vertragsparteien regelmäßig vertrauen dürfen. Im Übrigen ist eine Haftung von Mandarin Medien  ausgeschlossen.</p>

               <p>9.3 Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>

               <h4 id="10anwendbaresrechtgerichtsstandundsonstiges">10. Anwendbares Recht, Gerichtsstand und Sonstiges</h4>

               <p>10.1 Jutta kann Unterauftragnehmer einsetzen. Jutta bleibt auch bei Einsetzung von Unterauftragnehmern verantwortlich für die Erfüllung der durch Jutta übernommen Pflichten. </p>

               <p>10.2 Jutta ist berechtigt, Rechte und Pflichten ganz oder teilweise an Dritte zu übertragen. Im Übrigen ist das Recht auf Übertragung ausgeschlossen. </p>

               <p>10.3 Jutta kann Erklärungen gegenüber dem Nutzer per E-Mail, Fax oder Brief übermitteln, sofern in den AGB nicht anders geregelt. </p>

               <p>10.4 Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.</p>

               <p>10.5 Erfüllungsort ist der Sitz der Mandarin Medien.</p>

               <p>10.6 Es gilt deutsches Recht unter Ausschluss des internationalen Privatrechts und des ins deutsche Recht übernommenen UN-Kaufrechts.</p>
           </div>
        )
    }
}



export default TermsOfUse;

export {TermsOfUseWithPage}
