import React, {Component} from 'react';
import {Link} from "react-router-dom";

class UserCard extends Component {

    imgHasFailed = false;

    onImageLoadFail = (event) => {
        if (!this.imgHasFailed) {
            this.imgHasFailed = true;
            event.target.onerror = null;
            event.target.src = "/img/nopicture.png";
        }
    };

    render() {
        if (!!this.props.link) {
            return (
                <div className="user">
                   <div className="list-tile">
                       <Link to={this.props.link || ""}>
                           <div className="row justify-content-start">
                               <img className="user-avatar" src={this.props.image} onError={this.onImageLoadFail}/>
                               <p className="width-50">{this.props.name}</p>
                           </div>
                       </Link>
                       {
                           !!this.props.options  ? this.props.options() : ""
                       }
                   </div>
                </div>
            )

        }

        return (
                <div className="user">
                    <div className="list-tile">
                        <div className="row justify-content-start">
                            <img className="user-avatar" src={this.props.image} onError={this.onImageLoadFail}/>
                            <p>{this.props.name}</p>
                        </div>
                        {
                            !!this.props.options  ? this.props.options() : ""
                        }
                    </div>
                </div>
        )

    }
}

export default UserCard;