import * as elasticSearchController from "../elastic/elastic_controller";
import SearchResultEntry from "../elastic/search_result";
import React, {Component, Fragment} from "react";
import {WithAuthentication} from "../firebase/auth_provider";
import {WithFirebase} from "../firebase/firebase";
import {withRouter} from "react-router-dom";
import LocalizationsWithLanguage from "../localization_wrapper";

let strings = new LocalizationsWithLanguage({
    de:{
        searchPlaceholder:"Suche nach Reitställen",
        search: "Suche",
    },
    en: {
        searchPlaceholder:"Search for stables",
        search: "search",
    }
});


class SearchStables extends Component {

    onImageLoadFail = (event) => {
        event.target.onerror = null;
        event.target.src = "/img/nopicture.png";
    };

    constructor(props) {
        super(props);
        this.state = {
            term: "",
            searchStableResults: undefined,
            amount: this.props.amount || 5
        }
    }

    search = async (event) =>   {
        let val = event.target.value;
        this.state.term = val;
        this.setState({term: val});
        if (!!val && val.length >= 2) {
            let stableResult = await elasticSearchController.searchStable(val,this.state.amount);
            this.state.searchStableResults = stableResult;
        } else {
            this.state.searchStableResults = {};
        }
        if (!!this.props.onSearch) {
            this.props.onSearch(this.state.searchStableResults);
        }
        this.setState(this.state);
    };

    onClick = async (link) =>  {
        this.props.history.push(link);
        this.setState({
            term: "",
            searchStableResults: undefined
        });
    };

    render() {
        let stablesToRender = !!this.state.searchStableResults && !!this.state.searchStableResults.stables ? this.state.searchStableResults.stables : [];
        let searchResults;

        if( !this.props.hideResults && stablesToRender.length !== 0) {
            searchResults = <div className="search-results">
               {
                    stablesToRender.map((stable, i) => {
                        return (
                            <SearchResultEntry key={"stable"+ stable.sid} image={stable.header} name={stable.name} onClick={ (e) => this.onClick(`/stables/${stable.sid}/information`)}/>
                        )
                    })
                }

            </div>
        }

        return (
            <Fragment>
                <input
                    name="search"
                    className="search"
                    type="text"
                    onChange={this.search}
                    value={this.state.term}
                    placeholder={strings.searchPlaceholder}
                />
                {searchResults}
            </Fragment>
        )
    }
}

export default withRouter(WithAuthentication(WithFirebase(SearchStables)));
