const Privacy = {
    PUBLIC : 'Privacy.PUBLIC',
    PROTECTED : 'Privacy.PROTECTED',
    SECRET : 'Privacy.SECRET',
};


export default Privacy;


