import {getHorseAvatarUrl, getUserAvatarUrl} from "../additional/helpers";
import LikeType from "./live_type";
import {createShareFromHttps} from "./share";
import {createSharedLinkFromJSON} from "./shared_link";

export class Post{


    pid;
    uid;
    userName;
    userAvatar;
    createdAt;
    edited;
    textContent;

    images;
    videos;
    relations;

    stableId;
    stableName;
    asStable;

    geolocation;

    mentions;  //TEMPORÄR
    // List<Mention> mentions;


    // Comment previewComment;

    sharedInstance;
    sharedLink;



    likes;
    dislikes;
    shareCount;
    commentCount;
    reactionCount;

    deleted;


    userLikeType;
    //
    //    AutomaticPostType automaticPostType;
    //     HorseRelation automaticPostHorseRelation;
    //     String automaticPostHorseName;
    //     String automaticPostHorseId;
    //

    channelId;
    channelName;
    groupId;
    groupName;

    storyHorseId;
    storyHorseAvatar;
    storyHorseName;
    storyDate;


    isSaving = false;
    //     bool isHot = false;
    //     bool isBusiness = false;

    constructor({ pid = "", uid = "", userName = "", createdAt = new Date(), edited = undefined, textContent = "", images = [], videos = [], relations = {}, countryCode = undefined, geolocation = undefined, stableId = undefined, stableName = undefined, asStable=false } = {}) {
        this.pid = pid;
        this.uid = uid;
        this.userName = userName;
        this.userAvatar = getUserAvatarUrl(uid);
        this.createdAt = createdAt;
        this.edited = edited;
        this.textContent = textContent;
        this.images = images;
        this.videos = videos;
        this.stableId = stableId;
        this.stableName = stableName;
        this.asStable = asStable;
        this.likes = 0;
        this.dislikes = 0;
        this.shareCount = 0;
        this.commentCount = 0;
        this.reactionCount = 0;
        this.geolocation = geolocation;
        this.countryCode = countryCode;
        this.userLikeType= LikeType.NEUTRAL;
    }

    toHttpBody = () => {
        let jsonMap = {};
        if (this.geolocation != null) {
            jsonMap['latitude']= this.geolocation.lat;
            jsonMap['longitude']= this.geolocation.long;
        }
        jsonMap['textContent'] = this.textContent;
        jsonMap['userName'] = this.userName;
        jsonMap['countryCode'] = this.countryCode;

        if(this.sharedInstance != null && this.sharedInstance.shareType != null) {
            jsonMap = {...jsonMap, ...this.sharedInstance.toJson()};
        }

        if (!!this.images) {
            jsonMap['images'] = JSON.stringify(this.images);
        }

        if (!!this.videos) {
            jsonMap['videos'] = JSON.stringify(this.videos);
        }

        if(!!this.sharedLink) {
            jsonMap['sharedLinkData'] = JSON.stringify(this.sharedLink.toJSON());
        }

        if (!!this.postPrivacy) {
            jsonMap['privacies'] = JSON.stringify(this.postPrivacy);
        }

        // if (relations != null) {
        //     List<Map<String, dynamic>> relationsMap = [];
        //     relations.forEach((relation) => relationsMap.add(relation.toJSON()));
        //     jsonMap['relations'] = json.encode(relationsMap);
        // }

        if(!!this.pid) {
            jsonMap['pid'] = this.pid;
        }

        if (!!this.storyHorseId && !!this.storyHorseAvatar && !!this.storyHorseName  && !!this.storyDate ) {
            jsonMap['storyHorseAvatar'] = this.storyHorseAvatar;
            jsonMap['storyHorseId'] = this.storyHorseId;
            jsonMap['storyHorseName'] = this.storyHorseName;
            jsonMap['storyDate'] = JSON.stringify(this.storyDate.getTime());
        }

        if(!!this.groupId && !!this.channelId ) {
            jsonMap['groupId'] = this.groupId;
            jsonMap['channelId'] = this.channelId;
        }

        if(!!this.stableId && !!this.stableName) {
            jsonMap['stableId'] = this.stableId;
            jsonMap['stableName'] = this.stableName;
        }

        if(!!this.asStable) {
            jsonMap['asStable'] = ""+this.asStable;
        }

        if(!!this.groupName && !!this.channelName  ) {
            jsonMap['groupName'] = this.groupName;
            jsonMap['channelName'] = this.channelName;
        }

        if (!!this.mentions) {
            jsonMap['mentionedEntities'] = this.mentions;
        }

        // if (mentions != null && mentions.isNotEmpty) {
        //     List<Map<String, dynamic>> mentionsMap = [];
        //     Set<String> mentionedUsers = new Set();
        //     Set<String> mentionedGroups = new Set();
        //     Set<String> mentionedStables = new Set();
        //
        //     mentions.forEach((mention) {
        //         mentionsMap.add(mention.toJSON());
        //         switch (mention.type) {
        //             case Classes.STABLE:
        //                 mentionedStables.add(mention.id);
        //                 break;
        //             case Classes.USER:
        //                 mentionedUsers.add(mention.id);
        //                 break;
        //             case Classes.GROUP:
        //                 mentionedGroups.add(mention.id);
        //                 break;
        //             default:
        //                 break;
        //         }
        //     });
        //     jsonMap['mentionedEntities'] = json.encode(mentionsMap);
        //
        //     if (mentionedUsers.isNotEmpty) {
        //         jsonMap['mentionedUsers'] = jsonEncode(mentionedUsers.toList());
        //     }
        //     if (mentionedGroups.isNotEmpty) {
        //         jsonMap['mentionedGroups'] = jsonEncode(mentionedGroups.toList());
        //     }
        //     if (mentionedStables.isNotEmpty) {
        //         jsonMap['mentionedStables'] = jsonEncode(mentionedStables.toList());
        //     }
        // }
        //
        // if(automaticPostType != null) {
        //     jsonMap['automaticPostType'] = automaticPostType.toString();
        // }

        return jsonMap;
    };


}

export function createPostFromFirestoreDocument(firestoreDoc) {
    if ( _validateDocument(firestoreDoc)) {

        let data = firestoreDoc.data();

        return createPostFromData(firestoreDoc.id,data)
    }
    return null;
}

export function createPostFromHttps(data) {
    if ( _validateHttps(data)) {

        return createPostFromData(data.documentID,data)
    }
    return null;
}



function createPostFromData(postId, data){

    let post = new Post( {
        pid: postId,
        uid: data['uid'],
        userName: data['userName'],
        userAvatar: getUserAvatarUrl(data['uid']),
        createdAt: new Date(data['createdAt']),
        textContent: data['textContent'],
    });

    if (data['images']) {
        post.images = data['images'];
    }

    if (data['videos']) {
        post.videos = data['videos'];
    }

    if (data['sharedInstanceId'] != null && data['sharedInstanceType'] != null ) {
        post.sharedInstance = createShareFromHttps(data);
    }

    if (!!data['storyHorseId'] && !!data['storyHorseName'] && !!data['storyDate']) {
        post.storyHorseAvatar = getHorseAvatarUrl(data['storyHorseId']);
        post.storyHorseId = data['storyHorseId'];
        post.storyHorseName = data['storyHorseName'];
        post.storyDate = new Date(parseInt(data['storyDate']));
    }

    // if(doc.data['relations'] != null) {
    //     List<Relation> newRelations = [];
    //     doc.data['relations'].forEach((relation) {
    //         newRelations.add(Relation.fromJSON(new Map<String,dynamic>.from(relation)));
    //     });
    //     post.relations = newRelations;
    // }
    //
    if(!!data['sharedLinkData']) {
        post.sharedLink = createSharedLinkFromJSON(data['sharedLinkData']);
    }

    if(!!data['groupId']&& !!data['channelId'] && !!data['groupName'] && !!data['channelName']) {
        post.groupId = data['groupId'];
        post.channelId = data['channelId'];
        post.groupName = data['groupName'];
        post.channelName = data['channelName'];
    }

    if(data['stableId'] != null && data['stableName'] != null) {
        post.stableId = data['stableId'];
        post.stableName = data['stableName'];
    }

    if(data['asStable'] != null) {
        post.asStable = data['asStable'];
    }

    if(data['deleted'] != null ) {
        post.deletedAt = new Date(data['deleted']);
    }
    if(data['relevanceChangedAt'] != null ) {
        post.relevanceChangedAt = data['relevanceChangedAt'];
    }

    // if(data['deletedBy'] != null ) {
    //     post.deletedBy = DeletedBy.values.firstWhere((c) => c.toString() == doc.data['deletedBy'], orElse: () => null);
    // }

    if (!!data['mentionedEntities']) {
        post.mentions = data['mentionedEntities'];
    }

    // if (!!data['mentionedEntities'] != null ) {
    //     List<Mention> _mentions = [];
    //     data['mentionedEntities'].forEach((mentionData) {
    //         if (mentionData is Map) {
    //             _mentions.add(Mention.fromJSON(mentionData));
    //         }
    //     });
    //     post.mentions = _mentions;
    // }


    // if(doc.data['automaticPostType'] != null) {
    //     post.automaticPostType = AutomaticPostType.values.firstWhere((e) => e.toString() == doc.data['automaticPostType'], orElse: () => null);
    //     if (doc.data['aPHorseRel'] != null && doc.data['aPHorseName'] != null) {
    //         switch(post.automaticPostType) {
    //             case AutomaticPostType.ADDEDHORSE:
    //             case AutomaticPostType.REMOVEDHORSE:
    //                 post.automaticPostHorseRelation = HorseRelation.values.firstWhere((r) => r.toString() == doc.data['aPHorseRel'], orElse: () => null );
    //                 post.automaticPostHorseName = doc.data['aPHorseName'];
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }
    //     if (doc.data['aPHorseId'] != null) {
    //         post.automaticPostHorseId = doc.data['aPHorseId'];
    //     }
    //     if(post.automaticPostType == AutomaticPostType.NEWAVATAR) {
    //         MainCache.networkLoaderCache.removeImageProviderFromCache(post.userAvatar);
    //     }
    // }

    return post;

}


function _validateDocument(doc) {
    return !!doc
        && !!doc.data()
        && !!doc.id
        && !!doc.data().uid
        && !!doc.data().userName
        && !!doc.data().createdAt
        && (!!doc.data().textContent || !!doc.data().images);
}


function _validateHttps(data) {
    return !!data
        && !!data.documentID
        && !!data['uid']
        && !!data['userName']
        && !!data['createdAt']
        && (!!data['textContent'] || !!data['images']);
}

/**
 * @param {AuthUser} user
 * @param {String} content
 * @param {Array} images
 * @param {Stable} stable
 * */
export function createDummyPostForAuthUser ({ user: user,content: content,images: images }) {
    let dummyPost = new Post({
        pid: new Date().getTime(),
        uid: user.uid,
        userName: user.name,
        userAvatar: user.avatar,
        geolocation: user.getGeolocation(),
        createdAt: new Date().getTime(),
        countryCode: user.countryCode,
        textContent: content
    });
    dummyPost.isSaving = true;
    dummyPost.postPrivacy = [];

    dummyPost.images = images;
    return dummyPost;
};

/**
 * @param {Post} updatedPost
 * @param {String} content
 * @param {Stable} stable
 * */
export function updateDummyPostForAuthUser({ updatedPost: updatedPost,content: content }) {
    updatedPost.textContent = content;
    updatedPost.isSaving = true;
    return updatedPost;
}
