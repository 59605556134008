import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {WithAuthentication} from "../../../firebase/auth_provider";
import {WithFirebase} from "../../../firebase/firebase";
import StablePermission from "../../../models/stable_permissions";
import StableHeader from "../../header";
import LocalizationsWithLanguage from "../../../localization_wrapper";
import StableBookingsPermission from "../../../models/stable_bookings_permissions";
import StableBookingsRepeatingPermission from "../../../models/stable_bookings_repeating_permissions";

let strings = new LocalizationsWithLanguage({
    de:{
        permissions:"Berechtigungen",
        permissionWriteNewsText:"Einsteller können Beiträge in „News“ schreiben",
        permissionReactToNewsText:"Einsteller können auf Beiträge in „News“ reagieren",
        save: "Speichern",
        proPermissions:"Pro Berechtigungen",
        bookingsPermissionText:"Buchungen sind anonym",
        bookingsRepeatingPermissionText:"Wiederholende Buchungen für alle Einsteller deaktivieren. Admins und Mitarbeiter sind davon nicht betroffen.",
    },
    en: {
        permissions:"Permissions",
        permissionWriteNewsText:"Clients can write posts in „News“",
        permissionReactToNewsText:"Clients can react to posts in „News“",
        save: "Save",
        proPermissions:"Pro permissions",
        bookingsPermissionText:"Bookings are anonymous",
        bookingsRepeatingPermissionText:"Disable repeating bookings for all boarders. Admins and employees can use this regardless.",
    }
});


class PermissionsIndex extends Component{
    constructor(props) {
        super(props);
        this.state =  {
            stable : undefined,
            membersCanWriteNews: false,
            membersCanReactToNews: false,
            bookingsPermission: false,
            bookingsRepeatingPermission: false,
        };
    }

    onSubmit = async () => {
            let _stableController = this.props.firebase.stableController;

            let permissions = [];
            if (this.state.membersCanWriteNews) {
                permissions.push(StablePermission.CLIENTSCANWRITENEWS)
            }
            if (this.state.membersCanReactToNews) {
                permissions.push(StablePermission.CLIENTSCANREACTTONEWS)
            }

            if (this.state.bookingsPermission) {
                this.state.stable.bookingsPermission = StableBookingsPermission.HIDEALL;
            } else {
                this.state.stable.bookingsPermission = StableBookingsPermission.SHOWALL;
            }
            if (this.state.bookingsRepeatingPermission) {
                this.state.stable.bookingsRepeatingPermission = StableBookingsRepeatingPermission.DISABLED;
            } else {
                this.state.stable.bookingsPermission = StableBookingsRepeatingPermission.ALLOWED;
            }

             this.state.stable.permissions = permissions;

            _stableController.updateStable(this.state.stable);

            this.props.history.push("/stables/" + this.props.match.params.sid + "/settings");
    };

    onChange = event => {
        this.setState({ [event.target.name]: !this.state[event.target.name] });
    };

    async loadDisplayedStable() {
        if (await this.props.auth.isAdminOfStable(this.props.match.params.sid)) {
            this.props.firebase.stableController.loadStableByID(this.props.match.params.sid).then(async (stable) => {
                this.setState({
                    stable : stable,
                    membersCanWriteNews: stable.permissions.includes(StablePermission.CLIENTSCANWRITENEWS),
                    membersCanReactToNews: stable.permissions.includes(StablePermission.CLIENTSCANREACTTONEWS),
                    bookingsPermission: stable.bookingsPermission === StableBookingsPermission.HIDEALL,
                    bookingsRepeatingPermission: stable.bookingsRepeatingPermission === StableBookingsRepeatingPermission.DISABLED
                });
            });
        } else {
            this.props.history.push("/stables/" + this.props.match.params.sid + "/information");
        }
    }

    componentDidMount() {
        this.loadDisplayedStable();
    }

    render() {

        return (
            <div>
                <StableHeader name={this.state.stable == null ? "" : this.state.stable.name} stables={this.props.auth.stables == null ? true : this.props.auth.stables.active} currentStable={this.state.stable}/>

                <h3> {strings.permissions}</h3>

                <div className="row justify-content-start">
                    <input
                        name="membersCanWriteNews"
                        onChange={this.onChange}
                        className={"checkbox-margin-right"}
                        type="checkbox"
                        checked={this.state.membersCanWriteNews}
                    />

                    <div>{strings.permissionWriteNewsText}</div>
                </div>
                <div className="row justify-content-start">
                    <input
                        name="membersCanReactToNews"
                        className={"checkbox-margin-right"}
                        onChange={this.onChange}
                        type="checkbox"
                        checked={this.state.membersCanReactToNews}
                    />

                    <div>{strings.permissionReactToNewsText}</div>
                </div>

                {
                    !!this.state.stable  && this.state.stable.hasPro() &&
                        <>
                            <h3> {strings.proPermissions}</h3>

                            <div className="row justify-content-start">
                                <input
                                    name="bookingsPermission"
                                    onChange={this.onChange}
                                    className={"checkbox-margin-right"}
                                    type="checkbox"
                                    checked={this.state.bookingsPermission}

                                />

                                <div>{strings.bookingsPermissionText}</div>
                            </div>

                            <div className="row justify-content-start">
                                <input
                                    name="bookingsRepeatingPermission"
                                    className={"checkbox-margin-right"}
                                    onChange={this.onChange}
                                    type="checkbox"
                                    checked={this.state.bookingsRepeatingPermission}

                                />

                                <div>{strings.bookingsRepeatingPermissionText}</div>
                            </div>


                        </>
                }

                <button onClick={!!this.state.stable ? this.onSubmit : null}>
                    {strings.save}
                </button>

            </div>
        )
    }
}

export default withRouter(WithAuthentication(WithFirebase(PermissionsIndex)));