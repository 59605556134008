import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, withRouter} from 'react-router-dom';
import './index.css';

import Firebase, {
    FirebaseInstance,
} from './components/firebase/firebase';

import AuthProvider, {
    WithAuthentication,
} from './components/firebase/auth_provider';

import Authorized from "./components/authorized";
import Unauthorized from "./components/unauthorized";
import Loading from "./components/loading";
import BlockRouteProvider from "./components/navigation/block_route_provider";
import LocalizationsWithLanguage from "./components/localization_wrapper";
import Incomplete from "./components/incomplete";
import ErrorBoundaryPage from "./components/additional/error_boundary_page";

let strings = new LocalizationsWithLanguage({
    de:{
        titleAddInfo: "Erzähl uns etwas über dich!",
    },
    en: {
        titleAddInfo: "Add some additional information",
    }
});

class App extends React.PureComponent {
    render() {
        if (this.props.auth.loading) {
            return (
                <Loading/>
            )
        }

        if (this.props.auth.firebaseUser && (!this.props.auth.user.business && !this.props.auth.user.userSettings.geolocation)) {
            return (
                <Incomplete/>
            )
        }

        if (this.props.auth.firebaseUser && (this.props.auth.user.business || !!this.props.auth.user.userSettings.geolocation)) {
            return (
                <Authorized/>
            )
        }

        return (
            <Unauthorized/>
        )
    }
}
const AppWithProviders = withRouter(BlockRouteProvider(WithAuthentication(App)));

ReactDOM.render(
    <BrowserRouter>
        <Firebase.Provider value={new FirebaseInstance()}>
            <AuthProvider>
                <ErrorBoundaryPage>
                    <AppWithProviders/>
                </ErrorBoundaryPage>
            </AuthProvider>
        </Firebase.Provider>
    </BrowserRouter>,
    document.getElementById('root')
);