import React, {Component} from 'react';
import {WithAuthentication} from "../../firebase/auth_provider";
import {WithFirebase} from "../../firebase/firebase";
import {withRouter} from "react-router-dom";
import {getIntValuesForRepetition, secondsToTimeOfDay, timeOfDayToSeconds} from "../../additional/helpers";
import LocalizationsWithLanguage from "../../localization_wrapper";
import DatePicker from "react-datepicker";
import RepetitionInterval from "../../models/repetition_interval";
import RepetitionWeek from "../../models/repetition_week";
import {getTranslationsForRepetitionInterval} from "../../additional/text_helpers";
import ContextMenu from "../../additional/context_menu";
import WeekdaySelector from "../../additional/weekday_selector";
import StableBookingsRepeatingPermission from "../../models/stable_bookings_repeating_permissions";
import BookingCache from "../../caches/booking_cache";

let strings = new LocalizationsWithLanguage({
    de:{
        title: "Buchung bearbeiten",
        date: "Datum",
        startTime: "Startzeit",
        endTime: "Endzeit",
        takeNotes: "Mach Dir Notizen!",
        slots: "Plätze",
        allSlots: "Alle Plätze",
        save: "Änderungen speichern",
        repeatLabel: "Wiederhole",
        weekRepetitionLabel: "Alle",
        week: "Woche",
        weeks: "Wochen",
        maxBookingDuration: "Die maximale Buchungsdauer beträg xxx Minuten",
        errorTitleBookingDuration: "Buchungsdauer zu lang",
        errorDescriptionBookingDuration: "Die Buchungsdauer dieser Anlage ist beschränkt. Bitte wähle eine kürzere Buchungsdauer aus.",
        errorTitleNoOpenLanes: "Keine Slots verfügbar",
        errorDescriptionNoOpenLanes: "Keine verfügbaren Plätze für diesen Zeitraum. Bitte wähle eine andere Uhrzeit.",


    },
    en: {
        title: "Edit booking",
        date: "Date",
        startTime: "Start time",
        endTime: "End time",
        takeNotes: "Take some notes",
        slots: "Slots",
        allSlots: "All slots",
        save: "Save changes",
        repeatLabel: "Repeat",
        weekRepetitionLabel: "Every",
        week: "Week",
        weeks: "Weeks",
        maxBookingDuration: "The maximum allowed booking duration  is xxx minutes",
        errorTitleBookingDuration: "Booking duration to long",
        errorDescriptionBookingDuration: "The booking duration for this facility is limited. Please selected a shorter booking duration.",
        errorTitleNoOpenLanes: "No Slots avaiable",
        errorDescriptionNoOpenLanes: "There were no available slots for your requested time period. Please try another time frame.',\n",
    }
});

class EditBooking extends Component {
    dateFormatOptions = {year: "numeric", month: "long", day: "numeric", timeZone: 'UTC' };

    constructor(props) {
        super(props);
        this.state = {
            booking: props.booking,
            facility: props.facility,
            saving: false,
            repeatingAllowed:  props.stable.bookingsRepeatingPermission !== StableBookingsRepeatingPermission.DISABLED,
            slots: props.booking.lanes.length,
            date: props.booking.day,
            start: secondsToTimeOfDay(props.booking.start),
            end: secondsToTimeOfDay(props.booking.end),
            notes: props.booking.notes,
            oldDate: props.booking.day,
            maxDurationError: false,
            repetitionInterval: props.booking.repetitionInterval || RepetitionInterval.NEVER,
            repetitionWeek: props.booking.repetitionWeek || RepetitionWeek.ONEWEEK,
            repetitionDays: props.booking.repetitionDays || []
        }
    }

    componentDidMount() {
        this.checkRepeatingPermission();
    }

    checkRepeatingPermission = async () => {
        if (this.props.stable.bookingsRepeatingPermission === StableBookingsRepeatingPermission.DISABLED) {
            let canBookRepeating = await this.props.auth.isEmployeeOfStable(this.props.match.params.sid) || await this.props.auth.isAdminOfStable(this.props.match.params.sid);
            this.setState( { repeatingAllowed: canBookRepeating})
        }
    }

    onChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    onSave = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.state.maxDurationError = false;

        this.refs['edit-booking-form'].reportValidity();
        if (!!this.state.start && !!this.state.end && this.state.facility && this.state.facility.maxBookingDuration) {
            if ( (timeOfDayToSeconds(this.state.end) - timeOfDayToSeconds(this.state.start) )> this.state.facility.maxBookingDuration) {
                this.state.maxDurationError = true;
                if (!!this.props.onError) {
                    this.props.onError(strings.errorTitleBookingDuration, strings.errorDescriptionBookingDuration);
                }
            }
        }

        if(!this.state.saving && !this.state.maxDurationError && (!!this.state.slots || !!this.state.allSlots) && !!this.state.start && !!this.state.end && !!this.state.date) {
            this.setState({ saving: true });

            let chosenDate = new Date(this.state.date);
            chosenDate.setUTCHours(12);

            let booking = this.state.booking;

            if(!!this.state.allSlots) {
                booking.lanes = Array.from(Array(parseInt(this.state.facility.maxBookers)), (x, index) => index);
            } else if(parseInt(this.state.slots) !== booking.lanes.length) {
                booking.lanes = Array.from(Array(parseInt(this.state.slots)), (x, index) => index);
            }

            booking.day = chosenDate;
            booking.notes = this.state.notes;
            booking.start = timeOfDayToSeconds(this.state.start);
            booking.end = timeOfDayToSeconds(this.state.end);
            booking.repetitionInterval = this.state.repeatingAllowed ?  this.state.repetitionInterval : null;
            booking.repetitionWeek = this.state.repeatingAllowed ? this.state.repetitionWeek : null;
            booking.repetitionDays = this.state.repeatingAllowed ? this.state.repetitionDays : null;

            this.setState({ saving: false });
            this.props.onClose(true);

            let returnData = await this.props.firebase.facilityController.updateBooking(booking, this.state.oldDate.getTime());

            if (!returnData) {
                if (!!this.props.onError) {
                    this.props.onError(strings.errorTitleNoOpenLanes, strings.errorDescriptionNoOpenLanes);
                }
            }
        }
    };

    getDateButton() {
        return (
            <div className="select-date-input">
                <span>{this.state.date.toLocaleString(strings.locale, this.dateFormatOptions)}</span>
            </div>
        )
    }

    onDateChange = (date) => {
        this.setState({
            date: date
        });
    };

    onChosenDaysChange = (chosenDays) => {
        this.setState({
            repetitionDays: chosenDays
        })
    };

    onRepetitionIntervalChange = (event) => {
        this.setState({
            repetitionInterval: event.target.value
        })
    };

    onRepetitionWeekChange = (event) => {
        this.setState({
            repetitionWeek: event.target.value
        })
    };

    getWeekSelection() {
        if(this.state.repetitionInterval === RepetitionInterval.WEEKLY) {
            let repetitionWeekValues = getIntValuesForRepetition();
            return(
                <>
                    <div className="modal-row">
                        <label htmlFor="repetitionInterval">{strings.weekRepetitionLabel}</label>
                        <select className="repetition-type-input" name="repetitionInterval" onChange={this.onRepetitionWeekChange} defaultValue={this.state.repetitionWeek}>
                            {
                                Object.values(RepetitionWeek).map((week) => {
                                    return(
                                        <option key={week} value={week}>
                                            {repetitionWeekValues[week]} {repetitionWeekValues[week] === 1 ? strings.week : strings.weeks}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="modal-row">
                        <WeekdaySelector chosenDays={this.state.repetitionDays} onChange={this.onChosenDaysChange}/>
                    </div>
                </>
            )
        }
    }

    render() {
        const { date, start, end, notes, slots, allSlots } = this.state;
        const maxBookingDuration = !!this.state.facility ?  this.state.facility.maxBookingDuration : null ;

        let isInvalid = !start || !end || (!allSlots && !slots) || (!allSlots && slots <= 0);

        if (!isInvalid && !!this.state.start && !!this.state.end && this.state.facility && this.state.facility.maxBookingDuration) {
            if ( (timeOfDayToSeconds(this.state.end) - timeOfDayToSeconds(this.state.start) )> this.state.facility.maxBookingDuration) {
                isInvalid = true;
            }
        }
        return (
            <div className="modal-column">
                <h3>{strings.title}</h3>
                <form ref="edit-booking-form">
                    <div className="modal-row">
                        <label htmlFor="date">{strings.date}</label>
                        <DatePicker selected={date} onChange={(date) => this.onDateChange(date)} customInput={this.getDateButton()}/>
                    </div>

                    <div className="modal-row">
                        <label htmlFor="start">{strings.startTime}</label>
                        <input
                            name="start"
                            value={start}
                            onChange={this.onChange}
                            type="time"
                            placeholder={strings.startTime}
                            required
                        />
                    </div>
                    <div className="modal-row">
                        <label htmlFor="end">{strings.endTime}</label>
                        <input
                            name="end"
                            value={end}
                            onChange={this.onChange}
                            type="time"
                            placeholder={strings.endTime}
                            required
                        />
                    </div>
                    { !!maxBookingDuration &&
                    <div className="modal-row">
                        <p> {strings.maxBookingDuration.replace('xxx', Math.floor(maxBookingDuration/ 60))}</p>
                    </div>
                    }
                    <div className="modal-row">
                        <label htmlFor="notes">{strings.takeNotes}</label>
                        <input
                            name="notes"
                            value={notes}
                            onChange={this.onChange}
                            type="text"
                            placeholder={strings.takeNotes}
                        />
                    </div>
                    <div className="modal-row">
                        <label htmlFor="slots">{strings.slots}</label>
                        <input
                            name="slots"
                            value={slots}
                            onChange={this.onChange}
                            type="number"
                            placeholder={strings.slots}
                            disabled={!!this.state.allSlots}
                        />
                    </div>
                    <div className="checkbox-row">
                        <input
                            name="allSlots"
                            value={allSlots}
                            onChange={this.onChange}
                            type="checkbox"
                            placeholder={strings.allSlots}
                        />
                        <label htmlFor="allslots">{strings.allSlots}</label>
                    </div>

                    {
                        this.state.repeatingAllowed &&   <div className="modal-row">
                            <label htmlFor="repetitionInterval">{strings.repeatLabel}</label>
                            <select className="repetition-type-input" name="repetitionInterval" onChange={this.onRepetitionIntervalChange} defaultValue={this.state.repetitionInterval}>
                                {
                                    Object.values(RepetitionInterval).map((interval) => {
                                        return(
                                            <option key={interval} value={interval}>
                                                {getTranslationsForRepetitionInterval()[interval]}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }

                    {
                        this.state.repeatingAllowed &&  this.getWeekSelection()
                    }
                    <button disabled={isInvalid} onClick={this.onSave}>{strings.save}</button>
                </form>
            </div>
        );
    }
}

const EditBookingPage = withRouter(WithAuthentication(WithFirebase(EditBooking)));
export default EditBookingPage;